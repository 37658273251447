/** @jsxImportSource @emotion/react */
import { useChipStyles } from "./chipStyles";
import { ReactNode } from "react";
import { BoxBaseProps, PaletteProps, useBoxStyles } from "@bilar/ui";

type ChipSize = "small" | "regular" | "large";
type ChipLayout = "squared" | "rounded";
type ChipTextAlign = "left" | "center" | "right";

type ChipProps = {
	children?: ReactNode;
	backgroundColor?: string;
} & BoxBaseProps &
	PaletteProps &
	ChipDefaultProps;

type ChipDefaultProps = {
	size: ChipSize;
	layout: ChipLayout;
	textAlign?: ChipTextAlign;
};

const defaultProps: ChipDefaultProps = {
	size: "regular",
	layout: "rounded",
	textAlign: "left",
};

const Chip = (props: ChipProps) => {
	const styles = useChipStyles(props);
	const boxStyles = useBoxStyles(props);

	if (props.palette && props.backgroundColor) {
		throw new Error(
			"You can't have both a palette property and a custom backgroundColor property!",
		);
	}

	return <span css={[styles.root, boxStyles]}>{props.children}</span>;
};

Chip.defaultProps = defaultProps;

export { Chip };
export type { ChipProps };
