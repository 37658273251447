import {
	DocumentData,
	QueryDocumentSnapshot,
	SnapshotOptions,
	WithFieldValue,
} from "firebase/firestore";
import { VehicleImage } from "@bilar/models";
import { PinturaImageState } from "@pqina/pintura";

/**
 * Convert all imageState's undefined values to null because Firestore doesn't support undefined.
 */
export const vehicleImageConverter = {
	toFirestore(image: WithFieldValue<VehicleImage>): DocumentData {
		const { imageState, ...filtered } = image;
		const pinturaState = imageState as PinturaImageState;

		// Set all undefined values to null
		Object.keys(pinturaState).forEach((key) => {
			const _key = key as keyof PinturaImageState;
			if (pinturaState[_key] === undefined) {
				// @ts-ignore - we need to set the value to null because Firestore doesn't support undefined
				pinturaState[_key] = null;
			}
		});

		return {
			...filtered,
			imageState: pinturaState,
		};
	},
	fromFirestore: function (
		snapshot: QueryDocumentSnapshot,
		options: SnapshotOptions,
	): VehicleImage {
		const data = snapshot.data(options)! as VehicleImage;
		const { imageState, ...filteredData } = data;

		// Set all null values to undefined
		Object.keys(imageState).forEach((key) => {
			const _key = key as keyof PinturaImageState;
			if (imageState[_key] === null) {
				imageState[_key] = undefined;
			}
		});

		return {
			...filteredData,
			imageState,
		};
	},
};
