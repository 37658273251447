import { useDividerStyles } from "./dividerStyles";
import { BoxBaseProps, HtmlTag, Tag, useBoxStyles } from "@bilar/ui";

type DividerVariant = "solid" | "dotted" | "dashed";

type DividerProps = {} & DividerDefaultProps & BoxBaseProps;

type DividerDefaultProps = {
	variant: DividerVariant;
	as: HtmlTag;
};

const defaultProps: DividerDefaultProps = {
	variant: "solid",
	as: "hr",
};

/** The `<Divider>` component separates and sections different content. */
const Divider = (props: DividerProps) => {
	const styles = useDividerStyles(props);
	const boxStyles = useBoxStyles(props);
	const { as, variant, ...filteredProps } = props;

	// To make sure the grid takes no space at all which would offset the grid we place it in a pseudo element without height and width
	// and then place on screen using absolute positioning
	return (
		<Tag
			as={as}
			css={[styles.root, boxStyles]}
			role="separator"
			{...filteredProps}
		/>
	);
};

Divider.defaultProps = defaultProps;

export { Divider };
export type { DividerProps };
