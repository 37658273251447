import { ClassifiedVehicle } from "@bilar/models";
import {
	getOldGalleryImages,
	getVehicleImages,
	OldGalleryImage,
	VehicleDetails,
} from "@bilar/features";
import { useEffect, useState } from "react";

type VehicleDetailsModalProps = {
	data: ClassifiedVehicle | undefined;
	id: string;
};

const VehicleDetailsModal = (props: VehicleDetailsModalProps) => {
	const { data, id } = props;
	const [images, setImages] = useState<OldGalleryImage[]>([]);

	useEffect(() => {
		fetchImages(id);
	}, []);

	const fetchImages = async (vehicleAdId: string) => {
		const vehicleImages = await getVehicleImages(vehicleAdId);

		// Get gallery images with processed urls
		const imageList = getOldGalleryImages(vehicleImages);

		setImages(imageList);
	};

	return <VehicleDetails data={data!} images={images} />;
};

export { VehicleDetailsModal };
export type { VehicleDetailsModalProps };
