import { CSSObject } from "@emotion/react";
import { useDesignTokens } from "@bilar/ui";

type ContentStyles = {
	list: ListStyles;
	orderedList: ListStyles;
	unOrderedList: ListStyles;
};

type ListStyles = {
	root: CSSObject;
	item: CSSObject;
};

const useContentStyles = (): ContentStyles => {
	const { space, palette } = useDesignTokens();
	return {
		list: {
			root: {
				// padding: space[4],
				// border: "1px solid red",
			},
			item: {
				margin: `${space[2]} 0 ${space[2]} ${space[4]}`,
			},
		},
		orderedList: {
			root: {
				// border: "1px solid blue",
				listStyleType: "decimal",
			},
			item: {},
		},
		unOrderedList: {
			root: {
				listStyleType: "disc",
				// border: "1px solid green",
			},
			item: {},
		},
	};
};

export { useContentStyles };
