/** @jsxImportSource @emotion/react */
import Link from "next/link";
import { routes } from "@bilar/config";
import { Divider } from "@bilar/ui";

type AdminNavProps = {};

const AdminNav = (props: AdminNavProps) => {
	return (
		<>
			<ul>
				<li>
					<Link href={routes.admin.index}>Admin</Link> |{" "}
					<Link href={routes.admin.brands}>Brands</Link> |{" "}
					<Link href={routes.admin.models}>Models</Link> |{" "}
					<Link href={routes.admin.vehicles}>Classified Vehicles</Link>
				</li>
			</ul>
			<Divider />
		</>
	);
};

export { AdminNav };
export type { AdminNavProps };
