/** @jsxImportSource @emotion/react */
import { isValidElement, ReactElement, ReactNode } from "react";
import {
	BaseProps,
	BoxBaseProps,
	Elevation,
	emotionClone,
	getTestId,
	Heading,
	HeadingSize,
	HtmlTag,
	PaletteProps,
	Paper,
	Tag,
	useBoxStyles,
} from "../../";
import { useMessageStyles } from "./messageStyles";
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

type MessageProps = {
	heading?: string;
	icon?: ReactElement<FontAwesomeIconProps>;
	children?: ReactNode;
} & MessageDefaultProps &
	BoxBaseProps &
	BaseProps;

type MessageDefaultProps = {
	as?: HtmlTag;
	headingAs?: HtmlTag;
	elevation?: Elevation;
} & PaletteProps;

/** Use the `<Message>` component to provide important information to users. */
const Message = ({
	as = "section",
	headingAs = "h3",
	palette = "neutral",
	elevation = "none",
	paletteShade = "light",
	...restProps
}: MessageProps) => {
	const props = {
		as,
		headingAs,
		palette,
		elevation,
		paletteShade,
		...restProps,
	};
	const { heading, testId, icon, children } = props;
	const boxStyles = useBoxStyles(props);
	const styles = useMessageStyles(props);
	const headingSize: HeadingSize = "regular";

	const iconElement = () => {
		// If no icon size is provided, set a default one based on the heading size
		if (isValidElement(icon) && icon.props.icon && !icon.props.size) {
			return emotionClone(icon, {
				size: 6,
			});
		}
		return icon;
	};

	const renderMessageComponent = () => (
		<Tag as={as} css={[styles.root, boxStyles]} {...restProps}>
			{icon && <span css={styles.iconWrapper}>{iconElement()}</span>}
			{heading && (
				<Heading
					as={headingAs}
					mb={0}
					size={headingSize}
					testId={getTestId(testId, "heading")}
					css={styles.heading}
				>
					{heading}
				</Heading>
			)}
			{children && <div css={styles.body}>{children}</div>}
		</Tag>
	);

	if (elevation !== "none") {
		return (
			<Paper
				as="div"
				elevation={elevation}
				variant="regular"
				testId={getTestId(testId, "root")}
			>
				{renderMessageComponent()}
			</Paper>
		);
	}

	return renderMessageComponent();
};

export { Message };
export type { MessageProps, MessageDefaultProps };
