const getTestId = (testId?: string, suffix?: string) => {
	if (!testId) return undefined;

	if (suffix) {
		return `${testId}-${suffix}`;
	}

	return testId;
};

export { getTestId };
