/** @jsxImportSource @emotion/react */
import * as React from "react";
import {
	cloneElement,
	forwardRef,
	HTMLProps,
	isValidElement,
	useRef,
	useState,
} from "react";
import type { Placement } from "@floating-ui/react";
import {
	arrow,
	autoUpdate,
	flip,
	FloatingArrow,
	FloatingPortal,
	offset,
	shift,
	useDismiss,
	useFloating,
	useFocus,
	useHover,
	useInteractions,
	useMergeRefs,
	useRole,
	useTransitionStyles,
} from "@floating-ui/react";
import { gridSystem, useDesignTokens } from "../../styling";
import { CSSObject } from "@emotion/react";
import { useTooltipStyles } from "./useTooltipStyles";
import {
	Button,
	ButtonProps,
	PaletteOptions,
	PaletteShadeOptions,
} from "@bilar/ui";

interface TooltipOptions {
	initialOpen?: boolean;
	placement?: Placement;
	open?: boolean;
	onOpenChange?: (open: boolean) => void;
}

export function useTooltip({
	initialOpen = false,
	placement = "top",
	open: controlledOpen,
	onOpenChange: setControlledOpen,
}: TooltipOptions = {}) {
	const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
	const tokens = useDesignTokens();
	const arrowRef = useRef<SVGSVGElement>(null);

	const open = controlledOpen ?? uncontrolledOpen;
	const setOpen = setControlledOpen ?? setUncontrolledOpen;

	const data = useFloating({
		placement,
		open,
		onOpenChange: setOpen,
		whileElementsMounted: autoUpdate,
		middleware: [
			offset(gridSystem.size),
			flip({
				crossAxis: placement.includes("-"),
				fallbackAxisSideDirection: "start",
				padding: gridSystem.size,
			}),
			shift({ padding: gridSystem.size }),
			arrow({
				element: arrowRef,
				padding: gridSystem.size,
			}),
		],
	});

	const context = data.context;

	const hover = useHover(context, {
		move: false,
		enabled: controlledOpen == null,
	});
	const focus = useFocus(context, {
		enabled: controlledOpen == null,
	});
	const dismiss = useDismiss(context);
	const role = useRole(context, { role: "tooltip" });

	const interactions = useInteractions([hover, focus, dismiss, role]);

	return React.useMemo(
		() => ({
			open,
			setOpen,
			...interactions,
			...data,
			arrowRef,
		}),
		[open, setOpen, interactions, data],
	);
}

type ContextType = ReturnType<typeof useTooltip> | null;

const TooltipContext = React.createContext<ContextType>(null);

export const useTooltipContext = () => {
	const context = React.useContext(TooltipContext);

	if (context == null) {
		throw new Error("Tooltip components must be wrapped in <Tooltip />");
	}

	return context;
};

export function Tooltip({
	children,
	...options
}: { children: React.ReactNode } & TooltipOptions) {
	// This can accept any props as options, e.g. `placement`,
	// or other positioning options.
	const tooltip = useTooltip(options);
	return (
		<TooltipContext.Provider value={tooltip}>
			{children}
		</TooltipContext.Provider>
	);
}

type PropsWithButton = {
	children: string;
} & ButtonProps;

type PropsWithoutButton = {
	children: React.ReactElement;
};

type TooltipTriggerProps = React.HTMLProps<HTMLElement> &
	(PropsWithButton | PropsWithoutButton);

export const TooltipTrigger = forwardRef<HTMLElement, TooltipTriggerProps>(
	(props, propRef) => {
		const { children, ...rest } = props;
		const context = useTooltipContext();
		const childrenRef = (children as any).ref;
		const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

		if (
			(typeof children === "function" || typeof children === "object") &&
			isValidElement(children)
		) {
			return cloneElement(
				children,
				context.getReferenceProps({
					ref,
					...rest,
					...(children.props as {}),
				}),
			);
		}

		return (
			<Button ref={ref} {...context.getReferenceProps(props)}>
				{children}
			</Button>
		);
	},
);

TooltipTrigger.displayName = "TooltipTrigger";

type TooltipContentProps = HTMLProps<HTMLDivElement> & {
	css?: CSSObject;
	palette?: PaletteOptions;
	shade?: PaletteShadeOptions;
};

export const TooltipContent = forwardRef<HTMLDivElement, TooltipContentProps>(
	function TooltipContent(
		{ css, palette = "neutral", shade = "dark", children, ...props },
		propRef,
	) {
		const styles = useTooltipStyles(palette, shade);
		const { size, palette: tokensPalette } = useDesignTokens();
		const paletteShade = tokensPalette[palette][shade];
		const { context, getFloatingProps, arrowRef } = useTooltipContext();
		const { isMounted, styles: transitionStyles } = useTransitionStyles(
			context,
			{
				initial: ({ side }) => ({
					opacity: 0,
					translate:
						side === "top"
							? `0 ${size(1)}`
							: side === "bottom"
							? `0 -${size(1)}`
							: 0,
				}),
				close: ({ side }) => ({
					opacity: 0,
					translate:
						side === "top"
							? `0 ${size(1)}`
							: side === "bottom"
							? `0 -${size(1)}`
							: 0,
				}),
			},
		);
		const ref = useMergeRefs([context.refs.setFloating, propRef]);

		return (
			<FloatingPortal>
				{isMounted && (
					<div
						ref={ref}
						css={context.floatingStyles as CSSObject}
						{...getFloatingProps(props)}
					>
						<div css={[styles, css, transitionStyles as CSSObject]}>
							{children}
							<FloatingArrow
								ref={arrowRef}
								context={context}
								fill={paletteShade.background}
								tipRadius={2}
								strokeWidth={0}
								height={4}
								width={8}
							/>
						</div>
					</div>
				)}
			</FloatingPortal>
		);
	},
);
