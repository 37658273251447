/** @jsxImportSource @emotion/react */
import { CSSObject } from "@emotion/react";
import React, {
	AnchorHTMLAttributes,
	forwardRef,
	KeyboardEventHandler,
	MouseEventHandler,
	ReactNode,
	Ref,
} from "react";
import { DesignTokens } from "../../styling";

import { getHtmlProps, getTestId } from "../../utils";
import { BoxBaseProps, useDesignTokens } from "@bilar/ui";
import { BaseProps, InvisibleButton } from "..";

type LinkProps = {
	children: ReactNode;
	href?: string;
	underline?: boolean;
	cropAndAlignBaseline?: boolean;
	target?: "_blank" | "_self" | "_parent" | "_top";
	// Note: Couldn't use MouseEventHandler<HTMLElement> as button does because of conflicts with CustomMenuButton
	onClick?: (e: any) => void; // Needed for NextJS
	onKeyDown?: KeyboardEventHandler<HTMLElement>;
	onMouseDown?: MouseEventHandler<HTMLElement>;
	onMouseEnter?: MouseEventHandler<HTMLElement>;
	onMouseLeave?: MouseEventHandler<HTMLElement>;
} & LinkDefaultProps &
	BoxBaseProps &
	BaseProps &
	AnchorHTMLAttributes<HTMLAnchorElement>;

type LinkDefaultProps = {};

const defaultProps: LinkDefaultProps = {
	underline: true,
	cropAndAlignBaseline: true,
};

// Define all styles that needs to "reset" the styles from a global stylesheet
// For example if margin is set for h1, h2 etc in the global css that the site uses we want to reset it for our component.
const getResetForExternalStyles = (tokens: DesignTokens): CSSObject => {
	return {
		display: "inline",
		color: "inherit",
	};
};
const getUnderLineStyles = (
	props: LinkProps,
	tokens: DesignTokens,
): CSSObject => {
	return {
		textDecoration: props.underline ? "underline" : "none",
		textDecorationColor: tokens.palette.primary.main.background,
		textDecorationThickness: 2,
		textUnderlineOffset: 4,
		":hover": {
			textDecorationThickness: 2,
		},
	};
};

const getStyles = (props: LinkProps, tokens: DesignTokens): CSSObject => ({
	...getResetForExternalStyles(tokens),
	display: "inline-block",
	color: tokens.palette.neutral.dark.background,
	...getUnderLineStyles(props, tokens),
	":hover": {
		color: tokens.palette.neutral.darker.background,
	},
	":focus": {
		textDecoration: "none",
		outline: "auto",
	},
});

// Note:
// Do we need icons for the links? Document icons? External link icons?
// https://developers.google.com/web/tools/lighthouse/audits/noopener
// https://www.youtube.com/watch?v=aEDO_C_AI-0

// TODO: Add rel="noopener" on external links
// https://mathiasbynens.github.io/rel-noopener/

/**
 * The `<Link>` component specifies relationships between the current document and an external resource.
 *
 * Further reading [Accessibility best practices for links](http://atlas/confluence/display/DEV/Accessibility+best+practices#Accessibilitybestpractices-Links).
 * */

// We must reclare href to be required in this case because the NextJsLink requires it when passing href
// const NextLink = (props: LinkProps & { href: string }) => {
// 	return (
// 		<NextJsLink href={props.href} passHref>
// 			<Link {...props} />
// 		</NextJsLink>
// 	);
// };

type LinkWrapper = (props: { href?: string }) => JSX.Element;

// Forward ref is needed for the NextJSLink to be able to forward it's href tag etc
const Link = forwardRef((props: LinkProps, ref: Ref<HTMLAnchorElement>) => {
	const designTokens = useDesignTokens();
	const styles = getStyles(props, designTokens);
	const { testId, cropAndAlignBaseline } = props;

	// If we haven't set href and only onClick we render it as a button instead for a11y reasons etc
	if (props.href === undefined && props.onClick !== undefined) {
		return (
			<InvisibleButton
				css={styles}
				id={props.id}
				ref={ref}
				className={props.className}
				testId={getTestId(testId)}
				onClick={props.onClick}
				onKeyDown={props.onKeyDown}
				onMouseDown={props.onMouseDown}
				onMouseEnter={props.onMouseEnter}
				onMouseLeave={props.onMouseLeave}
				{...getHtmlProps(props)}
			>
				{props.children}
			</InvisibleButton>
		);
	}

	return (
		<a
			href={props.href}
			css={styles}
			ref={ref}
			id={props.id}
			className={props.className}
			data-testid={getTestId(testId)}
			onClick={props.onClick}
			onKeyDown={props.onKeyDown}
			onMouseDown={props.onMouseDown}
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}
			{...getHtmlProps(props)}
			{...getTargetProps(props)}
		>
			{props.children}
		</a>
	);
});

Link.displayName = "Link";
Link.defaultProps = defaultProps;

const getTargetProps = (props: LinkProps) => {
	if (!props.target) {
		return {};
	}
	return {
		target: props.target,
		rel: "noreferrer noopener",
	};
};

export { Link };
export type { LinkProps, LinkWrapper };
