import { DesignTokens } from "../..";
import { CSSObject } from "@emotion/react";
import chroma from "chroma-js";
import { OutlineSize, StylesTokens } from "./types";
import { PaletteOptions, PaletteShadeOptions } from "../../types";

const styles: StylesTokens = {
	getFocusOutlineStyles(
		rest: CSSObject,
		hex: string,
		size?: OutlineSize,
	): CSSObject {
		let rgbColor = chroma("white").rgb();

		if (hex) {
			rgbColor = chroma(hex).rgb();
		}
		const defaultBoxShadow = "0 0 3px 2px";
		let boxShadow = "0 0 3px 2px";
		if (size) {
			switch (size) {
				case "small":
					boxShadow = "0 0 3px 2px";
					break;
				case "large":
					boxShadow = defaultBoxShadow;
					break;
				default:
					boxShadow = defaultBoxShadow;
					break;
			}
		}

		return {
			outline: "none",
			boxShadow: `${boxShadow} rgba(${rgbColor}, .5)`,
			...rest,
		};
	},
	focusOutline(rest: CSSObject, hex: string, size?: OutlineSize): CSSObject {
		return {
			"&:focus:not(:active)": {
				...styles.getFocusOutlineStyles(rest, hex, size),
			},
		};
	},
	focusOutlineReset: {
		"&:focus": {
			outline: "none",
			boxShadow: "none",
		},
	},
	getPaletteShadeStyles: (
		palette: PaletteOptions,
		shade: PaletteShadeOptions,
	) => {
		return {};
	},
	getPaletteStyles: (
		tokens: DesignTokens,
		paletteProp?: PaletteOptions,
		paletteShadeProp?: PaletteShadeOptions,
		hoverStyles?: boolean,
	): CSSObject => {
		const { highlight, primary, secondary, success, error, neutral } =
			tokens.palette;

		// TODO: Add Shade

		const getHoverStyles = (backgroundColor: string, color: string) => {
			if (!hoverStyles) return {};

			const hoverBg = chroma(backgroundColor).darken(0.5).hex();

			return {
				"&:hover": {
					backgroundColor: hoverBg,
					color: color,
				},
			};
		};

		switch (paletteProp) {
			case "neutral":
				return {
					backgroundColor: neutral.lighter.background,
					color: neutral.lighter.contrast,
					...getHoverStyles(
						neutral.lighter.background,
						neutral.lighter.contrast,
					),
				};
			case "highlight":
				return {
					backgroundColor: highlight.main.background,
					color: highlight.main.contrast,
					...getHoverStyles(highlight.main.background, highlight.main.contrast),
				};
			case "primary":
				return {
					backgroundColor: primary.main.background,
					color: primary.main.contrast,
					...getHoverStyles(primary.main.background, primary.main.contrast),
				};
			case "secondary":
				return {
					backgroundColor: secondary.main.background,
					color: secondary.main.contrast,
					...getHoverStyles(secondary.main.background, secondary.main.contrast),
				};
			case "success":
				return {
					backgroundColor: success.main.background,
					color: success.main.contrast,
					...getHoverStyles(success.main.background, success.main.contrast),
				};
			case "error":
				return {
					backgroundColor: error.main.background,
					color: error.main.contrast,
					...getHoverStyles(error.main.background, error.main.contrast),
				};
			default:
				return {
					backgroundColor: primary.lighter.background,
					color: primary.light.contrast,
					...getHoverStyles(primary.main.background, primary.main.contrast),
				};
		}
	},
};

export default styles;
