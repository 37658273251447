/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import {
	FormHeader,
	FormSteps,
	SignIn,
	StepBasicInfo,
	StepImages,
	StepSummary,
	StepVehicleDesign,
	StepVehicleDetails,
	useVehicleFormContext,
	useVehicleFormStyles,
	vehicleFormNumberOfSteps,
} from "@bilar/features";
import { AuthStatus, useAuthManagement, useAuthUser } from "@bilar/auth";
import {
	Box,
	FaIcon,
	Heading,
	Message,
	NextLink,
	Panel,
	Stack,
} from "@bilar/ui";
import { AnimatePresence, motion, Transition, Variants } from "framer-motion";
import { useAppTranslation } from "@bilar/common";
import { useRouter } from "next/router";
import { routes } from "@bilar/config";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { StringParam, useQueryParam } from "use-query-params";
import posthog from "posthog-js";

type VehicleFormProps = {
	title: string;
	signInReturnRoute: string;
};

const getStepName = (step: number) => {
	switch (step) {
		case 1:
			return "Basic Information";
		case 2:
			return "Vehicle Design";
		case 3:
			return "Vehicle Details";
		case 4:
			return "Photos";
		case 5:
			return "Summary";
	}
};

const VehicleForm = (props: VehicleFormProps) => {
	const { signInReturnRoute, title } = props;
	const { t } = useAppTranslation();
	const { user } = useAuthUser();
	const { authStatus } = useAuthManagement();
	const router = useRouter();
	const stepParam = router.query.step as string;
	const [[step, direction], setStep] = useState<[number, number]>([1, 0]);
	const styles = useVehicleFormStyles(props);
	const { formValues } = useVehicleFormContext();
	const [createdId, setCreatedId] = useQueryParam("createdId", StringParam);
	const loadStepEventName = "Vehicle Form Step";

	const slideVariants: Variants = {
		enter: (direction: number) => {
			return {
				y: 0,
				x: direction < 0 ? 1000 : -1000,
				opacity: 0,
			};
		},
		final: {
			x: 0,
			y: 0,
			opacity: 1,
		},
		exit: (direction: number) => {
			return {
				y: 0,
				x: direction > 0 ? 1000 : -1000,
				opacity: 0,
			};
		},
	};

	const slideTransition: Transition = {
		x: {
			type: "spring",
			stiffness: 300,
			damping: 30,
		},
	};

	const setStepParam = (step: string) => {
		router.push({
			pathname: router.pathname,
			query: { ...router.query, step },
		});
	};

	useEffect(() => {
		posthog.capture(loadStepEventName, {
			stepNumber: 1,
			stepName: getStepName(1),
		});
	}, []);

	useEffect(() => {
		if (stepParam) {
			const parsedStepNumber = parseInt(stepParam);
			if (isNaN(parsedStepNumber)) {
				return;
			}

			const newDirection = parsedStepNumber < step ? 1 : -1;

			let newStep = parsedStepNumber;

			// If the user went back in history after saving the vehicle, we need to set the step as 1
			if (
				formValues.stepImagesValues.images.length === 0 &&
				parsedStepNumber === 5
			) {
				newStep = 1;
			}

			setStep([newStep, newDirection]);
			posthog.capture(loadStepEventName, {
				stepNumber: newStep,
				stepName: getStepName(newStep),
				stepDirection: newDirection > 0 ? "back" : "forward",
			});
		}
	}, [stepParam, formValues]);

	useEffect(() => {
		if (createdId) {
			posthog.capture(loadStepEventName, {
				stepNumber: 6,
				stepName: "Success",
			});
		}
	}, [createdId]);

	const handleSignInSuccess = (authStatus: AuthStatus) => {
		if (authStatus === "signInComplete") {
			router.push(`${signInReturnRoute}?step=1`);
		}
	};

	if (!user || authStatus === "completeProfile") {
		return (
			<Stack horizontalAlignment="center">
				<Box width={["100%", "50%"]}>
					<Panel.Body>
						<Heading as="h1" size="regular">
							{t("signInSignup")}
						</Heading>
						<Box as="p" mb={4}>
							{t("pleaseLoginToPostAd")}
						</Box>
						<SignIn
							returnRoute={routes.vehicleClassifieds.new}
							hideHeader={true}
							onSignInSuccess={handleSignInSuccess}
						/>
					</Panel.Body>
				</Box>
			</Stack>
		);
	}

	if (createdId) {
		return (
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 1 }}
			>
				<Message
					heading={t("classifiedHasBeenCreated")}
					palette="success"
					icon={<FaIcon icon={faCircleCheck} />}
				>
					<p>{t("timeToEffectMessage")}</p>
					{createdId && (
						<NextLink href={`${routes.vehicleClassifieds.index}/${createdId}`}>
							{t("viewYourAd")}
						</NextLink>
					)}
				</Message>
			</motion.div>
		);
	}

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 1 }}
		>
			<Panel css={styles.root}>
				<aside css={styles.aside}>
					<Heading as="h2" mb={0}>
						{title}
					</Heading>
					<FormSteps currentStep={step} />
				</aside>

				<section css={styles.main}>
					<FormHeader
						titles={[
							t("stepBasicInformation"),
							t("stepVehicleDesign"),
							t("stepVehicleDetails"),
							t("stepVehiclePhotos"),
							t("stepAlmostDone"),
						]}
						step={step}
						numberOfSteps={vehicleFormNumberOfSteps}
					/>
					<AnimatePresence initial={false} custom={direction} mode="wait">
						<motion.div
							key={step}
							custom={direction}
							variants={slideVariants}
							initial="enter"
							animate="final"
							exit="exit"
							transition={slideTransition}
						>
							{step === 1 && (
								<StepBasicInfo onContinue={() => setStepParam("2")} />
							)}

							{step === 2 && (
								<StepVehicleDesign
									key="stepVehicleDesign"
									onBack={() => setStepParam("1")}
									onContinue={() => setStepParam("3")}
								/>
							)}

							{step === 3 && (
								<StepVehicleDetails
									key="stepVehicleDetails"
									onBack={() => setStepParam("2")}
									onContinue={() => setStepParam("4")}
								/>
							)}

							{step === 4 && (
								<StepImages
									key="stepImages"
									onBack={() => setStepParam("3")}
									onContinue={() => setStepParam("5")}
								/>
							)}

							{step === 5 && (
								<StepSummary
									key="stepSummary"
									onBack={() => setStepParam("4")}
									onContinue={() => {
										alert("submit");
									}}
								/>
							)}
						</motion.div>
					</AnimatePresence>
				</section>
			</Panel>
		</motion.div>
	);
};

export { VehicleForm };
export type { VehicleFormProps };
