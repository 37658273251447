/** @jsxImportSource @emotion/react */
import { useMenuLinkStyles } from "./menuStyles";
import {
	MenuItem as ReachMenuItem,
	MenuItemProps as ReachMenuItemProps,
} from "@reach/menu-button";
import { ResponsiveProp, SpacingSize } from "../../styling";
import { BoxBaseProps, useBoxStyles } from "../layout/Box/boxStyles";
import { BaseProps } from "../index";

type MenuItemDefaultProps = {
	py: ResponsiveProp<SpacingSize>;
};

const defaultProps: MenuItemDefaultProps = {
	py: 2,
};

type MenuItemProps = {
	as?: any;
} & MenuItemDefaultProps &
	Partial<ReachMenuItemProps> &
	BoxBaseProps &
	BaseProps;

/**
 * The `<MenuItem>` component allows any content inside it.
 */
const MenuItem = (props: MenuItemProps) => {
	const styles = useMenuLinkStyles();
	const boxStyles = useBoxStyles(props);
	const { as, ...filteredProps } = props;

	return (
		<ReachMenuItem
			as={as}
			onSelect={() => {}} // This will be overwritten by the spreading of the props if needed.
			css={[styles, boxStyles]}
			{...filteredProps}
		>
			{props.children}
		</ReachMenuItem>
	);
};

MenuItem.defaultProps = defaultProps;

export { MenuItem };
export type { MenuItemProps };
