/** @jsxImportSource @emotion/react */
import { SerializedEditorState } from "lexical";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";

import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { TRANSFORMERS } from "@lexical/markdown";

import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import React, { useEffect, useRef, useState } from "react";
import { LexicalEditor } from "lexical/LexicalEditor";
import { editorConfig, EMPTY_EDITOR_STATE } from "./editorConfig";
import {
	BaseProps,
	FieldLabel,
	Stack,
	useAutoId,
	ValidationMessage,
} from "@bilar/ui";
import { useEditorStyles } from "./useEditorStyles";
import { EditorBlurPlugin } from "./plugins/EditorBlurPlugin";
import { Placeholder } from "./Placeholder";
import { useAppTranslation } from "@bilar/common";
import { EditorInstancePlugin } from "./plugins/EditorInstancePlugin";

type Props = {
	label?: string;
	value: SerializedEditorState | undefined | null;
	onChange: (value: SerializedEditorState) => void;
	onBlur?: (value: SerializedEditorState) => void;
	error?: string;
} & BaseProps;

export const RichTextEditor = (props: Props) => {
	const { label, error, onChange, onBlur } = props;
	const [editor, setEditor] = useState<LexicalEditor>();
	const id = useAutoId("rich-text-field", props.id);
	const styles = useEditorStyles();
	const { t } = useAppTranslation("editor");
	const initialContentSetRef = useRef(false);

	let value = EMPTY_EDITOR_STATE as unknown as SerializedEditorState;
	if (props.value) {
		value = props.value;
	}

	useEffect(() => {
		if (editor && !initialContentSetRef.current) {
			try {
				const newEditorState = editor.parseEditorState(value);
				editor.setEditorState(newEditorState);
				initialContentSetRef.current = true;
			} catch (error) {
				console.error(error);
				// TODO: Handle error
			}
		}
	}, [value, editor, initialContentSetRef.current]);

	return (
		<div>
			<LexicalComposer initialConfig={editorConfig}>
				<>
					{label && (
						<FieldLabel
							className="field-label"
							htmlFor={id}
							onClick={() => editor?.focus()}
						>
							{label}
						</FieldLabel>
					)}
				</>
				<Stack spacing={2} className="editor">
					<div css={styles.toolbar}>
						<ToolbarPlugin />
					</div>
					<div css={styles.inputWrapper}>
						<RichTextPlugin
							contentEditable={
								<ContentEditable
									css={[styles.input, styles.richText]}
									id={id}
								/>
							}
							ErrorBoundary={LexicalErrorBoundary}
							placeholder={
								<Placeholder>{t("richTextEditorPlaceholder")}</Placeholder>
							}
						/>
					</div>

					<OnChangePlugin onChange={(state) => onChange(state.toJSON())} />
					{onBlur && (
						<EditorBlurPlugin onBlur={(state) => onBlur(state.toJSON())} />
					)}
					<HistoryPlugin />
					<ListPlugin />
					<LinkPlugin />
					<AutoLinkPlugin />
					<EditorInstancePlugin onChange={setEditor} />
					<MarkdownShortcutPlugin transformers={TRANSFORMERS} />
					{error && <ValidationMessage error={error} />}
				</Stack>
			</LexicalComposer>
		</div>
	);
};
