// Note: Since we have our base font size set to the browser's defaults (16px)
// Our baseline grid becomes a grid of 4px

import { SpacingSize } from "@bilar/ui";

export type SpacingSizeType = {
	[key in SpacingSize]: string;
};

export const size = (unit: number): string => {
	return `${(unit * 0.25).toString()}rem`;
};

export const spacingSizes: SpacingSizeType = {
	0: "0",
	1: "0.25rem",
	2: "0.5rem",
	3: "0.75rem",
	4: "1rem",
	5: "1.25rem",
	6: "1.5rem",
	8: "2rem",
	10: "2.5rem",
	12: "3rem",
	16: "4rem",
	18: "4.5rem",
	20: "5rem",
	24: "6rem",
	28: "7rem",
	32: "8rem",
	36: "9rem",
	40: "10rem",
	48: "12rem",
	56: "14rem",
	64: "16rem",
	72: "18rem",
	80: "20rem",
	96: "24rem",
	112: "32rem",
	128: "32rem",
	160: "48rem",
	192: "48rem",
	224: "48rem",
	256: "64rem",
	320: "64rem",
};
