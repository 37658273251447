import { AppProviderContext } from "../../index";
import { useContext } from "react";

export const useAppContext = () => {
	const context = useContext(AppProviderContext);
	if (context === undefined) {
		throw new Error("useAppContext must be used within a AppProvider");
	}
	return context;
};
