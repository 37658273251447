import React, {
	DetailedHTMLProps,
	forwardRef,
	HTMLAttributes,
	ReactNode,
} from "react";
import { HtmlTag } from "../../types";
import { BaseProps, getTestId } from "@bilar/ui";

type TagProps = {
	as: HtmlTag;
	children?: ReactNode;
} & BaseProps &
	DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

const Tag = forwardRef<HTMLElement, TagProps>((props: TagProps, ref) => {
	const TagName = props.as;

	// Filter out some props such as "as"
	const { as, testId, ...includedProps } = props;

	// It complains about some properties that don't exist on all the supported types,
	// not sure how to solve if a more generic props type exists, will ignore for now /Johan
	return (
		//@ts-ignore
		<TagName ref={ref} {...includedProps} data-testid={getTestId(testId)}>
			{props.children}
		</TagName>
	);
});

Tag.displayName = "Tag";

// Add all your exports here
export { Tag };
export type { TagProps };
