export * from "./htmlUtils";
export * from "./isNilOrEmpty";
export * from "./objectUtils";
export * from "./testingUtils";
export * from "./emotionClone";
export * from "./numberUtils";
export * from "./numberHooks";
export * from "./dateUtils";
export * from "./deviceUtils";

//------------------ Hooks ------------------
export * from "./useAutoId";
export * from "./useCurrency";

//------------------ Utils ------------------
const getMapValue = (obj: any, key: any) => {
	if (!key) return 0;
	if (obj.hasOwnProperty(key)) return obj[key];
	throw new Error("Invalid sizeKey!");
};

export { getMapValue };
