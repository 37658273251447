import { collections } from "@bilar/config";
import initFirebase from "@bilar/firebase/initFirebase";
import { useCallback, useEffect, useState } from "react";
import { VehicleBrand, VehicleModel } from "@bilar/models";

import {
	collection,
	getDocs,
	getFirestore,
	query,
	where,
} from "firebase/firestore";
import { DropdownOption } from "@bilar/ui";

const useVehicleBrands = () => {
	const [brands, setBrands] = useState<VehicleBrand[]>([]);
	const [selectedBrand, setSelectedBrand] = useState<VehicleBrand | null>(null);
	const firebaseApp = initFirebase();
	const db = getFirestore(firebaseApp);

	useEffect(() => {
		getVehicleBrands();
	}, []);

	const getVehicleBrands = async () => {
		const q = query(
			collection(db, collections.VEHICLES),
			where("disabled", "!=", true),
		);

		const querySnapshot = await getDocs(q);

		//const data = await db.collection(collections.VEHICLES).get();
		const docs = querySnapshot.docs;
		const brandsData = docs.map((snapshot) => {
			return snapshot.data() as VehicleBrand;
		});

		setBrands(brandsData);
	};

	const getVehicleBrandById = (id: string): VehicleBrand | undefined => {
		// console.log("trying to find", id);

		if (brands.length <= 0) {
			console.log("NO BRANDS FOUND");
		}

		return brands.find((item) => item.id === id);
	};

	const mapBrandToDropdownOption = useCallback(
		(brand: VehicleBrand): DropdownOption => ({
			label: brand.name,
			value: brand.id,
		}),
		[],
	);

	const _setSelectedBrand = (brandId: string | null) => {
		if (brandId === null) {
			setSelectedBrand(null);
			return;
		}

		const brand = brands.find((item) => item.id === brandId);

		if (brand) {
			setSelectedBrand(brand);
		}
	};

	return {
		brands,
		getVehicleBrands,
		getVehicleBrandById,
		mapBrandToDropdownOption,
		selectedBrand,
		setSelectedBrand: _setSelectedBrand,
	};
};

const useVehicleModels = () => {
	const [brandModels, setModels] = useState<VehicleModel[]>([]);
	const [selectedModel, setSelectedModel] = useState<VehicleModel>();
	const firebaseApp = initFirebase();
	const db = getFirestore(firebaseApp);

	const getVehicleModels = async (brandId: string) => {
		const q = query(
			collection(
				db,
				`${collections.VEHICLES}/${brandId}/${collections.VEHICLES_MODELS}`,
			),
			where("disabled", "!=", true),
		);

		const querySnapshot = await getDocs(q);

		const docs = querySnapshot.docs;
		const modelsData = docs.map((snapshot) => {
			return snapshot.data() as VehicleModel;
		});

		setModels(modelsData);
	};

	const _setSelectedModel = (modelId: string | null) => {
		if (modelId === null) {
			setSelectedModel(undefined);
			return;
		}

		const model = brandModels.find((item) => item.id === modelId);

		if (model) {
			setSelectedModel(model);
		}
	};

	const getVehicleModelById = (
		id: string,
		fetch: boolean = false,
	): VehicleModel | undefined => {
		return brandModels.find((item) => item.id === id);
	};

	const mapToDropdownOption = useCallback(
		(brand: VehicleModel): DropdownOption => ({
			label: brand.modelName,
			value: brand.id,
		}),
		[],
	);

	return {
		brandModels,
		setModels,
		getVehicleModels,
		getVehicleModelById,
		selectedModel,
		setSelectedModel: _setSelectedModel,
		mapToDropdownOption,
	};
};

export { useVehicleBrands, useVehicleModels };
