import { useAuthUser } from "@bilar/auth";
import { collections } from "@bilar/config";
import { useState } from "react";
import {
	doc,
	getDoc,
	getFirestore,
	serverTimestamp,
	setDoc,
	writeBatch,
} from "firebase/firestore";
import { User } from "@bilar/models";
import { useUploadFile } from "react-firebase-hooks/storage";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import initFirebase from "@bilar/firebase/initFirebase";

const useUserProfile = () => {
	const { user, firebaseUser } = useAuthUser();
	const firebaseApp = initFirebase();
	const db = getFirestore(firebaseApp);
	const [profileLoading, setProfileLoading] = useState(false);
	const [uploadFile, uploading, snapshot, error] = useUploadFile();
	const storage = getStorage(firebaseApp);

	const uploadProfileImage = async () => {
		if (!user) {
			throw new Error("User not logged in");
		}

		// Get the user's profile image and upload it to Firebase Storage
		if (user.profileImageUrl) {
			const imagePath = `${user.userId}/images/profile`;
			const fileRef = ref(storage, imagePath);

			// Convert the profile image into a file
			const response = await fetch(user.profileImageUrl);
			const blob = await response.blob();
			const file = new File([blob], "profileImage.jpg", {
				type: "image/jpeg",
			});

			await uploadFile(fileRef, file);
			return await getDownloadURL(fileRef);
		}

		return;
	};

	const updateProfile = async (user: User) => {
		setProfileLoading(true);
		const newOrUpdatedUser = { ...user };
		newOrUpdatedUser.updatedAt = serverTimestamp();

		const docRef = doc(db, collections.USERS, user.userId);
		const docSnap = await getDoc(docRef);

		// Check if exists
		if (!docSnap.exists()) {
			newOrUpdatedUser.createdAt = serverTimestamp();
			if (user.profileImageUrl) {
				newOrUpdatedUser.profileImageUrl = await uploadProfileImage();
			}
		}

		// Update or Create
		await setDoc(docRef, newOrUpdatedUser, { merge: true });

		try {
			await updateLoginProviderDetails(user.userId);
		} catch (e) {
			console.log("Error with batch", e);
		}

		setProfileLoading(false);
	};

	const updateLoginProviderDetails = async (userId: string) => {
		if (!firebaseUser?.providerData.length) {
			return;
		}
		const batch = writeBatch(db);

		firebaseUser.providerData.map((provider) => {
			const docRef = doc(
				db,
				`${collections.USERS}/${userId}/${collections.USERS_LOGIN_PROVIDERS}/${provider?.providerId}`,
			);

			// const docRef = db
			// 	.collection(collections.USERS)
			// 	.doc(userId)
			// 	.collection(collections.USERS_LOGIN_PROVIDERS)
			// 	.doc(provider?.providerId);

			batch.set(docRef, { ...provider });
		});

		return batch.commit();
	};

	return {
		profileLoading,
		updateProfile,
		updateLoginProviderDetails,
		uploadProfileImage,
	};
};

export { useUserProfile };
