/** @jsxImportSource @emotion/react */

import { Heading, VehicleBodyCheckbox } from "@bilar/ui";
import { useAppTranslation } from "@bilar/common";
import { useDesignOptionsStyles, useSearch } from "@bilar/features";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { VehicleBodyType } from "@bilar/models";

type DesignOptionsProps = {
	open: boolean;
};

const DesignOptions = (props: DesignOptionsProps) => {
	const { open } = props;
	const { t } = useAppTranslation();
	const styles = useDesignOptionsStyles(props);
	const { searchState, setSearchState } = useSearch();
	const [bodyTypes, setBodyTypes] = useState<VehicleBodyType[]>(
		searchState.vehicleBodyTypes || [],
	);

	useEffect(() => {
		if (searchState.vehicleBodyTypes == null) {
			setBodyTypes([]);
		}
	}, [searchState]);

	const handleChange = (name: VehicleBodyType, checked: boolean) => {
		let newState: VehicleBodyType[] = [];
		if (checked) {
			newState = [...bodyTypes, name];
		} else {
			newState = bodyTypes.filter((item) => item !== name);
		}
		setBodyTypes(newState);

		setSearchState({
			...searchState,
			vehicleBodyTypes: newState.length > 0 ? newState : null,
		});
	};

	return (
		<motion.div key="advanced-options" css={styles.root}>
			<Heading as="h3" size="small">
				{t("stepVehicleDesign")}
			</Heading>

			<div css={styles.list}>
				<VehicleBodyCheckbox
					bodyType="micro"
					text={t("bodyType_micro")}
					checked={bodyTypes.includes("micro")}
					onChange={(name, checked) => handleChange(name, checked)}
				/>
				<VehicleBodyCheckbox
					bodyType="coupe"
					text={t("bodyType_coupe")}
					checked={bodyTypes.includes("coupe")}
					onChange={(name, checked) => handleChange(name, checked)}
				/>
				<VehicleBodyCheckbox
					bodyType="sedan"
					text={t("bodyType_sedan")}
					checked={bodyTypes.includes("sedan")}
					onChange={(name, checked) => handleChange(name, checked)}
				/>
				<VehicleBodyCheckbox
					bodyType="hatchback"
					text={t("bodyType_hatchback")}
					checked={bodyTypes.includes("hatchback")}
					onChange={(name, checked) => handleChange(name, checked)}
				/>
				<VehicleBodyCheckbox
					bodyType="liftback"
					text={t("bodyType_liftback")}
					checked={bodyTypes.includes("liftback")}
					onChange={(name, checked) => handleChange(name, checked)}
				/>
				<VehicleBodyCheckbox
					bodyType="station-wagon"
					text={t("bodyType_station-wagon")}
					checked={bodyTypes.includes("station-wagon")}
					onChange={(name, checked) => handleChange(name, checked)}
				/>
				<VehicleBodyCheckbox
					bodyType="crossover"
					text={t("bodyType_crossover")}
					checked={bodyTypes.includes("crossover")}
					onChange={(name, checked) => handleChange(name, checked)}
				/>
				<VehicleBodyCheckbox
					bodyType="suv"
					text={t("bodyType_suv")}
					checked={bodyTypes.includes("suv")}
					onChange={(name, checked) => handleChange(name, checked)}
				/>
				<VehicleBodyCheckbox
					bodyType="pickup"
					text={t("bodyType_pickup")}
					checked={bodyTypes.includes("pickup")}
					onChange={(name, checked) => handleChange(name, checked)}
				/>
				<VehicleBodyCheckbox
					bodyType="minivan"
					text={t("bodyType_minivan")}
					checked={bodyTypes.includes("minivan")}
					onChange={(name, checked) => handleChange(name, checked)}
				/>
				<VehicleBodyCheckbox
					bodyType="van"
					text={t("bodyType_van")}
					checked={bodyTypes.includes("van")}
					onChange={(name, checked) => handleChange(name, checked)}
				/>
				<VehicleBodyCheckbox
					bodyType="camper"
					text={t("bodyType_camper")}
					checked={bodyTypes.includes("camper")}
					onChange={(name, checked) => handleChange(name, checked)}
				/>
			</div>
		</motion.div>
	);
};

export { DesignOptions };
export type { DesignOptionsProps };
