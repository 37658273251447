import { CSSObject } from "@emotion/react";
import { StepVehicleDetailsProps } from "./StepVehicleDetails";
import { useDesignTokens } from "@bilar/ui";
import { StepVehicleDesignProps } from "@bilar/features";

type StepVehicleDesignStyles = {
	root: CSSObject;
	bodyStylesContainer: CSSObject;
};

const useStepVehicleDesignStyles = (
	props: StepVehicleDesignProps,
): StepVehicleDesignStyles => {
	const designTokens = useDesignTokens();
	const { space, mediaQueries } = designTokens;

	// TODO: Use container queries instead of media queries

	return {
		root: {
			cursor: "pointer",
		},
		bodyStylesContainer: {
			display: "grid",
			gap: space[4],
			gridTemplateColumns: "1fr 1fr",
			marginBottom: space[4],

			[mediaQueries.tabletAndAbove]: {
				gridTemplateColumns: "1fr 1fr 1fr",
			},

			[mediaQueries.desktopAndAbove]: {
				gridTemplateColumns: "1fr 1fr 1fr 1fr",
			},
		},
	};
};

type StepTwoStyles = {
	root: CSSObject;
	radioButtonContainer: CSSObject;
	dropDownContainer: CSSObject;
};

const useStepTwoStyles = (props: StepVehicleDetailsProps): StepTwoStyles => {
	const designTokens = useDesignTokens();
	const { space, mediaQueries } = designTokens;
	return {
		root: {},
		radioButtonContainer: {
			display: "grid",
			gap: space[4],
			gridTemplateColumns: "1fr 1fr",
			[mediaQueries.tabletAndAbove]: {
				gridTemplate: `
					"left right"
					"error error"
				`,
				gridTemplateColumns: "1fr 1fr",
			},

			".validation-error": {
				gridColumn: "1 / span 2",
			},
		},
		dropDownContainer: {
			margin: `${space[4]} 0`,
			display: "grid",
			gap: space[2],
			gridTemplate: `
					"year year"
					"month month"
					"error error"
				`,

			[mediaQueries.tabletAndAbove]: {
				gridTemplate: `
					"year month"
					"error error"
				`,
				gap: space[4],
				gridTemplateColumns: "1fr 1fr",
			},
		},
	};
};

type FormButtonStyles = {
	root: CSSObject;
	backButton: CSSObject;
	continueButton: CSSObject;
};
const useFormButtonStyles = (): FormButtonStyles => {
	const { space, mediaQueries, palette } = useDesignTokens();

	return {
		root: {
			display: "grid",
			gap: space[4],
			[mediaQueries.tabletAndAbove]: {
				gridTemplateColumns: "1fr 1fr",
			},
		},
		backButton: {
			order: 2,
			backgroundColor: palette.neutral.lighter.background,
			color: palette.neutral.lighter.contrast,

			[mediaQueries.tabletAndAbove]: {
				backgroundColor: "transparent",
				order: 1,
			},
		},
		continueButton: {
			order: 1,

			[mediaQueries.tabletAndAbove]: {
				order: 2,
			},
		},
	};
};

export { useStepVehicleDesignStyles, useStepTwoStyles, useFormButtonStyles };
