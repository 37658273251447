import { forwardRef, Fragment, MouseEventHandler, ReactNode } from "react";
import { BaseProps } from "..";
import { useButtonStyles } from "./buttonStyles";
import { PaletteProps } from "../../types";
import { getTestId } from "@bilar/ui";

type ButtonSize = "small" | "regular" | "large";
type ButtonColor =
	| "regular"
	| "primary"
	| "secondary"
	| "highlight"
	| "positive"
	| "negative";
type ButtonVariant = "regular" | "outlined" | "raised"; // Removed text variant, instead use the <Link /> component (it can behave like a button)
type ButtonType = "button" | "submit";
type ButtonLayout = "regular" | "squared" | "circular";

type ButtonProps = {
	onClick?: MouseEventHandler<HTMLElement>;
	href?: string;
	disabled?: boolean;
	children?: ReactNode;
	leftIcon?: ReactNode;
	rightIcon?: ReactNode;
	loading?: boolean;
	title?: string;
} & ButtonDefaultProps &
	BaseProps;

type ButtonDefaultProps = {
	type?: ButtonType;
	size?: ButtonSize;
	variant?: ButtonVariant;
	fluid?: boolean;
	layout?: ButtonLayout;
} & PaletteProps;

const defaultProps: ButtonDefaultProps = {
	type: "button",
	palette: "neutral",
	size: "regular",
	variant: "regular",
	fluid: false,
	layout: "regular",
};

/**
 * The `<Button>` component is used in forms or as a CTA button and available in different variants.
 */
const Button = forwardRef((props: ButtonProps, ref: any) => {
	const styles = useButtonStyles(props);
	const {
		type,
		onClick,
		disabled,
		className,
		id,
		title,
		fluid,
		href,
		paletteShade,
		testId,
		leftIcon,
		rightIcon,
		...filteredProps
	} = props;
	/* Show with icon or not? */
	const buttonContent: ReactNode =
		leftIcon || rightIcon ? (
			<div>
				{leftIcon && leftIcon}
				<span>{props.children}</span>
				{rightIcon && rightIcon}
			</div>
		) : (
			<Fragment>{props.children}</Fragment>
		);

	return !props.href ? (
		<button
			ref={ref}
			type={type}
			css={[styles]}
			onClick={onClick}
			disabled={disabled}
			className={className}
			id={id}
			title={title}
			data-testid={getTestId(testId)}
			{...filteredProps}
		>
			{buttonContent}
		</button>
	) : (
		<a
			ref={ref}
			css={[styles]}
			href={href}
			tabIndex={0}
			onClick={onClick}
			className={className}
			id={id}
			{...filteredProps}
		>
			{buttonContent}
		</a>
	);
});

Button.displayName = "Button";
Button.defaultProps = defaultProps;

export { Button };
export type { ButtonProps, ButtonColor, ButtonSize, ButtonVariant };
