import React, { ReactNode } from "react";
import { BaseProps, HtmlTag, Tag, useDesignTokens } from "@bilar/ui";
import { getStackStyles, StackBaseProps } from "../Stack/stackStyles";
import { BoxBaseProps, useBoxStyles } from "./boxStyles";

type BoxProps = { ariaLabel?: string; children?: ReactNode } & BoxDefaultProps &
	BoxBaseProps &
	Omit<StackBaseProps, "orientation"> &
	BaseProps;

type BoxDefaultProps = {
	as: HtmlTag;
};

const defaultProps: BoxDefaultProps = {
	as: "div",
};

/**
 * Use the `<Box>` component to create any block level element. It's also possible to adjust the alignmnet of it's children.<br>
 * The component can accept an [array](#responsive-dimension-sample) of sizes for responsive elements. The component follows the 4px gird system.
 */

const Box = (props: BoxProps) => {
	const designTokens = useDesignTokens();
	const boxStyles = useBoxStyles(props);
	const stackStyles =
		props.horizontalAlignment !== undefined ||
		props.verticalAlignment !== undefined
			? getStackStyles({ ...props, orientation: "horizontal" }, designTokens)
			: undefined;

	// If we are using stack styles the box will be in flexbox mode, we will only allow a single child or the alignment properties may not make sense.
	// They should use a Stack anyways if this happens.
	if (stackStyles !== undefined && React.Children.count(props.children) > 1) {
		throw new Error(
			"You can only pass a single child to the <Box /> component when using the horizontalAlignment or verticalAlignment props. You should probably use the <Stack/> component instead (it supports the same props as a Box). The child count was: " +
				React.Children.count(props.children),
		);
	}

	return (
		<Tag
			as={props.as}
			css={[boxStyles, stackStyles]}
			className={props.className}
			aria-label={props.ariaLabel}
		>
			{props.children}
		</Tag>
	);
};

Box.defaultProps = defaultProps;

export { Box };
export type { BoxProps };
