import {
	FormUploadProgress,
	GlobalValues,
	StepBasicInfoFormValues,
	StepImagesFormValues,
	StepSummaryFormValues,
	StepVehicleDesignFormValues,
	StepVehicleDetailsFormValues,
} from "@bilar/features";

const globalInitialValues: GlobalValues = {
	classifiedAd: null,
	stepsValid: 0,
};

const formUploadProgressInitialValues: FormUploadProgress = {
	numberOfFiles: 0,
	totalBytes: 0,
	bytesTransferred: 0,
};

const stepBasicInfoInitialValues: StepBasicInfoFormValues = {
	vehicleBrand: null,
	vehicleModel: null,
	price: null,
	willingToTrade: false,
	vehicleYear: null,
};

const stepVehicleDesignInitialValues: StepVehicleDesignFormValues = {
	colour: null,
	vehicleBodyType: null,
};

const stepVehicleDetailsInitialValues: StepVehicleDetailsFormValues = {
	vehicleMileage: null,
	vehicleInspected: null,
	vehicleInspectedMonth: null,
	vehicleInspectedYear: null,
	vehicleNumberOfDoors: null,
	vehicleNumberOfSeats: null,
	vehicleFuelType: null,
	vehicleTransmissionType: null,
	vehicleDescription: null,
};

const stepImagesInitialValues: StepImagesFormValues = {
	images: [],
};

const stepSummaryInitialValues: StepSummaryFormValues = {
	email: "",
	hideEmail: true,
	hidePhoneNumber: false,
	phoneNumber: "",
};

//setApplicantValues(value: MainApplicantValues): void {},

export {
	formUploadProgressInitialValues,
	globalInitialValues,
	stepBasicInfoInitialValues,
	stepVehicleDesignInitialValues,
	stepVehicleDetailsInitialValues,
	stepImagesInitialValues,
	stepSummaryInitialValues,
};
