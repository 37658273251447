/** @jsxImportSource @emotion/react */
import { Box, Heading, useDesignTokens } from "@bilar/ui";
import { getStyles } from "./userListStyles";
import React, { ReactNode } from "react";
import useSWR from "swr";
import { usersApiPath } from "../index";
import { User } from "@bilar/models";
import { Loading } from "@bilar/common";

type UserListProps = {
	children?: ReactNode;
	initialUsers: User[];
};

const UserList = (props: UserListProps) => {
	// Note: The "fetcher" method (mainApiDataFetcher) is set by using the SWRConfig in _app.tsx so we don't need to define it.
	const { data, error } = useSWR(usersApiPath, {
		initialData: props.initialUsers,
	});
	const designTokens = useDesignTokens();
	const styles = getStyles(props, designTokens);

	// TODO: Extract User component to a separate file
	type UserProps = {
		user: User;
	};

	const User = ({ user }: UserProps) => (
		<Box mb={1}>
			<Heading as="h2" size="small">
				{user.name.first + " " + user.name.last}
			</Heading>
		</Box>
	);

	if (!data) return <Loading variant="spinner" />;
	if (error)
		return (
			<Heading as="h2" size="large">
				Error!
			</Heading>
		);

	return (
		<div css={styles}>
			{data.map((user: User) => (
				<User key={user.userId} user={user} />
			))}
		</div>
	);
};

export { UserList };
export type { UserListProps };
