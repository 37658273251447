/** @jsxImportSource @emotion/react */
import {
	Box,
	Button,
	Checkbox,
	CurrencyField,
	Divider,
	FormFieldAndValueTuple,
	Panel,
	Stack,
	useFormWithYup,
} from "@bilar/ui";
import { StepBasicInfoFormValues } from "./formTypes";
import {
	stepBasicInfoValidation,
	useFormButtonStyles,
	useVehicleFormContext,
} from "@bilar/features";
import {
	useAppTranslation,
	VehicleBrandSelect,
	VehicleModelSelect,
	YearSelect,
} from "@bilar/common";
import { FormEvent, useEffect } from "react";

type StepBasicInfoProps = {
	classifiedAdId?: string;
	onContinue: () => void;
};

const StepBasicInfo = (props: StepBasicInfoProps) => {
	const { t } = useAppTranslation();
	const { formValues, setStepBasicInfoValues } = useVehicleFormContext();
	const {
		errors,
		values,
		touched,
		handleChange,
		handleSubmit,
		currencyFieldProps,
		booleanFieldProps,
		dropDownFieldProps,
	} = useFormWithYup<StepBasicInfoFormValues>({
		initialValues: formValues.stepBasicInfoValues,
		validationSchema: stepBasicInfoValidation(t),
	});

	const buttonStyles = useFormButtonStyles();

	useEffect(() => {
		if (formValues.globalValues.classifiedAd?.id) {
			// Map props to form values
			const newValues: FormFieldAndValueTuple<StepBasicInfoFormValues>[] = [];
			for (const key of Object.keys(formValues.stepBasicInfoValues)) {
				const _key = key as keyof StepBasicInfoFormValues;
				const propValue = formValues.stepBasicInfoValues[_key];
				if (propValue) {
					newValues.push([_key, propValue]);
				}
			}

			handleChange(newValues);
		}
	}, [formValues]);

	const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		handleSubmit(
			async () => {
				setStepBasicInfoValues(values);

				// console.log("Submit step one", values);
				props.onContinue();
			},
			(err) => {
				console.warn("Validation errors in step one", err);
			},
		);
	};

	return (
		<Panel.Body>
			<form onSubmit={onSubmit}>
				<VehicleBrandSelect
					width="100%"
					selectedBrandId={values.vehicleBrand?.value}
					hasDefaultMargins={false}
					{...dropDownFieldProps("vehicleBrand")}
				/>
				<Stack orientation={["vertical", "horizontal"]}>
					<VehicleModelSelect
						mr={[0, 2]}
						brandId={values.vehicleBrand?.value}
						selectedModelId={values.vehicleModel?.value}
						hasDefaultMargins={false}
						{...dropDownFieldProps("vehicleModel")}
					/>
					<YearSelect
						width="100%"
						ml={[0, 2]}
						selectedItem={values.vehicleYear || undefined}
						label={t("vehicleYear")}
						placeholder={t("selectVehicleYear")}
						{...dropDownFieldProps("vehicleYear")}
					/>
				</Stack>
				<Divider variant="solid" my={2} />

				<Stack orientation={"vertical"} spacing={4}>
					<Box>
						<CurrencyField
							aria-label={t("enterPrice")}
							label={t("price")}
							{...currencyFieldProps("price")}
						/>
					</Box>
					<Checkbox
						label={t("willingToTrade")}
						{...booleanFieldProps("willingToTrade")}
					/>
				</Stack>
				<Divider variant="solid" my={4} />
				<div css={buttonStyles.root}>
					<div></div>
					<Button palette="secondary" type="submit">
						{t("continueToNextStep")}
					</Button>
				</div>
			</form>
		</Panel.Body>
	);
};

export { StepBasicInfo };
export type { StepBasicInfoProps };
