import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect } from "react";
import { BLUR_COMMAND, COMMAND_PRIORITY_EDITOR, EditorState } from "lexical";

type EditorBlurPluginProps = {
	onBlur: (state: EditorState) => void;
};

export const EditorBlurPlugin = (props: EditorBlurPluginProps) => {
	const [editor] = useLexicalComposerContext();
	useEffect(() => {
		const unregisterBlur = editor.registerCommand(
			BLUR_COMMAND,
			() => {
				props.onBlur(editor._editorState);
				return true;
			},
			COMMAND_PRIORITY_EDITOR,
		);

		return () => {
			unregisterBlur();
		};
	}, []);

	return null;
};
