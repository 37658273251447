import { VirtualItem } from "@tanstack/react-virtual";
import * as React from "react";

export type ListItem<T> = {
	label: string | null;
	value: T | null;
	isDivider?: boolean;
};

export type OptionChildCallback<T> = (
	listItem: ListItem<T>,
	virtualItem: VirtualItem,
) => React.ReactNode;
