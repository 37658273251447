/** @jsxImportSource @emotion/react */
import { useFormStepsStyles } from "./formStepsStyles";
import { useAppTranslation } from "@bilar/common";
import { FaIcon, NextLink } from "@bilar/ui";
import { routes } from "@bilar/config";
import {
	faCar,
	faCarBolt,
	faImages,
	faShapes,
	faUserMagnifyingGlass,
} from "@fortawesome/pro-solid-svg-icons";
import { useVehicleFormContext } from "@bilar/features";

type FormStepsProps = {
	currentStep: number;
};

const FormSteps = (props: FormStepsProps) => {
	const { currentStep } = props;
	const styles = useFormStepsStyles(props);
	const { t } = useAppTranslation();
	const { formValues } = useVehicleFormContext();
	const isNewAd = !formValues.globalValues.classifiedAd?.id;
	const pageRoute = isNewAd
		? routes.vehicleClassifieds.new
		: `${routes.profile.classifieds}/${
				formValues.globalValues.classifiedAd!.id
		  }`;

	const getRouteLink = (step: number) => {
		if (shouldStepBeDisabled(step)) {
			return `${pageRoute}?step=${currentStep}`;
		}

		return `${pageRoute}?step=${step}`;
	};

	const shouldStepBeDisabled = (step: number) => {
		if (!isNewAd) {
			return false;
		}

		return formValues.globalValues.stepsValid < step - 1;
	};

	return (
		<div css={styles.root}>
			<ul css={styles.list}>
				<li css={[styles.listItem]}>
					<NextLink
						href={getRouteLink(1)}
						css={[
							styles.listItemLink,
							currentStep === 1 ? styles.listItemLinkSelected : undefined,
						]}
					>
						<FaIcon icon={faCar} />
						<span css={styles.listItemLinkText}>
							{t("stepBasicInformation")}
						</span>
					</NextLink>
				</li>
				<li css={styles.listItem}>
					<NextLink
						href={getRouteLink(2)}
						css={[
							styles.listItemLink,
							currentStep === 2 ? styles.listItemLinkSelected : undefined,
							currentStep !== 2 && shouldStepBeDisabled(2)
								? styles.listItemLinkDisabled
								: undefined,
						]}
					>
						<FaIcon icon={faShapes} />
						<span css={styles.listItemLinkText}>{t("stepVehicleDesign")}</span>
					</NextLink>
				</li>
				<li css={styles.listItem}>
					<NextLink
						href={getRouteLink(3)}
						css={[
							styles.listItemLink,
							currentStep === 3 ? styles.listItemLinkSelected : undefined,
							currentStep !== 3 && shouldStepBeDisabled(3)
								? styles.listItemLinkDisabled
								: undefined,
						]}
					>
						<FaIcon icon={faCarBolt} />
						<span css={styles.listItemLinkText}>{t("stepVehicleDetails")}</span>
					</NextLink>
				</li>
				<li css={styles.listItem}>
					<NextLink
						href={getRouteLink(4)}
						css={[
							styles.listItemLink,
							currentStep === 4 ? styles.listItemLinkSelected : undefined,
							currentStep !== 4 && shouldStepBeDisabled(4)
								? styles.listItemLinkDisabled
								: undefined,
						]}
					>
						<FaIcon icon={faImages} />
						<span css={styles.listItemLinkText}>{t("stepVehiclePhotos")}</span>
					</NextLink>
				</li>
				<li css={styles.listItem}>
					<NextLink
						href={getRouteLink(5)}
						css={[
							styles.listItemLink,
							currentStep === 5 ? styles.listItemLinkSelected : undefined,
							currentStep !== 5 && shouldStepBeDisabled(5)
								? styles.listItemLinkDisabled
								: undefined,
						]}
					>
						<FaIcon icon={faUserMagnifyingGlass} />
						<span css={styles.listItemLinkText}>{t("stepSummary")}</span>
					</NextLink>
				</li>
			</ul>
		</div>
	);
};

export { FormSteps };
export type { FormStepsProps };
