import { User } from "@bilar/models";

export * from "./SignIn";
export * from "./EditProfileForm";
export * from "./UserList";
export * from "./userHooks";
export * from "./userStream";
export * from "./WithUser";

export const usersApiPath = "/users";

export type ClassifiedUser = Pick<
	User,
	"userId" | "name" | "profileImageUrl" | "email" | "phone"
>;

export interface UserLoginProvider {
	uid: string;
	displayName: string;
	photoURL: string;
	email: string;
	phoneNumber: string;
	providerId: string;
}
