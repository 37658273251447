import { CSSObject } from "@emotion/react";
import { MyClassifiedItemProps } from "./MyClassifiedItem";
import { useDesignTokens } from "@bilar/ui";

type MyClassifiedItemStyles = {
	root: CSSObject;
	container: CSSObject;
	contentContainer: CSSObject;
	img: CSSObject;
	imgContainer: CSSObject;
	imgPlaceHolder: CSSObject;
	brandHeading: CSSObject;
	priceHeading: CSSObject;
	noImagesText: CSSObject;
	actionContainer: CSSObject;
};

const useMyClassifiedItemStyles = (
	props: MyClassifiedItemProps,
	imageWidth: number,
): MyClassifiedItemStyles => {
	const designTokens = useDesignTokens();
	const { palette, mediaQueries, space, radii } = designTokens;

	return {
		root: {},
		container: {
			[mediaQueries.tabletAndAbove]: {
				display: "grid",
				gridTemplateColumns: "160px auto auto",
			},
		},
		contentContainer: {},
		imgContainer: {
			minHeight: imageWidth,
			lineHeight: 0, // Fix when next/image adds extra space below
			[mediaQueries.tabletAndAbove]: {
				width: imageWidth,
				minHeight: imageWidth * 0.75,
			},
		},
		imgPlaceHolder: {
			borderRadius: radii.lg,
			backgroundColor: "#D8D8D8",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "column",
		},
		noImagesText: {
			marginTop: space[4],
		},
		img: {
			borderRadius: radii.lg,
		},
		brandHeading: {
			[mediaQueries.tabletAndAbove]: {
				margin: 0,
			},
		},
		priceHeading: {
			color: palette.success.dark.background,
			margin: 0,
			marginTop: space[2],
			[mediaQueries.tabletAndAbove]: {
				margin: 0,
			},
		},
		actionContainer: {
			display: "flex",
			flexDirection: "column",
			alignItems: "flex-end",
		},
	};
};

export { useMyClassifiedItemStyles };
