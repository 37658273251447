import {
	BaseProps,
	CountryCode,
	parseNumber,
	TextField,
	TextFieldBaseProps,
	TextFieldDefaultProps,
	useAutoId,
	useCountryCode,
	useNumberFormat,
} from "@bilar/ui";
import { useEffect, useMemo, useState } from "react";

type CurrencyFieldProps = {
	/**
	 * Input unit to show after the value
	 */
	unit?: string;
} & CurrencyFieldDefaultProps &
	TextFieldDefaultProps &
	TextFieldBaseProps<number | null> &
	BaseProps;

type CurrencyFieldDefaultProps = {
	min?: number;
	maxLength?: number;
	allowDecimals?: boolean;
};

const getCurrencyName = (countryCode: CountryCode) => {
	switch (countryCode) {
		case "IS":
		case "SE":
			return "kr";
		case "NO":
			return "kr"; // Don't use nok
		case "FI":
			return "€";
		default:
			throw new Error("Unknown currency name for country: " + countryCode);
	}
};

/**
 * TODO add notes about this component.
 */
const CurrencyField = ({
	size = "regular",
	min = 0,
	maxLength = 12,
	allowDecimals = false,
	...restProps
}: CurrencyFieldProps) => {
	const props = {
		...restProps,
		size,
		min,
		maxLength,
		allowDecimals,
	};
	const { ...filteredProps } = props;
	const { formatNumber } = useNumberFormat();
	const countryCode = useCountryCode();
	const [formattedValue, setFormattedValue] = useState("");
	const id = useAutoId("currency-field", props.id);
	const [isEditing, setIsEditing] = useState(false);

	useEffect(() => {
		let inputUnit = "";
		if (props.unit && !isEditing) {
			inputUnit = countryCode === "NO" ? `${props.unit} ` : ` ${props.unit}`;
		}

		if (props.value !== null && props.value !== undefined) {
			const inputValue = formatNumber(props.value.toString());
			setFormattedValue(
				countryCode === "NO"
					? `${inputUnit}${inputValue}`
					: `${inputValue}${inputUnit}`,
			);
		} else {
			setFormattedValue(inputUnit.trim());
		}
	}, [props.value, props.unit, isEditing, countryCode]);

	const startAdornment = useMemo(() => {
		if (props.startAdornment) return props.startAdornment;
		if (props.unit) return undefined;

		return countryCode === "NO" ? getCurrencyName(countryCode) : undefined;
	}, [props.startAdornment, countryCode]);

	const endAdornment = useMemo(() => {
		if (props.endAdornment) return props.endAdornment;
		if (props.unit) return undefined;

		return countryCode !== "NO"
			? getCurrencyName(countryCode as CountryCode)
			: undefined;
	}, [props.endAdornment, countryCode]);

	return (
		<TextField
			{...filteredProps}
			id={id}
			startAdornment={startAdornment}
			endAdornment={endAdornment}
			onChange={(newValue, event) => {
				let newNumericValue = parseNumber(newValue, allowDecimals);
				if (props.onChange) props.onChange(newNumericValue, event);
			}}
			onBlur={(newValue, event) => {
				setIsEditing(false);
				let newNumericValue = parseNumber(newValue, allowDecimals);
				if (props.onBlur) props.onBlur(newNumericValue, event);
			}}
			onFocus={(newValue, event) => {
				setIsEditing(true);
				let newNumericValue = parseNumber(newValue, allowDecimals);
				if (props.onFocus) props.onFocus(newNumericValue, event);
			}}
			value={formattedValue}
			disabled={props.disabled}
			inputMode="decimal"
		/>
	);
};

// Add all your exports here
export { CurrencyField };
export type { CurrencyFieldProps };
