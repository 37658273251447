import { CSSObject } from "@emotion/react";
import { forwardRef } from "react";
import { BaseProps } from "../";
import { Button, ButtonProps } from "./Button";
import { useDesignTokens } from "@bilar/ui";

type InvisibleButtonProps = {} & Omit<
	ButtonProps,
	"color" | "size" | "variant"
> &
	InvisibleButtonDefaultProps &
	BaseProps;

type InvisibleButtonDefaultProps = {};

const defaultProps: InvisibleButtonDefaultProps = {};

const useStyles = (): CSSObject => {
	const { palette } = useDesignTokens();
	return {
		color: palette.black,
		display: "block",
		background: "transparent",
		border: "none",
		outline: "none",
		margin: 0,
		":disabled": {
			opacity: 0.5,
		},
		":hover": {
			color: palette.neutral.lighter.contrast,
			backgroundColor: palette.neutral.lighter.background,
		},
		":focus": {
			// TODO: Change colours
			// boxShadow: "none",
		},

		":active": {
			border: "none",
			transform: "none",
		},
		":active:focus": {
			border: "none",
			transform: "none",
		},
	};
};

const InvisibleButton = forwardRef((props: InvisibleButtonProps, ref) => {
	const styles = useStyles();
	return (
		<Button ref={ref} css={styles} {...props}>
			{props.children}
		</Button>
	);
});

InvisibleButton.displayName = "InvisibleButton";
InvisibleButton.defaultProps = defaultProps;

// Add all your exports here
export { InvisibleButton };
export type { InvisibleButtonProps };
