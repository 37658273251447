import { CSSObject } from "@emotion/react";
import {
	PanelBodyProps,
	PanelFooterProps,
	PanelHeaderProps,
	PanelProps,
} from "./Panel";
import { DesignTokens, useDesignTokens } from "../..";
import { getMapValue } from "../../utils";

type PanelStyles = {
	root: CSSObject;
	header: CSSObject;
	footer: CSSObject;
	headerChildren: CSSObject;
};

const getPaddingStyles = (props: PanelBodyProps, tokens: DesignTokens) => {
	const { space } = tokens;

	let paddingStyles = {};

	const defaultPadding = {
		padding: space[4],
	};

	if (props.py !== undefined) {
		let paddingY = getMapValue(space, props.py);
		paddingStyles = {
			paddingTop: paddingY,
			paddingBottom: paddingY,
		};
	} else {
		if (props.pt !== undefined) {
			let paddingTop = getMapValue(space, props.pt);
			paddingStyles = {
				paddingTop: paddingTop,
			};
		}
		if (props.pb !== undefined) {
			let paddingBottom = getMapValue(space, props.pb);
			paddingStyles = {
				paddingBottom,
			};
		}
	}

	return {
		...defaultPadding,
		...paddingStyles,
	};
};

const usePanelBodyStyles = (props: PanelBodyProps): CSSObject => {
	const designTokens = useDesignTokens();
	return {
		...getPaddingStyles(props, designTokens),
		position: "relative",
		zIndex: designTokens.zIndices.base,
	};
};
const usePanelStyles = (
	props: PanelProps | PanelHeaderProps | PanelFooterProps,
): PanelStyles => {
	const designTokens = useDesignTokens();
	const { space, borders, palette } = designTokens;
	return {
		root: {
			position: "relative",
			marginBottom: space[4],
		},
		header: {
			paddingLeft: space[4],
			paddingRight: space[4],
			minHeight: space[12],
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "space-between",
			borderBottom: `1px solid ${borders.color.main}`,
		},
		footer: {
			backgroundColor: palette.neutral.lighter.background,
			borderTop: `1px dotted ${borders.color.main}`,
			padding: `${space[2]} ${space[4]}`,
			borderBottomLeftRadius: space[2],
			borderBottomRightRadius: space[2],
			display: "grid",
			justifyContent: "end",
			gridAutoFlow: "column",
			gridGap: space[4],
		},
		headerChildren: {
			button: {},
		},
	};
};

export { usePanelStyles, usePanelBodyStyles };
