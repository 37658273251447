import { CSSObject } from "@emotion/react";
import { ContactConfirmationBoxProps } from "./ContactConfirmationBox";
import { useDesignTokens } from "@bilar/ui";

type ContactConfirmationBoxStyles = {
	root: CSSObject;
	footer: CSSObject;
};

const useContactConfirmationBoxStyles = (
	props: ContactConfirmationBoxProps,
): ContactConfirmationBoxStyles => {
	const designTokens = useDesignTokens();
	const { palette, space, mediaQueries } = designTokens;
	return {
		root: {
			// borderTop: `${space[1]} solid ${palette.success.main.background}`,
			borderRadius: 0,
		},
		footer: {
			justifyContent: "stretch",
			borderRadius: 0,

			[mediaQueries.tabletAndAbove]: {
				justifyContent: "end",
			},
		},
	};
};

export { useContactConfirmationBoxStyles };
