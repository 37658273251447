import getSymbolFromCurrency from "currency-symbol-map";
import { useCountryCode } from "@bilar/ui";
import {
	formatCurrency,
	getCountryCurrency,
	getCurrencyUnit,
	getLanguageFromCurrency,
} from "@bilar/utils";

// FIXME: We don't currently support decimals at this point, we want to fix when we expand to a country that uses decimals.

const useCurrency = () => {
	const countryCode = useCountryCode();
	const currency = getCountryCurrency(countryCode);
	const lang = getLanguageFromCurrency(currency);

	const _formatCurrency = (
		money: string | number | null | undefined,
		includeSymbol = true,
	): string => formatCurrency(money, lang, currency, includeSymbol);

	const _getCurrencyUnitLocal = (moneyString: string): number =>
		getCurrencyUnit(moneyString, currency);

	const getCurrencyCode = () => {
		return currency.code;
	};

	const getCurrencySymbol = () => getSymbolFromCurrency(currency.code);

	return {
		formatCurrency: _formatCurrency,
		getCurrencyUnit: _getCurrencyUnitLocal,
		getCurrencyCode,
		getCurrencySymbol,
	};
};

export { useCurrency };
