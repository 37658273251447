import { SearchState } from "@bilar/features";

export const defaultSearchState: SearchState = {
	brand: null,
	model: null,
	priceFrom: null,
	priceTo: null,
	vehicleBodyTypes: null,
	fuelTypes: null,
	numberOfDoors: null,
	maxAge: 12,
};
