/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import {
	DropdownOnChange,
	DropdownOption,
	Select,
	SelectProps,
	useBreakpoints,
	useSelectOptions,
} from "@bilar/ui";
import { useAppTranslation } from "@bilar/common";

type BaseSelProps = Omit<SelectProps<false>, "options" | "value">;
type SeatsSelectProps = {
	maxSeats: number;
	selectedItemValue: number | null;
} & BaseSelProps;

const SeatsSelect = (props: SeatsSelectProps) => {
	const {
		label,
		placeholder,
		onChange,
		selectedItemValue,
		maxSeats,
		...filteredProps
	} = props;
	const [seats, setSeats] = useState<DropdownOption[]>([]);
	const [selected, setSelected] = useState<DropdownOption | null>(null);
	const options = useSelectOptions<DropdownOption>(seats);
	const { t } = useAppTranslation();
	const { isMobile } = useBreakpoints();

	useEffect(() => {
		const allSeats: DropdownOption[] = [];
		for (let i = 1; i <= maxSeats; i++) {
			const seat: DropdownOption = {
				label: i.toString(),
				value: i.toString(),
			};
			allSeats.push(seat);
		}

		const lastSeat: DropdownOption = {
			label: `${maxSeats + 1} ${t("orMore")}`,
			value: `${maxSeats + 1}`,
		};

		allSeats.push(lastSeat);

		setSeats(allSeats);
	}, []);

	useEffect(() => {
		if (seats.length > 0) {
			if (selectedItemValue) {
				if (selectedItemValue > props.maxSeats) {
					setSelected({
						label: `${props.maxSeats + 1} ${t("orMore")}`,
						value: `${props.maxSeats + 1}`,
					});
				} else {
					if (selectedItemValue <= 0) {
						setSelected({
							label: "1",
							value: "1",
						});
					} else {
						setSelected({
							label: selectedItemValue.toString(),
							value: selectedItemValue.toString(),
						});
					}
				}
			}
		}
	}, [seats, selectedItemValue]);

	const handleChange: DropdownOnChange<false> = (selected, actionMeta) => {
		setSelected(selected);

		if (onChange) {
			onChange(selected, actionMeta);
		}
	}; // Not searchable on mobile
	const isSearchable = !isMobile;

	return (
		<Select
			label={label || t("numberOfSeats")}
			placeholder={placeholder || t("selectNumberOfSeats")}
			options={options}
			isSearchable={props.isSearchable ?? isSearchable}
			isClearable={true}
			autoFocus={false}
			onChange={handleChange}
			{...filteredProps}
			value={selected}
		/>
	);
};

export { SeatsSelect };
export type { SeatsSelectProps };
