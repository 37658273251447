import { useEffect, useState } from "react";
import {
	DropdownOnChange,
	DropdownOption,
	Select,
	SelectProps,
	useBreakpoints,
	useSelectOptions,
} from "@bilar/ui";
import { generateYears, useAppTranslation } from "@bilar/common";

// Correctly omit 'options' and 'value' from SelectProps for a single selection mode
type BaseSelProps = Omit<SelectProps<false>, "options" | "value">;

type YearSelectProps = {
	selectedItem?: DropdownOption;
} & BaseSelProps &
	YearSelectDefaultProps;

type YearSelectDefaultProps = {
	countDirection?: "up" | "down";
	maxYears?: number;
};

const defaultProps: YearSelectDefaultProps = {
	maxYears: 50,
	countDirection: "down",
};

const YearSelect = (props: YearSelectProps) => {
	const { t } = useAppTranslation();
	const {
		maxYears,
		countDirection,
		label,
		placeholder,
		onChange,
		selectedItem,
		...filteredProps
	} = props;
	const [years, setYears] = useState<DropdownOption[]>([]);
	const [selectedYear, setYear] = useState<DropdownOption | null>(
		selectedItem || null,
	);
	const options = useSelectOptions(years);
	const { isMobile } = useBreakpoints();

	useEffect(() => {
		const years = generateYears(countDirection, maxYears);

		setYears(years);
	}, []);

	useEffect(() => {
		if (years.length > 0 && selectedItem?.value) {
			setYear(selectedItem);
		}
	}, [years, selectedItem]);

	const handleChange: DropdownOnChange<false> = (selected, actionMeta) => {
		setYear(selected);
		onChange?.(selected, actionMeta);
	};

	// Not searchable on mobile
	const isSearchable = !isMobile;

	return (
		<Select
			label={label || t("year")}
			placeholder={placeholder || t("selectYear")}
			options={options}
			isSearchable={props.isSearchable ?? isSearchable}
			isClearable={true}
			autoFocus={false}
			isMulti={false}
			onChange={handleChange}
			value={selectedYear}
			{...filteredProps}
		/>
	);
};

YearSelect.defaultProps = defaultProps;

export { YearSelect };
export type { YearSelectProps };
