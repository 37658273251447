import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

export const carDoorIcon: IconDefinition = {
	icon: [
		80, // Viewbox width
		67, // Viewbox height
		[], // Not used
		"", // Not used
		"M38.477556,0 C32.976556,0 25.463556,3.346 21.785556,7.435 L2.93755597,28.378 C0.48355597,31.103 -0.66244403,36.204 0.39255597,39.72 L6.56055597,60.28 C7.61255597,63.792 11.476556,66.667 15.144556,66.667 L73.333556,66.667 C77.018556,66.667 80.000556,63.682 80.000556,60 L80.000556,0 L38.477556,0 Z M73.333556,43.333 L60.000556,43.333 L60.000556,36.666 L73.333556,36.666 L73.333556,43.333 Z M73.333556,29.999 L10.444556,29.999 L26.739556,11.895 C29.161556,9.206 34.864556,6.666 38.477556,6.666 L73.333556,6.666 L73.333556,29.999 Z", // Path
	],
	iconName: "mobile",
	prefix: "fab",
};
