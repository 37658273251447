import { StringSchema } from "yup";
import { PhoneFieldValidationOptions, phoneValidation } from "./validations";

/**
 * Yup validation for the phone field, so that you can use it like Yup.string().phone()
 * Register it with addMethod(string, "phone", phoneFieldYupValidation).
 * @param options
 */
export const phoneFieldYupValidation = function phone(
	this: StringSchema,
	options?: PhoneFieldValidationOptions | string,
) {
	return this.test({
		name: "testValidPhoneNumber",
		test: (value, context) => {
			// Skip validation here because it's handled by the required() rule
			if (!value) {
				return true;
			}

			const _options = typeof options === "string" ? { message: { invalid: options } } : options;

			const { isValid, errorMessage } = phoneValidation(value || "", _options);
			if (isValid) {
				return true;
			}

			return context.createError({
				message: errorMessage,
			});
		},
	});
};
