import { createContext, Dispatch, useContext } from "react";
import { Action, initialState, ListBoxState } from "./state";
import { UseFloatingReturn, useInteractions } from "@floating-ui/react";
import { Virtualizer } from "@tanstack/react-virtual";
import { DividerItemProps } from "./ListBoxOption";
import { OptionChildCallback } from "./types";

type ListBoxContextProps<T> = {
	state: ListBoxState<T>;
	dispatch: Dispatch<Action<T>>;
	interactions: ReturnType<typeof useInteractions> | null;
	floating: UseFloatingReturn<HTMLButtonElement> | null;
	disabled: boolean;
	handleSelect: () => void;
	virtualizer: Virtualizer<HTMLDivElement, any> | null;
	wrapperRef: React.RefObject<HTMLDivElement>;
	wrapperParentRef: React.RefObject<HTMLDivElement>;
	isTypingRef: React.RefObject<boolean>;
	listElementsRef: React.RefObject<Array<HTMLElement | null>>;
	popoverWidth: number;
	getDividerComponent?: (props: DividerItemProps) => React.ReactElement;
	getOptionComponent?: OptionChildCallback<T>;
};

export const ListBoxContext = createContext<ListBoxContextProps<any>>({
	state: initialState,
	dispatch: () => {},
	interactions: null,
	floating: null,
	disabled: false,
	handleSelect: () => {},
	virtualizer: null,
	wrapperRef: { current: null },
	wrapperParentRef: { current: null },
	isTypingRef: { current: false },
	listElementsRef: { current: [] },
	popoverWidth: 0,
});

export const useListBoxContext = () => {
	const context = useContext(ListBoxContext);
	if (!context) {
		throw new Error(
			"ListBox compound components cannot be rendered outside the ListBox component",
		);
	}
	return context;
};
