;globalThis["__sentryRewritesTunnelPath__"] = "/m";globalThis["SENTRY_RELEASE"] = {"id":"4f30686ec101efa6182f19a7e385f10284fae9cb"};globalThis["__sentryBasePath"] = undefined;globalThis["__rewriteFramesAssetPrefixPath__"] = "";import * as Sentry from "@sentry/nextjs";

const sentryFeedback = Sentry.feedbackIntegration({
	colorScheme: "light",
	isNameRequired: true,
	isEmailRequired: true,
	autoInject: false,
	formTitle: "Senda ábendingu",
	nameLabel: "Nafn",
	namePlaceholder: "Nafnið þitt",
	emailLabel: "Netfang",
	emailPlaceholder: "Netfangið þitt",
	messageLabel: "Skilaboð",
	messagePlaceholder: "Lýstu villunni eða vandamálinu sem þú ert að lenda í.",
	submitButtonLabel: "Senda",
	cancelButtonLabel: "Hætta við",
	successMessageText: "Ábendingin þín hefur verið skráð!",
	showBranding: false,
});

Sentry.init({
	environment: process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.NODE_ENV,
	enabled: process.env.NODE_ENV === "production",
	dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

	// Adjust this value in production, or use tracesSampler for greater control
	tracesSampleRate: 1,

	// Setting this option to true will print useful information to the console while you're setting up Sentry.
	debug: false,

	replaysOnErrorSampleRate: 0.5,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// You can remove this option if you're not planning to use the Sentry Session Replay feature:
	integrations: [
		Sentry.replayIntegration({
			// Additional Replay configuration goes in here, for example:
			maskAllText: false,
			blockAllMedia: false,
			unblock: [".rp-unblock, [data-rp-unblock]"],
			unmask: [".rp-unmask, [data-rp-unmask]"],
			mask: [".rp-mask, [data-rp-mask]"],
		}),
		sentryFeedback,
		Sentry.browserTracingIntegration({
			enableInp: true,
		}),
		Sentry.captureConsoleIntegration({
			levels: ["error", "warn"],
		}),
	],
});
