import { CSSObject } from "@emotion/react";
import { AppFooterProps } from "./AppFooter";
import { useDesignTokens } from "@bilar/ui";

type AppFooterStyles = {
	root: CSSObject;
	contentWrapper: CSSObject;
	area: CSSObject;
	about: CSSObject;
	contact: CSSObject;
	terms: CSSObject;
	social: CSSObject;
	heading: CSSObject;
};

const useAppFooterStyles = (props: AppFooterProps): AppFooterStyles => {
	const designTokens = useDesignTokens();
	const { palette, size, mediaQueries } = designTokens;
	return {
		root: {
			display: "grid",
			alignItems: "center",
			minHeight: size(40),
			padding: size(4),
			backgroundColor: "#343434",
			color: "#fff",

			a: {
				color: "#fff",
				textDecoration: "none",
				"&:hover": {
					color: "#fff",
					textDecoration: "underline",
				},
			},
		},
		contentWrapper: {
			display: "grid",
			gridTemplateColumns: "1fr", // Single column layout for mobile
			gridTemplateAreas: `
                "about"
                "contact"
                "terms"
                "social"	
            `,
			gap: size(2),

			[mediaQueries.tabletAndAbove]: {
				gridTemplateAreas: `"about contact terms social"`, // Four columns layout for desktop
				gridTemplateColumns: "repeat(4, 1fr)", // Creates four columns of equal width
			},
		},
		area: {
			display: "flex",
			flexDirection: "column",
			gap: size(2),
		},
		about: {
			gridArea: "about",
		},
		contact: {
			gridArea: "contact",
		},
		terms: {
			gridArea: "terms",
		},
		social: {
			gridArea: "social",
		},
		heading: {
			color: "#fff",
			margin: 0,
		},
	};
};

export { useAppFooterStyles };
