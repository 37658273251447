/** @jsxImportSource @emotion/react */
import { useAppTranslation } from "@bilar/common";
import { SearchState, useSearch, useSharedSearchStyles } from "@bilar/features";
import { RadioButton, useDesignTokens } from "@bilar/ui";
import { CSSObject } from "@emotion/react/dist/emotion-react.cjs";

type DoorFieldsProps = {};

type FuelTypeFieldStyles = {
	optionsWrapper: CSSObject;
};
const useDoorFieldStyles = (): FuelTypeFieldStyles => {
	const { space, fontWeights } = useDesignTokens();
	const shared = useSharedSearchStyles();
	return {
		optionsWrapper: {
			margin: `${space[2]} 0`,
		},
	};
};

const DoorFields = (props: DoorFieldsProps) => {
	const { t } = useAppTranslation();
	const styles = useDoorFieldStyles();
	const sharedStyles = useSharedSearchStyles();
	const { searchState, setSearchState } = useSearch();

	const handleDoorSelect = (numberOfDoors: number) => {
		let newState: SearchState = { ...searchState };

		newState.numberOfDoors = numberOfDoors;
		setSearchState(newState);
	};

	return (
		<fieldset css={sharedStyles.fieldset}>
			<legend css={sharedStyles.legend}>{t("vehicleNumberOfDoors")}</legend>
			<div css={[sharedStyles.radioAndCheckboxWrapper, styles.optionsWrapper]}>
				<RadioButton
					text={t("twoDoors")}
					group="doors"
					layout="box"
					checked={searchState.numberOfDoors === 2}
					onChange={() => handleDoorSelect(2)}
				/>
				<RadioButton
					text={t("threeDoors")}
					group="doors"
					layout="box"
					checked={searchState.numberOfDoors === 3}
					onChange={() => handleDoorSelect(3)}
				/>
				<RadioButton
					text={t("fourDoors")}
					group="doors"
					layout="box"
					checked={searchState.numberOfDoors === 4}
					onChange={() => handleDoorSelect(4)}
				/>
				<RadioButton
					text={t("fiveDoors")}
					group="doors"
					layout="box"
					checked={searchState.numberOfDoors === 5}
					onChange={() => handleDoorSelect(5)}
				/>
			</div>
		</fieldset>
	);
};

export { DoorFields };
export type { DoorFieldsProps };
