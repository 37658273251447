import { CSSObject } from "@emotion/react";
import { HeadingProps } from "./Heading";
import { DesignTokens, getMapValue, useDesignTokens } from "@bilar/ui";

type HeadingStyles = {
	root: CSSObject;
};

const getMarginStyles = (props: HeadingProps, tokens: DesignTokens) => {
	const { space } = tokens;

	let styles = {};

	const defaultMargin = {
		margin: 0,
	};

	switch (props.size) {
		case "xs":
			styles = {
				...styles,
				marginBottom: space[2],
			};
			break;
		case "small":
			styles = {
				...styles,
				marginBottom: space[2],
			};
			break;
		case "regular":
			styles = {
				...styles,
				marginBottom: space[2],
			};
			break;
		case "large":
			styles = {
				...styles,
				marginBottom: space[4],
			};
			break;
	}

	// Vertical
	if (props.my !== undefined) {
		let marginY = getMapValue(space, props.my);
		styles = {
			marginTop: marginY,
			marginBottom: marginY,
		};
	}
	if (props.mt !== undefined) {
		let marginTop = getMapValue(space, props.mt);
		styles = {
			...styles,
			marginTop: marginTop,
		};
	}
	if (props.mb !== undefined) {
		let marginBottom = getMapValue(space, props.mb);
		styles = {
			...styles,
			marginBottom: marginBottom,
		};
	}

	// Horizontal
	if (props.mx !== undefined) {
		let marginX = getMapValue(space, props.mx);
		styles = {
			...styles,
			marginLeft: marginX,
			marginRight: marginX,
		};
	}
	if (props.ml !== undefined) {
		let marginLeft = getMapValue(space, props.ml);
		styles = {
			...styles,
			marginLeft: marginLeft,
		};
	}
	if (props.mr !== undefined) {
		let marginRight = getMapValue(space, props.mr);
		styles = {
			...styles,
			marginRight: marginRight,
		};
	}

	return {
		...defaultMargin,
		...styles,
	};
};

const getColorStyles = (
	props: HeadingProps,
	tokens: DesignTokens,
): CSSObject => {
	const { fontColors } = tokens;
	switch (props.shade) {
		case "light":
			return { color: fontColors.light };
		case "dark":
			return { color: fontColors.dark };
		default:
			return { color: fontColors.main };
	}
};
const getSizeStyles = (
	props: HeadingProps,
	tokens: DesignTokens,
): CSSObject => {
	const { fontSizes, fontWeights } = tokens;
	const { size } = props;
	let sizeStyles: CSSObject = {};
	switch (size) {
		case "xs":
			sizeStyles = {
				fontSize: fontSizes.md,
				lineHeight: fontSizes.lg,
			};
			break;
		case "small":
			sizeStyles = {
				fontSize: fontSizes.lg,
				lineHeight: fontSizes["2xl"],
			};
			break;

		case "large":
			sizeStyles = {
				fontSize: fontSizes["3xl"],
				lineHeight: fontSizes["4xl"],
			};
			break;
		case "hero":
			sizeStyles = {
				fontSize: fontSizes["6xl"],
				lineHeight: fontSizes["6xl"],
			};
			break;
		default:
			// Regular
			sizeStyles = {
				fontSize: fontSizes["2xl"],
				lineHeight: fontSizes["3xl"],
			};
			break;
	}

	return sizeStyles;
};

const getFontWeightStyles = (
	props: HeadingProps,
	tokens: DesignTokens,
): CSSObject => {
	if (!props.weight) {
		return {};
	}

	return {
		fontWeight: tokens.fontWeights[props.weight],
	};
};

const useHeadingStyles = (props: HeadingProps): CSSObject => {
	const tokens = useDesignTokens();
	const { fonts, fontWeights } = tokens;

	const baseStyles: CSSObject = {
		fontFamily: fonts.heading,
		fontWeight: fontWeights.normal,
		// These two are needed when we have icons inside
		display: "flex",
		alignItems: "center",
	};

	const colorStyles = getColorStyles(props, tokens);
	const sizeStyles = getSizeStyles(props, tokens);
	const weightStyles = getFontWeightStyles(props, tokens);
	const marginStyles = getMarginStyles(props, tokens);

	return {
		...marginStyles,
		...baseStyles,
		...sizeStyles,
		...weightStyles,
		...colorStyles,
	};
};

export { useHeadingStyles };
