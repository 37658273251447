import { Typography } from "./types";
import colors from "./colors";

const typography: Typography = {
	letterSpacings: {
		tighter: "-0.05em",
		tight: "-0.025em",
		normal: "0",
		wide: "0.025em",
		wider: "0.05em",
		widest: "0.1em",
	},
	lineHeights: {
		normal: "normal",
		none: "1",
		shorter: "1.25",
		short: "1.275",
		base: "1.5",
		tall: "1.625",
		taller: "2",
	},
	fontWeights: {
		hairline: 100,
		thin: 200,
		light: 300,
		normal: 400,
		medium: 500,
		semiBold: 600,
		bold: 700,
		extraBold: 800,
		black: 900,
	},
	fonts: {
		heading: `'halyard-display', Helvetica Neue, Helvetica, Arial, sans-serif`,
		body: `'Helvetica Neue', Helvetica, Arial, sans-serif`,
		mono: `SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`,
	},
	fontSizes: {
		xs: "0.75rem", //12px
		sm: "0.875rem", //14px
		md: "1rem", //16px
		lg: "1.125rem", //18px
		xl: "1.25rem", //20px
		"2xl": "1.5rem", //24px
		"3xl": "2rem", // 32px
		"4xl": "2.25rem", //36px
		"5xl": "2.5rem", // 40px
		"6xl": "4rem", //64px
	},
	fontColors: {
		light: "#4D4D4D",
		main: "#4D4D4D",
		dark: colors.black[800],
	},
};

export default typography;
