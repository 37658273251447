/** @jsxImportSource @emotion/react */
import { ReactNode, useState } from "react";
import { BaseProps } from "..";
import { motion } from "framer-motion";

export type FlipAnimationProps = {
	children?: ReactNode;
	flipped: boolean;
} & FaderDefaultProps &
	BaseProps;

type FaderDefaultProps = {};

const variants = {
	unflipped: { transform: "rotate(0deg)" },
	flipped: { transform: "rotate(180deg)" },
};

export const FlipAnimation = (props: FlipAnimationProps) => {
	const [initialFlipped] = useState(props.flipped);

	// By setting initial to hide we will always get a fade in transition at mount
	return (
		<motion.div
			initial={initialFlipped ? "flipped" : "unflipped"}
			animate={props.flipped ? "flipped" : "unflipped"}
			variants={variants}
			className={props.className}
			id={props.id}
		>
			{props.children}
		</motion.div>
	);
};
