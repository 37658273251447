import { IconTransformProps } from "./faIconTypes";
import { SpacingSize, useDesignTokens } from "@bilar/ui";

/**
 * Generate the transform props so that we can rotate, translate, scale and flip an icon correctly
 * @param props
 * @param size
 * @param positionMultiplier This used so that we can snap (or increment) the position in the 4px grid or arbitrarily
 */
const useIconTransform = (
	props: IconTransformProps,
	size: SpacingSize,
	positionMultiplier = 1,
) => {
	const designTokens = useDesignTokens();
	let rotate = 0;
	let translateX = 0;
	let translateY = 0;
	let scaleX = 1;
	let scaleY = 1;

	if (
		props.scale ||
		props.positionX ||
		props.positionY ||
		props.rotation ||
		props.flip
	) {
		translateX = (props.positionX || 0) * positionMultiplier;
		translateY = (props.positionY || 0) * positionMultiplier;
		// Scale and flip (-1 scale)
		scaleX =
			(props.scale || 1) *
			(props.flip === "horizontal" || props.flip === "both" ? -1 : 1);
		scaleY =
			(props.scale || 1) *
			(props.flip === "vertical" || props.flip === "both" ? -1 : 1);
		rotate = props.rotation || 0;
	}

	return {
		width: designTokens.size(size),
		height: designTokens.size(size),
		scaleX,
		scaleY,
		rotate,
		translateX,
		translateY,
	};
};

export { useIconTransform };
