import {
	CollectionReference,
	limit,
	orderBy,
	query,
	Query,
	Timestamp,
	where,
} from "firebase/firestore";
import { ClassifiedVehicle } from "@bilar/models";

export const getClassifiedsBaseQuery = (
	ref: CollectionReference<ClassifiedVehicle>,
	adCount: number,
	includeExpiresAt: boolean = true,
	includeCreatedAt: boolean = true,
): Query<ClassifiedVehicle> => {
	const todayAtMidnightUtc = new Date(
		Date.UTC(
			new Date().getUTCFullYear(),
			new Date().getUTCMonth(),
			new Date().getUTCDate(),
			0,
			0,
			0,
			0,
		),
	);

	const todayStampUtc = Timestamp.fromDate(todayAtMidnightUtc);

	// Start with basic query
	let baseQuery: Query<ClassifiedVehicle> = query(
		ref,
		where("published", "==", true),
	);

	if (adCount) {
		baseQuery = query(baseQuery, limit(adCount));
	}

	// Add the expiresAt filter conditionally
	if (includeExpiresAt) {
		baseQuery = query(
			baseQuery,
			where("expiresAt", ">", todayStampUtc),
			orderBy("expiresAt", "desc"),
		);
	}

	// Add the createdAt filter conditionally
	if (includeCreatedAt) {
		baseQuery = query(baseQuery, orderBy("createdAt", "desc"));
	}

	return baseQuery;
};
