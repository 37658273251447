import React, { ReactNode, useContext } from "react";
import {
	BaseProps,
	Box,
	DesignTokens,
	getMapValue,
	ResponsiveProp,
	Stack,
	useDesignTokens,
} from "@bilar/ui";
import { SpacingSize } from "../../../styling/designTokens/types";
import { BoxBaseProps, getBoxPropsOnly } from "../Box/boxStyles";
import { UnitConverter } from "../../../styling/unitConverter";
import { HorizontalAlignment, VerticalAlignment } from "../Stack/stackStyles";

type FlexGridProps = {
	children?: ReactNode;
	horizontalAlignment?: ResponsiveProp<HorizontalAlignment>; // The space commands might not be usable depending on orientation, can we type this?
	verticalAlignment?: ResponsiveProp<VerticalAlignment>; // The space commands might not be usable depending on orientation, can we type this?
	spacing?: SpacingSize; // TODO: Make ResponsiveProp
} & BoxBaseProps &
	BaseProps;

const gridColumnCount = 24;
const defaultGridSpacing = 2;
const SpacingContext = React.createContext<SpacingSize | undefined>(undefined);

function calculateGridSpacing(
	spacing: SpacingSize | undefined,
	negativeNumbers: boolean,
	designTokens: DesignTokens,
) {
	const unitConverter = new UnitConverter(16, "px");
	let sizeValue =
		spacing === undefined
			? unitConverter.convertSizeUnitToNumber(
					getMapValue(designTokens.space, defaultGridSpacing),
			  ) / 2
			: unitConverter.convertSizeUnitToNumber(
					getMapValue(designTokens.space, spacing),
			  ) / 2;

	return unitConverter.convertNumberToSizeUnit(
		negativeNumbers ? -sizeValue : sizeValue,
	);
}

/** Use the `<FlexGrid>` component to setup complicated UI layouts for both mobile and desktop devices. */
const FlexGrid = (props: FlexGridProps) => {
	const designTokens = useDesignTokens();
	const calculatedSpacing = calculateGridSpacing(
		props.spacing,
		true,
		designTokens,
	);

	return (
		<SpacingContext.Provider value={props.spacing}>
			<Box {...getBoxPropsOnly(props)}>
				<Stack
					css={{
						margin: calculatedSpacing,
						flexWrap: "wrap",
					}}
					orientation="horizontal"
					verticalAlignment={props.verticalAlignment}
					horizontalAlignment={props.horizontalAlignment}
				>
					{props.children}
				</Stack>
			</Box>
		</SpacingContext.Provider>
	);
};

type GridItemProps = {
	width: ResponsiveProp<
		number | "25%" | "33%" | "50%" | "66%" | "75%" | "100%"
	>;
	maxColumns?: number;
	children?: ReactNode;
} & BaseProps;

// Don't export, use Grid.Item instead
const GridItem = (props: GridItemProps) => {
	const designTokens = useDesignTokens();
	const { utils } = designTokens;
	const spacing = useContext(SpacingContext);

	const convertWidth = (
		width: number | "25%" | "33%" | "50%" | "66%" | "75%" | "100%" | undefined,
	) => {
		if (width === undefined) return undefined;

		if (typeof width === "string") {
			if (width === "33%") {
				// For better rounding
				return "33.3333%";
			}

			if (width === "66%") {
				// For better rounding
				return "66.6666%";
			}

			return width;
		}

		return (width / gridColumnCount) * 100 + "%";
	};

	const flexBasisStyles = utils.responsivePropToCss(
		props.width,
		"flexBasis",
		convertWidth,
	);
	const maxWidthStyles = utils.responsivePropToCss(
		props.width,
		"maxWidth",
		convertWidth,
	);
	const calculatedSpacing = calculateGridSpacing(spacing, false, designTokens);

	return (
		// We need max width or the grid could be messed up by content that forces sized boxes, long text etc (which we try to break apart)
		<div
			className={props.className}
			css={[
				{
					// Important that this is border-box or items will be to large and fall down to another "row"
					wordWrap: "break-word",
					padding: calculatedSpacing,
				},
				flexBasisStyles,
				maxWidthStyles,
			]}
		>
			<div css={{ height: "100%", width: "100%" }}>{props.children}</div>
		</div>
	);
};

FlexGrid.Item = GridItem;

export { FlexGrid };
export type { FlexGridProps };
