import { Variants } from "framer-motion";
import { SearchState } from "@bilar/features";

export * from "./AdvancedSearchOptions";
export * from "./BasicSearchOptions";
export * from "./BrandOptions";
export * from "./DesignOptions";
export * from "./designOptionsStyles";
export * from "./VehicleSearchProvider";
export * from "./MiniSearch";
export * from "./miniSearchStyles";
export * from "./VehicleSearch";
export * from "./vehicleSearchStyles";
export * from "./searchUtils";
export * from "./searchTypes";
export * from "./sections";
export * from "./defaultSearchState";

export const emptySearchState: SearchState = {
	brand: null,
	model: null,
	priceFrom: null,
	priceTo: null,
	vehicleBodyTypes: null,
	fuelTypes: null,
	numberOfDoors: null,
	maxAge: null,
};

export const searchVariants: Variants = {
	initial: {
		opacity: 1,
	},
	animate: {
		opacity: 1,
	},
	exit: {
		opacity: 0,
	},
};
