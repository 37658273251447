import { CSSObject } from "@emotion/react";
import { IconProps } from "./Icon";
import { DesignTokens, useDesignTokens } from "../..";

type IconStyles = {
	root: CSSObject;
};

const getMapValue = (obj: any, key: any) => {
	if (obj.hasOwnProperty(key)) return obj[key];
	throw new Error("Invalid sizeKey for Icon!");
};

const getMarginStyles = (props: IconProps, tokens: DesignTokens) => {
	let marginStyles = {};
	const { space } = tokens;

	if (props.ml) {
		let marginLeft = getMapValue(space, props.ml);
		marginStyles = {
			...marginStyles,
			marginLeft: marginLeft,
		};
	}
	if (props.mr) {
		let marginRight = getMapValue(space, props.mr);
		marginStyles = {
			...marginStyles,
			marginRight: marginRight,
		};
	}

	return marginStyles;
};

const useIconStyles = (props: IconProps): IconStyles => {
	const designTokens = useDesignTokens();
	const { space } = designTokens;
	const { palette, paletteShade, size } = props;

	const sizeKey = getMapValue(space, size);

	return {
		root: {
			color: "inherit", // tmp
			fontSize: sizeKey,
			lineHeight: 1,
			...getMarginStyles(props, designTokens),

			svg: {
				color: palette
					? designTokens.palette[palette][paletteShade!].background
					: undefined,
				verticalAlign: props.verticalAlign,
			},
		},
	};
};

export { useIconStyles };
