import { CSSObject } from "@emotion/react";
import { FontAlignment, useDesignTokens } from "@bilar/ui";

type SectionHeadingStyles = {
	root: CSSObject;
};

const useHeadingStyles = (textAlign: FontAlignment): SectionHeadingStyles => {
	const designTokens = useDesignTokens();
	const { space, palette } = designTokens;

	const itemAlignments = {
		left: "flex-start",
		right: "flex-end",
		center: "center",
		justify: "space-between",
	};

	return {
		root: {
			position: "relative",
			display: "inline-flex",
			flexDirection: "column",
			alignItems: itemAlignments[textAlign] || "start",
			"&:after": {
				content: "''",
				width: space[16],
				height: space[1],
				backgroundColor: palette.success.main.background,
				borderRadius: 9999,
				marginTop: space[2],
			},
		},
	};
};

export { useHeadingStyles };
