import { CSSObject } from "@emotion/react";
import { useDesignTokens } from "@bilar/ui";
import { VehicleListItemProps } from "@bilar/features";

type VehicleListItemStyles = {
	root: CSSObject;
	mediaArea: CSSObject;
	contentArea: CSSObject;
	title: CSSObject;
	titleLink: CSSObject;
	price: CSSObject;
	date: CSSObject;
	meta: CSSObject;
	image: {
		root: CSSObject;
		placeholder: CSSObject;
		noImagesText: CSSObject;
	};
};

const useAdsListStyles = (
	props: VehicleListItemProps,
): VehicleListItemStyles => {
	const designTokens = useDesignTokens();
	const { mediaQueries, space, size } = designTokens;
	return {
		root: {
			display: "grid",
			gridTemplateColumns: `
			   [card-start] ${size(4)} 
			   [container-start] 1fr [inner-start] 3fr [inner-end container-end] 
			   ${size(4)} 
			   [card-end]
			`,

			gridTemplateRows: `
			   [card-start]
			   ${size(4)}
			   [container-start] 1fr 
			   	[inner-start]
			   		auto 
			   	[inner-end container-end]
			   ${size(4)} 
			   [card-end]
			`,

			[mediaQueries.tabletAndAbove]: {
				gridTemplateRows: `
			   [card-start]
			   ${size(4)}
			   [container-start inner-start] 
			   1fr 
			   	[inner-end container-end]
			   ${size(4)} 
			   [card-end]
			`,
			},
		},
		contentArea: {
			gridColumn: "container-start / container-end",
			gridTemplateRows: "inner-end / card-end",
			paddingTop: size(4),

			[mediaQueries.tabletAndAbove]: {
				paddingTop: 0,
				paddingLeft: size(4),
				gridColumn: "inner-start / card-end",
				gridRow: "container-start / container-end",
			},
		},
		mediaArea: {
			gridColumn: "card-start / card-end",
			gridRow: "card-start / inner-start",

			[mediaQueries.tabletAndAbove]: {
				gridColumn: "container-start / inner-start",
				gridRow: "container-start / inner-start",
			},
		},
		title: {},
		titleLink: {
			color: "inherit",
			textDecoration: "none",
			"&:hover": {
				textDecoration: "underline",
			},
		},
		price: {},
		date: {},
		meta: {},
		image: {
			root: {
				maxWidth: "100%",
				borderTopLeftRadius: size(2),
				borderTopRightRadius: size(2),

				[mediaQueries.tabletAndAbove]: {
					borderRadius: size(2),
				},
			},
			placeholder: {},
			noImagesText: {},
		},
	};
};

export { useAdsListStyles };
