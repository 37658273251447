import { CSSObject } from "@emotion/react";
import { VehicleFormProps } from "./VehicleForm";
import { useDesignTokens } from "@bilar/ui";

type VehicleFormStyles = {
	root: CSSObject;
	aside: CSSObject;
	main: CSSObject;
};

const useVehicleFormStyles = (props: VehicleFormProps): VehicleFormStyles => {
	const designTokens = useDesignTokens();
	const { palette, mediaQueries, space } = designTokens;
	return {
		root: {
			display: "grid",

			[mediaQueries.tabletAndAbove]: {
				gridTemplateColumns: "1fr 2fr",
			},
		},
		aside: {
			padding: `${space[4]} ${space[4]}`,
			borderBottom: `1px solid ${palette.neutral.light.background}`,
			backgroundColor: palette.neutral.lighter.background,
			borderTopLeftRadius: space[2],
			borderTopRightRadius: space[2],

			[mediaQueries.tabletAndAbove]: {
				display: "block",
				borderRadius: 0,
				borderTopLeftRadius: space[2],
				borderBottomLeftRadius: space[2],
				borderBottom: "none",
			},
		},
		main: {
			overflow: "hidden",
			[mediaQueries.tabletAndAbove]: {
				minHeight: space[96],
			},
		},
	};
};

export { useVehicleFormStyles };
