import { useAuthUser } from "@bilar/auth";
import React, { ComponentType, FC } from "react";
import { BaseStaticProps } from "@bilar/pages-common";
import { User } from "@bilar/models";

export type WithUserProps = {
	user: User;
};

export type PageProps = BaseStaticProps & WithUserProps;

export const withUser = <P extends PageProps = PageProps>(
	Component: ComponentType<P>,
): FC<P> => {
	// We omit all props that are defined in WithUserProps,
	// so that we can pass the rest of the props to the wrapped component
	const WithUser = (props: Omit<P, keyof WithUserProps>) => {
		const { user } = useAuthUser();

		if (!user) {
			return <div>Not logged in!</div>;
		}

		// @ts-ignore NOTE: TS complains about the props here, I don't know why
		return <Component user={user} {...(props as P)} />;
	};

	return WithUser;
};
