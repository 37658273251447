import { DesignTokens, useDesignTokens } from "@bilar/ui";
import chroma from "chroma-js";

const generateBorderAlpha = (
	tokens: DesignTokens,
	color: string,
	alpha?: number,
) => {
	return chroma(color).alpha(alpha || tokens.bordersNew.outlineOpacity);
};

const generateBorderShadow = (
	tokens: DesignTokens,
	color: string,
	width = 3,
	alpha?: number,
) => {
	return `0 0 0px ${width}px ${generateBorderAlpha(tokens, color, alpha)}`;
};

export const useBorderTokens = () => {
	const tokens = useDesignTokens();
	return {
		borderAlpha: (color: string, alpha?: number) =>
			generateBorderAlpha(tokens, color, alpha),
		borderShadow: (color: string, width = 3, alpha?: number) =>
			generateBorderShadow(tokens, color, width, alpha),
	};
};
