import { useAppTranslation } from "@bilar/common";
import { DropdownOption } from "@bilar/ui";

const useMonthSelectOptions = () => {
	const { t } = useAppTranslation();

	const monthOptions: DropdownOption[] = [
		{ value: "1", label: t("monthJanuary") },
		{ value: "2", label: t("monthFebruary") },
		{ value: "3", label: t("monthMarch") },
		{ value: "4", label: t("monthApril") },
		{ value: "5", label: t("monthMay") },
		{ value: "6", label: t("monthJune") },
		{ value: "7", label: t("monthJuly") },
		{ value: "8", label: t("monthAugust") },
		{ value: "9", label: t("monthSeptember") },
		{ value: "10", label: t("monthOctober") },
		{ value: "11", label: t("monthNovember") },
		{ value: "12", label: t("monthDecember") },
	];

	const getMonthName = (monthNumber: number | null | undefined) => {
		if (monthNumber === null || monthNumber === undefined) {
			return "";
		}

		const monthOption = monthOptions.find(
			(option) => option.value === monthNumber.toString(),
		);
		return monthOption ? monthOption.label : "";
	};

	const getThisYearsPassedMonths = (
		selectedYear: string,
		monthOptions: DropdownOption[],
	): DropdownOption[] => {
		const now: Date = new Date();
		const year: number = now.getFullYear();
		const month: number = now.getMonth() + 1;

		if (parseInt(selectedYear) === year && month <= 12) {
			const limitedOptions: DropdownOption[] = [];
			monthOptions.filter(function (obj) {
				const objValue = parseInt(obj.value);
				if (objValue <= month) {
					limitedOptions.push(obj);
				}
			});

			return limitedOptions;
		} else {
			return monthOptions;
		}
	};

	return {
		monthOptions,
		getMonthName,
		getThisYearsPassedMonths,
	};
};

export { useMonthSelectOptions };
