// TODO: Import this interface from framer-motion instead of declaring here
import { gridSystem } from "./designTokens";
import { UnitConverter } from "./unitConverter";
import {
	mapResponsiveProp,
	mapResponsivePropsGrouped,
	ResponsiveProp,
} from "@bilar/ui";
import { CSSProperties } from "react";
import facepaint from "facepaint";
import mediaQueries from "./designTokens/mediaQueries";

interface TransformProperties {
	x?: string | number;
	y?: string | number;
	z?: string | number;
	translateX?: string | number;
	translateY?: string | number;
	translateZ?: string | number;
	rotate?: string | number;
	rotateX?: string | number;
	rotateY?: string | number;
	rotateZ?: string | number;
	scale?: string | number;
	scaleX?: string | number;
	scaleY?: string | number;
	scaleZ?: string | number;
	skew?: string | number;
	skewX?: string | number;
	skewY?: string | number;
	originX?: string | number;
	originY?: string | number;
	originZ?: string | number;
	perspective?: string | number;
}

type UtilsTokens = {
	framerTransformTemplate: (
		props: TransformProperties,
		transform: string,
	) => void;
	size: (size: number, modifySize?: (sizeInPixels: number) => number) => string;
	responsivePropToCss: <TValue, TResult>(
		value: ResponsiveProp<TValue | undefined> | (TValue | undefined)[],
		cssProperty: keyof CSSProperties,
		convertFn?: (value: TValue | undefined, index: number) => TResult,
	) => facepaint.DynamicStyle[];

	responsivePropsToCss: <T>(
		props: ResponsiveProp<T>[],
		usePreviousValues: boolean,
		convertFn: (...values: (T | undefined)[]) => any, // <-- Can we type this better?
	) => facepaint.DynamicStyle[];
};

/**
 * Used internally by the responsiveProp(s)ToCss functions.
 */
const facepaintMediaQueries = facepaint([
	mediaQueries.tabletAndAbove,
	mediaQueries.desktopAndAbove,
	mediaQueries.desktopWideAndAbove,
]);

const utils: UtilsTokens = {
	framerTransformTemplate: (props: TransformProperties, transform: string) =>
		transform.replace(" translateZ(0)", ""), // Disable GPU acceleration to prevent blurry text
	size: (
		size: number,
		modifySize?: (sizeInPixels: number) => number,
	): string => {
		let sizeInPixels = size * gridSystem.size;

		if (modifySize) {
			sizeInPixels = modifySize(sizeInPixels);
		}

		const unitConverter = new UnitConverter(16, "rem");

		// Use rem or px?
		// if (sizingMode === 'px') {
		// 	return sizeInPixels + 'px'
		// } else {
		// 	return unitConverter.convertPixelsToSizeUnit(sizeInPixels)
		// }

		return unitConverter.convertPixelsToSizeUnit(sizeInPixels);
	},

	/**
	 * When converting a *single* ResponsiveProp to a CSSObject, with the option of converting the values to valid CSS values along the way.
	 * For more advanced scenarios use the responsivePropsToCss function.
	 */
	responsivePropToCss: <TValue, TResult>(
		value: ResponsiveProp<TValue | undefined> | (TValue | undefined)[],
		cssProperty: keyof CSSProperties,
		convertFn?: (value: TValue | undefined, index: number) => TResult,
	): facepaint.DynamicStyle[] => {
		// First we convert all the values using a conversion function (if provided), for
		// example we might use our own values such as 'xs', 's', 'left' etc that are not valid css but we convert them into valid css
		// if no converFn is provided the regular value is used
		if (convertFn) {
			// Maps a single responsive prop
			const convertedValues = mapResponsiveProp(value, convertFn);
			return facepaintMediaQueries({ [cssProperty]: convertedValues });
		} else {
			return facepaintMediaQueries({ [cssProperty]: value });
		}
	},

	/**
	 * For more advanced scenarios when you need to take multiple responsive props into consideration, one might override the other etc.
	 * Used for stuff like px, mx, mt, m etc and also the horizontalAlignment, verticalAlignment props that for example need to react differently
	 * depending on the value of the orientation (which could also change per breakpoint)
	 */
	responsivePropsToCss: <T>(
		props: ResponsiveProp<T>[],
		usePreviousValues: boolean,
		convertFn: (...values: (T | undefined)[]) => any, // <-- Can we type this better?
	): facepaint.DynamicStyle[] => {
		const convertedValues = mapResponsivePropsGrouped(
			props,
			usePreviousValues,
			convertFn,
		);
		return facepaintMediaQueries(convertedValues);
	},
};

export default utils;
export type { UtilsTokens };
