import { LanguageCode } from "@bilar/ui";
import {
	formatRelative,
	FormatRelativeFnOptions,
	FormatRelativeToken,
	Locale,
} from "date-fns";
import { enGB, es, is, pl } from "date-fns/locale";

// TODO: Dynamically import these
const getLocale = (languageCode: LanguageCode) => {
	switch (languageCode) {
		case "is":
			return { ...is, ...customIcelandicLocale };
		case "en":
			return enGB;
		case "pl":
			return pl;
		case "es":
			return es;
		default:
			return is;
	}
};

// NOTE: We are overriding because this is a bug in date-fns
const dayPeriodValues = {
	lastWeek: "'síðasta' EEE 'kl.' p",
	yesterday: "'í gær kl.' p",
	today: "'í dag kl.' p",
	tomorrow: "'á morgun kl.' p",
	nextWeek: "'næsta' EEE 'kl.' p",
	other: "P",
};

// Custom Icelandic locale object
const customIcelandicLocale: Locale = {
	...is,
	// Override the formatRelative function
	formatRelative: (
		token: FormatRelativeToken,
		date: Date | number | string,
		baseDate: Date | number | string,
		options?: FormatRelativeFnOptions,
	) => {
		// Custom relative formatting logic
		switch (token) {
			case "lastWeek":
				return dayPeriodValues.lastWeek;
			case "yesterday":
				return dayPeriodValues.yesterday;
			case "today":
				return dayPeriodValues.today;
			case "tomorrow":
				return dayPeriodValues.tomorrow;
			case "nextWeek":
				return dayPeriodValues.nextWeek;
			default:
				return "P"; // Default case
		}
	},
};

export const getRelativeFormattedDate = (date: string, locale: LanguageCode) =>
	formatRelative(new Date(Date.parse(date)), new Date().toUTCString(), {
		locale: getLocale(locale),
	});
