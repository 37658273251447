/** @jsxImportSource @emotion/react */
import * as Slider from "@radix-ui/react-slider";
import { useDesignTokens } from "@bilar/ui";
import { CSSObject } from "@emotion/react";
import {
	defaultSearchState,
	useSearch,
	useSharedSearchStyles,
} from "@bilar/features";
import { useEffect, useState } from "react";
import { useAppTranslation } from "@bilar/common";

type YearOptionsProps = {};

type SliderStyles = {
	root: CSSObject;
	track: CSSObject;
	range: CSSObject;
	thumb: CSSObject;
};

const useSliderStyles = (): SliderStyles => {
	const { space, palette } = useDesignTokens();

	return {
		root: {
			position: "relative",
			display: "flex",
			alignItems: "center",
			userSelect: "none",
			touchAction: "none",
			width: "100%",
			height: space[6],
		},
		track: {
			backgroundColor: palette.success["200"].background,
			position: "relative",
			flexGrow: "1",
			borderRadius: "9999px",
			height: space[1],
		},
		range: {
			position: "absolute",
			backgroundColor: palette.success["500"].background,
			borderRadius: "9999px",
			height: "100%",
		},
		thumb: {
			display: "block",
			width: "20px",
			height: "20px",
			backgroundColor: palette.success["600"].background,
			boxShadow: "0 2px 10px var(--black-a7)",
			borderRadius: "10px",

			"&:hover": {
				backgroundColor: palette.success["700"].background,
			},

			"&:focus": {
				outline: "none",
				boxShadow: `0 0 0 2px ${palette.grey["800"]}`,
			},
		},
	};
};

const YearOptions = (props: YearOptionsProps) => {
	const styles = useSliderStyles();
	const sharedStyles = useSharedSearchStyles();
	const { t } = useAppTranslation();
	const { searchState, setSearchState, queryParams } = useSearch();
	const [internalMaxAge, setInternalMaxAge] = useState<number | undefined>(
		searchState.maxAge ?? defaultSearchState.maxAge ?? undefined,
	);

	useEffect(() => {
		if (searchState.maxAge) {
			setInternalMaxAge(searchState.maxAge);
		}
	}, [searchState.maxAge]);

	return (
		<fieldset css={sharedStyles.fieldset}>
			<legend css={sharedStyles.legend}>{t("maxAge")}</legend>
			<Slider.Root
				css={styles.root}
				defaultValue={searchState.maxAge ? [searchState.maxAge] : undefined}
				value={internalMaxAge ? [internalMaxAge] : undefined}
				max={20}
				min={1}
				step={1}
				onValueChange={(e) => {
					setInternalMaxAge(e[0]);
				}}
				onValueCommit={(newValue) => {
					setSearchState({ ...searchState, maxAge: newValue[0] });
				}}
			>
				<Slider.Track css={styles.track}>
					<Slider.Range css={styles.range} />
				</Slider.Track>
				<Slider.Thumb css={styles.thumb} aria-label="Volume" />
			</Slider.Root>
			<span>
				{internalMaxAge}{" "}
				{internalMaxAge && internalMaxAge > 1
					? t("yearsOfAge.other")
					: t("yearsOfAge.one")}
			</span>
		</fieldset>
	);
};

export { YearOptions };
export type { YearOptionsProps };
