import {
	getClassifiedsBaseQuery,
	isExpiredInUtc,
	SearchState,
	vehicleDataConverter,
	VehicleSearchResults,
} from "@bilar/features";
import { ClassifiedVehicle, FuelType, VehicleBodyType } from "@bilar/models";
import {
	collection,
	CollectionReference,
	getDocs,
	getFirestore,
	query,
	QueryConstraint,
	where,
} from "firebase/firestore";
import initFirebase from "@bilar/firebase/initFirebase";
import { collections } from "@bilar/config";

const getPriceConstraints = (searchState: SearchState): QueryConstraint[] => {
	const constraints = [];
	if (searchState.priceFrom) {
		constraints.push(where("price.amount", ">=", searchState.priceFrom));
	}
	if (searchState.priceTo) {
		constraints.push(where("price.amount", "<=", searchState.priceTo));
	}
	return constraints;
};

const getMaxAgeConstraints = (searchState: SearchState): QueryConstraint[] => {
	const constraints = [];
	if (searchState.maxAge !== undefined && searchState.maxAge !== null) {
		const today = new Date();
		const currentYear = today.getFullYear();
		const minYear = currentYear - searchState.maxAge;
		const maxYear = currentYear;

		const yearRange = [];
		for (let year = minYear; year <= maxYear; year++) {
			yearRange.push(year);
		}

		constraints.push(where("vehicleInfo.year", "in", yearRange));
	}
	return constraints;
};

const getBrandAndModelConstraints = (
	searchState: SearchState,
): QueryConstraint[] => {
	const constraints = [];
	if (searchState.brand) {
		constraints.push(where("vehicleInfo.brandId", "==", searchState.brand));
	}
	if (searchState.model) {
		constraints.push(where("vehicleInfo.modelId", "==", searchState.model));
	}
	return constraints;
};

const getBodyTypeConstraints = (
	searchState: SearchState,
): QueryConstraint[] => {
	const constraints = [];
	if (searchState.vehicleBodyTypes && searchState.vehicleBodyTypes.length > 0) {
		constraints.push(
			where("vehicleInfo.bodyType", "in", searchState.vehicleBodyTypes),
		);
	}
	return constraints;
};

const getFuelTypeConstraints = (
	searchState: SearchState,
): QueryConstraint[] => {
	const constraints = [];
	if (searchState.fuelTypes && searchState.fuelTypes.length > 0) {
		constraints.push(
			where("vehicleInfo.fuelType", "in", searchState.fuelTypes),
		);
	}
	return constraints;
};

const getNumberOfDoorsConstraints = (
	searchState: SearchState,
): QueryConstraint[] => {
	const constraints = [];
	if (searchState.numberOfDoors) {
		constraints.push(
			where("vehicleInfo.numberOfDoors", "==", searchState.numberOfDoors),
		);
	}
	return constraints;
};

async function fetchDataWithConstraints(
	constraints: QueryConstraint[],
	adsRef: CollectionReference<ClassifiedVehicle>,
	adCount: number,
): Promise<ClassifiedVehicle[]> {
	const baseQuery = getClassifiedsBaseQuery(adsRef, adCount, false, false);
	const searchQuery = query(baseQuery, ...constraints);
	const snapshot = await getDocs(searchQuery);

	return snapshot.docs.map((doc) => doc.data() as ClassifiedVehicle);
}

export const fetchVehicleBySearch = async (
	searchState: SearchState,
): Promise<VehicleSearchResults> => {
	let data: ClassifiedVehicle[] = [];
	const adCount = 0; // used in getClassifiedsBaseQuery, if set zero it will return all results

	// Initialize Firebase and get Firestore instance
	const firebaseApp = initFirebase();
	const db = getFirestore(firebaseApp);
	const adsRef = collection(db, collections.CLASSIFIEDS).withConverter(
		vehicleDataConverter,
	);

	// Base constraints: range-based and equality constraints
	const baseConstraints = [
		...getPriceConstraints(searchState),
		...getMaxAgeConstraints(searchState),
		...getBrandAndModelConstraints(searchState),
		...getNumberOfDoorsConstraints(searchState),
	];

	const baseResults = await fetchDataWithConstraints(
		baseConstraints,
		adsRef,
		adCount,
	);

	// Start with baseResults as our initial dataset
	data = [...baseResults];

	// Now, filter the base results client-side based on fuelType and bodyType
	if (searchState.vehicleBodyTypes?.length) {
		data = data.filter(
			(br) =>
				br.vehicleInfo.bodyType &&
				searchState.vehicleBodyTypes?.includes(
					br.vehicleInfo.bodyType as VehicleBodyType,
				),
		);
	}
	if (searchState.fuelTypes?.length) {
		data = data.filter(
			(br) =>
				br.vehicleInfo.fuelType &&
				searchState.fuelTypes?.includes(br.vehicleInfo.fuelType as FuelType),
		);
	}

	// Filter out expired listings client-side
	data = data.filter((item) => !isExpiredInUtc(item.expiresAt));

	return {
		data,
	};
};
