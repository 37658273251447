/** @jsxImportSource @emotion/react */
import { Box, DropdownOption, Stack } from "@bilar/ui";
import { PriceDropdown, useAppTranslation } from "@bilar/common";
import { SearchState, useSearch } from "@bilar/features";
import { useSharedSearchStyles } from "./sharedStyles";

type PriceOptionsProps = {};

const PriceFields = (props: PriceOptionsProps) => {
	const { t } = useAppTranslation();

	const sharedStyles = useSharedSearchStyles();
	const { searchState, setSearchState } = useSearch();

	const handlePriceFromChange = (model: DropdownOption | null) => {
		let newState: SearchState = { ...searchState };

		if (model === null) {
			newState = { ...searchState, priceFrom: undefined };
		} else if (model.value) {
			newState = {
				...searchState,
				priceFrom: parseInt(model.value),
				priceTo: undefined,
			};
		}

		setSearchState(newState);
	};

	const handlePriceToChange = (model: DropdownOption | null) => {
		let newState: SearchState = { ...searchState };
		if (model === null) {
			newState = { ...searchState, priceTo: undefined };
		} else if (model.value) {
			newState = { ...searchState, priceTo: parseInt(model.value) };
		}

		setSearchState(newState);
	};

	return (
		<fieldset css={sharedStyles.fieldset}>
			<Stack orientation={["vertical", "horizontal", "horizontal"]}>
				<Box width={["100%", "50%"]} ml={[0, 2]}>
					<PriceDropdown
						width="100%"
						label={t("priceFrom")}
						value={searchState.priceFrom ?? undefined}
						minValue={0}
						onChange={handlePriceFromChange}
					/>
				</Box>
				<Box width={["100%", "50%"]} ml={[0, 2]}>
					<PriceDropdown
						width="100%"
						label={t("priceTo")}
						value={searchState.priceTo ?? undefined}
						minValue={searchState.priceFrom ?? 100000}
						onChange={handlePriceToChange}
					/>
				</Box>
			</Stack>
		</fieldset>
	);
};

export { PriceFields };
export type { PriceOptionsProps };
