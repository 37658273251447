import { CSSObject } from "@emotion/react";
import { VehicleMiniItemProps } from "./VehicleMiniItem";
import { useDesignTokens } from "@bilar/ui";

type VehicleMiniItemStyles = {
	root: CSSObject;
	link: CSSObject;
	container: CSSObject;
	contentContainer: CSSObject;
	img: CSSObject;
	imgContainer: CSSObject;
	imgPlaceHolder: CSSObject;
	brandHeading: CSSObject;
	priceHeading: CSSObject;
	noImagesText: CSSObject;
};

const useVehicleMiniItemStyles = (
	props: VehicleMiniItemProps,
	imageWidth: number,
): VehicleMiniItemStyles => {
	const designTokens = useDesignTokens();
	const { palette, space, radii } = designTokens;
	return {
		root: {
			transition: "transform, 200ms linear",
			"&:hover": {
				transform: "scale(1.05)",
			},
			"&:active": {
				transform: "scale(0.95)",
			},
			"&:hover *": {
				textDecoration: "none",
			},
		},
		link: {
			display: "block",
			color: "inherit",
			textDecoration: "none",

			"&:hover": {
				color: "inherit",
				textDecoration: "none",
			},
		},
		container: {
			display: "grid",
			gridTemplateColumns: "160px auto",
		},
		contentContainer: {},
		imgContainer: {
			lineHeight: 0, // Fix when next/image adds extra space below
			width: imageWidth,
			minHeight: imageWidth * 0.75,
		},
		imgPlaceHolder: {
			borderRadius: radii.lg,
			backgroundColor: "#D8D8D8",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "column",
		},
		noImagesText: {
			marginTop: space[4],
		},
		img: {
			borderRadius: radii.lg,
		},
		brandHeading: {
			margin: 0,
		},
		priceHeading: {
			color: palette.success.dark.background,
			margin: 0,
		},
	};
};

export { useVehicleMiniItemStyles };
