import { ClassifiedVehicle } from "@bilar/models";
import {
	getGalleryImages,
	getVehicleImages,
	NewVehicleDetails,
} from "@bilar/features";
import { useEffect, useState } from "react";
import { GalleryImage } from "@bilar/ui";

type NewVehicleDetailsModalProps = {
	data: ClassifiedVehicle | undefined;
	id: string;
};

const NewVehicleDetailsModal = (props: NewVehicleDetailsModalProps) => {
	const { data, id } = props;
	const [images, setImages] = useState<GalleryImage[]>([]);

	useEffect(() => {
		fetchImages(id);
	}, []);

	const fetchImages = async (vehicleAdId: string) => {
		const vehicleImages = await getVehicleImages(vehicleAdId);

		// Get gallery images with processed urls
		const imageList = getGalleryImages(vehicleImages);

		setImages(imageList);
	};

	return <NewVehicleDetails data={data!} images={images} />;
};

export { NewVehicleDetailsModal };
export type { NewVehicleDetailsModalProps };
