import { ReactNode } from "react";
import { useButtonGroupStyles } from "./buttonGroupStyles";

type ButtonGroupProps = {
	children: ReactNode;
} & ButtonGroupDefaultProps;

type ButtonGroupDefaultProps = {};

const defaultProps: ButtonGroupDefaultProps = {};

const ButtonGroup = (props: ButtonGroupProps) => {
	const styles = useButtonGroupStyles(props);

	return <div css={styles.root}>{props.children}</div>;
};

ButtonGroup.defaultProps = defaultProps;

export { ButtonGroup };
export type { ButtonGroupProps };
