import { Divider } from "@bilar/ui";
import { motion } from "framer-motion";
import {
	DoorFields,
	FuelTypeFields,
	PriceFields,
	YearOptions,
} from "./sections";

type BasicSearchOptionsProps = {};

const BasicSearchOptions = (props: BasicSearchOptionsProps) => {
	return (
		<motion.div accessKey="basic-options">
			<PriceFields />
			<Divider variant="dotted" />
			<YearOptions />
			<Divider variant="dotted" />
			<FuelTypeFields />
			<Divider variant="dotted" />
			<DoorFields />
		</motion.div>
	);
};

export { BasicSearchOptions };
export type { BasicSearchOptionsProps };
