import { ReactNode } from "react";
import { useDesignTokens } from "@bilar/ui";

type HideOnMobileProps = {
	children: ReactNode;
};
const HideOnMobile = (props: HideOnMobileProps) => {
	const tokens = useDesignTokens();
	const styles = {
		display: "none",
		[tokens.mediaQueries.tabletAndAbove]: {
			display: "inline",
		},
	};
	return <span css={styles}>{props.children}</span>;
};

export { HideOnMobile };
export type { HideOnMobileProps };
