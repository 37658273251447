const parseNumber = (s: string, allowDecimals: boolean = false) => {
	// Remove any spaces + characters (for currency inputs etc)
	let normalizedText = s.replace(/ /g, "");
	normalizedText = normalizedText.replace(",", ".");

	// This will in JS actually work with strings like "100abc->100"
	const newNumericValue: number | null = allowDecimals
		? parseFloat(normalizedText)
		: parseInt(normalizedText);

	if (isNaN(newNumericValue)) {
		return null;
	} else {
		return newNumericValue;
	}
};

const numberWithSpaces = (number: string) => {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const formatMileage = (mileage: number): string => {
	let formattedMileage = mileage
		.toString()
		.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

	if (mileage >= 1000000) {
		// Split the string into the million and thousand parts
		let parts = formattedMileage.split(".");
		// Join the parts with a comma
		formattedMileage = parts.join(",");
		// Replace the last comma with a dot
		formattedMileage = formattedMileage.replace(/,([^,]*)$/, ".$1");
	}

	return formattedMileage;
};

export { parseNumber, numberWithSpaces, formatMileage };
