import initFirebase from "@bilar/firebase/initFirebase";
import {
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	getFirestore,
	limit,
	orderBy,
	query,
	setDoc,
	where,
	writeBatch,
} from "firebase/firestore";
import {
	FirestoreRequestPromise,
	vehicleDataConverter,
	vehicleImageConverter,
} from "@bilar/features";
import { collections } from "@bilar/config";
import {
	ClassifiedVehicle,
	ClassifiedVehicleDbModel,
	User,
	VehicleImage,
} from "@bilar/models";
import { addBreadcrumb } from "@sentry/core";

const firebaseApp = initFirebase();
const db = getFirestore(firebaseApp);

export const deleteDummyData = async () => {
	const classifiedsVehiclesRef = collection(
		db,
		collections.CLASSIFIEDS,
	).withConverter(vehicleDataConverter);

	// ["John", "Jane", "Bob", "Alice", "Charlie", "Sophia"]
	const q = query(
		classifiedsVehiclesRef,
		where("user.name.first", "==", "John"),
	);

	const snapshot = await getDocs(q);

	const batch = writeBatch(db);
	snapshot.docs.forEach((doc) => {
		batch.delete(doc.ref);
	});

	return await batch.commit();
};

const getVehicle = async (
	id: string,
): Promise<ClassifiedVehicle | undefined> => {
	const docRef = doc(db, collections.CLASSIFIEDS, id).withConverter(
		vehicleDataConverter,
	);
	const docSnap = await getDoc(docRef);

	return docSnap.data();
};

const getLatestVehicles = async (
	numberOfAds: number,
): Promise<ClassifiedVehicle[]> => {
	const classifiedsVehiclesRef = collection(
		db,
		collections.CLASSIFIEDS,
	).withConverter(vehicleDataConverter);

	const todayAtMidnightUtc = new Date(
		Date.UTC(
			new Date().getUTCFullYear(),
			new Date().getUTCMonth(),
			new Date().getUTCDate(),
			0,
			0,
			0,
			0,
		),
	);

	// TODO: Would love a solution where I didn't have to query 100 only to filter them out later,
	//  but firestore doesn't support multiple where clauses in this way as far as I know.
	const q = query(
		classifiedsVehiclesRef,
		where("published", "==", true),
		orderBy("createdAt", "desc"),
		limit(100),
	);

	const snapshot = await getDocs(q);

	const nonExpiredAds: ClassifiedVehicle[] = snapshot.docs
		.map((doc) => doc.data())
		.filter((ad) => new Date(ad.expiresAt) > todayAtMidnightUtc);

	return nonExpiredAds.splice(0, numberOfAds);
};

const getVehicleImages = async (id: string): Promise<VehicleImage[]> => {
	const imagesRef = collection(
		db,
		collections.CLASSIFIEDS,
		id,
		collections.CLASSIFIEDS_IMAGES,
	).withConverter(vehicleImageConverter);
	const images = await getDocs(query(imagesRef, orderBy("orderNumber", "asc")));

	return images.docs.map((doc) => doc.data());
};

const getClassifiedsForUser = async (user: User) => {
	const classifiedsVehiclesRef = collection(
		db,
		collections.CLASSIFIEDS,
	).withConverter(vehicleDataConverter);

	const q = query(
		classifiedsVehiclesRef,
		where("user.userId", "==", user.userId),
	);

	const snapshot = await getDocs(q);

	const myClassifiedsData = snapshot.docs.map((doc) => ({
		...doc.data(),
	}));

	return myClassifiedsData;
};

const getClassifiedForUser = async (id: string, user: User) => {
	const docRef = doc(db, collections.CLASSIFIEDS, id).withConverter(
		vehicleDataConverter,
	);

	const docSnap = await getDoc(docRef);
	const data = docSnap.data();

	if (!data) {
		throw new Error("Classified not found");
	}

	// Maybe use Firebase security rules instead?
	if (data?.user.userId !== user.userId) {
		throw new Error("Unauthorized");
	}

	return data;
};

const saveVehicle = async (id: string, model: Partial<ClassifiedVehicle>) => {
	addBreadcrumb({
		category: "vehiclesDataLayer:saveVehicle",
		message: `Saving vehicle with Id: ${id}`,
		level: "info",
	});
	return new Promise<FirestoreRequestPromise>(async (resolve, reject) => {
		try {
			const docRef = doc(db, collections.CLASSIFIEDS, id).withConverter(
				vehicleDataConverter,
			);

			await setDoc(docRef, model, { merge: true });

			if (model.images) {
				await saveImages(id, model.images);
			}

			return resolve({
				message: "Classified was saved successfully",
				docId: id,
			});
		} catch (e) {
			return reject(e);
		}
	});
};

/**
 * Creates or updates a Classified for Vehicle and returns a promise.
 * @param model
 */
const saveVehicleToDb = (
	model: ClassifiedVehicleDbModel,
): Promise<FirestoreRequestPromise> => saveVehicle(model.id, model);

const patchVehicleInDb = async (
	docId: string,
	data: Partial<ClassifiedVehicle>,
) => saveVehicle(docId, data);

const deleteVehicleFromDb = async (docId: string) => {
	const docRef = doc(db, `${collections.CLASSIFIEDS}/${docId}`);

	return deleteDoc(docRef);
};

const saveImages = async (docId: string, images?: VehicleImage[]) => {
	if (!images || !images.length) return Promise.resolve();

	const batch = writeBatch(db);
	images.forEach((item) => {
		const docRef = doc(
			db,
			`${collections.CLASSIFIEDS}/${docId}/${collections.CLASSIFIEDS_IMAGES}/${item.id}`,
		).withConverter(vehicleImageConverter);

		batch.set(docRef, item);
	});

	return await batch.commit();
};

const deleteImagesFromDb = async (docId: string, ids: string[]) => {
	if (!ids.length) return Promise.resolve();

	addBreadcrumb({
		category: "vehiclesDataLayer:deleteImagesFromDb",
		message: `DocId: ${docId}, ImageIds: ${ids.join(", ")}`,
		level: "info",
	});

	const batch = writeBatch(db);
	ids.forEach((id) => {
		const docRef = doc(
			db,
			`${collections.CLASSIFIEDS}/${docId}/${collections.CLASSIFIEDS_IMAGES}/${id}`,
		);

		batch.delete(docRef);
	});

	return await batch.commit();
};

export {
	getVehicle,
	getLatestVehicles,
	getVehicleImages,
	saveVehicleToDb,
	deleteVehicleFromDb,
	getClassifiedsForUser,
	getClassifiedForUser,
	deleteImagesFromDb,
	patchVehicleInDb,
};
