/** @jsxImportSource @emotion/react */
import {
	MenuButton as ReachMenuButton,
	MenuButtonProps as ReachMenuButtonProps,
} from "@reach/menu-button";
import { Link, LinkProps } from "../links/Link";
import { InvisibleButton } from "..";
import { Button, ButtonProps } from "../buttons/Button";

/**
 * The `<MenuButtonInvisible>` component is used to open/close the menu.
 */
const MenuButtonInvisible = (props: ButtonProps) => {
	return (
		<MenuButtonCustom as={InvisibleButton} {...props}>
			{props.children}
		</MenuButtonCustom>
	);
};

/**
 * The `<MenuButtonLink>` component is used to open/close the menu.
 */
const MenuButtonLink = (props: LinkProps) => {
	return (
		<MenuButtonCustom as={Link} {...props}>
			{props.children}
		</MenuButtonCustom>
	);
};

/**
 * The `<MenuButton>` component is used to open/close the menu.
 */
const MenuButton = (props: ButtonProps) => {
	return (
		// <ReachMenuButton
		// 	as={Button}
		// >
		// 	{props.children}
		// </ReachMenuButton>
		<MenuButtonCustom as={Button} {...props}>
			{props.children}
		</MenuButtonCustom>
	);
};

/**
 * The `<MenuButtonCustom>` component is used to open/close the menu.
 */
const MenuButtonCustom = (
	props: Partial<ReachMenuButtonProps> & { as: any },
) => {
	const { as, ...other } = props;

	return (
		<ReachMenuButton as={as} {...other}>
			{props.children}
		</ReachMenuButton>
	);
};

export { MenuButton, MenuButtonLink, MenuButtonCustom, MenuButtonInvisible };
