import { Errors, Touched } from "./formTypes";

const createInitialValues = <T, R>(initialValues: T, initialValue: any): R => {
	const values = {} as R;
	const keys = Object.keys(initialValues as {}) as Array<keyof T & keyof R>;

	keys.forEach((key) => {
		values[key] = initialValue;

		if (typeof initialValues[key] === "object" && initialValues[key] !== null) {
			values[key] = createInitialValues(
				initialValues[key as keyof T],
				initialValue,
			);
		}
	});

	return values;
};

const createInitialErrors = <T extends object>(initialValues: T): Errors<T> => {
	let initialErrors: Errors<T> = {} as Errors<T>;
	Object.keys(initialValues).forEach((key) => {
		initialErrors[key as keyof T] = "";
	});

	return initialErrors;
};

const createInitialTouched = <T>(
	initialValues: T,
	initialValue: boolean,
): Touched<T> => {
	const initialTouched: Record<string, boolean> = {};
	Object.keys(initialValues as {}).forEach((key) => {
		initialTouched[key] = initialValue;
	});

	return initialTouched as Touched<T>;
};

export { createInitialErrors, createInitialTouched };
