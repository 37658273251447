import React, { Fragment, useEffect, useState } from "react";
import {
	Box,
	Button,
	Divider,
	FaIcon,
	Heading,
	InvisibleButton,
	Message,
	Panel,
	Paper,
	Stack,
	ValidationMessage,
} from "@bilar/ui";
import { AuthStatus, useAuthManagement, useAuthUser } from "@bilar/auth";
import { useSignInStyles, useUserProfile } from "@bilar/features";
import { Loading, useAppTranslation } from "@bilar/common";
import { faMerge, faWarning } from "@fortawesome/pro-solid-svg-icons";
import { Trans } from "react-i18next";
import { useFeatureFlagEnabled } from "posthog-js/react";
import Image from "next/image";

type SignInProps = {
	hideHeader?: boolean;
	returnRoute?: string;
	onSignInSuccess: (authStatus: AuthStatus) => void;
};

// TODO: Figure out a better solution regarding when to show modal, redirects, using a provider etc.

const isMessengerBrowser = () => {
	// User agent: "Mozilla/5.0 (iPhone; CPU iPhone OS 17_3_1 like Mac OS X)
	// AppleWebKit/605.1.15 (KHTML, like Gecko)
	// Mobile/15E148 [FBAN/FBIOS;FBAV/450.0.0.35.111;FBBV/578158956;FBDV/iPhone14,7;FBMD/iPhone;FBSN/iOS;FBSV/17.3.1;FBSS/3;FBCR/;FBID/phone;FBLC/en-GB;FBOP/80]"

	const userAgent = navigator.userAgent;
	return userAgent.indexOf("FBAN") > -1 || userAgent.indexOf("FBAV") > -1;
};

const SignIn = (props: SignInProps) => {
	const { hideHeader, returnRoute } = props;
	const { t } = useAppTranslation();
	const { firebaseUser } = useAuthUser();

	const {
		authError,
		linkAccount,
		setLinkAccount,
		linkingDone,
		linkProviders,
		signInWithGoogle,
		signInWithTwitter,
		signInWithFacebook,
		signInWithMicrosoft,
		authStatus,
	} = useAuthManagement();
	const isFacebookEnabled = useFeatureFlagEnabled("facebook-login");
	const styles = useSignInStyles(props);
	const { updateLoginProviderDetails } = useUserProfile();
	const [isFacebookBrowser, setIsFacebookBrowser] = useState(false);

	useEffect(() => {
		if (linkingDone && firebaseUser?.uid) {
			updateLoginProviderDetails(firebaseUser.uid);
		}
	}, [linkingDone]);

	useEffect(() => {
		if (authStatus === "signInComplete" || authStatus === "completeProfile") {
			props.onSignInSuccess(authStatus);
		}
	}, [authStatus]);

	useEffect(() => {
		if (isMessengerBrowser()) {
			setIsFacebookBrowser(true);
		}
	}, []);

	if (isFacebookBrowser) {
		return (
			<>
				<Message
					headingAs="h2"
					palette="warning"
					icon={<FaIcon icon={faWarning} />}
					heading={t("messengerBrowserWarningTitle")}
				>
					<p>{t("messengerBrowserSecurityText")}</p>
					<p>{t("messengerBrowserUseOther")}</p>
				</Message>
				<Divider my={4} />
				<Paper>
					<Box p={4}>
						<Heading as="h3" size="regular">
							{t("messengerBrowserOpenInExternalBrowser")}
						</Heading>

						<p>{t("messengerBrowserExternalBrowserDescription")}</p>
						<Image
							src="/img/facebook-open-external.jpg"
							css={{ borderRadius: 8 }}
							alt={`${t("pictureOf")}: ${t("messengerBrowserOpenInExternalBrowser")}`}
							width={693}
							height={1536}
							layout="responsive"
						/>
					</Box>
				</Paper>
			</>
		);
	}

	return (
		<div css={styles.root}>
			{authStatus === "inProgress" || authStatus === "completeProfile" ? (
				<Panel.Body>
					<Loading
						centered={true}
						size={12}
						variant="dots"
						// text={t("loading")}
					/>
				</Panel.Body>
			) : (
				<div>
					{authError && authError.message && (
						<Box my={4}>
							<ValidationMessage
								error={authError.message}
								layoutMode="regular"
							/>
						</Box>
					)}

					{linkAccount && linkAccount.status && (
						<div>
							<Heading as="h3" size="small">
								{t("accountExists.heading")}
							</Heading>
							<Divider variant="dotted" mb={4} />
							<Stack spacing={4}>
								<Box as="p" m={0}>
									<Trans
										i18nKey="accountExists.info"
										values={{
											email: linkAccount.email,
											existingProvider: linkAccount.existingProviderName,
										}}
										components={{ email: <strong />, provider: <strong /> }}
									/>
								</Box>
								<Box as="p" m={0}>
									<Trans
										i18nKey="accountExists.action"
										values={{
											newProvider: linkAccount.newProviderName,
											existingProvider: linkAccount.existingProviderName,
										}}
									/>
								</Box>
								<Divider variant="dotted" mb={2} />

								<Button
									fluid={true}
									palette="success"
									onClick={() =>
										linkProviders(
											linkAccount.existingProvider!,
											linkAccount.pendingCredentials,
											returnRoute,
										)
									}
								>
									{t("accountExists.linkAccounts", {
										existingProvider: linkAccount.existingProviderName,
									})}
									<FaIcon icon={faMerge} ml={2} />
								</Button>
								<InvisibleButton
									fluid={true}
									palette="neutral"
									onClick={() =>
										setLinkAccount({
											status: false,
											email: "",
											newProviderName: "",
											existingProviderName: "",
											existingProvider: undefined,
											newProvider: undefined,
											pendingCredentials: undefined,
										})
									}
								>
									{t("cancel")}
								</InvisibleButton>
							</Stack>
						</div>
					)}

					{!linkAccount ||
						(!linkAccount.status && (
							<Fragment>
								{!hideHeader && (
									<Box mb={8}>
										<h1 css={styles.logo}>
											<span>{t("siteName")}</span>
										</h1>
										<Stack orientation="vertical" horizontalAlignment="center">
											<Heading as="h2">{t("signInSignup")}</Heading>
											<Divider css={styles.divider} />
										</Stack>
									</Box>
								)}
								<Box>
									<Stack spacing={4}>
										{isFacebookEnabled && (
											<Button
												type="button"
												fluid={true}
												css={[styles.button, styles.facebook]}
												onClick={() => signInWithFacebook(returnRoute)}
												disabled={false}
												data-testid="btn-sign-in-facebook"
											>
												{t("signInWithProvider", {
													providerName: "Facebook",
												})}
											</Button>
										)}
										<Button
											type="button"
											fluid={true}
											css={[styles.button, styles.google]}
											onClick={() => signInWithGoogle(returnRoute)}
											data-testid="btn-sign-in-google"
										>
											{t("signInWithProvider", {
												providerName: "Google",
											})}
										</Button>
										<Button
											type="button"
											fluid={true}
											css={[styles.button, styles.twitter]}
											onClick={() => signInWithTwitter(returnRoute)}
											data-testid="btn-sign-in-twitter"
										>
											{t("signInWithProvider", {
												providerName: "Twitter",
											})}
										</Button>
										<Button
											type="button"
											fluid={true}
											css={[styles.button, styles.microsoft]}
											onClick={() => signInWithMicrosoft(returnRoute)}
											data-testid="btn-sign-in-microsoft"
										>
											{t("signInWithProvider", {
												providerName: "Microsoft",
											})}
										</Button>
										{/*<Button*/}
										{/*	type="button"*/}
										{/*	fluid={true}*/}
										{/*	css={[styles.button, styles.apple]}*/}
										{/*	onClick={() => {*/}
										{/*		alert("not implemented");*/}
										{/*	}}*/}
										{/*	disabled={true}*/}
										{/*>*/}
										{/*	{t("signInWithProvider", {*/}
										{/*		providerName: "Apple",*/}
										{/*	})}*/}
										{/*</Button>*/}
									</Stack>
								</Box>
							</Fragment>
						))}
				</div>
			)}
		</div>
	);
};

export { SignIn };
export type { SignInProps };
