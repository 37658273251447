/** @jsxImportSource @emotion/react */
import {
	MonthSelect,
	SeatsSelect,
	useAppTranslation,
	YearSelect,
} from "@bilar/common";
import {
	StepVehicleDetailsFormValues,
	useFormButtonStyles,
	useStepTwoStyles,
	useStepVehicleDetailsValidation,
	useVehicleFormContext,
} from "@bilar/features";
import {
	Box,
	Button,
	CurrencyField,
	Divider,
	DropdownOption,
	FieldLabel,
	InvisibleButton,
	Panel,
	RadioButton,
	RichTextEditor,
	useFormWithYup,
	ValidationMessage,
} from "@bilar/ui";
import React, { FormEvent, useState } from "react";
import { SerializedEditorState } from "lexical";

type StepVehicleDetailsProps = {
	onBack: () => void;
	onContinue: () => void;
};

const StepVehicleDetails = (props: StepVehicleDetailsProps) => {
	const maxSeats = 9;

	const { t } = useAppTranslation();
	const { formValues, setStepVehicleDetailsValues } = useVehicleFormContext();
	const detailsValidationSchema = useStepVehicleDetailsValidation();
	const {
		errors,
		values,
		handleChange,
		handleSubmit,
		currencyFieldProps,
		dropDownFieldProps,
	} = useFormWithYup<StepVehicleDetailsFormValues>({
		initialValues: formValues.stepVehicleDetailsValues,
		validationSchema: detailsValidationSchema,
	});

	const initialShowCustomSeats =
		values?.vehicleNumberOfSeats !== null &&
		values.vehicleNumberOfSeats > maxSeats;

	const [showSeatsCustom, setShowSeatsCustom] = useState<boolean>(
		initialShowCustomSeats,
	);

	const styles = useStepTwoStyles(props);
	const buttonStyles = useFormButtonStyles();

	const handleChangeYear = (selOption: DropdownOption | null) => {
		if (!selOption) {
			handleChange([
				[
					"vehicleInspectedYear",
					{
						label: "",
						value: "",
					},
				],
				[
					"vehicleInspectedMonth",
					{
						label: "",
						value: "",
					},
				],
			]);
		} else {
			handleChange("vehicleInspectedYear", selOption);
		}
	};

	const handleChangeMonth = (selOption: DropdownOption | null) => {
		handleChange("vehicleInspectedMonth", selOption);
	};

	const handleSeatsChange = (selected: DropdownOption | null) => {
		if (selected === null) {
			setShowSeatsCustom(false);
			handleChange("vehicleNumberOfSeats", null);
			return;
		}

		if (selected.value === `${maxSeats + 1}`) {
			setShowSeatsCustom(true);
			handleChange("vehicleNumberOfSeats", maxSeats + 1);
		} else {
			setShowSeatsCustom(false);
			handleChange("vehicleNumberOfSeats", parseInt(selected.value));
		}
	};

	const handleCustomSeatsBlur = (value: number | null) => {
		if (value !== null && value <= maxSeats) {
			setShowSeatsCustom(false);
			if (value <= 0) {
				handleChange("vehicleNumberOfSeats", 1);
			}
		}
	};

	const handleDescriptionChange = (value: SerializedEditorState) => {
		handleChange("vehicleDescription", value);
	};

	const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		handleSubmit(
			async () => {
				setStepVehicleDetailsValues(values);
				// console.log("Submit step two", values);
				props.onContinue();
			},
			() => {
				console.log(
					"We got some validation errors in step two",
					errors,
					values,
				);
			},
		);
	};

	return (
		<Panel.Body>
			<form onSubmit={onSubmit}>
				<CurrencyField
					aria-label={t("vehicleMileage")}
					label={t("vehicleMileage")}
					endAdornment={t("km")}
					{...currencyFieldProps("vehicleMileage")}
				/>
				<Divider variant="dashed" my={4} />

				<SeatsSelect
					selectedItemValue={values.vehicleNumberOfSeats}
					maxSeats={maxSeats}
					{...dropDownFieldProps("vehicleNumberOfSeats")}
					onChange={handleSeatsChange}
				/>
				{showSeatsCustom && (
					<CurrencyField
						label={t("vehicleNumberOfSeats")}
						endAdornment={t("seats")}
						{...currencyFieldProps("vehicleNumberOfSeats")}
						onBlur={handleCustomSeatsBlur}
					/>
				)}

				<Divider variant="dashed" my={4} />

				<FieldLabel htmlFor={undefined}>{t("vehicleInspected")}</FieldLabel>
				<div css={styles.radioButtonContainer}>
					<RadioButton
						css={{ gridArea: "left" }}
						text={t("yes")}
						group="inspected"
						checked={values.vehicleInspected === true}
						layout="box"
						onChange={(checked) => handleChange("vehicleInspected", checked)}
					/>
					<RadioButton
						css={{ gridArea: "right" }}
						text={t("no")}
						group="inspected"
						checked={values.vehicleInspected === false}
						layout="box"
						onChange={(checked) => handleChange("vehicleInspected", !checked)}
					/>
					<div className="validation-error">
						<ValidationMessage error={errors.vehicleInspected} />
					</div>
				</div>
				{values.vehicleInspected && (
					<div css={styles.dropDownContainer}>
						<Box css={{ gridArea: "year" }}>
							<YearSelect
								selectedItem={values.vehicleInspectedYear ?? undefined}
								css={{ gridArea: "year" }}
								mr={[0, 4]}
								maxYears={7}
								countDirection="up"
								label={t("vehicleInspectionYear")}
								onChange={handleChangeYear}
							/>
						</Box>
						<Box css={{ gridArea: "month" }}>
							<MonthSelect
								selectedItem={values.vehicleInspectedMonth ?? undefined}
								isDisabled={
									values.vehicleInspectedYear === null ||
									!values.vehicleInspectedYear?.value
								}
								onChange={handleChangeMonth}
							/>
						</Box>
						<div className="validation-error" css={{ gridArea: "error" }}>
							<ValidationMessage
								error={
									errors.vehicleInspectedYear || errors.vehicleInspectedMonth
								}
							/>
						</div>
					</div>
				)}

				<Divider variant="dashed" my={4} />

				<FieldLabel htmlFor={undefined}>{t("vehicleTransmission")}</FieldLabel>
				<div css={styles.radioButtonContainer}>
					<RadioButton
						css={{ gridArea: "left" }}
						text={t("transmissionAutomatic")}
						group="transmissionType"
						checked={values.vehicleTransmissionType === "automatic"}
						layout="box"
						onChange={() =>
							handleChange("vehicleTransmissionType", "automatic")
						}
					/>
					<RadioButton
						css={{ gridArea: "right" }}
						text={t("transmissionManual")}
						group="transmissionType"
						checked={values.vehicleTransmissionType === "manual"}
						layout="box"
						onChange={() => handleChange("vehicleTransmissionType", "manual")}
					/>
					<div className="validation-error">
						<ValidationMessage error={errors.vehicleTransmissionType} />
					</div>
				</div>
				<Divider variant="dashed" my={4} />

				{/* TODO: Need to find a better layout, where mobile can have two radio buttons per row */}
				{/* TODO: Translations */}
				<FieldLabel htmlFor={undefined}>{t("vehicleFuelType")}</FieldLabel>
				<div css={styles.radioButtonContainer}>
					<RadioButton
						text={t("fuelTypeGasoline")}
						group="fuelType"
						checked={values.vehicleFuelType === "gasoline"}
						layout="box"
						onChange={() => {
							handleChange("vehicleFuelType", "gasoline");
						}}
					/>
					<RadioButton
						text={t("fuelTypeDiesel")}
						group="fuelType"
						checked={values.vehicleFuelType === "diesel"}
						layout="box"
						onChange={() => {
							handleChange("vehicleFuelType", "diesel");
						}}
					/>
					<RadioButton
						text={t("fuelTypeElectric")}
						group="fuelType"
						checked={values.vehicleFuelType === "electric"}
						layout="box"
						onChange={() => {
							handleChange("vehicleFuelType", "electric");
						}}
					/>
					<RadioButton
						text={t("fuelTypeHybrid")}
						group="fuelType"
						checked={values.vehicleFuelType === "hybrid"}
						layout="box"
						onChange={() => {
							handleChange("vehicleFuelType", "hybrid");
						}}
					/>
					<RadioButton
						text={t("fuelTypePluginHybrid")}
						group="fuelType"
						checked={values.vehicleFuelType === "plug-in-hybrid"}
						layout="box"
						onChange={() => {
							handleChange("vehicleFuelType", "plug-in-hybrid");
						}}
					/>
					<RadioButton
						text={t("fuelTypeMethane")}
						group="fuelType"
						checked={values.vehicleFuelType === "methane"}
						layout="box"
						onChange={() => {
							handleChange("vehicleFuelType", "methane");
						}}
					/>
					<RadioButton
						text={t("fuelTypeHydrogen")}
						group="fuelType"
						checked={values.vehicleFuelType === "hydrogen"}
						layout="box"
						onChange={() => {
							handleChange("vehicleFuelType", "hydrogen");
						}}
					/>
					<RadioButton
						text={t("fuelTypeOther")}
						group="fuelType"
						checked={values.vehicleFuelType === "other"}
						layout="box"
						onChange={() => {
							handleChange("vehicleFuelType", "other");
						}}
					/>
					<div className="validation-error">
						<ValidationMessage error={errors.vehicleFuelType} />
					</div>
				</div>
				<Divider variant="dashed" my={4} />
				<FieldLabel htmlFor={undefined}>{t("vehicleNumberOfDoors")}</FieldLabel>
				<div css={styles.radioButtonContainer}>
					<RadioButton
						text={t("twoDoors")}
						group="doors"
						checked={values.vehicleNumberOfDoors === 2}
						layout="box"
						onChange={() => {
							handleChange("vehicleNumberOfDoors", 2);
						}}
					/>
					<RadioButton
						text={t("threeDoors")}
						group="doors"
						checked={values.vehicleNumberOfDoors === 3}
						layout="box"
						onChange={() => {
							handleChange("vehicleNumberOfDoors", 3);
						}}
					/>
					<RadioButton
						text={t("fourDoors")}
						group="doors"
						checked={values.vehicleNumberOfDoors === 4}
						layout="box"
						onChange={() => {
							handleChange("vehicleNumberOfDoors", 4);
						}}
					/>
					<RadioButton
						text={t("fiveDoors")}
						group="doors"
						checked={values.vehicleNumberOfDoors === 5}
						layout="box"
						onChange={() => {
							handleChange("vehicleNumberOfDoors", 5);
						}}
					/>
					<div className="validation-error">
						<ValidationMessage error={errors.vehicleNumberOfDoors} />
					</div>
				</div>

				<Divider variant="dashed" my={4} />

				<RichTextEditor
					label={t("vehicleDescription")}
					value={values.vehicleDescription}
					onChange={handleDescriptionChange}
					error={errors.vehicleDescription}
				/>

				<Divider variant="solid" my={4} />

				<div css={buttonStyles.root}>
					<InvisibleButton
						type="button"
						onClick={props.onBack}
						css={buttonStyles.backButton}
					>
						{t("goBack")}
					</InvisibleButton>
					<Button
						type="submit"
						palette="secondary"
						css={buttonStyles.continueButton}
					>
						{t("continueToNextStep")}
					</Button>
				</div>
			</form>
		</Panel.Body>
	);
};

export { StepVehicleDetails };
export type { StepVehicleDetailsProps };
