/** @jsxImportSource @emotion/react */
import { useLanguageMenuStyles } from "./languageMenuStyles";
import React, { ReactNode } from "react";
import { Menu, Stack } from "@bilar/ui";
import Image from "next/legacy/image";
import Link from "next/link";
import isFlag from "@bilar/assets/img/flags/3x2/IS.svg";
import enFlag from "@bilar/assets/img/flags/3x2/GB.svg";
import plFlag from "@bilar/assets/img/flags/3x2/PL.svg";
import { i18n } from "next-i18next";
import { useRouter } from "next/router";
import { useAppTranslation } from "@bilar/common";

type LanguageMenuProps = {
	children?: ReactNode;
} & LanguageMenuDefaultProps;

type LanguageMenuDefaultProps = {};

const defaultProps: LanguageMenuDefaultProps = {};

const getDefaultFlag = () => {
	if (!i18n?.language) {
		return isFlag;
	}

	switch (i18n.language) {
		case "en":
			return enFlag;
		case "pl":
			return plFlag;
		default:
			return isFlag;
	}
};

const getAltText = () => {
	if (!i18n?.language) {
		return "Íslenski fáninn";
	}

	switch (i18n.language) {
		case "en":
			return "Flag of the United Kingdom";
		case "pl":
			return "Polska flaga";
		default:
			return "Íslenski fáninn";
	}
};

const LanguageMenu = (props: LanguageMenuProps) => {
	const styles = useLanguageMenuStyles(props);
	const { t } = useAppTranslation();
	const { pathname, query } = useRouter();

	return (
		<div css={styles.root}>
			<Menu id="languageMenu">
				<Menu.ButtonInvisible
					css={styles.button}
					aria-label={t("changeLanguage")}
				>
					<Image
						src={getDefaultFlag()}
						width={32}
						height={24}
						layout="fixed"
						css={styles.img}
						alt={getAltText()}
					/>
				</Menu.ButtonInvisible>
				<Menu.List css={styles.list}>
					<Menu.List.TextItem onSelect={() => {}} px={4}>
						<Link href={{ pathname, query }} locale="is">
							<Stack orientation="horizontal" verticalAlignment="center">
								<Image
									src={isFlag}
									width={32}
									height={24}
									layout="fixed"
									css={styles.img}
									alt="Íslenski fáninn"
								/>
								<span css={styles.itemText}>Íslenska</span>
							</Stack>
						</Link>
					</Menu.List.TextItem>
					<Menu.List.TextItem onSelect={() => {}} px={4}>
						<Link href={{ pathname, query }} locale="en">
							<Stack orientation="horizontal" verticalAlignment="center">
								<Image
									src={enFlag}
									width={32}
									height={24}
									layout="fixed"
									css={styles.img}
									alt="Flag of the United Kingdom"
								/>
								<span css={styles.itemText}>English</span>
							</Stack>
						</Link>
					</Menu.List.TextItem>
					{/*<Menu.List.TextItem onSelect={() => {}} px={4}>
						<Link href={{ pathname, query }} locale="pl">
							<a>
								<Stack orientation="horizontal" verticalAlignment="center">
									<Image
										src={plFlag}
										width={32}
										height={24}
										layout="fixed"
										css={styles.img}
										alt="Polska flaga"
									/>
									<span css={styles.itemText}>Polski</span>
								</Stack>
							</a>
						</Link>
					</Menu.List.TextItem>*/}
				</Menu.List>
			</Menu>
		</div>
	);
};

LanguageMenu.defaultProps = defaultProps;

export { LanguageMenu };
export type { LanguageMenuProps };
