import { useEffect, useState } from "react";

const getWidth = () => {
	if (typeof window === "undefined") {
		return 0;
	}

	return (
		window.innerWidth ||
		document.documentElement.clientWidth ||
		document.body.clientWidth
	);
};
const getHeight = () => {
	if (typeof window === "undefined") {
		return 0;
	}

	return (
		window.innerHeight ||
		document.documentElement.clientHeight ||
		document.body.clientHeight
	);
};

export function useBrowserSize() {
	let [size, setSize] = useState({
		width: getWidth(),
		height: getHeight(),
	});

	useEffect(() => {
		const resizeListener = () => {
			setSize({
				width: getWidth(),
				height: getHeight(),
			});
		};

		// Set resize listener
		window.addEventListener("resize", resizeListener);

		// Clean up function
		return () => {
			// Remove resize listener
			window.removeEventListener("resize", resizeListener);
		};
	}, []);

	return size;
}
