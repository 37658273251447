import { CSSObject } from "@emotion/react";
import { DividerProps } from "./Divider";
import { useDesignTokens } from "../..";

type DividerStyles = {
	root: CSSObject;
};

const useDividerStyles = (props: DividerProps): DividerStyles => {
	const designTokens = useDesignTokens();
	const { borders, palette, space } = designTokens;

	const borderStyle = props.variant;

	const commonPseudoStyles: CSSObject = {
		content: "''",
		display: "block",
		width: "100%",
	};

	return {
		root: {
			appearance: "none",
			position: "relative",
			margin: 0,
			padding: 0,
			height: space[2],
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			lineHeight: 0,
			border: "none",
			overflow: "visible",
			"&:before": {
				...commonPseudoStyles,
				borderTop: `1px ${borderStyle} ${palette.neutral.light.background}`,
			},
			"&:after": {
				...commonPseudoStyles,
				borderBottom: `1px ${borderStyle} ${palette.neutral.lighter.background}`,
			},
		},
	};
};

export { useDividerStyles };
export type { DividerStyles };
