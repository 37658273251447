import { CSSObject } from "@emotion/react";
import { SignInProps } from "./SignIn";
import { useDesignTokens } from "@bilar/ui";
import chroma from "chroma-js";
import logo from "@bilar/assets/img/bilar-logo-green.svg";
import facebook from "@bilar/assets/img/social/facebook/logo-circle.svg";
import google from "@bilar/assets/img/social/google/logo-square.svg";
import twitter from "@bilar/assets/img/social/twitter/logo-circle.svg";
import apple from "@bilar/assets/img/social/apple/logo.svg";
import microsoft from "@bilar/assets/img/social/microsoft/logo.svg";

type SignInStyles = {
	root: CSSObject;
	logo: CSSObject;
	divider: CSSObject;
	button: CSSObject;
	facebook: CSSObject;
	google: CSSObject;
	twitter: CSSObject;
	microsoft: CSSObject;
	apple: CSSObject;
};

const useSignInStyles = (props: SignInProps): SignInStyles => {
	const designTokens = useDesignTokens();
	const { palette, space, colors, mediaQueries, fontSizes } = designTokens;
	return {
		root: {
			width: "100%",
			// minHeight: space[96],
		},
		logo: {
			height: space[12],
			backgroundImage: `url(${logo})`,
			backgroundRepeat: "no-repeat",
			backgroundSize: "contain",
			backgroundPosition: "50% 0",
			margin: `0 0 ${space[8]}`,

			span: {
				display: "none",
			},
		},
		divider: {
			width: "100%",
			height: space[2],
			"&:before": {
				border: "none",
			},
			"&:after": {
				borderColor: palette.success.main.background,
				borderWidth: 2,
			},
		},
		button: {
			padding: `0 ${space[4]} 0 ${space[12]}`,
			textAlign: "left",
			borderRadius: space[2],
			backgroundRepeat: "no-repeat",
			backgroundSize: space[6],
			backgroundPosition: `${space[3]} 50%`,
			minHeight: space[12],

			fontSize: fontSizes.md,

			[mediaQueries.tabletAndAbove]: {
				fontSize: fontSizes.lg,
			},
		},
		facebook: {
			backgroundColor: colors.facebook,
			backgroundImage: `url(${facebook})`,
			"&:hover": {
				backgroundColor: chroma(colors.facebook).darken(0.5).hex(),
			},
		},
		google: {
			backgroundColor: colors.google,
			backgroundImage: `url(${google})`,
			backgroundSize: space[8],
			backgroundPositionX: space[2],
			"&:hover": {
				backgroundColor: chroma(colors.google).darken(0.5).hex(),
			},
		},
		twitter: {
			backgroundColor: colors.twitter,
			backgroundImage: `url(${twitter})`,
			"&:hover": {
				backgroundColor: chroma(colors.twitter).darken(0.5).hex(),
			},
		},
		microsoft: {
			color: colors.grey["700"],
			backgroundColor: colors.microsoft,
			backgroundImage: `url(${microsoft})`,
			border: `1px solid ${colors.grey["300"]}`,

			"&:hover": {
				color: colors.grey["700"],
				backgroundColor: colors.grey["200"],
			},
		},
		apple: {
			backgroundColor: colors.apple,
			backgroundImage: `url(${apple})`,

			"&:hover": {
				backgroundColor: "#292929",
			},
		},
	};
};

export { useSignInStyles };
