/** @jsxImportSource @emotion/react */
import { Global } from "@emotion/react";
import { Fragment } from "react";
import {
	Menu as ReachMenu,
	MenuProps as ReachMenuProps,
} from "@reach/menu-button";
import { baseMenuStyles } from "./menuStyles";
import {
	MenuButton,
	MenuButtonCustom,
	MenuButtonInvisible,
	MenuButtonLink,
} from "./MenuButton";
import { MenuList, MenuListProps, MenuListStyleProps } from "./MenuList";

type MenuProps = {} & ReachMenuProps;

/**
 * The `<Menu>` component is the parent element for a menu that opens on button click.
 */
const Menu = (props: MenuProps) => {
	return (
		<Fragment>
			<ReachMenu {...props}>{props.children}</ReachMenu>
			<Global styles={baseMenuStyles} />
		</Fragment>
	);
};

Menu.Button = MenuButton;
Menu.ButtonLink = MenuButtonLink;
Menu.ButtonCustom = MenuButtonCustom;
Menu.ButtonInvisible = MenuButtonInvisible;
Menu.List = MenuList;

export { Menu };
export type { MenuProps, MenuListProps, MenuListStyleProps };
