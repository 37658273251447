import React, { createContext, ReactNode, useContext } from "react";
import { DesignTokens } from "@bilar/ui";
import { getBaseStyles } from "./baseStyles";
import { resetStyles } from "./cssReset";
import { Global } from "@emotion/react";

type DesignTokensProviderProps = {
	designTokens: DesignTokens;
	children: ReactNode;
};

const DesignTokensProviderContext = createContext<DesignTokens | undefined>(
	undefined,
);

const DesignTokensProvider = (props: DesignTokensProviderProps) => {
	const baseStyles = getBaseStyles(props.designTokens);
	return (
		<DesignTokensProviderContext.Provider value={props.designTokens}>
			{props.children}
			<Global styles={[resetStyles, baseStyles]} />
		</DesignTokensProviderContext.Provider>
	);
};

const useDesignTokens = (): DesignTokens => {
	const value = useContext(DesignTokensProviderContext);

	if (value === undefined)
		throw new Error(
			"Did not get any design tokens, you probably forgot to set up your DesignTokensProvider",
		);

	return value;
};

export { DesignTokensProvider, useDesignTokens };
export type { DesignTokensProviderProps };
