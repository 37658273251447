import { CSSObject } from "@emotion/react/dist/emotion-react.cjs";
import { DialogOverrideStyles, useDesignTokens } from "@bilar/ui";

type VehicleListAllStyles = {
	root: CSSObject;
	list: CSSObject;
	modal: DialogOverrideStyles;
};
export const useVehicleListAllStyles = (): VehicleListAllStyles => {
	const { size, space, mediaQueries, radii } = useDesignTokens();

	return {
		root: {},
		list: {
			display: "grid",
			gap: size(6),
		},
		modal: {
			content: {
				root: {
					width: "100%",
					margin: 0,
					borderRadius: 0,
					[mediaQueries.tabletAndAbove]: {
						width: `calc(100vw - ${space[12]})`, // margins * 2
						margin: `${space[6]}`,
						borderRadius: radii.lg,
					},
				},
				animationWrapper: {},
				inner: {},
			},
			footer: {
				borderBottomLeftRadius: 0,
				borderBottomRightRadius: 0,
				[mediaQueries.tabletAndAbove]: {
					borderBottomLeftRadius: radii.lg,
					borderBottomRightRadius: radii.lg,
				},
			},
		},
	};
};
