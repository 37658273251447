import React from "react";

export type HtmlTag = React.ElementType<React.HTMLAttributes<HTMLElement>>;

export type VerticalAlign =
	| "baseline"
	| "bottom"
	| "inherit"
	| "initial"
	| "middle"
	| "sub"
	| "super"
	| "text-bottom"
	| "text-top"
	| "top"
	| "unset";

export type PaletteOptions =
	| "primary"
	| "secondary"
	| "highlight"
	| "neutral"
	| "error"
	| "success"
	| "warning"
	| "grey"
	| "info";

export type PaletteShadeOptions =
	| "lighter"
	| "light"
	| "main"
	| "dark"
	| "darker";

export type FontAlignment = "left" | "right" | "center" | "justify";

export type PaletteProps = {
	palette?: PaletteOptions;
	paletteShade?: PaletteShadeOptions;
};

export type RecursivePartial<T> = {
	[P in keyof T]?: RecursivePartial<T[P]>;
};

/**
 * Used when we only need a part of all the union types
 * Ex: type AppCountryCode = Subset<Iso2CountryCode, "SE" | "NO" | "FI">;
 */
type Subset<Parent, Sub extends Parent> = Extract<Parent, Sub>;

/**
 * Utility type to use when we need at least one property from a type
 */
type RequireAtLeastOne<T> = {
	[K in keyof T]-?: Required<Pick<T, K>> &
		Partial<Pick<T, Exclude<keyof T, K>>>;
}[keyof T];

/**
 * Utility type to make all the properties and sub-properties of a type optional
 */
type DeepPartial<T> = {
	[P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

// https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
// https://www.iana.org/assignments/language-subtag-registry/language-subtag-registry
type LanguageCode =
	| "is"
	| "pl"
	| "es"
	| "sv"
	| "nb"
	| "fi"
	| "de"
	| "da"
	| "en";

export * from "./countryTypes";
export type { LanguageCode, RequireAtLeastOne, DeepPartial, Subset };

export type ValidationResult = {
	isValid: boolean;
	errorMessage?: string;
};
