/** @jsxImportSource @emotion/react */
import { ReactNode } from "react";
import { BaseProps, Tag } from "../";
import { ResponsiveProp, useDesignTokens } from "../../styling";
import { getTestId } from "../../utils";
import { HtmlTag } from "@bilar/ui";

export type VisibilityProps = {
	children?: ReactNode;
	visible: ResponsiveProp<boolean>;
} & VisibilityDefaultProps &
	BaseProps;

type VisibilityDefaultProps = { as: HtmlTag };

const defaultProps: VisibilityDefaultProps = { as: "div" };

/** The `<Visibility>` component gives you control whether or not an element is visible on desktop or mobile. */
export const Visibility = (props: VisibilityProps) => {
	const { utils } = useDesignTokens();
	const { testId } = props;

	const visibilityStyles = utils.responsivePropToCss(
		props.visible,
		"display",
		(visible) => {
			if (visible === undefined) return undefined;

			if (visible) {
				return "block";
			} else {
				return "none";
			}
		},
	);

	return (
		<Tag
			as={props.as}
			className={props.className}
			id={props.id}
			css={visibilityStyles}
			testId={getTestId(testId)}
		>
			{props.children}
		</Tag>
	);
};

Visibility.defaultProps = defaultProps;
