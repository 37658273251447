/** @jsxImportSource @emotion/react */
import { useVisuallyHiddenStyles } from "./visuallyHiddenStyles";
import { ReactNode } from "react";

type VisuallyHiddenProps = {
	children?: ReactNode;
};

const VisuallyHidden = (props: VisuallyHiddenProps) => {
	const styles = useVisuallyHiddenStyles(props);

	return <span css={styles.root}>{props.children}</span>;
};

export { VisuallyHidden };
export type { VisuallyHiddenProps };
