import { CSSObject } from "@emotion/react";
import { PaletteShadeOptions, useDesignTokens } from "@bilar/ui";
import { PaletteTokens } from "../../styling/designTokens/palette";

export const useTooltipStyles = (
	_palette: keyof Omit<PaletteTokens, "white" | "black">,
	shade: PaletteShadeOptions,
): CSSObject => {
	const { size, fontSizes, palette, radii } = useDesignTokens();
	const paletteShade = palette[_palette][shade];

	return {
		color: paletteShade.contrast,
		backgroundColor: paletteShade.background,
		borderRadius: radii.md,
		pointerEvents: "none",
		paddingLeft: size(2),
		paddingRight: size(2),
		paddingTop: size(1),
		paddingBottom: size(1),
		fontSize: fontSizes.sm,
		maxWidth: 400,
	};
};
