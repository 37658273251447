import { CSSObject } from "@emotion/react";
import { SpacingSize, useDesignTokens } from "../../../styling";
import { PhoneFieldProps } from "./PhoneField";

type PhoneFieldStyles = {
	adornment: {
		flag: CSSObject;
		chevron: CSSObject;
		button: CSSObject;
		countryCode: CSSObject;
	};
	menuList: CSSObject;
	countryDivider: CSSObject;
	countryFilter: CSSObject;
	listItem: {
		root: CSSObject;
		flag: CSSObject;
		name: CSSObject;
		callingCode: CSSObject;
	};
};

const usePhoneFieldStyles = (
	props: Omit<PhoneFieldProps, "countries">,
	error?: string,
): PhoneFieldStyles => {
	const { size, palette, fontWeights } = useDesignTokens();
	// NOTE: Because of this aspect ratio, if using the height or width, one or the other is not "in the 4px grid",
	// so... do we want the height or the width "in the grid"?
	const flagRatio = 3 / 2;
	let adornmentFlagHeight: SpacingSize = 24;
	let buttonPadding: SpacingSize = 2;

	switch (props.size) {
		case "small":
			adornmentFlagHeight = 24;
			break;
		case "regular":
			adornmentFlagHeight = 24;
			buttonPadding = 3;
			break;
		case "large":
			adornmentFlagHeight = 32;
			buttonPadding = 4;
			break;
	}

	return {
		adornment: {
			flag: {
				height: adornmentFlagHeight,
				width: adornmentFlagHeight * flagRatio,
				display: "flex",
				// NOTE: This fixes the flag icon not appearing in Safari
				"> svg": {
					width: "100%",
				},
			},
			chevron: {
				opacity: 0.5,
				marginLeft: size(2),
			},
			button: {
				paddingLeft: size(buttonPadding),
				paddingRight: size(buttonPadding),
				borderColor: error ? palette.error.light.background : undefined,
			},
			countryCode: {
				borderLeft: `1px dotted ${
					error ? palette.error.main.contrast : palette.neutral.main.background
				}`,
				paddingLeft: size(2),
				height: adornmentFlagHeight,
				marginLeft: size(2),
				display: "flex",
				alignItems: "center",
				fontWeight: fontWeights.medium,
			},
		},
		menuList: {
			maxHeight: "50vh",
			overflow: "auto",
		},
		countryDivider: {
			paddingTop: size(1),
			paddingBottom: size(1),
		},
		countryFilter: {
			margin: size(2),
		},
		listItem: {
			root: {
				display: "flex",
				alignItems: "center",
			},
			flag: {
				display: "inline-flex",
				height: 20,
				width: 20 * flagRatio,
				marginRight: size(3),
				flexShrink: 0,
			},
			callingCode: {
				marginLeft: size(4),
				flexShrink: 0,
			},
			name: {
				flexGrow: 1,
				whiteSpace: "nowrap",
				overflow: "hidden",
				textOverflow: "ellipsis",
			},
		},
	};
};

export { usePhoneFieldStyles };
export type { PhoneFieldStyles };
