import colors from "./colors";
import { size, spacingSizes } from "./sizes";
import typography from "./typography";
import styles from "./styles";
import palette from "./palette";
import { DesignTokens } from "./types";
import shadows from "./shadows";
import breakpoints, { breakpointWidths } from "./breakpoints";
import mediaQueries from "./mediaQueries";
import zIndices from "./zIndices";
import borders, { bordersNew } from "./borders";
import radii from "./radius";
import opacity from "./opacity";
import utils from "../utils";

const designTokens: DesignTokens = {
	borders: borders,
	bordersNew: bordersNew,
	breakpoints: breakpoints,
	breakpointWidths,
	colors: colors,
	fontSizes: typography.fontSizes,
	fontWeights: typography.fontWeights,
	fonts: typography.fonts,
	fontColors: typography.fontColors,
	letterSpacings: typography.letterSpacings,
	lineHeights: typography.lineHeights,
	mediaQueries: mediaQueries,
	opacity: opacity,
	radii: radii,
	shadows: shadows,
	space: spacingSizes,
	size: size,
	zIndices: zIndices,
	palette: palette,
	styles: styles,
	utils: utils,
};

export const gridSystem = {
	size: 4,
};

//export const sizingMode = 'rem'

export { designTokens };
