import { blue, lightBlue } from "@mui/material/colors";
import { Colors } from "./types";
import chroma from "chroma-js";

const colors: Colors = {
	transparent: "transparent",
	current: "currentColor",

	facebook: "#1A77F2",
	google: "#4285F4",
	twitter: "#1D9BF0",
	apple: "#000",
	microsoft: "#fff",

	grey: {
		50: "#F7F7F7",
		100: "#F2F2F2",
		200: "#E6E6E6",
		300: "#D9D9D9",
		400: "#CCCCCC",
		500: "#B3B3B3",
		600: "#808080",
		700: "#666666",
		800: "#4D4D4D",
		900: "#333333",
	},

	black: {
		50: chroma.hsl(0, 0, 0.9).hex(),
		100: chroma.hsl(0, 0, 0.8).hex(),
		200: chroma.hsl(0, 0, 0.7).hex(),
		300: chroma.hsl(0, 0, 0.6).hex(),
		400: chroma.hsl(0, 0, 0.5).hex(),
		500: chroma.hsl(0, 0, 0.4).hex(),
		600: chroma.hsl(0, 0, 0.3).hex(),
		700: chroma.hsl(0, 0, 0.2).hex(),
		800: chroma.hsl(0, 0, 0.1).hex(),
		900: chroma.hsl(0, 0, 0).hex(),
	},

	white: "#fff",

	lightBlue,

	whiteAlpha: {
		50: "rgba(255, 255, 255, 0.04)",
		100: "rgba(255, 255, 255, 0.06)",
		200: "rgba(255, 255, 255, 0.08)",
		300: "rgba(255, 255, 255, 0.16)",
		400: "rgba(255, 255, 255, 0.24)",
		500: "rgba(255, 255, 255, 0.36)",
		600: "rgba(255, 255, 255, 0.48)",
		700: "rgba(255, 255, 255, 0.64)",
		800: "rgba(255, 255, 255, 0.80)",
		900: "rgba(255, 255, 255, 0.92)",
	},

	blackAlpha: {
		50: "rgba(0, 0, 0, 0.04)",
		100: "rgba(0, 0, 0, 0.06)",
		200: "rgba(0, 0, 0, 0.08)",
		300: "rgba(0, 0, 0, 0.16)",
		400: "rgba(0, 0, 0, 0.24)",
		500: "rgba(0, 0, 0, 0.36)",
		600: "rgba(0, 0, 0, 0.48)",
		700: "rgba(0, 0, 0, 0.64)",
		800: "rgba(0, 0, 0, 0.80)",
		900: "rgba(0, 0, 0, 0.92)",
	},

	blueGray: {
		50: "#F0F4F8",
		100: "#D9E2EC",
		200: "#BCCCDC",
		300: "#9FB3C8",
		400: "#829AB1",
		500: "#627D98",
		600: "#486581",
		700: "#334E68",
		800: "#243B53",
		900: "#102A43",
	},

	blue,

	gray: {
		50: "#F8F8F8",
		100: "#F0F0F0",
		200: "#E7E7E7",
		300: "#DEDEDE",
		400: "#D8D8D8",
		500: "#BFBFBF",
		600: "#B5B5B5",
		700: "#ADADAD",
		800: "#A6A6A6",
		900: "#9C9C9C",
	},

	red: {
		50: "#FFEEEE",
		100: "#FACDCD",
		200: "#F29B9B",
		300: "#E66A6A",
		400: "#D64545",
		500: "#BA2525",
		600: "#A61B1B",
		700: "#911111",
		800: "#780A0A",
		900: "#610404",
	},

	orange: {
		50: "#FFC9B7",
		100: "#FFA68A",
		200: "#FF906E",
		300: "#FD7C53",
		400: "#FF6838",
		500: "#F6511D",
		600: "#E14514",
		700: "#CE390A",
		800: "#AD2E07",
		900: "#852102",
	},

	yellow: {
		50: "#FFFAEB",
		100: "#FCEFC7",
		200: "#F8E3A3",
		300: "#F9DA8B",
		400: "#F7D070",
		500: "#E9B949",
		600: "#C99A2E",
		700: "#A27C1A",
		800: "#7C5E10",
		900: "#513C06",
	},

	green: {
		50: "#DCEFDD",
		100: "#C8E6C9",
		200: "#A3D7A5",
		300: "#7FC782",
		400: "#5BB85F",
		500: "#4CAF50", // maybe change to #2C812F ?
		600: "#388E3C",
		700: "#2B6E2E",
		800: "#1D491E",
		900: "#163717",
	},

	pink: {
		50: "#FFE3EC",
		100: "#FFB8D2",
		200: "#FF8CBA",
		300: "#F364A2",
		400: "#E8368F",
		500: "#DA127D",
		600: "#BC0A6F",
		700: "#A30664",
		800: "#870557",
		900: "#620042",
	},
};

export default colors;
