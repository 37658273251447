import { useEffect, useState } from "react";

export const useWindowHistoryState = () => {
	const [path, setPath] = useState("");

	useEffect(() => {
		if (typeof window !== "undefined") {
			setPath(window.history.state);

			const listenToPopstate = () => {
				const winPath = window.history.state;
				setPath(winPath);
			};

			window.addEventListener("popstate", listenToPopstate);
			return () => {
				window.removeEventListener("popstate", listenToPopstate);
			};
		}
	}, []);

	return path;
};
