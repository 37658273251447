/** @jsxImportSource @emotion/react */
import { useMyClassifiedItemStyles } from "./myClassifiedItemStyles";
import {
	patchVehicleInDb,
	useDisplayFormat,
	useVehicleClassifiedsDb,
} from "@bilar/features";
import {
	Box,
	Button,
	Dialog,
	FaIcon,
	Heading,
	Paper,
	Stack,
	useCalendarDate,
} from "@bilar/ui";
import { ClassifiedVehicle } from "@bilar/models";
import Image from "next/legacy/image";
import mainImagePlaceholder from "@bilar/assets/img/logo-green-car-only.svg";
import { routes } from "@bilar/config";
import { imageSizeConfig } from "@bilar/utils";
import Link from "next/link";
import {
	faArrowUpRightFromSquare,
	faInfoCircle,
	faWarning,
} from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useState } from "react";
import { useAuthUser } from "@bilar/auth";

type MyClassifiedItemProps = {
	data: ClassifiedVehicle;
	onDeleted: () => void;
	onSold: (sold: boolean) => void;
	onPublish: (published: boolean) => void;
};

const MyClassifiedItem = (props: MyClassifiedItemProps) => {
	const imageWidth = imageSizeConfig.xs.width;
	const styles = useMyClassifiedItemStyles(props, imageWidth);
	const { t } = useTranslation();
	const { getBrandDisplay, getPriceDisplay } = useDisplayFormat();
	const router = useRouter();
	const { data, onDeleted, onSold, onPublish } = props;
	const {
		id,
		vehicleInfo,
		description,
		mainImageUrl,
		createdAt,
		sold,
		published,
		expired,
		expiresAt,
	} = data;
	const calendarCreatedAt = useCalendarDate(createdAt);
	const calendarExpiresAt = useCalendarDate(expiresAt);
	const detailsPath = routes.vehicleClassifieds.index;
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showSoldModal, setShowSoldModal] = useState(false);
	const [showPublishModal, setShowPublishModal] = useState(false);
	const { deleteVehicle } = useVehicleClassifiedsDb();
	const { user } = useAuthUser();

	const handleDelete = async () => {
		if (!user) {
			return;
		}

		try {
			await deleteVehicle(user, data);
			onDeleted();
		} catch (error) {
			console.error("Error deleting vehicle", error);
			return;
		}
	};

	const handlePublish = async () => {
		if (!user) {
			return;
		}

		try {
			await patchVehicleInDb(id, {
				published: !published,
				sold: !published ? false : sold ?? false, // !published because it's the previous state
			});
			onPublish(true);
			setShowPublishModal(false);
		} catch (error) {
			console.error("Error publishing/un-publishing vehicle", error);

			return;
		}
	};

	const handleMarkAsSold = async () => {
		if (!user) {
			return;
		}

		try {
			await patchVehicleInDb(id, {
				sold: !sold,
				published: !sold ? false : published, // !sold because it's the previous state
			});
			onSold(true);
			setShowSoldModal(false);
		} catch (error) {
			console.error("Error marking vehicle as sold", error);
			return;
		}
	};

	return (
		<Paper as="article" css={styles.root} p={4}>
			<div css={styles.container}>
				{mainImageUrl ? (
					<figure css={styles.imgContainer}>
						<Image
							alt={`${description}`}
							src={mainImageUrl}
							css={styles.img}
							width={imageWidth}
							height={imageWidth * 0.75}
							quality={50}
							layout="fixed"
							placeholder="blur"
							blurDataURL="/blur.png"
						/>
					</figure>
				) : (
					<div css={[styles.imgContainer, styles.imgPlaceHolder]}>
						<Image
							alt={`${description}`}
							src={mainImagePlaceholder}
							css={styles.img}
							width={38}
							height={32}
							quality={50}
							layout="fixed"
						/>
						<p css={styles.noImagesText}>No images</p>
					</div>
				)}
				<div css={styles.contentContainer}>
					<Heading as="h4" size="small" css={styles.priceHeading}>
						{getPriceDisplay(data)}
					</Heading>
					<Heading
						as="h4"
						size="small"
						weight="normal"
						css={styles.brandHeading}
					>
						{getBrandDisplay(vehicleInfo)}
					</Heading>
					<div>
						{t("createdAt")}: {calendarCreatedAt}
					</div>
					<div css={{ color: expired ? "red" : "inherit" }}>
						{expired ? (
							<>
								<FaIcon icon={faWarning} /> {t("expired")}
							</>
						) : (
							t("expiresAt")
						)}
						: {calendarExpiresAt}
					</div>
				</div>
				<Stack
					orientation="vertical"
					horizontalAlignment={["stretch", "right"]}
					verticalAlignment="space-between"
				>
					<Box my={[5, 0]}>
						{sold ? (
							<Heading as="h4" size="small">
								{t("vehicleSold")}
							</Heading>
						) : (
							<Link
								href={{
									pathname: `${detailsPath}/[vehicleAdId]`,
									query: { vehicleAdId: id },
								}}
								as={`${detailsPath}/${id}`}
								passHref
								target="_blank"
							>
								<Stack orientation="horizontal" verticalAlignment="center">
									{t("view")}
									<FaIcon icon={faArrowUpRightFromSquare} ml={2} />
								</Stack>
							</Link>
						)}
					</Box>

					<Stack
						horizontalAlignment={["stretch", "right"]}
						spacing={2}
						orientation={["vertical", "vertical", "horizontal"]}
					>
						<Button
							palette="neutral"
							onClick={() => router.push(`${routes.profile.classifieds}/${id}`)}
						>
							{t("edit")}
						</Button>
						<Button palette="error" onClick={() => setShowDeleteModal(true)}>
							{t("delete")}
						</Button>
						<Button
							palette={sold ? "secondary" : "success"}
							onClick={() => setShowSoldModal(true)}
						>
							{sold ? t("unmarkAsSold") : t("markAsSold")}
						</Button>
						<Button
							palette={published ? "highlight" : "grey"}
							onClick={() => setShowPublishModal(true)}
						>
							{published ? t("unpublish") : t("publish")}
						</Button>
					</Stack>
				</Stack>
			</div>

			<Dialog
				isOpen={showDeleteModal}
				onDismiss={() => setShowDeleteModal(false)}
				heading={t("deleteClassified")}
				footerComponent={
					<>
						<Button
							type="button"
							palette="neutral"
							onClick={() => setShowDeleteModal(false)}
						>
							{t("no")}
						</Button>
						<Button type="button" palette="error" onClick={handleDelete}>
							{t("yes")}
						</Button>
					</>
				}
			>
				<p>
					{t("deleteClassifiedMessage", {
						name: `${vehicleInfo.brandName} - ${vehicleInfo.modelName}`,
					})}
				</p>
				<Box as="p" mb={0}>
					<FaIcon icon={faInfoCircle} mr={2} />
					{t("timeToEffectMessage")}
				</Box>
			</Dialog>

			<Dialog
				isOpen={showPublishModal}
				onDismiss={() => setShowPublishModal(false)}
				heading={
					published
						? t("unpublishClassifiedTitle")
						: t("publishClassifiedTitle")
				}
				footerComponent={
					<>
						<Button
							type="button"
							palette="neutral"
							onClick={() => setShowPublishModal(false)}
						>
							{t("no")}
						</Button>
						<Button type="button" palette="error" onClick={handlePublish}>
							{t("yes")}
						</Button>
					</>
				}
			>
				{published ? (
					<p>{t("unpublishClassifiedMessage")}</p>
				) : (
					<p>{t("publishClassifiedMessage")}</p>
				)}
				<Box as="p" mb={0}>
					<FaIcon icon={faInfoCircle} mr={2} />
					{t("timeToEffectMessage")}
				</Box>
			</Dialog>

			<Dialog
				isOpen={showSoldModal}
				onDismiss={() => setShowSoldModal(false)}
				heading={sold ? t("unmarkAsSoldQuestion") : t("markAsSoldQuestion")}
				footerComponent={
					<>
						<Button
							type="button"
							palette="neutral"
							onClick={() => setShowSoldModal(false)}
						>
							{t("no")}
						</Button>
						<Button type="button" palette="error" onClick={handleMarkAsSold}>
							{t("yes")}
						</Button>
					</>
				}
			>
				{sold ? (
					<p>{t("unmarkAsSoldClassifiedMessage")}</p>
				) : (
					<p>{t("markAsSoldClassifiedMessage")}</p>
				)}
				<Box as="p" mb={0}>
					<FaIcon icon={faInfoCircle} mr={2} />
					{t("timeToEffectMessage")}
				</Box>
			</Dialog>
		</Paper>
	);
};

export { MyClassifiedItem };
export type { MyClassifiedItemProps };
