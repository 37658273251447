import {
	OldGalleryImage,
	useAdDetailsStyles,
	useDisplayFormat,
	VehicleImageGallery,
	VehicleInfoChips,
} from "@bilar/features";
import {
	Avatar,
	Box,
	Button,
	Divider,
	FaIcon,
	formatMileage,
	Heading,
	InvisibleButton,
	RichTextView,
	Stack,
	Tooltip,
	TooltipContent,
	TooltipTrigger,
	usePhoneNumber,
	Visibility,
} from "@bilar/ui";
import { useAppTranslation } from "@bilar/common";
import { ClassifiedVehicle } from "@bilar/models";
import {
	faAt,
	faCopy,
	faEnvelope,
	faPhone,
} from "@fortawesome/pro-solid-svg-icons";
import { splitEmail } from "@bilar/utils";
import copy from "copy-to-clipboard";

type VehicleDetailsProps = {
	data: ClassifiedVehicle;
	images: OldGalleryImage[];
	previewMode?: boolean;
};

const VehicleHeading = (props: VehicleDetailsProps) => {
	const styles = useAdDetailsStyles(props);
	const { getBrandDisplay, getPriceDisplay } = useDisplayFormat();
	const { data } = props;

	return (
		<div>
			<Heading as="h2" css={styles.vehicleInfo.nameHeading}>
				{getBrandDisplay(data.vehicleInfo)}
			</Heading>
			<Heading as="h3" css={styles.vehicleInfo.priceHeading}>
				{getPriceDisplay(data)}
			</Heading>
		</div>
	);
};

const VehicleDetails = (props: VehicleDetailsProps) => {
	const styles = useAdDetailsStyles(props);
	const { t } = useAppTranslation();
	const { formatPhoneNumber } = usePhoneNumber();
	const { data, images, previewMode } = props;

	return (
		<div css={styles.root}>
			<div css={styles.grid}>
				<Stack
					css={{
						gridArea: "details",
					}}
					verticalAlignment="space-between"
					spacing={6}
				>
					<Stack spacing={8}>
						<Visibility as="header" visible={[false, false, true]}>
							<VehicleHeading {...props} />
						</Visibility>
						<dl css={styles.detailsList.root}>
							<dt css={styles.detailsList.list}>{t("vehicleYear")}</dt>
							<dd css={[styles.detailsList.list, styles.detailsList.data]}>
								{data.vehicleInfo.year}
							</dd>

							<dt css={styles.detailsList.list}>{t("vehicleMileage")}</dt>
							<dd css={[styles.detailsList.list, styles.detailsList.data]}>
								{data.vehicleInfo.mileage &&
									formatMileage(data.vehicleInfo.mileage.mileage)}{" "}
								{data.vehicleInfo.mileage?.metricName}
							</dd>

							<dt css={styles.detailsList.list}>{t("vehicleInspected")}</dt>
							<dd css={[styles.detailsList.list, styles.detailsList.data]}>
								{data.vehicleInfo.inspected ? t("yes") : t("no")}
								{data.vehicleInfo.inspected?.year && (
									<> ({data.vehicleInfo.inspected.year})</>
								)}
							</dd>
							<dt css={styles.detailsList.list}>{t("willingToTrade")}</dt>
							<dd css={[styles.detailsList.list, styles.detailsList.data]}>
								{data.willingToTrade ? t("yes") : t("no")}
							</dd>
						</dl>

						<VehicleInfoChips data={data.vehicleInfo} />
					</Stack>

					<Stack spacing={5}>
						<Stack spacing={1}>
							<Heading as="h5" size="regular" weight="light" mb={0}>
								{t("seller")}
							</Heading>
							<Stack
								orientation="horizontal"
								verticalAlignment="top"
								spacing={4}
								css={styles.sellerCard}
							>
								<Avatar
									user={data.user}
									src={data.user.profileImageUrl}
									imgWidth={48}
									imgHeight={48}
								/>
								<Box>
									<Heading as="h5" size="small" data-rp-mask="true">
										{`${data.user.name.first} ${data.user.name.last}`}
									</Heading>
									{!data.hidePhoneNumber && (
										<Stack
											orientation="horizontal"
											verticalAlignment="center"
											as="p"
											spacing={1}
											mb={0}
										>
											<FaIcon icon={faPhone} />
											<Tooltip>
												<TooltipTrigger>
													<a
														href={`tel:${formatPhoneNumber(
															data.contactPhone.internationalNumber!,
															"e164",
														)}`}
														data-rp-mask="true"
													>
														{data.contactPhone.internationalNumber}
													</a>
												</TooltipTrigger>
												<TooltipContent>
													{`${t("callNumber")} ${
														data.contactPhone.internationalNumber
													}`}
												</TooltipContent>
											</Tooltip>
										</Stack>
									)}
									{!data.hideEmail && (
										<Stack
											orientation="horizontal"
											verticalAlignment="center"
											as="p"
											spacing={1}
											mb={0}
										>
											<FaIcon icon={faEnvelope} />
											<Tooltip>
												<TooltipTrigger>
													<a
														href={`mailto:${data.contactEmail.address}`}
														css={{
															textWrap: "nowrap",
														}}
														data-rp-mask="true"
													>
														{splitEmail(data.contactEmail.address)[0]}
														<FaIcon icon={faAt} />
														{splitEmail(data.contactEmail.address)[1]}
													</a>
												</TooltipTrigger>
												<TooltipContent>
													{`${t("sendEmailTo")} ${data.contactEmail.address}`}
												</TooltipContent>
											</Tooltip>
											<Tooltip>
												<TooltipTrigger>
													<InvisibleButton
														css={{
															padding: `0 8px`,
															marginLeft: 4,
														}}
														onClick={() => {
															copy(data.contactEmail.address);
														}}
													>
														<FaIcon icon={faCopy} />
													</InvisibleButton>
												</TooltipTrigger>
												<TooltipContent>{t("copyToClipboard")}</TooltipContent>
											</Tooltip>
										</Stack>
									)}
								</Box>
							</Stack>
						</Stack>
						{!previewMode && (
							<Stack
								orientation="horizontal"
								verticalAlignment="center"
								spacing={3}
							>
								{!data.hidePhoneNumber ? (
									<Button
										href={`tel:${formatPhoneNumber(
											data.contactPhone.internationalNumber!,
											"e164",
										)}`}
										palette="success"
										css={styles.buttons.contactSeller}
										leftIcon={<FaIcon icon={faPhone} mr={2} />}
										fluid={true}
									>
										{t("callSeller")}
									</Button>
								) : (
									<Button
										href={`mailto:${data.contactEmail.address}`}
										palette="success"
										css={styles.buttons.contactSeller}
										leftIcon={<FaIcon icon={faPhone} mr={2} />}
										fluid={true}
									>
										{t("emailSeller")}
									</Button>
								)}
								{/*<Button*/}
								{/*	css={styles.buttons.favorite}*/}
								{/*	leftIcon={<FaIcon icon={faHeart} />}*/}
								{/*>*/}
								{/*	Add to favorites*/}
								{/*</Button>*/}
							</Stack>
						)}
					</Stack>
				</Stack>
				<div css={styles.gallery}>
					<Stack spacing={8}>
						<Visibility as="header" visible={[true, true, false]}>
							<VehicleHeading {...props} />
						</Visibility>
						<VehicleImageGallery
							images={images}
							vehicleInfo={data.vehicleInfo}
						/>
					</Stack>
				</div>
				<div css={{ gridArea: "description" }}>
					<Divider variant="dotted" mb={6} />
					<Heading as="h5" size="regular" weight="light">
						{t("vehicleDescription")}
					</Heading>
					<RichTextView value={data.description} />
				</div>
			</div>
		</div>
	);
};

export { VehicleDetails };
export type { VehicleDetailsProps };
