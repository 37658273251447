/** @jsxImportSource @emotion/react */
import React, { MouseEvent } from "react";
import { User } from "@bilar/models";
import { MobileNav } from "./MobileNav";
import { MainNav } from "./MainNav";

type AppNavProps = {
	user?: User;
	onLoginClick: (e?: MouseEvent<HTMLAnchorElement>) => void;
};

const AppNav = (props: AppNavProps) => {
	const { user, onLoginClick: handleLoginClick } = props;

	return (
		<>
			<MobileNav user={user} onLoginClick={handleLoginClick} />
			<MainNav user={user} onLoginClick={handleLoginClick} />
		</>
	);
};

export { AppNav };
export type { AppNavProps };
