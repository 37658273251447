import { CSSObject } from "@emotion/react";
import { TextFieldProps } from "./TextField";
import {
	DesignTokens,
	getMapValue,
	useBorderTokens,
	useDesignTokens,
} from "@bilar/ui";

type TextFieldStyles = {
	root: CSSObject;
	label: CSSObject;
	input: CSSObject;
	adornment: {
		container: CSSObject;
		start: CSSObject;
		end: CSSObject;
	};
};

const getTextAreaStyles = (props: TextFieldProps, tokens: DesignTokens) => {
	if (!props.multiline) return {};
	const { space } = tokens;

	let height = "";
	if (props.multiline) {
		let rows = getMapValue(
			space,
			props.multilineRows ? props.multilineRows * 8 : 24,
		);
		height = rows;
	}

	return {
		paddingTop: space[2],
		paddingBottom: space[2],
		lineHeight: 1.75,
		height,
	};
};

const useTextFieldStyles = (props: TextFieldProps): TextFieldStyles => {
	const { startAdornment, endAdornment } = props;
	const designTokens = useDesignTokens();
	const { borderShadow } = useBorderTokens();
	const { fonts, space, radii, palette, fontSizes, zIndices } = designTokens;
	let height = "";
	let fontSize: undefined | number | string;

	switch (props.size) {
		case "small":
			fontSize = fontSizes.sm;
			height = space[10];
			break;
		case "regular":
			fontSize = fontSizes.md;
			height = space[12];
			break;
		case "large":
			fontSize = fontSizes.xl;
			height = space[16];
			break;
	}

	const borderRadius = radii.md;
	let radiusProps: CSSObject = {
		borderTopLeftRadius: startAdornment ? 0 : borderRadius,
		borderBottomLeftRadius: startAdornment || props.error ? 0 : borderRadius,
		borderTopRightRadius: endAdornment ? 0 : borderRadius,
		borderBottomRightRadius: endAdornment || props.error ? 0 : borderRadius,
	};

	return {
		root: {
			position: "relative",
			zIndex: zIndices.base,
		},
		label: {},
		adornment: {
			container: {
				display: "flex",
				alignItems: "center",
			},
			start: {
				alignSelf: "stretch",
				display: "flex",
				alignItems: "center",
				"&:hover": {
					zIndex: 2,
				},
			},
			end: {
				alignSelf: "stretch",
				display: "flex",
				alignItems: "center",
				"&:hover": {
					zIndex: 2,
				},
			},
		},
		input: {
			fontFamily: fonts.body,
			WebkitAppearance: "none",
			fontSize: fontSize,
			...radiusProps,
			borderWidth: "2px",
			borderStyle: "solid",
			borderColor: props.error
				? palette.error.light.background
				: palette.neutral.light.background,
			height: height,
			paddingLeft: space[2],
			paddingRight: space[2],
			transitionProperty: "all",
			transitionDuration: "500ms",
			transitionTimingFunction: "ease-out",
			":focus, :focus-visible": {
				boxShadow: borderShadow(palette.neutral.darker.background, 4, 0.15),
				outlineColor: palette.neutral.main.background,
				borderColor: palette.neutral.main.background,
			},
			width: "100%",
			...getTextAreaStyles(props, designTokens),
		},
	};
};

type TextFieldAdornmentStyles = {
	root: CSSObject;
};

const useTextFieldAdornmentStyles = (): TextFieldAdornmentStyles => {
	const tokens = useDesignTokens();
	const { space, palette, radii } = tokens;
	const borderRadius = radii.md;

	return {
		root: {
			position: "relative",
			flexBasis: 1,
			display: "flex",
			alignItems: "center",
			alignSelf: "stretch",
			paddingLeft: space[2],
			paddingRight: space[2],
			backgroundColor: palette.neutral.lighter.background,
			color: palette.neutral.lighter.contrast,

			// Borders
			borderWidth: "2px",
			borderStyle: "solid",
			borderRadius,
			borderColor: palette.neutral.light.background,

			"[data-valid='false'] &": {
				borderColor: palette.error.light.background,
				backgroundColor: palette.error.lighter.background,
				color: palette.error.lighter.contrast,
			},

			"& button, & [data-reach-listbox-input]": {
				display: "flex",
				height: "auto",
				alignSelf: "stretch",
				whiteSpace: "nowrap",
			},

			// Dependent on this className from TextField
			".adornment-start &": {
				marginRight: "-2px",
				borderRadius,
				borderTopRightRadius: 0,
				borderBottomRightRadius: 0,

				"[data-valid='false'] &": {
					borderBottomLeftRadius: 0,
					borderBottomRightRadius: 0,
				},

				"& button": {
					borderTopRightRadius: 0,
					borderBottomRightRadius: 0,
					borderTopLeftRadius: borderRadius,
					borderBottomLeftRadius: borderRadius,
					minHeight: "auto",

					"[data-valid='false'] &": {
						borderBottomLeftRadius: 0,
						borderBottomRightRadius: 0,
					},
				},
			},
			".adornment-end &": {
				marginLeft: "-2px",
				borderRadius: borderRadius,
				borderTopLeftRadius: 0,
				borderBottomLeftRadius: 0,

				"[data-valid='false'] &": {
					borderBottomLeftRadius: 0,
					borderBottomRightRadius: 0,
				},

				"& button": {
					borderTopLeftRadius: 0,
					borderBottomLeftRadius: 0,
					borderTopRightRadius: borderRadius,
					borderBottomRightRadius: borderRadius,
					minHeight: "auto",

					"[data-valid='false'] &": {
						borderBottomLeftRadius: 0,
						borderBottomRightRadius: 0,
					},
				},
			},
		},
	};
};

export { useTextFieldStyles, useTextFieldAdornmentStyles };
