import { ReactNode } from "react";
import { usePaperStyles } from "./paperStyles";
import { BaseProps, BoxBaseProps, Tag, useBoxStyles } from "@bilar/ui";
import { HtmlTag } from "../../types";

type Elevation = "none" | "small" | "medium" | "large";

type PaperVariant = "outlined" | "regular";

type PaperProps = {
	children?: ReactNode;
} & PaperDefaultProps &
	BoxBaseProps &
	BaseProps;

type PaperDefaultProps = {
	elevation: Elevation;
	variant: PaperVariant;
	as: HtmlTag;
};

const defaultProps: PaperDefaultProps = {
	elevation: "none",
	variant: "outlined",
	as: "section",
};

/** The `<Paper>` component can be used to encapsulate messages with different emphasis on elevation. */
const Paper = (props: PaperProps) => {
	const { as, ...filteredProps } = props;
	const styles = usePaperStyles(props);
	const boxStyles = useBoxStyles(props);

	return (
		<Tag as={props.as} css={[styles, boxStyles]} {...filteredProps}>
			{props.children}
		</Tag>
	);
};

Paper.defaultProps = defaultProps;

export { Paper };
export type { PaperProps, Elevation };
