/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useProgressBarStyles } from "@bilar/ui";

type ProgressBarProps = {
	percentage: number;
};

const ProgressBar = (props: ProgressBarProps) => {
	const { percentage } = props;
	const [progress, setProgress] = useState(percentage);
	const styles = useProgressBarStyles(props);

	useEffect(() => {
		setProgress(percentage);
	}, [percentage]);

	return (
		<div css={styles.root}>
			<motion.div
				css={styles.bar}
				style={{ width: `${progress}%` }}
				animate={{ width: `${progress}%` }}
				transition={{ duration: 1, ease: "easeInOut" }}
			/>
		</div>
	);
};

export { ProgressBar };
export type { ProgressBarProps };
