export class UnitConverter {
	constructor(
		private baseFontSize: number,
		private tokensSizingMode: "rem" | "px",
	) {}

	sizingMode = this.tokensSizingMode;

	convertPixelsToSizeUnit = (value: number): string => {
		if (this.sizingMode === "px") {
			return value + "px";
		} else {
			return parseFloat((value / this.baseFontSize).toPrecision(4)) + "rem";
		}
	};

	convertSizeUnitToNumber = (value: string): number => {
		const numberValue =
			this.sizingMode === "px"
				? value.replace("px", "")
				: value.replace("rem", "px");
		return parseFloat(numberValue);
	};

	convertNumberToSizeUnit = (value: number): string => {
		if (this.sizingMode === "px") {
			return value + "px";
		} else {
			return value + "rem";
		}
	};

	// private convertPixelsToRemNumber = (pixelValue: number): number => {
	// 	return parseFloat((pixelValue / this.baseFontSize).toPrecision(4));
	// };

	// convertPixelsToRem = (pixelValue: number): string => {
	// 	return `${this.convertPixelsToRemNumber(pixelValue)}rem`;
	// };

	convertPixelsToNumber = (px: string): number => {
		px = px.replace("px", "");
		return parseFloat(px);
	};

	// convertRemToPixelsNumber = (rem: string): number => {
	// 	rem = rem.replace("rem", "");
	// 	return parseFloat(rem) * this.baseFontSize;
	// };
}
