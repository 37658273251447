/** @jsxImportSource @emotion/react */
import {
	MenuList as ReachMenuList,
	MenuListProps as ReachMenuListProps,
} from "@reach/menu-button";
import { MenuListStyleProps, useMenuListStyles } from "./menuStyles";
import { MenuItemText } from "./MenuItemText";
import { MenuItemLink } from "./MenuItemLink";
import { MenuItem } from "./MenuItem";
import { BoxBaseProps, useBoxStyles } from "@bilar/ui";
import { BaseProps } from "..";

type MenuListProps = {
	styleProps?: MenuListStyleProps;
} & ReachMenuListProps &
	BoxBaseProps &
	BaseProps;

/**
 * The `<MenuList>` component is the popover parent for the content.
 */
const MenuList = (props: MenuListProps) => {
	const styles = useMenuListStyles(props.styleProps);
	const boxStyles = useBoxStyles(props);

	return (
		<ReachMenuList css={[styles, boxStyles]} {...props}>
			{props.children}
		</ReachMenuList>
	);
};

MenuList.Item = MenuItem;
MenuList.TextItem = MenuItemText;
MenuList.LinkItem = MenuItemLink;

export { MenuList };
export type { MenuListProps, MenuListStyleProps };
