/** @jsxImportSource @emotion/react */

import { Checkbox, useDesignTokens } from "@bilar/ui";
import React from "react";
import { useAppTranslation } from "@bilar/common";
import { SearchState, useSearch, useSharedSearchStyles } from "@bilar/features";
import { CSSObject } from "@emotion/react";
import { FuelType } from "@bilar/models";

type FuelTypeFieldsProps = {};

type FuelTypeFieldStyles = {
	optionsWrapper: CSSObject;
};
const useFuelTypeFieldStyles = (): FuelTypeFieldStyles => {
	const { space, fontWeights } = useDesignTokens();

	return {
		optionsWrapper: {
			margin: `${space[2]} 0`,
		},
	};
};

const FuelTypeFields = (props: FuelTypeFieldsProps) => {
	const { t } = useAppTranslation();
	const styles = useFuelTypeFieldStyles();
	const sharedStyles = useSharedSearchStyles();
	const { searchState, setSearchState } = useSearch();

	const handleToggleFuelType = (fuelType: FuelType) => {
		let newState: SearchState = { ...searchState };

		if (newState.fuelTypes?.includes(fuelType)) {
			newState.fuelTypes = newState.fuelTypes?.filter(
				(type: FuelType) => type !== fuelType,
			);
		} else {
			newState.fuelTypes = [...(newState.fuelTypes ?? []), fuelType];
		}

		setSearchState(newState);
	};

	return (
		<fieldset css={sharedStyles.fieldset}>
			<legend css={sharedStyles.legend}>{t("fuelType")}</legend>
			<div css={[sharedStyles.radioAndCheckboxWrapper, styles.optionsWrapper]}>
				<Checkbox
					label={t("fuelTypeGasoline")}
					layout="box"
					checked={searchState.fuelTypes?.includes("gasoline") ?? false}
					onChange={() => handleToggleFuelType("gasoline")}
				/>
				<Checkbox
					label={t("fuelTypeDiesel")}
					layout="box"
					checked={searchState.fuelTypes?.includes("diesel") ?? false}
					onChange={() => handleToggleFuelType("diesel")}
				/>
				<Checkbox
					label={t("fuelTypeElectric")}
					layout="box"
					checked={searchState.fuelTypes?.includes("electric") ?? false}
					onChange={() => handleToggleFuelType("electric")}
				/>
				<Checkbox
					label={t("fuelTypeHybrid")}
					layout="box"
					checked={searchState.fuelTypes?.includes("hybrid") ?? false}
					onChange={() => handleToggleFuelType("hybrid")}
				/>
			</div>
		</fieldset>
	);
};

export { FuelTypeFields };
export type { FuelTypeFieldsProps };
