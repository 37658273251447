import React, { createContext, ReactNode, useEffect, useState } from "react";
import {
	getAuth,
	onAuthStateChanged,
	User as FirebaseUser,
} from "firebase/auth";
import initFirebase from "@bilar/firebase/initFirebase";
import { setSession } from "./firebaseSessionHandler";
import { User } from "@bilar/models";

import { getFirestore, Unsubscribe } from "firebase/firestore";
import { parseFullName } from "parse-full-name";
import { parsePhoneNumber } from "react-phone-number-input"; // FIXME: Quite large 168 kB (43kB gzip)
import { streamFirestoreUser } from "@bilar/features";
import posthog from "posthog-js";

// https://benmcmahen.com/using-firebase-with-react-hooks/

interface FireBaseAuthContext {
	firebaseUser: FirebaseUser | null;
	user?: User;
}

const AuthContext = createContext<FireBaseAuthContext>({
	firebaseUser: null,
});

type AuthProviderProps = {
	children: ReactNode;
};
const AuthProvider = (props: AuthProviderProps) => {
	const auth = useFirebaseSession();

	return (
		<AuthContext.Provider
			value={{
				firebaseUser: auth.firebaseUser,
				user: auth.user,
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};

const convertFirebaseUserToUser = (firebaseUser: FirebaseUser): User => {
	const { first, last } = parseFullName(firebaseUser.displayName || "");
	const phoneNumber = parsePhoneNumber(firebaseUser.phoneNumber || "");

	let photoURL = firebaseUser.photoURL || "";
	const oauthAccessToken = localStorage.getItem("oauthAccessToken");
	const facebookUserData = firebaseUser.providerData.find(
		(d) => d.providerId === "facebook.com",
	);

	// Get the real photoURL for facebook users, only do this if there is only one provider and its Facebook
	if (
		firebaseUser.providerData.length === 1 &&
		oauthAccessToken &&
		facebookUserData
	) {
		photoURL = `https://graph.facebook.com/${facebookUserData.uid}/picture?width=400&access_token=${oauthAccessToken}`;
	}

	return {
		userId: firebaseUser?.uid!,
		name: {
			first: first || "",
			last: last || "",
		},
		email: {
			address: firebaseUser.email || "",
		},
		phone: {
			internationalNumber: phoneNumber?.number || "",
			nationalNumber: phoneNumber?.nationalNumber || "",
			countryCode: phoneNumber?.countryCallingCode || "",
		},
		profileImageUrl: photoURL,
	};
};

/**
 * Monitors the firebase auth state and returns the auth state.
 */
const useFirebaseSession = () => {
	const firebaseApp = initFirebase();
	const auth = getAuth(firebaseApp);
	const db = getFirestore(firebaseApp);

	const [state, setState] = useState<
		Pick<FireBaseAuthContext, "user" | "firebaseUser">
	>(() => {
		const user = auth.currentUser;
		return {
			firebaseUser: user,
			user: undefined,
		};
	});

	useEffect(() => {
		let unsubscribe: Unsubscribe = () => {};

		if (state.firebaseUser) {
			unsubscribe = streamFirestoreUser(state.firebaseUser.uid, (docSnap) => {
				let user: User | undefined;

				if (docSnap.exists()) {
					user = docSnap.data() as User;
				} else {
					user = convertFirebaseUserToUser(state.firebaseUser!);
				}

				setState({
					firebaseUser: state.firebaseUser,
					user,
				});

				posthog.identify(user.userId, {
					email: user.email,
					name: `${user.name.first} ${user.name.last}`,
				});
			});
		}

		return () => unsubscribe();
	}, [state.firebaseUser]);

	useEffect(() => {
		// Listen for auth state changes.
		const unsubscribe = onAuthStateChanged(auth, _onAuthChange);

		// Unsubscribe to the listener when unmounting.
		return () => unsubscribe();
	}, []);

	const _onAuthChange = async (firebaseUser: FirebaseUser | null) => {
		setState({ firebaseUser });

		// Call server to update session.
		setSession(firebaseUser);
	};

	return state;
};

export { AuthContext, AuthProvider };
