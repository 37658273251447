import { CSSObject } from "@emotion/react";
import { BaseProps, ExpandAnimation, Icon } from "../../";
import { DesignTokens } from "../../../styling";
import { useDesignTokens } from "../../..";
import { MdWarning } from "react-icons/md";

type ValidationMessageProps = {
	error?: string;
} & ValidationMessageDefaultProps &
	BaseProps;

type ValidationMessageDefaultProps = {
	layoutMode?: "regular" | "textField";
};

const defaultProps: ValidationMessageDefaultProps = {
	layoutMode: "regular",
};

type ValidationMessagesStyles = {
	root: CSSObject;
	text: CSSObject;
	icon: CSSObject;
};

const getStyles = (
	props: ValidationMessageProps,
	tokens: DesignTokens,
): ValidationMessagesStyles => {
	const { space, palette, radii, fontSizes, fontWeights } = tokens;
	return {
		root: {
			position: "relative",
			top: 0,
			padding: space[2],
			paddingTop: props.layoutMode === "textField" ? space[3] : space[2],
			zIndex: -1,
			borderWidth: 2,
			borderStyle: "solid",
			borderColor: palette.error.light.background,
			borderRadius: radii.md,
			backgroundColor: palette.error.lighter.background,
		},
		icon: {
			//fontSize: fontSizes.md,

			marginRight: space[1],
			lineHeight: fontSizes.xl,
			svg: {
				fill: palette.error.lighter.contrast,
				verticalAlign: "text-bottom",
			},
		},
		text: {
			display: "inline-block",
			fontSize: fontSizes.md,
			lineHeight: fontSizes.xl,
			fontWeight: fontWeights.medium,
			color: palette.error.lighter.contrast,
		},
	};
};

const ValidationMessage = (props: ValidationMessageProps) => {
	const designTokens = useDesignTokens();
	const styles = getStyles(props, designTokens);
	const visible = !!(props.error !== undefined && props.error.length);

	return (
		<ExpandAnimation
			overWriteCss={{ position: "relative", top: "-" + designTokens.space[2] }}
			expanded={visible}
		>
			<div css={styles.root}>
				<div className={props.className} id={props.id} css={styles}>
					<Icon icon={<MdWarning />} size={5} css={styles.icon} />
					<span css={styles.text}>{props.error}</span>
				</div>
			</div>
		</ExpandAnimation>
	);
};

ValidationMessage.defaultProps = defaultProps;

// Add all your exports here
export { ValidationMessage };
export type { ValidationMessageProps };
