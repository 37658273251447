/** @jsxImportSource @emotion/react */
import { useMainNavStyles } from "./mainNavStyles";
import React, { MouseEvent, useEffect } from "react";
import { useAuthManagement } from "@bilar/auth";
import {
	Avatar,
	Divider,
	FaIcon,
	Heading,
	Icon,
	Menu,
	NextLink,
	Stack,
	useBreakpoints,
} from "@bilar/ui";
import { useAppTranslation } from "@bilar/common";
import { routes } from "@bilar/config";
import { MdPowerSettingsNew } from "react-icons/md";
import { User } from "@bilar/models";
import {
	faBars,
	faCars,
	faLock,
	faPlusSquare,
} from "@fortawesome/pro-solid-svg-icons";
import { useRouter } from "next/router";

type MainNavProps = {
	user?: User;
	onLoginClick: (e?: MouseEvent<HTMLAnchorElement>) => void;
};

const MainNav = (props: MainNavProps) => {
	const { onLoginClick } = props;
	const { user } = props;
	const styles = useMainNavStyles(props, user !== undefined);
	const { signOut } = useAuthManagement();
	const { t } = useAppTranslation();
	const router = useRouter();
	const { isTabletSmall, isMobile, device } = useBreakpoints();
	const [shouldRender, setShouldRender] = React.useState(true);

	useEffect(() => {
		if (!isTabletSmall && !isMobile) {
			setShouldRender(true);
		} else {
			setShouldRender(false);
		}
	}, [isTabletSmall, isMobile, device]);

	if (!shouldRender) return null;

	if (!user)
		return (
			<Menu id="mainMenu">
				<Menu.ButtonInvisible aria-label={t("menu")} css={styles.root}>
					<Heading as="span" size="small" weight="normal" css={styles.userName}>
						{t("menu")}
					</Heading>
					<FaIcon icon={faBars} />
				</Menu.ButtonInvisible>
				<Menu.List css={styles.list.root}>
					<Menu.List.TextItem
						onSelect={() => onLoginClick()}
						css={styles.list.item}
					>
						<NextLink
							href={routes.signIn}
							css={styles.list.itemLink}
							title={t("signInOrSignup")}
							onClick={onLoginClick}
						>
							<Stack orientation="horizontal" horizontalAlignment="center">
								<FaIcon icon={faLock} size={6} mr={6} />
								{t("signInSignup")}
							</Stack>
						</NextLink>
					</Menu.List.TextItem>
					<Divider my={2} />
					<Menu.List.TextItem
						onSelect={() => router.push(routes.vehicleClassifieds.new)}
						css={styles.list.item}
					>
						<NextLink
							href={routes.vehicleClassifieds.new}
							css={styles.list.itemLink}
							title={t("postNewAd")}
						>
							<Stack orientation="horizontal" horizontalAlignment="center">
								<FaIcon icon={faPlusSquare} size={6} mr={6} />
								{t("postNewAd")}
							</Stack>
						</NextLink>
					</Menu.List.TextItem>
				</Menu.List>
			</Menu>
		);

	return (
		<div>
			<Menu id="mainMenu">
				<Menu.ButtonInvisible aria-label={t("menu")} css={styles.root}>
					<Avatar src={user.profileImageUrl} user={user} mr={2} height={8} />

					<Heading as="span" size="small" weight="normal" css={styles.userName}>
						{t("menu")}
					</Heading>
					<FaIcon icon={faBars} />
				</Menu.ButtonInvisible>
				<Menu.List css={styles.list.root}>
					<Menu.List.TextItem
						onSelect={() => router.push(routes.profile.index)}
						css={styles.list.item}
					>
						<NextLink href={routes.profile.index} css={styles.list.itemLink}>
							<div css={styles.list.profileButton}>
								<Avatar
									src={user.profileImageUrl}
									user={user}
									imgWidth={48}
									imgHeight={48}
								/>

								<div>
									<Heading
										as="span"
										size="small"
										css={{ whiteSpace: "nowrap" }}
										my={0}
										data-rp-mask="true"
									>
										{`${user.name.first} ${user.name.last}`}
									</Heading>
									<p css={{ whiteSpace: "nowrap", margin: 0 }}>
										{t("seeYourProfile")}
									</p>
								</div>
							</div>
						</NextLink>
					</Menu.List.TextItem>
					<Divider my={2} />
					<Menu.List.TextItem
						onSelect={() => router.push(routes.profile.classifieds)}
						css={styles.list.item}
					>
						<NextLink
							href={routes.profile.classifieds}
							css={styles.list.itemLink}
						>
							<Stack orientation="horizontal" horizontalAlignment="center">
								<FaIcon icon={faCars} size={6} mr={6} />
								{t("myClassifieds")}
							</Stack>
						</NextLink>
					</Menu.List.TextItem>
					<Divider my={2} />
					<Menu.List.TextItem
						onSelect={() => router.push(routes.vehicleClassifieds.new)}
						css={styles.list.item}
					>
						<NextLink
							href={routes.vehicleClassifieds.new}
							css={styles.list.itemLink}
							title={t("postNewAd")}
						>
							<Stack orientation="horizontal" horizontalAlignment="center">
								<FaIcon icon={faPlusSquare} size={6} mr={6} />
								{t("postNewAd")}
							</Stack>
						</NextLink>
					</Menu.List.TextItem>
					<Divider my={2} />
					<Menu.List.Item
						as="button"
						css={[styles.list.item, styles.list.signOutButton]}
						onSelect={() => signOut()}
					>
						<span>
							<Icon icon={<MdPowerSettingsNew />} size={6} mr={2} />
							{t("signOut")}
						</span>
					</Menu.List.Item>
				</Menu.List>
			</Menu>
		</div>
	);
};

export { MainNav };
export type { MainNavProps };
