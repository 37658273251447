import { useBreakpoints } from "@bilar/ui";
import ContentLoader, { IContentLoaderProps } from "react-content-loader";

type PostLoaderProps = IContentLoaderProps;

const PostLoader = (props: PostLoaderProps) => {
	const { isMobile } = useBreakpoints();

	if (isMobile) {
		return <MobileLoader />;
	}

	return <DesktopLoader />;
};

const MobileLoader = (props: PostLoaderProps) => (
	<ContentLoader
		width={320}
		height={400}
		viewBox="0 0 320 400"
		backgroundColor="#f0f0f0"
		foregroundColor="#dedede"
		{...props}
	>
		<rect x="0" y="304" rx="4" ry="4" width="271" height="9" />
		<rect x="0" y="323" rx="3" ry="3" width="119" height="6" />
		<rect x="0" y="77" rx="10" ry="10" width="388" height="217" />
	</ContentLoader>
);

const DesktopLoader = (props: PostLoaderProps) => (
	<ContentLoader
		viewBox="0 0 800 240"
		width={800}
		height={240}
		speed={2}
		title="Hleður..."
		backgroundColor="#f5f5f5"
		foregroundColor="#dbdbdb"
		{...props}
	>
		<rect x="18" y="16" rx="5" ry="5" width="268" height="200" />
		<rect x="304" y="24" rx="4" ry="4" width="320" height="16" />
		<rect x="304" y="56" rx="4" ry="4" width="200" height="16" />
		<rect x="304" y="88" rx="4" ry="4" width="120" height="16" />
		<rect x="304" y="124" rx="4" ry="4" width="400" height="16" />
	</ContentLoader>
);

export { PostLoader };
export type { PostLoaderProps };
