import { useRouterLocale } from "@bilar/common";
import { useEffect, useState } from "react";
import { getRelativeFormattedDate } from "../utils";

const useCalendarDate = (date: string): string => {
	const { locale } = useRouterLocale();
	const [relativeDate, setRelativeDate] = useState<string>("");

	// NOTE: We had some problems with hydration in prod, where the server side differed from the client side.
	// So we format the date on the client side to avoid this issue.
	// These were the errors we got:
	// https://reactjs.org/docs/error-decoder.html?invariant=425
	// https://reactjs.org/docs/error-decoder.html?invariant=418
	useEffect(() => {
		setRelativeDate(() => getRelativeFormattedDate(date, locale));
	}, [date]);

	return relativeDate;
};

export { useCalendarDate };
