import facepaint from "facepaint";
import {
	DesignTokens,
	ResponsiveProp,
	SpacingSize,
	useDesignTokens,
} from "@bilar/ui";

type StackBaseProps = {
	orientation: ResponsiveProp<Orientation>;
	horizontalAlignment?: ResponsiveProp<HorizontalAlignment>; // The space commands might not be usable depending on orientation, can we type this?
	verticalAlignment?: ResponsiveProp<VerticalAlignment>; // The space commands might not be usable depending on orientation, can we type this?
	spacing?: SpacingSize; // TODO: Make ResponsiveProp
};

type Orientation = "horizontal" | "vertical";
type HorizontalAlignment =
	| "left"
	| "center"
	| "right"
	| "stretch"
	| "space-around"
	| "space-between"
	| "space-evenly"
	| undefined;
type VerticalAlignment =
	| "top"
	| "center"
	| "bottom"
	| "stretch"
	| "space-around"
	| "space-between"
	| "space-evenly"
	| undefined;

// https://developer.mozilla.org/en-US/docs/Web/CSS/justify-content
// https://css-tricks.com/almanac/properties/a/align-items/

/**
 * A hook that can be used for other components that want the same behavior as the Stack component
 * */
function useStackStyles(stackProps: StackBaseProps) {
	const designTokens = useDesignTokens();
	return getStackStyles(stackProps, designTokens);
}

function getStackStyles(
	stackProps: StackBaseProps,
	designTokens: DesignTokens,
): facepaint.DynamicStyle[] {
	const stackStyles = designTokens.utils.responsivePropsToCss<any>(
		[
			stackProps.orientation,
			stackProps.verticalAlignment,
			stackProps.horizontalAlignment,
		],
		true,
		(
			orientation: Orientation,
			verticalAlignment: VerticalAlignment,
			horizontalAlignment: HorizontalAlignment,
		) => {
			return {
				display: "flex",
				gap:
					stackProps.spacing !== undefined
						? designTokens.size(stackProps.spacing)
						: undefined,
				flexDirection: orientation === "horizontal" ? "row" : "column",
				alignItems:
					orientation === "horizontal"
						? convertFlexProperty(verticalAlignment, "align-items", orientation)
						: convertFlexProperty(
								horizontalAlignment,
								"align-items",
								orientation,
						  ),

				justifyContent:
					orientation === "horizontal"
						? convertFlexProperty(
								horizontalAlignment,
								"justify-content",
								orientation,
						  )
						: convertFlexProperty(
								verticalAlignment,
								"justify-content",
								orientation,
						  ),
			};
		},
	);

	return stackStyles;
}

function convertFlexProperty(
	property:
		| undefined
		| "left"
		| "right"
		| "top"
		| "bottom"
		| "center"
		| "stretch"
		| "space-around"
		| "space-between"
		| "space-evenly",
	context: "align-items" | "justify-content",
	orientation: "horizontal" | "vertical" | undefined,
) {
	// The spacing + stretch properties can't always be used, we check for that here, would be nice if TypeScript could support this (maybe it can?)
	if (
		context === "align-items" &&
		property !== undefined &&
		property.startsWith("space")
	) {
		throw new Error(
			`The property: ${property} can't be used for the selected orientation: ${orientation}`,
		);
	} else if (
		context === "justify-content" &&
		property !== undefined &&
		property === "stretch"
	) {
		throw new Error(
			`The property: ${property} can't be used for the selected orientation: ${orientation}`,
		);
	}

	switch (property) {
		case "left":
		case "top":
			return "flex-start";
		case "right":
		case "bottom":
			return "flex-end";
	}

	return property;
}

export { getStackStyles, useStackStyles };
export type {
	StackBaseProps,
	HorizontalAlignment,
	VerticalAlignment,
	Orientation,
};
