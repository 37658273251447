/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { useAppTranslation, useMonthSelectOptions } from "@bilar/common";
import {
	DropdownOnChange,
	DropdownOption,
	Select,
	SelectProps,
	useBreakpoints,
} from "@bilar/ui";

type BaseSelProps = Omit<SelectProps<false>, "options" | "value">;

type MonthSelectProps = {
	selectedItem?: DropdownOption;
} & BaseSelProps &
	MonthSelectDefaultProps;

type MonthSelectDefaultProps = {};

const defaultProps: MonthSelectDefaultProps = {};

const MonthSelect = (props: MonthSelectProps) => {
	const { selectedItem } = props;
	const { t } = useAppTranslation();
	const { label, placeholder, onChange, ...filteredProps } = props;
	const [selectedMonth, setSelectedMonth] = useState<DropdownOption | null>(
		null,
	);
	const { monthOptions } = useMonthSelectOptions();
	const { isMobile } = useBreakpoints();

	useEffect(() => {
		if (selectedItem?.value) {
			setSelectedMonth(selectedItem);
		}
	}, [selectedItem]);

	const handleChange: DropdownOnChange<false> = (
		selected: DropdownOption | null,
		actionMeta,
	) => {
		setSelectedMonth(selected);

		if (onChange) {
			onChange(selected, actionMeta);
		}
	};

	// Not searchable on mobile
	const isSearchable = !isMobile;

	return (
		<Select
			label={label || t("month")}
			placeholder={placeholder || t("selectMonth")}
			options={monthOptions}
			value={selectedMonth}
			isSearchable={props.isSearchable ?? isSearchable}
			isClearable={true}
			autoFocus={false}
			onChange={handleChange}
			{...filteredProps}
		/>
	);
};

MonthSelect.defaultProps = defaultProps;

export { MonthSelect };
export type { MonthSelectProps };
