import {
	DocumentData,
	FieldValue,
	QueryDocumentSnapshot,
	SnapshotOptions,
	Timestamp,
	WithFieldValue,
} from "firebase/firestore";
import { ClassifiedVehicle } from "@bilar/models";

const stringToTimestamp = (
	date: FieldValue | WithFieldValue<string>,
): Timestamp => {
	return Timestamp.fromDate(new Date(Date.parse(date.toString())));
};

/**
 * Converter used by withConverter() to transform user objects of type T into Firestore data.
 * Using the converter allows you to specify generic type arguments when storing and retrieving objects from Firestore.
 * https://firebase.google.com/docs/reference/node/firebase.firestore.FirestoreDataConverter
 */
const vehicleDataConverter = {
	toFirestore(
		vehicle: WithFieldValue<Partial<ClassifiedVehicle>>,
	): DocumentData {
		const {
			createdAt,
			updatedAt,
			expiresAt,
			images,
			description,
			...filtered
		} = vehicle;

		const data: DocumentData = {
			...filtered,
		};

		if (createdAt) {
			data.createdAt = stringToTimestamp(createdAt);
		}
		if (updatedAt) {
			data.updatedAt = stringToTimestamp(updatedAt);
		}
		if (expiresAt) {
			data.expiresAt = stringToTimestamp(expiresAt);
		}

		if (description) {
			data.description = JSON.stringify(description);
		}

		return data;
	},
	fromFirestore: function (
		snapshot: QueryDocumentSnapshot,
		options: SnapshotOptions,
	): ClassifiedVehicle {
		const data = snapshot.data(options)! as ClassifiedVehicle;
		const { createdAt, updatedAt, expiresAt, description, ...filteredData } =
			data;

		return {
			...filteredData,
			createdAt: (createdAt as unknown as Timestamp).toDate().toISOString(),
			updatedAt: (updatedAt as unknown as Timestamp).toDate().toISOString(),
			expiresAt: (expiresAt as unknown as Timestamp).toDate().toISOString(),
			description: description
				? JSON.parse(description as unknown as string)
				: null,
		};
	},
};

export { vehicleDataConverter };
