import {
	DropdownOnChange,
	filterByLabel,
	Select,
	SelectProps,
	useDropdownOptions,
} from "@bilar/ui";
import { useVehicleBrands } from "./index";
import initFirebase from "@bilar/firebase/initFirebase";
import { useEffect } from "react";
import { useAppTranslation } from "@bilar/common";
import { VehicleBrand } from "@bilar/models";

type BaseSelProps = Omit<SelectProps<false>, "options" | "value">;
type VehicleTypeSelectProps = {
	selectedBrandId: string | undefined;
} & BaseSelProps;

initFirebase();

const VehicleBrandSelect = (props: VehicleTypeSelectProps) => {
	const { t } = useAppTranslation();
	const { onChange, ...filteredProps } = props;
	const { brands, selectedBrand, mapBrandToDropdownOption, setSelectedBrand } =
		useVehicleBrands();
	const options = useDropdownOptions<VehicleBrand>(
		brands,
		mapBrandToDropdownOption,
	);

	// If we set the value from the outside, we need to update the selected brand.
	useEffect(() => {
		if (options.length > 0 && props.selectedBrandId) {
			setSelectedBrand(props.selectedBrandId);
		}
	}, [options, props.selectedBrandId]);

	const handleChange: DropdownOnChange<false> = (selected, actionMeta) => {
		setSelectedBrand(selected?.value ?? null);

		if (onChange) {
			onChange(selected, actionMeta);
		}
	};

	return (
		<Select
			label={t("vehicleBrand")}
			placeholder={t("selectVehicleBrand")}
			aria-label={t("selectVehicleBrand")}
			options={options}
			isSearchable={true}
			isClearable={true}
			autoFocus={false}
			onChange={handleChange}
			filterOption={filterByLabel}
			{...filteredProps}
			value={selectedBrand ? mapBrandToDropdownOption(selectedBrand) : null}
		/>
	);
};

export { VehicleBrandSelect };
