import { useEffect, useState } from "react";

type DeviceInfo = {
	isIOS: boolean;
	isAndroid: boolean;
	isMobile: boolean;
	isTablet: boolean;
	isDesktop: boolean;
	device: string;
};

export const useDevice = (): DeviceInfo => {
	const [isIOS, setIsIOS] = useState(false);
	const [isAndroid, setIsAndroid] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const [isTablet, setIsTablet] = useState(false);
	const [isDesktop, setIsDesktop] = useState(false);
	const [device, setDevice] = useState("");

	// Note: isIOS does not work on iPads.

	useEffect(() => {
		const handleDeviceDetection = () => {
			const userAgent = navigator.userAgent.toLowerCase();

			setDevice(navigator.userAgent);

			setIsIOS(/iphone|ipad|ipod/g.test(userAgent));
			setIsAndroid(/android/g.test(userAgent));
			setIsMobile(
				/iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgent),
			);
			setIsTablet(
				/(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent),
			);
			setIsDesktop(!isMobile && !isTablet);
		};

		handleDeviceDetection();
		window.addEventListener("resize", handleDeviceDetection);

		return () => {
			window.removeEventListener("resize", handleDeviceDetection);
		};
	}, []);

	return { isIOS, isAndroid, isMobile, isTablet, isDesktop, device };
};
