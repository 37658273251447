import { CSSObject } from "@emotion/react";
import { VehicleBodyCheckboxProps } from "./VehicleBodyCheckbox";
import { useDesignTokens } from "@bilar/ui";

type VehicleBodyRadioStyles = {
	root: CSSObject;
	iconWrapper: CSSObject;
};

const useVehicleBodyCheckboxStyles = (
	props: VehicleBodyCheckboxProps,
): VehicleBodyRadioStyles => {
	const designTokens = useDesignTokens();
	const { space, palette } = designTokens;
	return {
		root: {
			backgroundColor: palette.neutral.lighter.background,
		},
		iconWrapper: {
			padding: `${space[4]} ${space[4]}`,
			// border: `1px solid red`,
			cursor: "pointer",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
	};
};

export { useVehicleBodyCheckboxStyles };
