import countries from "../assets/countries.json";
import { Country, CountryCode, CountryData } from "../types";
import * as Flags from "country-flag-icons/react/3x2";

export const renderCountryFlag = (country: string) => {
	// Lazily load the flag icon
	// @ts-ignore
	const Flag = Flags[country] as Flags.FlagComponent;
	return <Flag />;
};

type CountryCache = { [key: string]: Country[] };

// Since the countries array is big, we store it in the module scope, that way we can cache it.
// This prevents a huge amount of memory being used when using multiple instances of this hook.
let countriesCache: CountryCache = {};

/**
 * Returns a list of countries.
 * @param countriesOverride
 */
const getCountries = (countriesOverride?: CountryCode[]): Country[] => {
	const _countries = countriesOverride || countries;
	const key = _countries.join("");

	// If the cache is not empty, return it
	if (countriesCache[key]) {
		return countriesCache[key];
	}

	// Map the data to a format that is easier to work with
	const mappedCountries: Country[] = (countries as CountryData[])
		.filter((c) =>
			countriesOverride ? countriesOverride.includes(c[1]) : true,
		)
		.map((country) => ({
			name: country[0],
			iso2: country[1],
			callingCode: country[2],
			usesZeroPrefix: country[3],
		}));

	// Save the countries to the cache
	countriesCache[key] = mappedCountries;

	return mappedCountries;
};

/**
 * Hook that returns a list of countries.
 * This is not a React hook, it's a regular function that returns a list of countries.
 * We should probably rename it to something else.
 */
const useCountries = getCountries;

export { useCountries, getCountries };
