import { useEffect, useState } from "react";

type TableSchema = {
	name: string;
	options?: IDBObjectStoreParameters;
	columns: {
		name: string;
		options?: IDBIndexParameters;
	}[];
};

type UseDatabaseProps = {
	name: string;
	version: number;
	schema: TableSchema[] | TableSchema;
};

const getStore = (storeName: string, database?: IDBDatabase) => {
	return new Promise<IDBObjectStore>((resolve, reject) => {
		if (!database) {
			reject("No database, or database not ready");
			return;
		}

		const store = database
			.transaction(storeName, "readwrite")
			.objectStore(storeName);

		if (!store) {
			reject("No store found with name: " + storeName);
			return;
		}

		resolve(store);
	});
};

export const useIndexedDB = (props: UseDatabaseProps) => {
	const { name, version = 1, schema } = props;
	const [database, setDatabase] = useState<IDBDatabase>();
	const [databaseRequest, setDatabaseRequest] = useState<IDBOpenDBRequest>();
	const [error, setError] = useState<Error>();

	// TODO: Strongly type storeName
	const getAll = <T>(storeName: string): Promise<T[]> => {
		return new Promise((resolve, reject) => {
			getStore(storeName, database)
				.then((store) => {
					const request = store.getAll();
					request.onsuccess = () => {
						resolve(request.result);
					};
					request.onerror = (e) => {
						reject(e);
					};
				})
				.catch((e) => {
					reject(e);
				});
		});
	};

	// TODO: strongly type storeName
	const insert = (storeName: string, data: any) => {
		return new Promise((resolve, reject) => {
			getStore(storeName, database)
				.then((store) => {
					const request = store.put(data);

					request.onsuccess = () => {
						resolve(request.result);
					};
					request.onerror = () => {
						reject(request.error);
						setError(new Error(request.error?.message));
					};
				})
				.catch((err) => {
					reject(err);
					setError(new Error(err));
				});
		});
	};

	const put = (
		storeName: string,
		data: any,
		key?: IDBValidKey,
	): Promise<IDBValidKey> => {
		return new Promise((resolve, reject) => {
			getStore(storeName, database)
				.then((store) => {
					const request = store.put(data, key);

					request.onsuccess = () => {
						resolve(request.result);
					};
					request.onerror = () => {
						reject(request.error);
						setError(new Error(request.error?.message));
					};
				})
				.catch((err) => {
					reject(err);
					setError(new Error(err));
				});
		});
	};

	// TODO: strongly type storeName and key
	const deleteOne = (storeName: string, key: any) => {
		const store = database
			?.transaction(storeName, "readwrite")
			.objectStore(storeName);
		return store?.delete(key);
	};

	// TODO: strongly type storeName
	const clear = async (storeName: string) => {
		return new Promise((resolve, reject) => {
			getStore(storeName, database).then((store) => {
				const request = store.clear();

				request.onsuccess = () => {
					resolve(request.result);
				};
				request.onerror = () => {
					reject(request.error);
					setError(new Error(request.error?.message));
				};
			});
		});
	};

	useEffect(() => {
		const request = indexedDB.open(name, version);

		request.onerror = (e) => {
			setError(new Error("Database failed to open"));
			console.error("Database failed to open", e);
		};

		request.onsuccess = () => {
			setDatabaseRequest(request);
			setDatabase(request.result);
		};

		// Set up the database tables if this has not already been done
		request.onupgradeneeded = (e: any) => {
			const db = e.target?.result as IDBDatabase;
			let schemaArray = schema;
			if (!Array.isArray(schema)) {
				schemaArray = [schema];
			}

			for (const table of schemaArray as TableSchema[]) {
				const objectStore = db.createObjectStore(table.name, table.options);

				for (const column of table.columns) {
					objectStore?.createIndex(column.name, column.name, column.options);
				}
			}
		};
	}, [name, version]);

	return {
		database,
		databaseRequest,
		error,
		getAll,
		insert,
		put,
		update: put,
		deleteOne,
		clear,
		truncate: clear,
		deleteAll: clear,
	};
};
