/** @jsxImportSource @emotion/react */
import { ReactNode } from "react";
import { FaIcon, HtmlTag, NextLink, Stack } from "@bilar/ui";
import { CommonHeading } from "./CommonHeading";
import { useSectionHeadingStyles } from "./sectionHeadingStyles";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";

type SectionHeadingProps = {
	children?: ReactNode;
	title: string;
	link?: string;
	linkTitle?: string;
	as: HtmlTag;
} & SectionHeadingDefaultProps;

type SectionHeadingDefaultProps = {};

const defaultProps: SectionHeadingDefaultProps = {};

const SectionHeading = (props: SectionHeadingProps) => {
	const { as, title, link, linkTitle } = props;
	const styles = useSectionHeadingStyles(props);
	return (
		<Stack
			orientation="horizontal"
			horizontalAlignment="space-between"
			mt={8}
			mb={4}
		>
			<CommonHeading as={as} mb={0} css={styles.root}>
				{title}
			</CommonHeading>
			{link && (
				<div css={styles.linkContainer}>
					<NextLink href={link} css={styles.link} data-testid="lnk-section">
						{linkTitle}
					</NextLink>
					<FaIcon icon={faChevronRight} ml={2} />
				</div>
			)}
		</Stack>
	);
};

SectionHeading.defaultProps = defaultProps;

export { SectionHeading };
export type { SectionHeadingProps };
