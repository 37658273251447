/** @jsxImportSource @emotion/react */
import { ReactNode } from "react";
import { SearchState, useSearch } from "@bilar/features";
import {
	SelectOption,
	VehicleBrandSelect,
	VehicleModelSelect,
} from "@bilar/common";
import { Box, Divider, Stack } from "@bilar/ui";

type BrandOptionsProps = {
	children?: ReactNode;
};

const BrandOptions = (props: BrandOptionsProps) => {
	const { searchState, setSearchState } = useSearch();

	const handleBrandChange = (brand: SelectOption | null) => {
		let newState: SearchState = { ...searchState };
		if (brand === null) {
			newState = { ...searchState, brand: undefined, model: undefined };
			setSearchState(newState);
		} else {
			newState = { ...searchState, brand: brand.value };
			setSearchState(newState);
		}
	};

	const handleModelChange = (model: SelectOption | null) => {
		const newState: SearchState = {
			...searchState,
			model: model?.value ?? undefined,
		};

		setSearchState(newState);
	};
	return (
		<>
			<Stack orientation={["vertical", "horizontal", "horizontal"]}>
				<Box width={["100%", "50%"]} mr={[0, 2]}>
					<VehicleBrandSelect
						width="100%"
						selectedBrandId={searchState.brand ?? undefined}
						onChange={handleBrandChange}
					/>
				</Box>
				<Box width={["100%", "50%"]} ml={[0, 2]}>
					<VehicleModelSelect
						width="100%"
						brandId={searchState.brand ?? undefined}
						selectedModelId={searchState.model ?? undefined}
						onChange={handleModelChange}
					/>
				</Box>
			</Stack>
			<Divider my={4} />
		</>
	);
};

export { BrandOptions };
export type { BrandOptionsProps };
