type ResponsiveProp<T> = (T | undefined) | (T | undefined)[];

// Use this when we need to remap our own "made up props" to something useful in css
function mapResponsiveProp<TValue, TResult>(
	value: ResponsiveProp<TValue | undefined> | (TValue | undefined)[],
	convertFn: (value: TValue | undefined, index: number) => TResult,
): TResult | TResult[] {
	if (!Array.isArray(value)) {
		// Single value
		return convertFn(value, 0);
	} else {
		// Remap
		return value.map((val, i) => convertFn(val, i));
	}
}

function mapResponsivePropsGrouped<T>(
	props: ResponsiveProp<T>[],
	usePreviousValues: boolean,
	convertFn: (...values: (T | undefined)[]) => any,
): any[] {
	const result: any = {};

	_iterateResponsivePropsAsGroup(props, usePreviousValues, (values) => {
		//console.log("values", values);
		const keyValueObject = convertFn(...values);
		//console.log("kvp obj", keyValueObject);

		Object.entries(keyValueObject).forEach((kvp) => {
			const key: string = kvp[0];
			const val = kvp[1];
			(result[key] || (result[key] = [])).push(val);
		});
	});

	return result;
}

function _iterateResponsivePropsAsGroup(
	props: ResponsiveProp<any>[],
	usePreviousValues: boolean,
	callback: (values: any[]) => void,
) {
	const lengths = props.map((p) => {
		if (Array.isArray(p)) {
			return p.length;
		} else {
			return 1;
		}
	});

	const maxLength = Math.max(...lengths);

	for (let i = 0; i < maxLength; i++) {
		const values = props.map((p) => {
			return usePreviousValues
				? _getResponsivePropAtIndexOrUseLast(p, i)
				: _getResponsivePropAtIndex(p, i);
		});

		callback(values);
	}
}

function _getResponsivePropAtIndex(prop: ResponsiveProp<any>, index: number) {
	if (Array.isArray(prop)) {
		// Get value and index or try to fall back to the last item
		return prop[index];
	} else {
		if (index === 0) {
			return prop;
		} else {
			return undefined;
		}
	}
}

function _getResponsivePropAtIndexOrUseLast(
	prop: ResponsiveProp<any>,
	index: number,
) {
	if (Array.isArray(prop)) {
		// Get value and index or try to fall back to the last item
		if (index < prop.length) {
			return prop[index];
		} else {
			// Get last item instead
			return prop[prop.length - 1];
		}
	} else {
		return prop;
	}
}

export { mapResponsiveProp, mapResponsivePropsGrouped };
export type { ResponsiveProp };
