import React, { ReactNode } from "react";
import { AppFooter, AppHeader } from "..";
import { useBaseLayoutStyles } from "./baseLayoutStyles";
import { useSearch, VehicleSearch } from "@bilar/features";
import { AnimatePresence } from "framer-motion";
import Head from "next/head";
import { useAppTranslation } from "@bilar/common";
import { getConfig } from "@bilar/config";

type BaseLayoutProps = {
	children?: ReactNode;
};

const BaseLayout = (props: BaseLayoutProps) => {
	const styles = useBaseLayoutStyles(props);
	const { searchOpen } = useSearch();
	const { t } = useAppTranslation();
	const { baseUrl, facebook } = getConfig().public;

	return (
		<div css={styles.root}>
			<Head>
				<title>Bílar.is - {t("titleTag")}</title>
				<meta property="og:title" content={t("ogTitle")} key="og-title" />
				<meta
					property="og:description"
					content={t("ogDescription")}
					key="og-description"
				/>
				<meta
					property="og:image"
					content={`${baseUrl}/open-graph.png`}
					key="og-image"
				/>
				<meta property="fb:app_id" content={facebook.appId} />
				<meta property="og:image:alt" content="Bilar.is" key="og-image-alt" />

				<meta name="twitter:card" content="summary" key="twitter-card" />
				<meta name="twitter:site" content="@bilar_is" key="twitter-site" />
				<meta
					name="twitter:creator"
					content="@bilar_is"
					key="twitter-creator"
				/>
				<meta name="twitter:title" content={t("ogTitle")} key="twitter-title" />
				<meta
					name="twitter:description"
					content={t("ogDescription")}
					key="twitter-description"
				/>
				<meta
					name="twitter:image"
					content={`${baseUrl}/open-graph.png`}
					key="twitter-image"
				/>
				<meta
					name="twitter:image:alt"
					content="Bilar.is"
					key="twitter-image-alt"
				/>
				<meta
					name="description"
					content={t("ogDescription")}
					key="meta-description"
				/>
			</Head>
			<AppHeader />
			<main css={styles.main}>
				<div css={styles.mainContainer}>
					<AnimatePresence>{searchOpen && <VehicleSearch />}</AnimatePresence>
					{props.children}
				</div>
			</main>
			<AppFooter />
		</div>
	);
};

export { BaseLayout };
export type { BaseLayoutProps };
