import { boolean, number, object, ObjectSchema, string } from "yup";
import {
	StepBasicInfoFormValues,
	StepVehicleDetailsFormValues,
	TransmissionType,
} from "@bilar/features";
import { useAppTranslation } from "@bilar/common";
import { FuelType, VehicleBodyType } from "@bilar/models";
import { TFunction } from "next-i18next";

const selectOptionRequired = {
	label: string().required(),
	value: string().required(),
};

const selectOptionOptional = {
	label: string().defined(),
	value: string().defined(),
};

const stepBasicInfoValidation: (
	t: TFunction,
) => ObjectSchema<StepBasicInfoFormValues> = (t) =>
	object({
		price: number().required(t("enterPrice")),
		vehicleBrand: object()
			.shape(selectOptionRequired)
			.required(t("selectVehicleBrand")),
		vehicleModel: object()
			.shape(selectOptionRequired)
			.required(t("selectVehicleModel")),
		vehicleYear: object()
			.shape(selectOptionRequired)
			.required(t("selectVehicleYear")),

		willingToTrade: boolean().defined(),
	});

const useStepVehicleDesignValidation = () => {
	const { t } = useAppTranslation();
	const designValidationSchema = object({
		colour: object().shape({
			name: string().required(),
			hexValue: string().required(),
		}),
		vehicleBodyType: string()
			.defined()
			.required(t("selectVehicleBodyType"))
			.oneOf<VehicleBodyType>([
				"camper",
				"coupe",
				"convertible",
				"crossover",
				"hatchback",
				"liftback",
				"micro",
				"minivan",
				"sedan",
				"station-wagon",
				"suv",
				"pickup",
				"van",
				"wagon",
				"other",
			]),
	});

	return {
		designValidationSchema,
	};
};

const useStepVehicleDetailsValidation = (): ObjectSchema<
	Omit<StepVehicleDetailsFormValues, "vehicleDescription"> & {
		vehicleDescription?: any | null;
	}
> => {
	const { t } = useAppTranslation("validation");

	return object({
		vehicleMileage: number().required(t("enterVehicleMileage")),
		vehicleInspected: boolean().required(t("selectVehicleInspected")),
		vehicleInspectedYear: object(selectOptionOptional)
			.nullable()
			.when("vehicleInspected", ([vehicleInspected], field) =>
				vehicleInspected
					? field.required(t("selectVehicleInspectedYear"))
					: field,
			),
		vehicleInspectedMonth: object(selectOptionOptional)
			.nullable()
			.when("vehicleInspected", ([vehicleInspected], field) =>
				vehicleInspected
					? field.required(t("selectVehicleInspectedMonth"))
					: field,
			),
		vehicleTransmissionType: string()
			.required(t("selectVehicleTransmissionType"))
			.oneOf<TransmissionType>(["manual", "automatic"]),
		vehicleFuelType: string()
			.required(t("selectVehicleFuelType"))
			.oneOf<FuelType>([
				"gasoline",
				"diesel",
				"electric",
				"hybrid",
				"plug-in-hybrid",
				"methane",
				"hydrogen",
				"other",
			]),
		vehicleNumberOfDoors: number().required(t("selectVehicleNumberOfDoors")),
		vehicleNumberOfSeats: number().required(t("selectVehicleNumberOfSeats")),
		vehicleDescription: object().optional(),
	});
};

export {
	stepBasicInfoValidation,
	useStepVehicleDesignValidation,
	useStepVehicleDetailsValidation,
};
