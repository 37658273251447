import { CSSObject } from "@emotion/react";
import { VisuallyHiddenProps } from "./VisuallyHidden";

type VisuallyHiddenStyles = {
	root: CSSObject;
};

const useVisuallyHiddenStyles = (
	props: VisuallyHiddenProps,
): VisuallyHiddenStyles => {
	return {
		root: {
			border: "0",
			height: "1px",
			width: "1px",
			margin: "-1px",
			padding: "0",
			overflow: "hidden",
			whiteSpace: "nowrap",
			position: "absolute",
		},
	};
};

export { useVisuallyHiddenStyles };
