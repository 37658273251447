import { CSSObject } from "@emotion/react";
import { DesignTokens } from "@bilar/ui";
import colors from "./designTokens/colors";

const getBaseStyles = (tokens: DesignTokens): CSSObject => {
	return {
		html: {
			fontSize: "100%",
			height: "100vh",
			"@supports (height: -webkit-fill-available)": {
				height: "-webkit-fill-available",
			},
			"@supports (height: 100dvh)": {
				height: "100dvh",
			},
		},
		body: {
			minHeight: "100vh",
			"@supports (min-height: -webkit-fill-available)": {
				minHeight: "-webkit-fill-available",
			},
			"@supports (height: 100dvh)": {
				minHeight: "100dvh",
			},

			padding: 0,
			margin: 0,
			backgroundColor: base.backgroundColor,
			color: base.color,
			fontSize: base.fontSize,
			lineHeight: base.lineHeight,
			fontFamily: base.fontFamily,
			fontSmoothing: "antialised",
			overflowX: "hidden",
			minWidth: base.pageMinWidth,
			display: "grid",
			gridTemplateRows: "1fr",
		},
		p: {
			margin: "0 0 1rem",
		},

		"*": {
			boxSizing: "border-box",
		},
		"#__next": {
			display: "grid",
		},
		strong: {
			fontWeight: "bold",
		},
		a: {
			color: "inherit",
			textDecoration: "underline",
			"&:hover": {
				textDecoration: "none",
			},
		},
		hr: {
			display: "block",
		},
		fieldset: {
			border: "none",

			padding: 0,
			margin: 0,
		},
		svg: {
			display: "inline-block",
		},
		"*[hidden]": {
			display: "none",
		},
		"#sentry-feedback": {
			"--submit-background": colors.green["500"],
			"--submit-background-hover": colors.green["600"],
			"--submit-border": colors.green["500"],
			"--input-outline-focus": colors.green["500"],
			"--border-radius": "16px",
		},
	};
};

export const base = {
	backgroundColor: colors.grey["100"],
	borderColor: "#dadde3",
	color: "#2F3035",
	fontSize: "100%",
	pageMinWidth: "320px",
	fontFamily: `'Helvetica Neue', Helvetica, Arial`,
	lineHeight: "1.5rem",
	link: {
		color: "",
	},
};

export { getBaseStyles };
