import { useContext, useState } from "react";
import Router from "next/router";
import { collections, routes } from "@bilar/config";
import { AuthContext } from "./AuthProvider";
import {
	AuthErrorState,
	AuthStatus,
	LinkAccountState,
	SupportedProviders,
} from "./authTypes";
import initFirebase from "@bilar/firebase/initFirebase";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import {
	FacebookAuthProvider,
	fetchSignInMethodsForEmail,
	getAuth,
	GithubAuthProvider,
	GoogleAuthProvider,
	linkWithCredential,
	OAuthProvider,
	signInWithPopup,
	TwitterAuthProvider,
	User,
} from "firebase/auth";
import posthog from "posthog-js";

const initialAuthErrorState: AuthErrorState = { message: "" };

const initialLinkedAccountState: LinkAccountState = {
	status: false,
	existingProviderName: "",
	newProviderName: "",
	email: "",
	pendingCredentials: undefined,
	newProvider: undefined,
	existingProvider: undefined,
};

/**
 * Hook that returns the logged-in user.
 */
const useAuthUser = () => {
	return useContext(AuthContext);
};

/**
 * Hook for managing authentication (login, logout etc)
 */
const useAuthManagement = () => {
	const [linkingDone, setLinkingDone] = useState(false);
	const [authError, setAuthError] = useState<AuthErrorState>(
		initialAuthErrorState,
	);
	const [linkAccount, setLinkAccount] = useState<LinkAccountState>(
		initialLinkedAccountState,
	);
	const [authStatus, setAuthStatus] = useState<AuthStatus>(undefined);

	const firebaseApp = initFirebase();
	const db = getFirestore(firebaseApp);
	const auth = getAuth(firebaseApp);

	//---------- Public functions ----------
	const trySignInWithProvider = async (
		provider: SupportedProviders,
		returnRoute: string = "",
	) => {
		setAuthStatus("inProgress");
		try {
			const result = await signInWithPopup(auth, provider);

			// The signed-in user info.
			const user = result.user;

			// console.log("user", user);

			if (provider instanceof OAuthProvider) {
				//const credential = OAuthProvider.credentialFromResult(result);
				// const accessToken = credential?.accessToken;
				// const idToken = credential?.idToken;
			} else if (provider instanceof FacebookAuthProvider) {
				const credential = FacebookAuthProvider.credentialFromResult(result);
				// We use localStorage so that we can store the token even efter refresh,
				// since this function only gets executed when logging in
				localStorage.setItem("oauthAccessToken", credential?.accessToken!);
			}

			await _redirectWhereNeeded(user, returnRoute);
		} catch (err) {
			_handleSignInErrors(err, provider);
		}
	};

	// TODO: v9 (beta6) always succeeds even though an email user for example is already existing. And overrides the older user.
	const signInWithGoogle = async (returnRoute: string = "") => {
		const provider = new GoogleAuthProvider();
		provider.addScope("profile");
		provider.addScope("email");
		provider.setCustomParameters({
			prompt: "select_account",
		});

		await trySignInWithProvider(provider, returnRoute);
	};

	const signInWithMicrosoft = async (returnRoute: string = "") => {
		const provider = new OAuthProvider("microsoft.com");
		// provider.setCustomParameters({
		// 	prompt: "consent",
		// });

		// Note: not sure exactly what we need
		// provider.addScope("email");
		provider.addScope("profile");
		// provider.addScope("user.read");

		await trySignInWithProvider(provider, returnRoute);
	};

	const signInWithFacebook = async (returnRoute: string = "") => {
		const provider = new FacebookAuthProvider();
		provider.addScope("email");
		provider.setCustomParameters({
			display: "popup",
		});

		await trySignInWithProvider(provider, returnRoute);
	};

	const signInWithTwitter = async (returnRoute: string = "") => {
		const provider = new TwitterAuthProvider();

		await trySignInWithProvider(provider, returnRoute);
	};

	// const signInWithEmail = async (email: string, password: string) => {
	// 	try {
	// 		setAuthError(initialAuthErrorState);
	// 		const fireBaseUser = await signInWithEmailAndPassword(
	// 			auth,
	// 			email,
	// 			password,
	// 		);
	//
	// 		await _redirectWhereNeeded(fireBaseUser.user);
	// 	} catch (err) {
	// 		const provider = new EmailAuthProvider();
	// 		_handleSignInErrors(err, provider);
	// 	}
	// };

	/**
	 * Signs into the existing provider and then link the new provider with it's credentials.
	 * @param existingProvider
	 * @param newProviderCredentials
	 * @param returnRoute
	 */
	const linkProviders = (
		existingProvider: SupportedProviders,
		newProviderCredentials: any,
		returnRoute: string = "",
	) => {
		// TODO: v9

		signInWithPopup(auth, existingProvider).then((result) => {
			if (!result || !result.user) {
				setAuthError({
					message: "Something went wrong, please try again!",
				});
				return;
			}

			// Link the accounts.
			// As we have access to the pending credential, we can directly call the link method.
			linkWithCredential(result.user, newProviderCredentials).then(
				(userCredentials) => {
					// new provider account was successfully linked to the existing Firebase user.
					setAuthError(initialAuthErrorState);
					setLinkAccount(initialLinkedAccountState);
					setLinkingDone(true);
					setAuthStatus("signInComplete");

					Router.push(returnRoute ?? "/");
				},
			);
		});
	};

	const signOut = () => {
		const firebaseApp = initFirebase();
		const auth = getAuth(firebaseApp);

		auth
			.signOut()
			.then(function () {
				// Sign-out successful.
				setAuthStatus(undefined);
				posthog.reset();
				Router.push("/");
			})
			.catch(function (error) {
				// An error happened.
			});
	};

	//---------- Private functions ----------

	const _getProvider = (providerId: string) => {
		switch (providerId) {
			case GoogleAuthProvider.PROVIDER_ID:
				return new GoogleAuthProvider();
			case FacebookAuthProvider.PROVIDER_ID:
				return new FacebookAuthProvider();
			case GithubAuthProvider.PROVIDER_ID:
				return new GithubAuthProvider();
			case "microsoft.com":
				return new OAuthProvider("microsoft.com");
			default:
				throw new Error(`No provider implemented for ${providerId}`);
		}
	};

	const _supportedPopupSignInMethods = [
		GoogleAuthProvider.PROVIDER_ID,
		FacebookAuthProvider.PROVIDER_ID,
		TwitterAuthProvider.PROVIDER_ID,
	];

	const _getProviderFriendlyName = (providerId: string) => {
		switch (providerId) {
			case GoogleAuthProvider.PROVIDER_ID:
				return "Google";
			case FacebookAuthProvider.PROVIDER_ID:
				return "Facebook";
			case TwitterAuthProvider.PROVIDER_ID:
				return "Twitter";
			case "microsoft.com":
				return "Microsoft";
			default:
				throw new Error(`No provider implemented for ${providerId}`);
		}
	};

	function _handleSignInErrors(error: any, newProvider: SupportedProviders) {
		setAuthStatus(undefined);
		// console.log("error.customData.email", error.customData.email);
		console.log("error.code", error.code);

		if (
			error.customData.email &&
			//error.credential &&
			error.code === "auth/account-exists-with-different-credential"
		) {
			// User's email already exists.
			// The pending provider credential.
			const pendingCred = OAuthProvider.credentialFromError(error);
			//const pendingCred = error.credential;

			// The provider account's email address.
			const email = error.customData.email;

			// Get sign-in methods for this email.

			fetchSignInMethodsForEmail(auth, email).then((methods) => {
				// console.log("methods[0]", methods[0]);

				if (methods[0] === "password") {
					// TODO: Implement prompting for password
					// https://firebase.google.com/docs/auth/web/google-signin#web-version-9_1
					return;
				}

				// All the other cases are external providers.
				// Construct provider object for that provider.
				// TODO: as of firebase beta 9, fetchSignInMethodsForEmail returns a list of strings so we do "any" :)
				const providerMethod = methods.find((p: any) =>
					_supportedPopupSignInMethods.includes(p),
				);

				// Test: Could this happen with email link then trying social provider?
				if (!providerMethod) {
					throw new Error(
						`Your account is linked to a provider that isn't supported.`,
					);
				}

				const existingProvider = _getProvider(providerMethod);

				// Set the login hint so when the user clicks continue to link the accounts the account will already be silently selected.
				existingProvider.setCustomParameters({
					login_hint: error.customData.email,
				});

				// Set the state, so we can provide user actions to link accounts together
				setLinkAccount({
					status: true,
					email: error.customData.email,
					existingProvider: existingProvider,
					existingProviderName: _getProviderFriendlyName(
						existingProvider.providerId,
					),
					newProviderName: _getProviderFriendlyName(newProvider.providerId),
					newProvider: newProvider,
					pendingCredentials: pendingCred,
				});
			});
		} else if (error.code === "auth/wrong-password") {
			setAuthStatus(undefined);
			setAuthError(error);
		} else {
			setAuthStatus(undefined);
			setAuthError(error);
		}
	}

	/**
	 * When a user signs in, we check if he is a user in the DB, if he doesn't exist we need to complete the profile.
	 * @param fireBaseUser
	 * @constructor
	 */
	async function _redirectWhereNeeded(
		fireBaseUser: User,
		returnRoute: string = "",
	) {
		// I don't know why the user object would ever be null
		if (!fireBaseUser) {
			Router.push(routes.error);
			return;
		}

		// Fix when figure out how to do in firebase v9
		// firebase.analytics().logEvent(firebase.analytics.EventName.LOGIN, {
		// 	method: fireBaseUser.providerId,
		// });

		const docRef = doc(db, collections.USERS, fireBaseUser.uid);

		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			// console.log("sign in complete");

			setAuthStatus("signInComplete");

			Router.push(returnRoute ?? "/");

			return;
		}

		setAuthStatus("completeProfile");
		const encodedUrl = encodeURIComponent(returnRoute);
		const completeSignupUrl = returnRoute
			? `${routes.completeSignup}?returnUrl=${encodedUrl}`
			: routes.completeSignup;

		Router.push(completeSignupUrl);
	}

	return {
		authStatus,
		authError,
		linkAccount,
		linkingDone,
		setLinkAccount,
		linkProviders,
		signInWithGoogle,
		signInWithFacebook,
		signInWithTwitter,
		signInWithMicrosoft,
		// signInWithEmailAndPassword: signInWithEmail,
		signOut,
	};
};

export { useAuthUser, useAuthManagement };
