import { useEditProfileFormStyles } from "./editProfileFormStyles";
import React, { FormEvent, useEffect, useState } from "react";
import {
	Box,
	Button,
	FlexGrid,
	Icon,
	Panel,
	PhoneField,
	Stack,
	TextField,
	useFormWithYup,
} from "@bilar/ui";
import * as yup from "yup";
import { MdCheckCircle } from "react-icons/md";
import { useUserProfile } from "@bilar/features";
import Router from "next/router";
import Image from "next/legacy/image";
import { User } from "@bilar/models";
import { parsePhoneNumber } from "react-phone-number-input";
import { CountryCode } from "libphonenumber-js";
import { Loading, useAppTranslation } from "@bilar/common";
import { StringParam, useQueryParams } from "use-query-params";

type EditProfileFormProps = {
	user?: User;
	submitButtonText: string;
} & EditProfileFormDefaultProps;

type EditProfileFormDefaultProps = {};

const defaultProps: EditProfileFormDefaultProps = {};

interface EditProfileFormValues {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
}

const toDto = (user: User, values: EditProfileFormValues): User => {
	// TODO: Get proper type for phoneNumber
	const phoneNumber = parsePhoneNumber(values.phoneNumber as any);

	return {
		...user,
		name: {
			first: values.firstName,
			last: values.lastName,
		},
		email: {
			address: values.email,
		},
		phone: {
			countryCode: phoneNumber?.countryCallingCode ?? "",
			internationalNumber: phoneNumber?.number ?? "",
			nationalNumber: phoneNumber?.nationalNumber ?? "",
		},
	};
};

const EditProfileForm = (props: EditProfileFormProps) => {
	const { user, submitButtonText } = props;
	const styles = useEditProfileFormStyles(props);
	const { profileLoading, updateProfile } = useUserProfile();
	const { t } = useAppTranslation();
	const { t: vt } = useAppTranslation("validation");
	const [queryParams, setQueryParams] = useQueryParams({
		returnUrl: StringParam,
	});
	const [defaultCountry, setDefaultCountry] = useState<
		CountryCode | undefined
	>();
	const [redirecting, setRedirecting] = useState(false);

	const schema = yup.object().shape({
		firstName: yup
			.string()
			.min(2, vt("min", { label: t("firstName") }))
			.required(vt("required")),
		lastName: yup
			.string()
			.min(2, vt("min", { label: t("firstName") }))
			.required(vt("required")),
		email: yup.string().email().required(vt("required")),
		phoneNumber: yup
			.string()
			.phone(vt("invalidPhoneNumber"))
			.required(vt("required")),
	});

	const {
		textFieldProps,
		phoneFieldProps,
		values,
		handleChange,
		handleSubmit,
	} = useFormWithYup<EditProfileFormValues>({
		initialValues: {
			firstName: user?.name.first || "",
			lastName: user?.name.last || "",
			email: user?.email.address || "",
			phoneNumber: user?.phone.internationalNumber || "",
		},
		validationSchema: schema,
	});

	useEffect(() => {
		if (user) {
			// Get the country code for the phone number
			const phoneNumber = parsePhoneNumber(
				user.phone.internationalNumber as string,
			);

			// Set the form values
			handleChange([
				["firstName", user.name.first],
				["lastName", user.name.last],
				["email", user.email.address],
				["phoneNumber", user.phone.internationalNumber],
			]);
			setDefaultCountry(phoneNumber?.country);
		}
	}, [user]);

	const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		handleSubmit(
			async () => {
				const dto = toDto(user!, values);
				await updateProfile(dto);
				setRedirecting(true);

				Router.push(queryParams.returnUrl ?? "/");
			},
			() => {},
		);
	};

	if (!user) {
		return (
			<Panel elevation="large">
				<Panel.Header title={t("profileInformation")} />
				<Panel.Body>
					<Loading centered={true} variant="dots" />
				</Panel.Body>
			</Panel>
		);
	}

	if (redirecting || profileLoading) {
		return (
			<Panel elevation="large">
				<Panel.Body>
					<Loading centered={true} variant="dots" text={t("savingProfile")} />
				</Panel.Body>
			</Panel>
		);
	}

	return (
		<form onSubmit={onSubmit}>
			<Panel elevation="large" css={styles.root}>
				<Panel.Header title={t("profileInformation")} />
				<Panel.Body>
					{user.profileImageUrl && (
						<Stack horizontalAlignment="center" mb={2}>
							<Image
								src={user.profileImageUrl}
								css={styles.profileImage}
								alt={`${user.name.first} ${user.name.last}`}
								width={96}
								height={96}
								layout="fixed"
							/>
						</Stack>
					)}
					<FlexGrid>
						<FlexGrid.Item width={["100%", "50%"]}>
							<Box>
								<TextField
									label={t("firstName")}
									{...textFieldProps("firstName")}
									autoComplete="firstName"
									mr={[0, 2]}
									data-rp-mask="true"
								/>
							</Box>
						</FlexGrid.Item>
						<FlexGrid.Item width={["100%", "50%"]}>
							<Box>
								<TextField
									label={t("lastName")}
									{...textFieldProps("lastName")}
									autoComplete="lastName"
									ml={[0, 2]}
									data-rp-mask="true"
								/>
							</Box>
						</FlexGrid.Item>
						<FlexGrid.Item width={["100%", "50%"]}>
							<Box>
								<TextField
									label={t("email")}
									{...textFieldProps("email")}
									autoComplete="email"
									mr={[0, 2]}
									data-rp-mask="true"
								/>
							</Box>
						</FlexGrid.Item>
						<FlexGrid.Item width={["100%", "50%"]}>
							<Box>
								<PhoneField
									defaultCountry="IS"
									preferredCountries={["IS", "PL"]}
									format={"e164"}
									label={t("phone")}
									{...phoneFieldProps("phoneNumber")}
									ml={[0, 2]}
									data-rp-mask="true"
								/>
							</Box>
						</FlexGrid.Item>
					</FlexGrid>
				</Panel.Body>
				<Panel.Footer>
					<Button
						type="submit"
						palette="success"
						fluid={true}
						disabled={profileLoading}
					>
						{submitButtonText}
						<Icon icon={<MdCheckCircle />} ml={2} />
					</Button>
				</Panel.Footer>
			</Panel>
		</form>
	);
};

EditProfileForm.defaultProps = defaultProps;

export { EditProfileForm };
export type { EditProfileFormProps };
