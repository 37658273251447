import { CSSObject } from "@emotion/react";
import { SectionHeadingProps } from "./SectionHeading";
import { useDesignTokens } from "@bilar/ui";

type SectionHeadingStyles = {
	root: CSSObject;
	linkContainer: CSSObject;
	link: CSSObject;
};

const useSectionHeadingStyles = (
	props: SectionHeadingProps,
): SectionHeadingStyles => {
	const designTokens = useDesignTokens();
	const { space, palette, fontColors, fontWeights } = designTokens;
	return {
		root: {},
		linkContainer: {
			display: "grid",
			gridTemplateColumns: "auto 1fr",
			alignSelf: "end",
			alignItems: "center",
		},
		link: {
			color: fontColors.main,
			fontWeight: fontWeights.medium,
			whiteSpace: "nowrap",
			textDecoration: "underline",

			"&:hover": {
				color: fontColors.main,
				textDecoration: "none",
			},
		},
	};
};

export { useSectionHeadingStyles };
