import { CSSObject } from "@emotion/react";
import { FormStepsProps } from "./FormSteps";
import { useDesignTokens } from "@bilar/ui";

type FormStepsStyles = {
	root: CSSObject;
	list: CSSObject;
	listItem: CSSObject;
	listItemLink: CSSObject;
	listItemLinkSelected: CSSObject;
	listItemLinkDisabled: CSSObject;
	listItemLinkText: CSSObject;
};

const useFormStepsStyles = (props: FormStepsProps): FormStepsStyles => {
	const designTokens = useDesignTokens();
	const { palette, space, fontColors, fontWeights, mediaQueries } =
		designTokens;
	return {
		root: {
			display: "none",

			[mediaQueries.tabletAndAbove]: {
				display: "grid",
				background: palette.white,
				borderRadius: space[1],
				padding: space[2],
				marginTop: space[6],
			},
		},
		list: {
			margin: 0,
			padding: 0,
		},
		listItem: {
			padding: 0,
			margin: 0,
		},
		listItemLink: {
			display: "grid",
			gridTemplateColumns: "auto 1fr",
			alignItems: "center",
			"> span": {
				alignItems: "center",
			},
			color: fontColors.main,
			fontWeight: fontWeights.normal,
			padding: `${space[2]}`,
			textDecoration: "none",

			"&:hover": {
				color: fontColors.main,
				textDecoration: "none",
				backgroundColor: palette.neutral.lighter.background,
			},
		},
		listItemLinkText: {
			paddingLeft: space[2],
		},
		listItemLinkSelected: {
			backgroundColor: palette.neutral.lighter.background,
			fontWeight: fontWeights.medium,
		},
		listItemLinkDisabled: {
			opacity: 0.6,
			cursor: "not-allowed",

			"&:hover": {
				backgroundColor: "#fff",
			},
		},
	};
};

export { useFormStepsStyles };
