import facepaint from "facepaint";

import { CSSProperties } from "react";
import {
	DesignTokens,
	getMapValue,
	ResponsiveProp,
	useDesignTokens,
} from "@bilar/ui";
import { Size, SpacingSize } from "../../../styling/designTokens/types";

type BoxBaseProps = {
	/** Full padding */
	p?: ResponsiveProp<SpacingSize>;
	/** Padding left and right */
	px?: ResponsiveProp<SpacingSize>;
	/** Padding top and bottom */
	py?: ResponsiveProp<SpacingSize>;
	/** Padding top */
	pt?: ResponsiveProp<SpacingSize>;
	/** Padding bottom */
	pb?: ResponsiveProp<SpacingSize>;
	/** Padding left */
	pl?: ResponsiveProp<SpacingSize>;
	/** Padding right */
	pr?: ResponsiveProp<SpacingSize>;

	/** Full margin */
	m?: ResponsiveProp<SpacingSize>;
	/** Margin left and right */
	mx?: ResponsiveProp<SpacingSize>;
	/** Margin top and bottom */
	my?: ResponsiveProp<SpacingSize>;
	/** Margin top */
	mt?: ResponsiveProp<SpacingSize>;
	/** Margin bottom */
	mb?: ResponsiveProp<SpacingSize>;
	/** Margin left */
	ml?: ResponsiveProp<SpacingSize>;
	/** Margin right */
	mr?: ResponsiveProp<SpacingSize>;

	// Size
	height?: ResponsiveProp<Size>;
	width?: ResponsiveProp<Size>;

	maxWidth?: ResponsiveProp<Size>;
	minWidth?: ResponsiveProp<Size>;
	maxHeight?: ResponsiveProp<Size>;
	minHeight?: ResponsiveProp<Size>;
};

function resolveMarginPaddingValue(
	topLeftBottomRightValue: SpacingSize | undefined,
	xyValue: SpacingSize | undefined,
	combinedValue: SpacingSize | undefined,
) {
	if (topLeftBottomRightValue !== undefined) return topLeftBottomRightValue;
	if (xyValue !== undefined) return xyValue;
	if (combinedValue !== undefined) return combinedValue;
}

/**
 * Filters out any "non box props" from a props object, good for spreading box props
 * @param props A props object that contains box props
 */
function getBoxPropsOnly(props: BoxBaseProps): BoxBaseProps {
	const boxPropsOnly: BoxBaseProps = {
		p: props.p,
		px: props.px,
		py: props.py,
		pt: props.pt,
		pb: props.pb,
		pl: props.pl,
		pr: props.pr,
		m: props.m,
		mx: props.mx,
		my: props.my,
		mt: props.mt,
		mb: props.mb,
		ml: props.ml,
		mr: props.mr,
		height: props.height,
		width: props.width,
		maxWidth: props.maxWidth,
		minWidth: props.minWidth,
		minHeight: props.minHeight,
	};

	return boxPropsOnly;
}

const removeBoxProps = <T extends BoxBaseProps>(props: T) => {
	const {
		p,
		px,
		py,
		pt,
		pb,
		pl,
		pr,
		m,
		mx,
		my,
		mt,
		mb,
		ml,
		mr,
		height,
		width,
		maxWidth,
		minWidth,
		minHeight,
		maxHeight,
		...filteredProps
	} = props;

	return filteredProps;
};

/**
 * A hook that can be used for other components that wan't the same behavior as the Box component
 * */
function useBoxStyles(boxProps: BoxBaseProps) {
	const tokens = useDesignTokens();
	return getBoxStyles(boxProps, tokens);
}

const sizeOrDefault = (
	designTokens: DesignTokens,
	size: SpacingSize | undefined,
) => {
	if (size === undefined) return undefined;
	const { space } = designTokens;

	return getMapValue(space, size);
};

function getBoxStyles(boxProps: BoxBaseProps, designTokens: DesignTokens) {
	const totalResult: facepaint.DynamicStyle[][] = [];
	const { utils } = designTokens;

	if (
		boxProps.m !== undefined ||
		boxProps.mt !== undefined ||
		boxProps.mb !== undefined ||
		boxProps.ml !== undefined ||
		boxProps.mr !== undefined ||
		boxProps.mx !== undefined ||
		boxProps.my !== undefined
	) {
		const marginStyles = utils.responsivePropsToCss<SpacingSize>(
			[
				boxProps.m,
				boxProps.mt,
				boxProps.mb,
				boxProps.ml,
				boxProps.mr,
				boxProps.mx,
				boxProps.my,
			],
			false,
			(m, mt, mb, ml, mr, mx, my) => {
				const resolvedMarginTop = resolveMarginPaddingValue(mt, my, m);
				const resolvedMarginBottom = resolveMarginPaddingValue(mb, my, m);
				const resolvedMarginLeft = resolveMarginPaddingValue(ml, mx, m);
				const resolvedMarginRight = resolveMarginPaddingValue(mr, mx, m);

				return {
					marginTop: sizeOrDefault(designTokens, resolvedMarginTop),
					marginBottom: sizeOrDefault(designTokens, resolvedMarginBottom),
					marginLeft: sizeOrDefault(designTokens, resolvedMarginLeft),
					marginRight: sizeOrDefault(designTokens, resolvedMarginRight),
				};
			},
		);

		totalResult.push(marginStyles);
	}

	if (
		boxProps.p !== undefined ||
		boxProps.pt !== undefined ||
		boxProps.pb !== undefined ||
		boxProps.pl !== undefined ||
		boxProps.pr !== undefined ||
		boxProps.px !== undefined ||
		boxProps.py !== undefined
	) {
		const paddingStyles = utils.responsivePropsToCss<SpacingSize>(
			[
				boxProps.p,
				boxProps.pt,
				boxProps.pb,
				boxProps.pl,
				boxProps.pr,
				boxProps.px,
				boxProps.py,
			],
			false,
			(p, pt, pb, pl, pr, px, py) => {
				const resolvedPaddingTop = resolveMarginPaddingValue(pt, py, p);
				const resolvedPaddingBottom = resolveMarginPaddingValue(pb, py, p);
				const resolvedMarginLeft = resolveMarginPaddingValue(pl, px, p);
				const resolvedPaddingRight = resolveMarginPaddingValue(pr, px, p);

				return {
					paddingTop: sizeOrDefault(designTokens, resolvedPaddingTop),
					paddingBottom: sizeOrDefault(designTokens, resolvedPaddingBottom),
					paddingLeft: sizeOrDefault(designTokens, resolvedMarginLeft),
					paddingRight: sizeOrDefault(designTokens, resolvedPaddingRight),
				};
			},
		);

		totalResult.push(paddingStyles);
	}

	if (boxProps.height !== undefined) {
		const heightStyles = getResponsiveWidthHeightCss(
			designTokens,
			boxProps.height,
			"height",
		);
		totalResult.push(heightStyles);
	}

	if (boxProps.maxHeight !== undefined) {
		const maxHeightStyles = getResponsiveWidthHeightCss(
			designTokens,
			boxProps.maxHeight,
			"maxHeight",
		);
		totalResult.push(maxHeightStyles);
	}

	if (boxProps.minHeight !== undefined) {
		const minHeightStyles = getResponsiveWidthHeightCss(
			designTokens,
			boxProps.minHeight,
			"minHeight",
		);
		totalResult.push(minHeightStyles);
	}

	if (boxProps.width !== undefined) {
		const widthStyles = getResponsiveWidthHeightCss(
			designTokens,
			boxProps.width,
			"width",
		);
		totalResult.push(widthStyles);
	}

	if (boxProps.maxWidth !== undefined) {
		const maxWidthStyles = getResponsiveWidthHeightCss(
			designTokens,
			boxProps.maxWidth,
			"maxWidth",
		);
		totalResult.push(maxWidthStyles);
	}

	if (boxProps.minWidth !== undefined) {
		const minWidthStyles = getResponsiveWidthHeightCss(
			designTokens,
			boxProps.minWidth,
			"minWidth",
		);
		totalResult.push(minWidthStyles);
	}

	return totalResult;
}

const getResponsiveWidthHeightCss = (
	designTokens: DesignTokens,
	responsiveWidthHeightProp: ResponsiveProp<Size>,
	cssPropName: keyof CSSProperties,
) => {
	const { utils } = designTokens;
	return utils.responsivePropToCss(
		responsiveWidthHeightProp,
		cssPropName,
		(widthOrHeight) => {
			if (typeof widthOrHeight === "string") {
				// It was a string so we return it as is (so units like % etc can be used)
				return widthOrHeight;
			} else {
				return sizeOrDefault(designTokens, widthOrHeight);
			}
		},
	);
};

export {
	resolveMarginPaddingValue,
	getBoxStyles,
	getBoxPropsOnly,
	useBoxStyles,
	removeBoxProps,
};
export type { BoxBaseProps };
