/** @jsxImportSource @emotion/react */
import { forwardRef, ReactNode } from "react";
import { Orientation, StackBaseProps, useStackStyles } from "./stackStyles";
import {
	BaseProps,
	BoxBaseProps,
	getTestId,
	HtmlTag,
	removeBoxProps,
	ResponsiveProp,
	Tag,
	useBoxStyles,
} from "@bilar/ui";

type StackProps = {
	children?: ReactNode;
} & DefaultStackProps &
	Omit<StackBaseProps, "orientation"> &
	BoxBaseProps &
	BaseProps;

type DefaultStackProps = {
	as?: HtmlTag;
	orientation?: ResponsiveProp<Orientation>;
	/**
	 * @deprecated This prop is not needed anymore.
	 */
	itemsAs?: HtmlTag;
};

/** The `<Stack>` component is used for arranging items in a horizontal or vertical order. */
const Stack = forwardRef<any, StackProps>(
	({ as = "div", orientation = "vertical", ...props }, ref) => {
		// If the user sets the vertical and/or horizontal alignment props,
		// we treat this as a stack panel almost (but only support a single child)
		const stackStyles = useStackStyles({ ...props, orientation });
		const boxStyles = useBoxStyles(props);
		const {
			testId,
			horizontalAlignment,
			itemsAs,
			verticalAlignment,
			spacing,
			...rest
		} = removeBoxProps(props);

		return (
			<Tag
				ref={ref}
				as={as}
				id={props.id}
				className={props.className}
				css={[stackStyles, boxStyles]}
				testId={getTestId(testId)}
				{...rest}
			>
				{props.children}
			</Tag>
		);
	},
);

Stack.displayName = "Stack";

export { Stack };
export type { StackProps };
