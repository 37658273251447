/** @jsxImportSource @emotion/react */
import { useDesignTokens } from "../../../styling";
import { BaseProps } from "../../";
import { useRadioButtonStyles } from "./radioButtonStyles";
import { ChangeEvent, FocusEvent, useEffect, useState } from "react";
import { getTestId } from "../../../utils";
import { motion } from "framer-motion";
import { LabelPosition } from "@bilar/ui";

type RadioButtonProps = {
	text: string;
	disabled?: boolean;
	onChange?: (checked: boolean) => void;
	group: string;
} & RadioButtonDefaultProps &
	BaseProps;

type RadioButtonSize = "regular" | "medium" | "large";

type RadioButtonDefaultProps = {
	layout?: "regular" | "box";
	checked?: boolean;
	labelPosition?: LabelPosition;
	size?: RadioButtonSize;
};

const defaultProps: RadioButtonDefaultProps = {
	layout: "regular",
	checked: false,
	labelPosition: "right",
	size: "regular",
};

/**
 * TODO add notes about this component.
 */
const RadioButton = (props: RadioButtonProps) => {
	// Focus and blur used in styles
	const designTokens = useDesignTokens();
	const styles = useRadioButtonStyles(props, designTokens);
	const { text, testId, disabled } = props;
	const [variant, setVariant] = useState("unchecked");

	const dotVariants = {
		checked: {
			opacity: 1,
			scale: 1,
		},
		unchecked: {
			opacity: 0,
			scale: 0.5,
		},
		disabled: {
			backgroundColor: designTokens.palette.neutral.main.background,
			opacity: 0.5,
			scale: 1,
		},
	};

	useEffect(() => {
		if (props.checked) {
			setVariant("checked");
		} else {
			setVariant("unchecked");
		}

		if (props.disabled) {
			setVariant("disabled");
		}

		if (props.disabled && !props.checked) {
			setVariant("unchecked");
		}
	}, [props.checked, props.disabled]);

	const onChangeLocally = (event: ChangeEvent<HTMLInputElement>) => {
		if (props.onChange) props.onChange(event.target.checked);
	};

	const onFocus = (event: FocusEvent<HTMLInputElement>) => {};
	const onBlur = (event: FocusEvent<HTMLInputElement>) => {};

	return (
		<label css={styles.root} data-testid={getTestId(testId, "label")}>
			<input
				data-testid={getTestId(testId)}
				checked={props.checked}
				css={styles.input}
				name={props.group}
				disabled={disabled}
				type="radio"
				onChange={onChangeLocally}
				onFocus={onFocus}
				onBlur={onBlur}
			/>
			<div css={styles.radioWrapper} className="radio-wrapper">
				<motion.div
					className="radio-dot"
					css={styles.radioDot}
					variants={dotVariants}
					initial="unchecked"
					animate={variant}
					transition={{
						type: "spring",
						stiffness: 300,
						damping: 30,
					}}
				/>
			</div>
			<span css={styles.text} data-testid={getTestId(testId, "text")}>
				{text}
			</span>
		</label>
	);
};

RadioButton.defaultProps = defaultProps;

export { RadioButton };
export type { RadioButtonProps, RadioButtonSize };
