import * as originalYup from "yup";
import { phoneFieldYupValidation } from "@bilar/ui";

//------------------ Components ------------------
// Custom components
export * from "./components";

// Custom components with the same names as Chakra (avoid ambiguity)
export {
	Button,
	ButtonGroup,
	Divider,
	Heading,
	Icon,
	Tag,
	Box,
	Stack,
	Checkbox,
	Select,
	Tooltip,
} from "./components";

//------------------ Styling ------------------
export * from "./styling";

//------------------ Utils ------------------
export * from "./utils";

//------------------ Hooks ------------------
export * from "./hooks";

//------------------ Types ------------------
export * from "./types";

//------------------ Providers ------------------
export * from "./providers";

//------------------ Custom Yup methods ------------------
export * from "./yup.module";

type Yup = typeof originalYup;

/**
 * Registers all of Abstract's custom yup methods like .phone() and .ssn()
 * @param yup
 */
// TODO: Miguel, we should probably move this out of here?
export const registerYupMethods = (yup: Yup): Yup => {
	yup.addMethod(yup.string, "phone", phoneFieldYupValidation);
	return yup;
};

// To use the Yup methods: yup.string().phone()
export const yup = registerYupMethods(originalYup);

// When using the string schema directly: string().phone()
export const string = yup.string;
