import colors from "./colors";
import chroma from "chroma-js";
import { ColorHues } from "@bilar/ui";

const { red, blueGray, pink, yellow, gray, black, lightBlue, orange } = colors;

type PaletteTokens = {
	primary: PaletteItemTokens;
	secondary: PaletteItemTokens;
	highlight: PaletteItemTokens;
	neutral: PaletteItemTokens;
	error: PaletteItemTokens;
	warning: PaletteItemTokens;
	success: PaletteItemTokens;
	info: PaletteItemTokens;
	grey: PaletteItemTokens;
	white: string;
	black: string;
};

interface PaletteItemTokens {
	lighter: PaletteShadeTokens;
	light: PaletteShadeTokens;
	main: PaletteShadeTokens;
	dark: PaletteShadeTokens;
	darker: PaletteShadeTokens;
	// 0: PaletteShadeTokens;
	50: PaletteShadeTokens;
	100: PaletteShadeTokens;
	200: PaletteShadeTokens;
	300: PaletteShadeTokens;
	400: PaletteShadeTokens;
	500: PaletteShadeTokens;
	600: PaletteShadeTokens;
	700: PaletteShadeTokens;
	800: PaletteShadeTokens;
	900: PaletteShadeTokens;
	// 1000: PaletteShadeTokens;
}

interface PaletteShadeTokens {
	background: string;
	contrast: string;
}

const highlight: ColorHues = {
	"50": "#FFF5E5",
	"100": "#FFEACC",
	"200": "#FFD699",
	"300": "#FFC166",
	"400": "#FFAD33",
	"500": "#FF9800",
	"600": "#CC7A00",
	"700": "#995B00",
	"800": "#663D00",
	"900": "#331E00",
};

const palette: PaletteTokens = {
	primary: {
		lighter: {
			background: colors.green[100],
			contrast: chroma(colors.green[100]).luminance(0.015).hex(),
		},
		light: {
			background: colors.green[300],
			contrast: chroma(colors.green[300]).luminance(0.015).hex(),
		},
		main: {
			background: colors.green[500],
			contrast: chroma(colors.green[500]).luminance(0.015).hex(),
		},
		dark: {
			background: colors.green[600],
			contrast: colors.white,
		},
		darker: {
			background: colors.green[900],
			contrast: colors.white,
		},

		// TODO: Verify correct contrasts
		50: {
			background: colors.green[50],
			contrast: colors.green[900],
		},
		100: {
			background: colors.green[100],
			contrast: colors.green[900],
		},
		200: {
			background: colors.green[200],
			contrast: chroma(colors.green[200]).luminance(0.04).hex(),
		},
		300: {
			background: colors.green[300],
			contrast: chroma(colors.green[300]).luminance(0.015).hex(),
		},
		400: {
			background: colors.green[400],
			contrast: chroma(colors.green[400]).luminance(0.015).hex(),
		},
		500: {
			background: colors.green[500],
			contrast: colors.green["900"],
			// contrast: chroma(colors.green[500]).luminance(0.015).hex(),
		},
		600: {
			background: colors.green[600],
			contrast: chroma(colors.green[600]).luminance(0.015).hex(),
		},
		700: {
			background: colors.green[700],
			contrast: "#fff",
		},
		800: {
			background: colors.green[800],
			contrast: colors.white,
		},
		900: {
			background: colors.green[900],
			contrast: colors.white,
		},
	},
	secondary: {
		lighter: {
			background: lightBlue[50],
			contrast: lightBlue[900],
		},
		light: {
			background: lightBlue[600],
			contrast: lightBlue[900],
		},
		main: {
			background: blueGray[700],
			contrast: "#fff",
		},
		dark: {
			background: blueGray[800],
			contrast: chroma(blueGray[800]).luminance(1).hex(),
		},
		darker: {
			background: blueGray[900],
			contrast: chroma(blueGray[900]).luminance(1).hex(),
		},
		// TODO: Verify correct contrasts & colours (we have a mix of lightBlue and blueGray)
		50: {
			background: colors.blueGray[50],
			contrast: colors.blueGray[900],
		},
		100: {
			background: colors.blueGray[100],
			contrast: colors.blueGray[900],
		},
		200: {
			background: colors.blueGray[200],
			contrast: chroma(colors.blueGray[200]).luminance(0.04).hex(),
		},
		300: {
			background: colors.blueGray[300],
			contrast: chroma(colors.blueGray[300]).luminance(0.015).hex(),
		},
		400: {
			background: colors.blueGray[400],
			contrast: chroma(colors.blueGray[400]).luminance(0.015).hex(),
		},
		500: {
			background: colors.blueGray[500],
			contrast: chroma(colors.blueGray[500]).luminance(0.015).hex(),
		},
		600: {
			background: colors.blueGray[600],
			contrast: chroma(colors.blueGray[600]).luminance(0.015).hex(),
		},
		700: {
			background: colors.blueGray[700],
			contrast: chroma(colors.blueGray[700]).luminance(0.01).hex(),
		},
		800: {
			background: colors.blueGray[800],
			contrast: colors.white,
		},
		900: {
			background: colors.blueGray[900],
			contrast: colors.white,
		},
	},
	highlight: {
		lighter: {
			background: highlight[50],
			contrast: highlight[900],
		},
		light: {
			background: highlight[200],
			contrast: highlight[900],
		},
		main: {
			background: highlight[500],
			contrast: highlight[900],
		},
		dark: {
			background: highlight[600],
			contrast: "#fff",
		},
		darker: {
			background: highlight[900],
			contrast: "#fff",
		},
		// TODO: Verify correct contrasts
		50: {
			background: highlight[50],
			contrast: highlight[900],
		},
		100: {
			background: highlight[100],
			contrast: highlight[900],
		},
		200: {
			background: highlight[200],
			contrast: chroma(highlight[200]).luminance(0.04).hex(),
		},
		300: {
			background: highlight[300],
			contrast: chroma(highlight[300]).luminance(0.015).hex(),
		},
		400: {
			background: highlight[400],
			contrast: chroma(highlight[400]).luminance(0.015).hex(),
		},
		500: {
			background: highlight[500],
			contrast: chroma(highlight[500]).luminance(0.015).hex(),
		},
		600: {
			background: highlight[600],
			contrast: chroma(highlight[600]).luminance(0.015).hex(),
		},
		700: {
			background: highlight[700],
			contrast: chroma(highlight[700]).luminance(0.01).hex(),
		},
		800: {
			background: highlight[800],
			contrast: colors.white,
		},
		900: {
			background: highlight[900],
			contrast: colors.white,
		},
	},

	neutral: {
		lighter: {
			background: blueGray[50],
			contrast: blueGray[900],
		},
		light: {
			background: blueGray[200],
			contrast: blueGray[900],
		},
		main: {
			background: blueGray[500],
			contrast: "#fff",
		},
		dark: {
			background: blueGray[700],
			contrast: "#fff",
		},
		darker: {
			background: blueGray[900],
			contrast: "#fff",
		},
		// TODO: Verify correct contrasts
		50: {
			background: colors.blueGray[50],
			contrast: colors.blueGray[900],
		},
		100: {
			background: colors.blueGray[100],
			contrast: colors.blueGray[900],
		},
		200: {
			background: colors.blueGray[200],
			contrast: chroma(colors.blueGray[200]).luminance(0.04).hex(),
		},
		300: {
			background: colors.blueGray[300],
			contrast: chroma(colors.blueGray[300]).luminance(0.015).hex(),
		},
		400: {
			background: colors.blueGray[400],
			contrast: chroma(colors.blueGray[400]).luminance(0.015).hex(),
		},
		500: {
			background: colors.blueGray[500],
			contrast: chroma(colors.blueGray[500]).luminance(0.015).hex(),
		},
		600: {
			background: colors.blueGray[600],
			contrast: chroma(colors.blueGray[600]).luminance(0.015).hex(),
		},
		700: {
			background: colors.blueGray[700],
			contrast: chroma(colors.blueGray[700]).luminance(0.01).hex(),
		},
		800: {
			background: colors.blueGray[800],
			contrast: colors.white,
		},
		900: {
			background: colors.blueGray[900],
			contrast: colors.white,
		},
	},
	error: {
		lighter: {
			background: red[50],
			contrast: red[900],
		},
		light: {
			background: red[200],
			contrast: red[900],
		},
		main: {
			background: red[500],
			contrast: "#fff",
		},
		dark: {
			background: red[700],
			contrast: chroma(red[700]).luminance(0.85).hex(),
		},
		darker: {
			background: red[900],
			contrast: chroma(red[900]).luminance(0.85).hex(),
		},
		// TODO: Verify correct contrasts
		50: {
			background: colors.red[50],
			contrast: colors.red[900],
		},
		100: {
			background: colors.red[100],
			contrast: colors.red[900],
		},
		200: {
			background: colors.red[200],
			contrast: chroma(colors.red[200]).luminance(0.04).hex(),
		},
		300: {
			background: colors.red[300],
			contrast: chroma(colors.red[300]).luminance(0.015).hex(),
		},
		400: {
			background: colors.red[400],
			contrast: chroma(colors.red[400]).luminance(0.015).hex(),
		},
		500: {
			background: colors.red[500],
			contrast: chroma(colors.red[500]).luminance(0.015).hex(),
		},
		600: {
			background: colors.red[600],
			contrast: chroma(colors.red[600]).luminance(0.015).hex(),
		},
		700: {
			background: colors.red[700],
			contrast: chroma(colors.red[700]).luminance(0.01).hex(),
		},
		800: {
			background: colors.red[800],
			contrast: colors.white,
		},
		900: {
			background: colors.red[900],
			contrast: colors.white,
		},
	},
	success: {
		lighter: {
			background: colors.green[100],
			contrast: chroma(colors.green[100]).luminance(0.015).hex(),
		},
		light: {
			background: colors.green[300],
			contrast: chroma(colors.green[300]).luminance(0.015).hex(),
		},
		main: {
			background: colors.green[500],
			contrast: chroma(colors.green[500]).luminance(0.015).hex(),
		},
		dark: {
			background: colors.green[700],
			contrast: colors.white,
		},
		darker: {
			background: colors.green[900],
			contrast: colors.white,
		},
		// TODO: Verify correct contrasts
		50: {
			background: colors.green[50],
			contrast: colors.green[900],
		},
		100: {
			background: colors.green[100],
			contrast: colors.green[900],
		},
		200: {
			background: colors.green[200],
			contrast: chroma(colors.green[200]).luminance(0.04).hex(),
		},
		300: {
			background: colors.green[300],
			contrast: chroma(colors.green[300]).luminance(0.015).hex(),
		},
		400: {
			background: colors.green[400],
			contrast: chroma(colors.green[400]).luminance(0.015).hex(),
		},
		500: {
			background: colors.green[500],
			contrast: chroma(colors.green[500]).luminance(0.015).hex(),
		},
		600: {
			background: colors.green[600],
			contrast: chroma(colors.green[600]).luminance(0.015).hex(),
		},
		700: {
			background: colors.green[700],
			contrast: chroma(colors.green[700]).luminance(0.01).hex(),
		},
		800: {
			background: colors.green[800],
			contrast: colors.white,
		},
		900: {
			background: colors.green[900],
			contrast: colors.white,
		},
	},
	warning: {
		lighter: {
			background: yellow[50],
			contrast: yellow[900],
		},
		light: {
			background: yellow[200],
			contrast: yellow[900],
		},
		main: {
			background: yellow[500],
			contrast: yellow[900],
		},
		dark: {
			background: yellow[700],
			contrast: chroma(yellow[700]).luminance(0.01).hex(),
		},
		darker: {
			background: yellow[900],
			contrast: yellow[50],
		},
		// TODO: Verify correct contrasts
		50: {
			background: yellow[50],
			contrast: yellow[900],
		},
		100: {
			background: yellow[100],
			contrast: yellow[900],
		},
		200: {
			background: yellow[200],
			contrast: chroma(yellow[200]).luminance(0.04).hex(),
		},
		300: {
			background: yellow[300],
			contrast: chroma(yellow[300]).luminance(0.015).hex(),
		},
		400: {
			background: yellow[400],
			contrast: chroma(yellow[400]).luminance(0.015).hex(),
		},
		500: {
			background: yellow[500],
			contrast: chroma(yellow[500]).luminance(0.015).hex(),
		},
		600: {
			background: yellow[600],
			contrast: chroma(yellow[600]).luminance(0.015).hex(),
		},
		700: {
			background: yellow[700],
			contrast: chroma(yellow[700]).luminance(0.01).hex(),
		},
		800: {
			background: yellow[800],
			contrast: colors.white,
		},
		900: {
			background: yellow[900],
			contrast: colors.white,
		},
	},
	info: {
		lighter: {
			background: "#A2CEFF",
			contrast: "#000",
		},
		light: {
			background: "#A2CEFF",
			contrast: "#000",
		},
		main: {
			background: "#A2CEFF",
			contrast: "#000",
		},
		dark: {
			background: "#A2CEFF",
			contrast: "#000",
		},
		darker: {
			background: "#A2CEFF",
			contrast: "#000",
		},
		// TODO: Verify correct contrasts
		50: {
			background: colors.lightBlue[50],
			contrast: colors.lightBlue[900],
		},
		100: {
			background: colors.lightBlue[100],
			contrast: colors.lightBlue[900],
		},
		200: {
			background: colors.lightBlue[200],
			contrast: chroma(colors.lightBlue[200]).luminance(0.04).hex(),
		},
		300: {
			background: colors.lightBlue[300],
			contrast: chroma(colors.lightBlue[300]).luminance(0.015).hex(),
		},
		400: {
			background: colors.lightBlue[400],
			contrast: chroma(colors.lightBlue[400]).luminance(0.015).hex(),
		},
		500: {
			background: colors.lightBlue[500],
			contrast: chroma(colors.lightBlue[500]).luminance(0.015).hex(),
		},
		600: {
			background: colors.lightBlue[600],
			contrast: chroma(colors.lightBlue[600]).luminance(0.015).hex(),
		},
		700: {
			background: colors.lightBlue[700],
			contrast: chroma(colors.lightBlue[700]).luminance(0.01).hex(),
		},
		800: {
			background: colors.lightBlue[800],
			contrast: colors.white,
		},
		900: {
			background: colors.lightBlue[900],
			contrast: colors.white,
		},
	},
	grey: {
		lighter: {
			background: colors.grey[50],
			contrast: colors.grey[900],
		},
		light: {
			background: colors.grey[300],
			contrast: colors.grey[900],
		},
		main: {
			background: colors.grey[500],
			contrast: colors.grey[900]!,
		},
		dark: {
			background: colors.grey[700],
			contrast: colors.white,
		},
		darker: {
			background: colors.grey[900],
			contrast: colors.white,
		},
		// 0: {
		// 	background: colors.grey[0],
		// 	contrast: colors.grey[900],
		// },
		50: {
			background: colors.grey[50],
			contrast: colors.grey[900],
		},
		100: {
			background: colors.grey[100],
			contrast: colors.grey[900],
		},
		200: {
			background: colors.grey[200],
			contrast: colors.grey[900],
		},
		300: {
			background: colors.grey[300],
			contrast: colors.grey[900],
		},
		400: {
			background: colors.grey[400],
			contrast: colors.grey[900],
		},
		500: {
			background: colors.grey[500],
			contrast: colors.grey[900],
		},
		600: {
			background: colors.grey[600],
			contrast: "#000",
		},
		700: {
			background: colors.grey[700],
			contrast: colors.white,
		},
		800: {
			background: colors.grey[800],
			contrast: colors.white,
		},
		900: {
			background: colors.grey[900],
			contrast: colors.white,
		},
		// 1000: {
		// 	background: colors.grey[1000],
		// 	contrast: colors.grey[900],
		// },
	},
	black: "#212121",
	white: "#fff",
};

export default palette;

export type { PaletteTokens, PaletteShadeTokens, PaletteItemTokens };
