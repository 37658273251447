import { useCurrency } from "@bilar/ui";
import { ClassifiedVehicle, FuelType } from "@bilar/models";
import { getBrandDisplay } from "@bilar/features";
import { useAppTranslation } from "@bilar/common";

export const useDisplayFormat = () => {
	const { t } = useAppTranslation();
	const { formatCurrency } = useCurrency();

	const getPriceDisplay = (values: ClassifiedVehicle) => {
		if (values.price.offer) {
			return "Tilboð"; // TODO: Lokalise
		}

		return formatCurrency(values.price.amount!);
	};

	const getFuelTypeLabel = (fuelType: FuelType | null) => {
		if (!fuelType) {
			return "";
		}
		switch (fuelType) {
			case "gasoline":
				return t("fuelTypeGasoline");
			case "diesel":
				return t("fuelTypeDiesel");
			case "electric":
				return t("fuelTypeElectric");
			case "hybrid":
				return t("fuelTypeHybrid");
			case "plug-in-hybrid":
				return t("fuelTypePluginHybrid");
			case "methane":
				return t("fuelTypeMethane");
			case "hydrogen":
				return t("fuelTypeHydrogen");
			default:
				return t("fuelTypeOther");
		}
	};

	return {
		getPriceDisplay,
		getBrandDisplay,
		getFuelTypeLabel,
	};
};
