import {
	getAsYouType,
	ParsedPhoneNumber,
	parsePhoneNumber as parseAwesomePhoneNumber,
	PhoneNumberFormat,
} from "awesome-phonenumber";
import { Country, CountryCode } from "../../../types";
import { useCountries } from "../../../hooks";
import { useMemo } from "react";

const findCountryFromNumber = (countries: Country[], number: string) => {
	let found: Country[] | undefined;

	// If the number starts with 00, then we get the country code from the third and fourth digits.
	if (number.startsWith("00") && number.length >= 3) {
		const callingCode = parseInt(number.substring(2, 4));
		found = countries.filter((c) => c.callingCode === callingCode);
	}

	// If the number starts with +, then we get the country code from the second, third and fourth digits.
	if (!found?.length && number.startsWith("+")) {
		found = countries.filter(
			(c) => c.callingCode === parseInt(number.substring(1, 4)),
		);
	}

	// Use awesome-phonenumber to get the country code from the number.
	if (!found?.length) {
		const countryCode = parseAwesomePhoneNumber(number).regionCode;
		found = countries.filter((c) => c.iso2 === countryCode);
	}

	return found;
};

type PhoneResult = {
	/**
	 * The phone number with spaces, dash and parentheses.
	 */
	asYouTypeNumber: string;
	phoneNumber?: ParsedPhoneNumber;
	country?: Country;
};

/**
 * Formats a phone number as you type, according to the rules specified for the given country.
 *
 * - If the input is a "+" or a partial international code starting with "+", it returns the input as-is.
 * - If the input starts with a series of zeros (e.g., "0", "00", "000"), it returns the input as-is.
 * - If the input contains the full international code for the given country (e.g., "+46" or "0046"), it formats the national number with a leading zero wrapped in parentheses.
 * - For national numbers, it adds a leading zero if necessary and formats the number, wrapping the leading zero in parentheses.
 *
 * @param {string} number - The phone number to be formatted.
 * @param {Country} country - An object containing the country's details (e.g., name, ISO code, calling code).
 * @returns {string} The formatted national phone number.
 */
const formatAsYouType = (number: string, country: Country): string => {
	const asYouType = getAsYouType(country.iso2);

	// If the input is a "+" or partial country code starting with "+", return it as-is
	if (
		number === "+" ||
		(number.startsWith("+") && !number.startsWith(`+${country.callingCode}`))
	) {
		return number;
	}

	// Remove the country calling code if it is present (new "+" or old "00")
	const regex = new RegExp(`^(\\+?|00)${country.callingCode}`);
	let nationalNumber = number?.replace(regex, "").trim() || "";

	// If the number is an empty string, just a 0, or user is typing international code "00"
	if (
		number === "" ||
		number === "0" ||
		number === "00" ||
		(number.startsWith("00") && !number.match(regex))
	) {
		return number;
	}

	// This fixes the issue when you are in Sweden, and type +47 to switch to Norway, the national number is empty.
	// But if we continue it will add a 0, which will confuse the user, so we return an empty string.
	if (nationalNumber === "") {
		return "";
	}

	// as-you-type formatting needs a leading 0
	if (!nationalNumber.startsWith("0") && country.usesZeroPrefix) {
		// Add the leading 0 for the formatting
		nationalNumber = `0${nationalNumber}`;
	}

	// Apply as-you-type formatting
	nationalNumber = asYouType?.reset(nationalNumber);

	// Add parentheses around the leading zero
	if (country.usesZeroPrefix && nationalNumber.startsWith("0")) {
		nationalNumber = `(0) ${nationalNumber.slice(1)}`;
	}

	return nationalNumber;
};

/**
 * Returns the normalized phone number and the formatted phone number, adding the parentheses if a local 0 is detected.
 * @param value
 * @param countries
 * @param defaultCountry
 */
const parsePhoneNumber = (
	value: string,
	countries: Country[],
	defaultCountry?: Country,
) => {
	const cleanNumber = value.replace(/[^\d+]|(?!^)\+/g, ""); // Keep only numbers and "+" at the start
	const country =
		findCountryFromNumber(countries, cleanNumber)?.[0] || defaultCountry;

	if (!value || !country) {
		return {
			asYouTypeNumber: cleanNumber,
			country: defaultCountry,
			phoneNumber: parseAwesomePhoneNumber(cleanNumber, {
				regionCode: defaultCountry?.iso2,
			}),
		};
	}

	return {
		asYouTypeNumber: formatAsYouType(cleanNumber, country),
		country: country,
		phoneNumber: parseAwesomePhoneNumber(cleanNumber, {
			regionCode: country.iso2,
		}),
	};
};

const usePhoneFieldValue = (
	value: string,
	defaultCountry?: Country,
	countries?: Country[],
): PhoneResult | null => {
	const allCountries = useCountries();
	if (!countries) {
		countries = allCountries;
	}

	return useMemo(() => {
		if (value && countries) {
			return parsePhoneNumber(value, countries, defaultCountry);
		}
		return null;
	}, [value, defaultCountry, countries]);
};

const usePhoneNumber = () => {
	// TODO: Expose and export these into a module. Add the validations.ts:validateMobileNumber() concept
	//  as well.
	return {
		formatPhoneNumber: (number: string, format: PhoneNumberFormat) =>
			parseAwesomePhoneNumber(number).number?.[format],
		isPhoneNumberValid: (number: string) =>
			parseAwesomePhoneNumber(number).valid,
		isPhoneNumberMobile: (number: string) =>
			parseAwesomePhoneNumber(number).typeIsMobile,
		isPossiblePhoneNumber: (number: string) =>
			parseAwesomePhoneNumber(number).possible,
		isPhoneNumberFixedLine: (number: string) =>
			parseAwesomePhoneNumber(number).typeIsFixedLine,
		canPhoneNumberBeInternationallyDialled: (number: string) =>
			parseAwesomePhoneNumber(number).canBeInternationallyDialled,
		getPhoneNumberRegionCode: (number: string): CountryCode | undefined =>
			parseAwesomePhoneNumber(number).regionCode as CountryCode,
		getPhoneNumberCountryCode: (number: string): number | undefined => {
			const parsedNumber = parseAwesomePhoneNumber(number);
			return parsedNumber.countryCode;
		},
		getPhoneNumberType: (number: string) =>
			parseAwesomePhoneNumber(number).type,
	};
};

export {
	usePhoneFieldValue,
	usePhoneNumber,
	formatAsYouType,
	parsePhoneNumber,
};
export type { PhoneResult };
