import { CSSObject } from "@emotion/react";
import { ContainerProps } from "./Container";
import { useDesignTokens } from "../..";

type ContainerStyles = {
	root: CSSObject;
};

const useContainerStyles = (props: ContainerProps): ContainerStyles => {
	const designTokens = useDesignTokens();
	const { space, mediaQueries } = designTokens;
	return {
		root: {
			margin: "0 auto",
			width: "100%",
			paddingLeft: space[4],
			paddingRight: space[4],
			[mediaQueries.tabletAndAbove]: {
				maxWidth: 768,
			},
			[mediaQueries.desktopAndAbove]: {
				maxWidth: 960,
			},
			[mediaQueries.desktopWideAndAbove]: {
				maxWidth: 1136,
			},
		},
	};
};

export { useContainerStyles };
