import { CSSObject } from "@emotion/react";
import { ColourSelectorProps } from "./ColourSelector";
import { useDesignTokens } from "@bilar/ui";

type ColourSelectorStyles = {
	root: CSSObject;
	label: CSSObject;
	colorSelectionWrapper: CSSObject;
	buttonWrapper: CSSObject;
	button: CSSObject;
	buttonInnerLabel: CSSObject;
	buttonLabel: CSSObject;
	silhouette: CSSObject;
};

const useColourSelectorStyles = (
	props: ColourSelectorProps,
): ColourSelectorStyles => {
	const designTokens = useDesignTokens();
	const { space, palette, radii, mediaQueries } = designTokens;
	return {
		root: {},
		label: {
			marginBottom: space[2],
		},
		colorSelectionWrapper: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			margin: `0 0 ${space[2]}`,
			flexWrap: "wrap",
		},
		buttonWrapper: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			minWidth: space[12],
		},
		button: {
			padding: 0,
			margin: 0,
			width: space[8],
			height: space[8],
			border: `2px solid transparent`,
			borderRadius: radii.md,

			"&:hover": {
				borderColor: "#000",
				outline: `1px solid #000`,
			},

			'&[data-selected="true"]': {
				borderColor: palette.success.main.background,
				outline: `1px solid ${palette.success.main.background}`,
			},

			'&[data-colour="black"]': {
				"&:hover": {
					borderColor: palette.success.main.background,
					outlineColor: palette.success.main.background,
				},

				'&[data-selected="true"]': {
					borderColor: palette.success.main.background,
				},
			},

			'&[data-colour="white"]': {
				borderColor: palette.neutral.main.background,

				"&:hover": {
					borderColor: "#000",
				},

				'&[data-selected="true"]': {
					borderColor: palette.success.main.background,
				},
			},

			'&[data-colour="other"]': {
				borderColor: palette.neutral.main.background,

				"&:hover": {
					borderColor: "#000",
				},

				'&[data-selected="true"]': {
					borderColor: palette.success.main.background,
				},
			},
		},
		buttonInnerLabel: {
			display: "none",
		},
		buttonLabel: {
			fontSize: "14px",
			lineHeight: space[8],
			'&[data-selected="true"]': {
				fontWeight: 600,
			},
		},
		silhouette: {
			width: 76,
			height: 48,
		},
	};
};

export { useColourSelectorStyles };
