import { AppHeaderVariables, AppNavVariables } from "./types";

export const appHeaderVariables: AppHeaderVariables = {
	heightUnit: 28,
	mobileHeightUnit: 16,
};

export const appNavVariables: AppNavVariables = {
	// heightUnit: 28,
	mobileHeightUnit: 18,
};
