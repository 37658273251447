import { createEditor, SerializedEditorState } from "lexical";
import { CreateEditorArgs } from "lexical/LexicalEditor";
import { editorConfig } from "./editorConfig";
import { getHtml, getText } from "./richTextUtils";

const createArgs: CreateEditorArgs = {
	nodes: editorConfig.nodes,
	namespace: editorConfig.namespace,
};

export const useRichText = () => {
	const editor = createEditor(createArgs);

	const _getHtml = async (state: SerializedEditorState) =>
		getHtml(state, editor);

	const _getText = async (state: SerializedEditorState) =>
		getText(state, editor);

	return {
		editor,
		getHtml: _getHtml,
		getText: _getText,
	};
};
