import { CSSObject } from "@emotion/react";
import { EditProfileFormProps } from "./EditProfileForm";
import { useDesignTokens } from "@bilar/ui";

type EditProfileFormStyles = {
	root: CSSObject;
	content: CSSObject;
	infoSection: CSSObject;
	formSection: CSSObject;
	profileImage: CSSObject;
};

const useEditProfileFormStyles = (
	props: EditProfileFormProps,
): EditProfileFormStyles => {
	const designTokens = useDesignTokens();
	const { borders, space, mediaQueries, palette } = designTokens;
	return {
		root: {},
		content: {
			[mediaQueries.tabletAndAbove]: {
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
			},
		},
		infoSection: {
			[mediaQueries.tabletAndAbove]: {
				flexBasis: "33%",
			},
		},
		formSection: {
			[mediaQueries.tabletAndAbove]: {
				flexBasis: "55%",
			},
		},
		profileImage: {
			borderRadius: "50%",
			border: `2px solid ${palette.neutral.light.background} !important`,
			maxHeight: space[24],
			margin: "0 auto",
			marginTop: space[4],
			marginBottom: space[4],
			display: "block",
			[mediaQueries.tabletAndAbove]: {
				display: "inline-block",
			},
		},
	};
};

export { useEditProfileFormStyles };
