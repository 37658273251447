/** @jsxImportSource @emotion/react */
import { useColourSelectorStyles } from "./colourSelectorStyles";
import { colours, useAppTranslation } from "@bilar/common";
import { VehicleColour } from "@bilar/models";
import { FieldLabel, ValidationMessage } from "@bilar/ui";
import { useState } from "react";
import { capitalizeFirstLetter } from "@bilar/utils";

type ColourSelectorProps = {
	onChange: (selectedColour: VehicleColour) => void;
	value: VehicleColour | undefined;
	errors: string | undefined;
} & ColourSelectorDefaultProps;

type ColourSelectorDefaultProps = {};

const defaultProps: ColourSelectorDefaultProps = {};

const ColourSelector = (props: ColourSelectorProps) => {
	const { t } = useAppTranslation();
	const styles = useColourSelectorStyles(props);
	const [selectedColour, setSelectedColour] = useState<
		VehicleColour | undefined
	>(props.value);
	const { onChange } = props;

	const handleChange = (selColour: VehicleColour) => {
		setSelectedColour(selColour);
		onChange(selColour);
	};

	return (
		<div css={styles.root}>
			<FieldLabel css={styles.label} htmlFor={undefined}>
				{t("colour")}
			</FieldLabel>
			<div css={styles.colorSelectionWrapper}>
				{colours.map((colour) => (
					<div key={colour.name} css={styles.buttonWrapper}>
						<button
							type="button"
							data-colour={colour.name}
							data-selected={colour.name === selectedColour?.name}
							css={[styles.button, { backgroundColor: colour.hexValue }]}
							onClick={() => handleChange(colour)}
						>
							<span css={styles.buttonInnerLabel}>{colour.name}</span>
						</button>
						<label
							css={styles.buttonLabel}
							data-selected={colour.name === selectedColour?.name}
						>
							{t(`colour${capitalizeFirstLetter(colour.name)}`)}
						</label>
					</div>
				))}
			</div>
			<ValidationMessage layoutMode="regular" error={props.errors} />
		</div>
	);
};

ColourSelector.defaultProps = defaultProps;

export { ColourSelector, colours };
export type { ColourSelectorProps };
