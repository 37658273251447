import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect } from "react";
import { LexicalEditor } from "lexical/LexicalEditor";

type EditorInstancePluginProps = {
	onChange: (value: LexicalEditor) => void;
};

export const EditorInstancePlugin = (props: EditorInstancePluginProps) => {
	const { onChange } = props;
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		onChange(editor);
	}, [editor, onChange]);

	return null;
};
