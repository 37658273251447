import { CSSObject } from "@emotion/react";
import { useBorderTokens, useDesignTokens } from "@bilar/ui";

type UseEditorStyles = {
	input: CSSObject;
	toolbar: CSSObject;
	inputWrapper: CSSObject;
	richText: CSSObject;
};

export const useEditorStyles = (): UseEditorStyles => {
	const designTokens = useDesignTokens();
	const { borderShadow } = useBorderTokens();
	const { fonts, space, radii, palette, fontSizes, zIndices } = designTokens;

	return {
		inputWrapper: {
			position: "relative",
		},
		input: {
			fontFamily: fonts.body,
			WebkitAppearance: "none",
			borderRadius: radii.md,
			borderWidth: "2px",
			borderStyle: "solid",
			borderColor: palette.neutral.light.background,
			paddingLeft: space[2],
			paddingRight: space[2],
			transitionProperty: "all",
			transitionDuration: "500ms",
			transitionTimingFunction: "ease-out",
			minHeight: "100px",
			":focus, :focus-visible": {
				boxShadow: borderShadow(palette.neutral.darker.background, 4, 0.15),
				outlineColor: palette.neutral.main.background,
				borderColor: palette.neutral.main.background,
			},
		},
		toolbar: {
			overflowX: "auto",
		},
		richText: {
			"ul, ol": {
				paddingLeft: space[4],
			},
			ul: {
				listStyleType: "disc",
			},
			ol: {
				listStyleType: "decimal",
			},
			h1: {
				fontSize: fontSizes["2xl"],
			},
			h2: {
				fontSize: fontSizes.xl,
			},
			h3: {
				fontSize: fontSizes.lg,
			},
			h4: {
				fontSize: fontSizes.md,
			},
			h5: {
				fontSize: fontSizes.sm,
			},
			h6: {
				fontSize: fontSizes.xs,
			},
		},
	};
};
