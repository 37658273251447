import { DropdownOption, useCurrency } from "@bilar/ui";

const initialStep = 100000;

export const useGetPrices = () => {
	const { formatCurrency } = useCurrency();
	const getPrices = (minValue: number) => {
		const items: DropdownOption[] = [];

		// console.log("getPrices minValue", minValue);

		// First 2M
		for (let i = 0; i < 2000000; i += initialStep) {
			const item: DropdownOption = {
				value: i.toString(),
				label: formatCurrency(i),
			};

			items.push(item);
		}

		for (let i = 2000000; i < 6000000; i += 500000) {
			const item: DropdownOption = {
				value: i.toString(),
				label: formatCurrency(i),
			};

			items.push(item);
		}

		for (let i = 6000000; i < 21000000; i += 1000000) {
			const item: DropdownOption = {
				value: i.toString(),
				label: formatCurrency(i),
			};

			items.push(item);
		}

		// Remove items below minValue
		return items.filter((i) => parseInt(i.value) > minValue);
	};

	return {
		getPrices,
	};
};
