import { VehicleColour } from "@bilar/models";

export const colours: VehicleColour[] = [
	{ name: "black", hexValue: "#000" },
	{ name: "blue", hexValue: "#0014E1" },
	{ name: "brown", hexValue: "#80601A" },
	{ name: "cream", hexValue: "#DFD2A3" },
	{ name: "green", hexValue: "#007C05" },
	{ name: "gray", hexValue: "#C9C9C9" },
	{ name: "orange", hexValue: "#fd5f08" },
	{ name: "red", hexValue: "#f50000" },
	{ name: "silver", hexValue: "#E8E8E8" },
	{ name: "white", hexValue: "#fff" },
	{ name: "other", hexValue: "#fff" },
];
