import { DecodedValueMap, objectToSearchString } from "use-query-params";
import {
	defaultSearchState,
	SearchState,
	VehicleSearchQueryParamConfig,
} from "@bilar/features";

export const getSearchPathWithParams = (
	searchRoutePath: string,
	values: SearchState,
) => {
	const queryParamsToUse: Partial<
		DecodedValueMap<VehicleSearchQueryParamConfig>
	> = mapSearchStateToQueryParams(values);

	// const encodedQuery = encodeQueryParams(
	// 	{
	// 		brand: StringParam,
	// 		model: StringParam,
	// 		priceFrom: NumberParam,
	// 		priceTo: NumberParam,
	// 	},
	// 	values,
	// );

	return `${searchRoutePath}/?${objectToSearchString(queryParamsToUse)}`;
};

export const isSearchEmpty = (state: SearchState): boolean => {
	return Object.values(state).every(
		(value) => value === null || value === undefined,
	);
};

type PartialSearchState = Partial<SearchState>;

export const mapSearchStateToQueryParams = (
	searchState: PartialSearchState,
): Partial<DecodedValueMap<VehicleSearchQueryParamConfig>> => {
	const queryParams: Partial<DecodedValueMap<VehicleSearchQueryParamConfig>> =
		{};

	for (const key in searchState) {
		if (
			searchState[key as keyof PartialSearchState] !== null &&
			searchState[key as keyof PartialSearchState] !== undefined
		) {
			queryParams[key as keyof VehicleSearchQueryParamConfig] = searchState[
				key as keyof PartialSearchState
			] as any; // Typecast to 'any' to satisfy TypeScript
		}
	}

	return queryParams;
};

export const mapQueryParamsToSearchState = (
	queryParams: DecodedValueMap<VehicleSearchQueryParamConfig>,
): SearchState => {
	return {
		brand: queryParams.brand || defaultSearchState.brand,
		model: queryParams.model || defaultSearchState.model,
		priceFrom: queryParams.priceFrom || defaultSearchState.priceFrom,
		priceTo: queryParams.priceTo || defaultSearchState.priceTo,
		vehicleBodyTypes:
			queryParams.vehicleBodyTypes || defaultSearchState.vehicleBodyTypes,
		fuelTypes: queryParams.fuelTypes || defaultSearchState.fuelTypes,
		numberOfDoors:
			queryParams.numberOfDoors || defaultSearchState.numberOfDoors,
		maxAge: queryParams.maxAge || defaultSearchState.maxAge,
	};
};
