import { Borders, BorderTokens } from "./types";
import colors from "./colors";
import palette from "./palette";

const borders: Borders = {
	none: 0,
	"1px": "1px solid",
	"2px": "2px solid",
	"4px": "4px solid",
	color: {
		darker: colors.gray[800],
		dark: colors.gray[600],
		main: colors.gray[400],
		light: colors.gray[200],
		lighter: colors.gray[50],
	},
};

// NOTE: Sorry Miguel for the "new" :)
// TODO: We will rename this to borders once we get rid of all the old borders object.
const bordersNew: BorderTokens = {
	outlineOpacity: 0.2,
	animation: {
		easing: "ease-in-out",
		duration: 250,
	},
	focus: {
		borderColor: palette.secondary.light.background,
		outlineColor: palette.secondary.light.background,
		outlineWidth: 4,
	},
	hover: {
		borderColor: palette.neutral.main.background,
		outlineColor: palette.neutral.main.background,
		outlineWidth: 4,
	},
	radius: {
		none: 0,
		small: 1,
		regular: 4,
		large: 8,
		round: 320,
	},
	color: {
		light: "#f4f4f4", // TODO: TBD
		regular: "#eee", // TODO: TBD
		dark: "#ccc", // TODO: TBD
	},
	width: {
		small: "1px",
		medium: "2px",
		large: "4px",
	},
};

// const borderWidths = {
//   xl: "2rem",
//   lg: "1rem",
//   md: "0.5rem",
//   sm: "0.25rem",
//   xs: "0.125rem",
//   "2xs": "0.0625rem",
//   none: 0
// };

export default borders;
export { bordersNew };
