import { CSSObject } from "@emotion/react";
import { DesignOptionsProps } from "./DesignOptions";
import { useDesignTokens } from "@bilar/ui";

type DesignOptionsStyles = {
	root: CSSObject;
	list: CSSObject;
};

const useDesignOptionsStyles = (
	props: DesignOptionsProps,
): DesignOptionsStyles => {
	const designTokens = useDesignTokens();
	const { space, size, mediaQueries } = designTokens;
	return {
		root: {},
		list: {
			display: "flex",
			width: "100%",
			overflowX: "auto",
			height: size(32),
			gap: space[4],
			marginBottom: space[4],

			[mediaQueries.tabletAndAbove]: {
				display: "grid",
				height: "auto",
				gridTemplateColumns: "1fr 1fr 1fr",
			},

			[mediaQueries.desktopAndAbove]: {
				gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
			},
		},
	};
};

export { useDesignOptionsStyles };
