import { CSSObject } from "@emotion/react";
import { ProgressBarProps, useDesignTokens } from "@bilar/ui";

type ProgressBarStyles = {
	root: CSSObject;
	bar: CSSObject;
};

const useProgressBarStyles = (props: ProgressBarProps): ProgressBarStyles => {
	const designTokens = useDesignTokens();
	const { palette, space } = designTokens;
	return {
		root: {
			height: space[8],
			backgroundColor: palette.neutral.lighter.background,
			margin: `0 ${space[8]}`,
			width: `calc(100% - ${space[8]})`,
		},
		bar: {
			height: "100%",
			backgroundColor: palette.success.main.background,
		},
	};
};

export { useProgressBarStyles };
