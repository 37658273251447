import { useContainerStyles } from "./containerStyles";
import { ReactNode } from "react";

type ContainerProps = {
	children?: ReactNode;
} & ContainerDefaultProps;

type ContainerDefaultProps = {};

const defaultProps: ContainerDefaultProps = {};

const Container = (props: ContainerProps) => {
	const styles = useContainerStyles(props);

	return (
		<div css={styles.root} {...props}>
			{props.children}
		</div>
	);
};

Container.defaultProps = defaultProps;

export { Container };
export type { ContainerProps };
