/** @jsxImportSource @emotion/react */
import {
	MenuItem as ReachMenuItem,
	MenuItemProps as ReachMenuItemProps,
} from "@reach/menu-button";
import { useMenuItemStyles } from "./menuStyles";
import { BoxBaseProps, useBoxStyles } from "../layout/Box/boxStyles";
import { BaseProps } from "..";
import { HtmlTag } from "../../types";

type MenuItemTextProps = {} & MenuItemTextDefaultProps &
	ReachMenuItemProps &
	BoxBaseProps &
	BaseProps;

type MenuItemTextDefaultProps = {
	textAs: HtmlTag;
	//size: TextSize;
};

const defaultProps: MenuItemTextDefaultProps = {
	textAs: "span",
	//size: "regular",
};

/**
 * The `<MenuItemText>` is used for text in menus.
 */
const MenuItemText = (props: MenuItemTextProps) => {
	const styles = useMenuItemStyles();
	const boxStyles = useBoxStyles(props);
	const { textAs, ...filteredProps } = props;

	return (
		<ReachMenuItem css={[styles, boxStyles]} {...filteredProps}>
			{/*<Text as={textAs} size={size}>*/}
			<span>{props.children}</span>
		</ReachMenuItem>
	);
};

MenuItemText.defaultProps = defaultProps;

export { MenuItemText };
export type { MenuItemTextProps as MenuItemProps };
