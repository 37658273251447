import { usePanelBodyStyles, usePanelStyles } from "./panelStyles";
import { ReactNode } from "react";
import { BoxBaseProps, Heading, Paper } from "@bilar/ui";
import { HtmlTag } from "../../types";
import { Elevation } from "../Paper/Paper";

type PanelProps = {
	children: ReactNode;
	title?: string;
	loading?: boolean;
	headerChildren?: ReactNode;
} & BoxBaseProps &
	PanelDefaultProps;

type PanelDefaultProps = {
	as: HtmlTag;
	elevation: Elevation;
};

const defaultProps: PanelDefaultProps = {
	as: "article",
	elevation: "small",
};

const Panel = (props: PanelProps) => {
	const styles = usePanelStyles(props);
	const { as, elevation, loading, title, headerChildren, ...rest } = props;

	return (
		<Paper as={as} css={styles.root} elevation={elevation} {...rest}>
			{props.title && (
				<PanelHeader title={title}>
					{props.headerChildren && (
						<div css={styles.headerChildren}>{props.headerChildren}</div>
					)}
				</PanelHeader>
			)}
			{props.children}
		</Paper>
	);
};

Panel.defaultProps = defaultProps;

type PanelBodyProps = {
	children: ReactNode;
	py?: number;
	pt?: number;
	pb?: number;
};
const PanelBody = (props: PanelBodyProps) => {
	const styles = usePanelBodyStyles(props);
	return (
		<section css={styles} {...props}>
			{props.children}
		</section>
	);
};

type PanelHeaderProps = {
	title?: string;
	children?: ReactNode;
};
const PanelHeader = (props: PanelHeaderProps) => {
	const styles = usePanelStyles(props);
	return (
		<header css={styles.header} {...props}>
			<Heading as="h2" size="small" shade="dark" mb={0}>
				{props.title}
			</Heading>
			{props.children}
		</header>
	);
};

type PanelFooterProps = {
	children: ReactNode;
};
const PanelFooter = (props: PanelFooterProps) => {
	const styles = usePanelStyles(props);
	return (
		<footer css={styles.footer} {...props}>
			{props.children}
		</footer>
	);
};

Panel.Body = PanelBody;
Panel.Header = PanelHeader;
Panel.Footer = PanelFooter;

export { Panel };
export type { PanelProps, PanelBodyProps, PanelHeaderProps, PanelFooterProps };
