import React from "react";
import { Stack, useDesignTokens } from "@bilar/ui";
import { BaseLoadingProps } from "./index";
import { CircleSpinner } from "./CircleSpinner";
import { ThreeDotsWave } from "./ThreeDotsWave";
import { CSSObject } from "@emotion/react";

type LoadingProps = {
	centered?: boolean;
	text?: string;
	variant?: "spinner" | "dots";
} & BaseLoadingProps;

type LoadingStyles = {
	text: CSSObject;
};
const useLoadingStyles = (props: LoadingProps): LoadingStyles => {
	const { fontSizes, fontWeights, space } = useDesignTokens();

	return {
		text: {
			margin: `${space[4]} 0`,
			fontSize: fontSizes.md,
			fontWeight: fontWeights.medium,
		},
	};
};

const Loading = (props: LoadingProps) => {
	const { centered, text, size = 8, variant = "spinner" } = props;

	const styles = useLoadingStyles(props);

	const renderSpinner = () => {
		switch (variant) {
			case "spinner":
				return <CircleSpinner size={size} />;
			case "dots":
				return <ThreeDotsWave />;
			default:
				return <CircleSpinner size={size} />;
		}
	};

	if (centered) {
		return (
			<Stack horizontalAlignment="center" verticalAlignment="center">
				{renderSpinner()}
				{text && <p css={styles.text}>{text}</p>}
			</Stack>
		);
	}

	return <>{renderSpinner()}</>;
};

export { Loading };
