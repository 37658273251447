import { CSSObject } from "@emotion/react";
import { useDesignTokens } from "../../styling";

// We need to set global styles on :root so we won't get warnings from @reachui
const baseMenuStyles = (): CSSObject => {
	return {
		":root": {
			"--reach-menu-button": "1",
		},
	};
};

type MenuListStyleProps = {
	itemBackgroundColor?: string;
	itemColor?: string;
	itemHoverBackgroundColor?: string;
	itemHoverColor?: string;
};

const useMenuListStyles = (props: MenuListStyleProps = {}): CSSObject => {
	const { borders, palette, radii, space, zIndices } = useDesignTokens();

	return {
		"[data-reach-menu-item][data-selected], [data-reach-menu-item][data-selected]:hover":
			{
				backgroundColor:
					props.itemHoverBackgroundColor || palette.neutral.lighter.background,
				color: props.itemHoverColor || palette.black,
			},
		outline: "none",
		borderStyle: "solid",
		position: "relative",
		zIndex: zIndices.popover,
		backgroundColor: props.itemBackgroundColor || palette.white,
		color: props.itemColor || palette.black,
		borderColor: borders.color.dark,
		borderRadius: radii.md,
		borderWidth: borders["1px"],
		marginTop: space[1],
	};
};

const useMenuItemStyles = (): CSSObject => {
	const { space } = useDesignTokens();

	return {
		display: "block",
		":hover": {
			cursor: "pointer",
		},
		border: "none",
		padding: `${space[2]} ${space[2]}`,
		outline: "none",
	};
};

const useMenuLinkStyles = (): CSSObject => {
	const { palette } = useDesignTokens();
	const itemStyles = useMenuItemStyles();

	return {
		...itemStyles,
		":hover": {
			textDecoration: "none",
		},
		textDecoration: "none",
		display: "block",
		borderRadius: 0,
		color: palette.black,
	};
};

export {
	baseMenuStyles,
	useMenuListStyles,
	useMenuItemStyles,
	useMenuLinkStyles,
};
export type { MenuListStyleProps };
