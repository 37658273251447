import { DropdownOption } from "@bilar/ui";

export const filterByLabel = (option: DropdownOption, inputValue: string) => {
	// Return true if no input value is provided (i.e., do not filter out any options)
	if (!inputValue) {
		return true;
	}

	// Check if the option's label includes the input value (case-insensitive)
	return option.label.toLowerCase().includes(inputValue.toLowerCase());
};

export const prioritizeExactMatch = (
	options: DropdownOption[],
	inputValue: string,
) => {
	if (!inputValue) return options; // Return all options if no input is provided

	const inputLower = inputValue.toLowerCase();

	return options.sort((a, b) => {
		const labelALower = a.label.toLowerCase();
		const labelBLower = b.label.toLowerCase();

		const isAExactMatch = labelALower === inputLower;
		const isBExactMatch = labelBLower === inputLower;

		if (isAExactMatch && !isBExactMatch) {
			return -1; // A is an exact match, prioritize it
		}
		if (!isAExactMatch && isBExactMatch) {
			return 1; // B is an exact match, prioritize it
		}
		return 0; // No prioritization needed if both are exact matches or neither are
	});
};
