/** @jsxImportSource @emotion/react */
import { motion } from "framer-motion";
import { useVehicleSearchStyles } from "./vehicleSearchStyles";
import {
	AdvancedSearchOptions,
	BasicSearchOptions,
	emptySearchState,
	searchVariants,
	useSearch,
} from "@bilar/features";
import React, { FormEvent, useState } from "react";
import { useAppTranslation } from "@bilar/common";
import { Button, FaIcon, InvisibleButton, Paper } from "@bilar/ui";
import { useRouter } from "next/router";
import { faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import { useScrollLock } from "usehooks-ts";

type VehicleSearchProps = {};

const VehicleSearch = (props: VehicleSearchProps) => {
	const styles = useVehicleSearchStyles(props);
	const { t } = useAppTranslation("common");
	const { searchUrl, setSearchState, setSearchOpen, isOnSearchPage } =
		useSearch();

	const { push } = useRouter();
	const [selectedTab, setSelectedTab] = useState<"simple" | "advanced">(
		"simple",
	);

	// Locking body scroll on all devices
	useScrollLock();

	// TODO: Doesn't work fully, problem lies elsewhere
	// for example when the search state is cleared, brand etc becomes "null", but BasicSearchOptions doesn't pick up on that
	const handleClearSearch = () => {
		setSearchState(emptySearchState);
	};
	const handleSearch = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (isOnSearchPage) {
			handleClose();
		} else if (searchUrl) {
			// console.log("searchUrl: ", searchUrl);

			push(searchUrl);
			handleClose();
		}
	};

	const handleClose = () => {
		setSearchOpen(false);
	};

	return (
		<motion.div key="searchForm">
			<motion.div
				layoutId="searchWrapper"
				variants={searchVariants}
				initial="initial"
				animate="animate"
				exit="exit"
				css={styles.root}
			>
				<div css={styles.innerWrapper}>
					<div css={styles.header}>
						<div css={styles.tabs}>
							<button
								css={[
									styles.tabItem,
									selectedTab === "simple" && styles.tabItemActive,
								]}
								onClick={() => setSelectedTab("simple")}
							>
								{t("simpleSearch")}
							</button>
							<button
								css={[
									styles.tabItem,
									selectedTab === "advanced" && styles.tabItemActive,
								]}
								onClick={() => setSelectedTab("advanced")}
							>
								{t("advancedSearch")}
							</button>
						</div>
						<button
							css={[styles.closeButton]}
							className="close-button"
							onClick={handleClose}
						>
							<span>{t("close")}</span>
							<FaIcon
								aria-hidden={true}
								icon={faCircleXmark}
								// css={[styles.closeIcon]}
								size={6}
								ml={2}
								verticalAlign={"middle"}
							/>
						</button>
					</div>
					<form onSubmit={handleSearch} css={styles.form}>
						<Paper css={styles.content}>
							{selectedTab === "simple" ? (
								<BasicSearchOptions />
							) : (
								<AdvancedSearchOptions />
							)}
						</Paper>
						<footer css={styles.footer}>
							<Button
								palette="success"
								type="submit"
								css={styles.searchButton}
								disabled={!searchUrl}
							>
								{t("performSearch")}
							</Button>

							<InvisibleButton
								css={styles.clearButton}
								onClick={handleClearSearch}
							>
								{t("clearSearch")}
							</InvisibleButton>
						</footer>
					</form>
				</div>
			</motion.div>
		</motion.div>
	);
};

export { VehicleSearch };
export type { VehicleSearchProps };
