import {
	createContext,
	Dispatch,
	ReactNode,
	useContext,
	useState,
} from "react";

type SignInModalContext = {
	showLoginModal: boolean;
	setShowLoginModal: Dispatch<boolean>;
};

const SignInModalContext = createContext<SignInModalContext>({
	showLoginModal: false,
	setShowLoginModal(show): void {},
});

const useSignInModalContext = () => {
	return useContext(SignInModalContext);
};

type SignInModalProviderProps = {
	children: ReactNode;
};

const SignInModalProvider = (props: SignInModalProviderProps) => {
	const [showModal, setShowModal] = useState(false);
	return (
		<SignInModalContext.Provider
			value={{
				showLoginModal: showModal,
				setShowLoginModal: setShowModal,
			}}
		>
			{props.children}
		</SignInModalContext.Provider>
	);
};

export { SignInModalProvider, useSignInModalContext };
