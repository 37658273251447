import { CSSObject } from "@emotion/react";
import {
	DesignTokens,
	useBorderTokens,
	useDesignTokens,
} from "../../../styling";
import { CheckboxProps, CheckboxSize, LabelPosition } from "./Checkbox";

type CheckboxStyles = {
	root: CSSObject; // the label tag
	checkWrapper: CSSObject;
	checkBackground: CSSObject;
	checkboxInput: CSSObject; // the input itself which we will hide
	text: CSSObject; // can you guess? :)
};

const getCheckboxSize = (size: CheckboxSize): number => {
	switch (size) {
		case "regular":
			return 32;
		case "large":
			return 40;
	}

	return 24;
};

const getLayoutStyles = (
	props: CheckboxProps,
	tokens: DesignTokens,
): CSSObject => {
	const { space, palette, radii } = tokens;
	const { checked } = props;
	if (props.layout === "regular") return {};

	return {
		display: "flex",
		width: "auto",
		padding: `${space[2]} ${space[4]}`,
		minWidth: space[36],
		minHeight: space[12],
		borderRadius: radii.md,
		outline: "2px solid transparent",
		backgroundColor: checked
			? palette.neutral.light.background
			: palette.neutral.lighter.background,

		"&:hover": {
			backgroundColor: palette.neutral.light.background,
		},

		"&:focus-within": {
			outlineColor: palette.neutral.main.background,
		},
	};
};

const getLabelPropsStyles = (props: CheckboxProps): CSSObject => {
	if (!props.disabled) return {};

	return {
		cursor: "not-allowed",
	};
};

const getTextAlignmentStyles = (
	labelAlignment: LabelPosition,
	tokens: DesignTokens,
): CSSObject => {
	// if (labelAlignment === "left")
	// 	return {
	// 		paddingRight: tokens.size(2),
	// 	};

	return {
		paddingLeft: tokens.space[2],
	};
};

const useCheckboxStyles = (
	props: CheckboxProps,
	focused: boolean,
): CheckboxStyles => {
	const tokens = useDesignTokens();
	const { borderShadow } = useBorderTokens();
	const { palette, bordersNew, space } = tokens;
	const checkboxSize = getCheckboxSize(props.size!);
	let wrapperOrder = 1;
	let textOrder = 2;

	const checkboxTokens = {
		checkbox: {
			borderWidth: 2, // TODO: correctly calculate the checkmark with different widths, it only works with 2
			borderStyle: "solid",
			borderRadius: "small",
			borderColor: bordersNew.color.dark,
			checkedColor: palette.success.main.background,
			uncheckedColor: palette.white,
			focusedColor: palette.secondary.main.background,
			checkColor: palette.white,
			focus: {
				...bordersNew.hover,
				borderColor: "transparent",
				outlineOpacity: 0.5,
			},
			hover: {
				...bordersNew.hover,
				borderColor: "transparent",
			},
		},
	};

	const {
		checkedColor,
		borderRadius,
		borderWidth,
		borderColor,
		borderStyle,
		focusedColor,
		hover,
		focus,
	} = checkboxTokens.checkbox;

	let finalBorderColor = borderColor;
	if (focused) {
		finalBorderColor = focus.borderColor ?? focusedColor;
	}
	if (focused && props.checked) {
		finalBorderColor = tokens.palette.white;
	}
	if (props.checked && !focused) {
		finalBorderColor = checkedColor;
	}

	return {
		root: {
			cursor: "pointer",
			display: "inline-flex",
			alignItems: "center",
			verticalAlign: "middle",
			transform: "translateZ(0)", // Force 3d rendering
			":hover": {
				// We do this here so that we can target the checkbox while hovering over the label
				// Note: className="check-wrapper" needs to be in markup for this to work.
				".check-wrapper": {
					borderColor:
						!focused && !props.disabled ? hover.borderColor : undefined,
					borderWidth:
						!focused && !props.disabled ? hover.borderWidth : undefined,
					boxShadow:
						!focused && !props.disabled && hover.outlineColor
							? borderShadow(hover.outlineColor, hover.outlineWidth)
							: undefined,
				},
			},
			...getLayoutStyles(props, tokens),
			...getLabelPropsStyles(props),
		},
		checkWrapper: {
			transition: "all 0.2s ease-in-out",
			position: "relative",
			flex: "0 0 auto",
			width: checkboxSize,
			height: checkboxSize,
			padding: 0,
			overflow: "hidden",
			order: wrapperOrder,
			borderWidth,
			borderColor: finalBorderColor,
			borderStyle,
			borderRadius: space[2],
			boxShadow:
				focused && focus.outlineColor
					? borderShadow(focus.outlineColor, focus.outlineWidth)
					: undefined,
		},
		checkBackground: {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			zIndex: tokens.zIndices.hide,
		},

		checkboxInput: {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			cursor: "inherit",
			margin: 0,
			padding: 0,
			opacity: 0,
			zIndex: 1,
		},
		text: {
			order: textOrder,
			...getTextAlignmentStyles(props.labelPosition!, tokens),
			opacity: props.disabled ? 0.5 : 1,
		},
	};
};

export { useCheckboxStyles, getCheckboxSize };
export type { CheckboxStyles };
