/** @jsxImportSource @emotion/react */
import React from "react";
import { Box, Dialog } from "@bilar/ui";
import { SignIn, useSignInModalContext } from "@bilar/features";

type SignInModalProps = {
	onDismiss: (event?: React.SyntheticEvent) => void;
} & SignInModalDefaultProps;

type SignInModalDefaultProps = {};

const defaultProps: SignInModalDefaultProps = {};

const SignInModal = (props: SignInModalProps) => {
	const { onDismiss } = props;
	const { showLoginModal, setShowLoginModal } = useSignInModalContext();

	const handleSignInSuccess = () => {
		setShowLoginModal(false);
	};

	return (
		<Dialog isOpen={showLoginModal} onDismiss={onDismiss}>
			<Box p={4}>
				<SignIn onSignInSuccess={handleSignInSuccess} />
			</Box>
		</Dialog>
	);
};

SignInModal.defaultProps = defaultProps;

export { SignInModal };
export type { SignInModalProps };
