import { useEffect, useState } from "react";
import * as yup from "yup";
import { AnyObject, Maybe } from "yup";
import {
	createInitialErrors,
	createInitialTouched,
	Errors,
	FormFieldAndValueTuple,
	Touched,
} from "../index";
import {
	CheckboxProps,
	CurrencyFieldProps as CurrencyFieldComponentProps,
	PhoneFieldProps as PhoneFieldComponentProps,
	SelectProps,
	TextFieldBaseProps,
	TextFieldProps as TextFieldComponentProps,
} from "@bilar/ui";

export type ValidateYup<T extends Maybe<AnyObject>> = (
	schema: yup.ObjectSchema<T>,
	values: T,
) => Errors<T>;

type UseFormWithYupOptions<T extends Maybe<AnyObject>> = {
	initialValues: T;
	validate?: (formValues: T) => Errors<T>;
	validationSchema?:
		| yup.ObjectSchema<T>
		| ((formValues?: T) => yup.ObjectSchema<T>);
	initialErrors?: Errors<T>;
	initialTouched?: Touched<T>;
};

type OnChangeHandler<T> = {
	(fieldName: keyof T, fieldValue: any): void;
	(fields: FormFieldAndValueTuple<T>[], fieldValue: never): void;
	(
		fieldNameOrArray: keyof T | FormFieldAndValueTuple<T>[],
		fieldValue?: any,
	): void;
};

type OnBlurHandler<T> = (fieldName: keyof T) => void;

type TextFieldProps<T> = <K extends keyof T>(
	fieldName: K,
) => {
	value: T[K];
	error: Errors<T>[K];
	onBlur: TextFieldComponentProps["onBlur"];
	onChange: TextFieldComponentProps["onChange"];
};

type DropDownFieldProps<T> = <K extends keyof T>(
	fieldName: K,
) => {
	value: T[K];
	error: Errors<T>[K];
	onBlur: SelectProps<false>["onBlur"];
	onChange: SelectProps<false>["onChange"];
};

type CurrencyFieldProps<T> = <K extends keyof T>(
	fieldName: K,
) => {
	value: T[K];
	error: Errors<T>[K];
	onBlur: CurrencyFieldComponentProps["onBlur"];
	onChange: CurrencyFieldComponentProps["onChange"];
};

type PhoneFieldProps<T> = <K extends keyof T>(
	fieldName: K,
) => {
	value: T[K];
	error: Errors<T>[K];
	onBlur: TextFieldBaseProps<T[K]>["onBlur"];
	onChange: PhoneFieldComponentProps["onChange"];
};

type BooleanFieldProps<T> = <K extends keyof T>(
	fieldName: K,
) => {
	checked: T[K];
	error: Errors<T>[K];
	onChange: CheckboxProps["onChange"];
};

export type UseFormReturn<T extends Maybe<AnyObject>> = {
	values: T;
	errors: Errors<T>;
	touched: Touched<T>;
	handleChange: OnChangeHandler<T>;
	handleBlur: OnBlurHandler<T>;
	handleSubmit: (
		onSubmit: (formValues: T) => void,
		onValidationError: (errors: Errors<T>, formValues: T) => void,
	) => void;
	resetForm: () => void;
	textFieldProps: TextFieldProps<T>;
	currencyFieldProps: CurrencyFieldProps<T>;
	phoneFieldProps: PhoneFieldProps<T>;
	booleanFieldProps: BooleanFieldProps<T>;
	dropDownFieldProps: DropDownFieldProps<T>;
	validateYup: ValidateYup<T>;
};

/*
	why we do not use useReducer for values because
		1) we need to add immer to make changes to state
		2) to validate we need to run reducer function to get the latest state
*/
export const useFormWithYup = <T extends object>(
	options: UseFormWithYupOptions<T>,
): UseFormReturn<T> => {
	const {
		initialValues,
		validate,
		validationSchema,
		initialErrors,
		initialTouched,
	} = options;

	const getInitialErrors = (newValues: T, newErrors?: Errors<T>) =>
		newErrors !== undefined ? newErrors : createInitialErrors<T>(newValues);
	const getInitialTouched = (newValues: T, newTouched?: Touched<T>) =>
		newTouched !== undefined
			? newTouched
			: createInitialTouched(newValues, false);

	const [values, setValues] = useState(initialValues);
	const [errors, setErrors] = useState<Errors<T>>(
		getInitialErrors(initialValues, initialErrors),
	);
	const [touched, setTouched] = useState<Touched<T>>(
		getInitialTouched(initialValues, initialTouched),
	);
	const [, setIsSubmitting] = useState(false);

	const filterErrorsByTouchedFields = (
		errors: Errors<T>,
		touched: Touched<T>,
	) => {
		return Object.entries(errors || {}).reduce((acc, [key, value]) => {
			//@ts-ignore
			if (touched[key]) {
				acc[key] = value as string;
			}
			return acc;
		}, {} as Errors<any>);
	};

	// We use this to carry the actual touched values later in the logic,
	// because "touched" from the state gets stale is some cases
	let internalTouched = touched;

	const handleBlur: OnBlurHandler<T> = (fieldName) => {
		const value = values[fieldName];
		const valuesToValidate = { ...values };

		if (typeof value === "string") {
			// @ts-ignore
			valuesToValidate[fieldName] = value.trim();
			setValues(valuesToValidate);
		}
		internalTouched = {
			...internalTouched,
			[fieldName]: true,
		};
		setTouched(internalTouched);
		setErrors(
			filterErrorsByTouchedFields(
				internalValidate(valuesToValidate),
				internalTouched,
			),
		);
	};

	const handleChange: OnChangeHandler<T> = (fieldNameOrArray, fieldValue) => {
		if (Array.isArray(fieldNameOrArray)) {
			_handleChanges(fieldNameOrArray);
		} else {
			_handleChange(fieldNameOrArray, fieldValue);
		}
	};

	const _handleChange = <K extends keyof T>(fieldName: K, value: any) => {
		const newValues = {
			...values,
			[fieldName]: value,
		};
		internalTouched = {
			...internalTouched,
			[fieldName]: true,
		};
		setValues(newValues);
		setTouched(internalTouched);
		setErrors(
			filterErrorsByTouchedFields(internalValidate(newValues), internalTouched),
		);
	};

	const _handleChanges = (fields: FormFieldAndValueTuple<T>[]) => {
		const newValues = {
			...values,
		};

		// Set value for each field into newValues
		for (const field of fields) {
			newValues[field[0]] = field[1];
			internalTouched = {
				...internalTouched,
				[field[0]]: true,
			};
		}

		setTouched(internalTouched);
		setValues(newValues);
		setErrors(
			filterErrorsByTouchedFields(internalValidate(newValues), internalTouched),
		);
	};

	const internalValidate = (formValues: T): Errors<T> => {
		if (validationSchema) {
			// Is the schema a function (used when, for example, max / min values need to be dynamic based on closures)
			const schema =
				typeof validationSchema === "function"
					? validationSchema(formValues)
					: validationSchema;

			return validateYup(schema, formValues);
		}

		if (validate) {
			return validate(formValues);
		} else {
			throw new Error(
				"You have not provided a validation function or validation schema",
			);
		}
	};

	const resetForm = () => {
		setValues(initialValues);
	};

	const textFieldProps: TextFieldProps<T> = (fieldName) => {
		return {
			value: values[fieldName],
			error: errors[fieldName],
			onBlur: () => handleBlur(fieldName),
			onChange: (newValue) => handleChange(fieldName, newValue),
		};
	};

	const currencyFieldProps: CurrencyFieldProps<T> = (fieldName) => {
		return {
			value: values[fieldName],
			error: errors[fieldName],
			onBlur: () => handleBlur(fieldName),
			onChange: (newValue) => handleChange(fieldName, newValue),
		};
	};

	const dropDownFieldProps: DropDownFieldProps<T> = (fieldName) => {
		return {
			value: values[fieldName],
			error: errors[fieldName],
			onBlur: () => handleBlur(fieldName),
			// Since we are using type overloading for ClearableProps, we need to cast the value to "any"
			onChange: (newValue: any) => handleChange(fieldName, newValue),
		};
	};

	const phoneFieldProps: PhoneFieldProps<T> = (fieldName) => {
		return {
			value: values[fieldName],
			error: errors[fieldName],
			onBlur: () => handleBlur(fieldName),
			onChange: (newValue) => {
				const newValues = {
					...values,
					[fieldName]: newValue,
				};
				setValues(newValues);
			},
		};
	};

	const booleanFieldProps: BooleanFieldProps<T> = (fieldName) => ({
		checked: values[fieldName],
		error: errors[fieldName],
		onChange: (newValue: any) => {
			handleBlur(fieldName);
			handleChange(fieldName, newValue);
		},
	});

	const validateYup: ValidateYup<T> = (schema, values) => {
		try {
			schema.validateSync(values, { abortEarly: false });

			return createInitialErrors(initialValues);
		} catch (err) {
			const errorType = err as yup.ValidationError;
			const errors = createInitialErrors(initialValues);

			for (const validationError of errorType.inner) {
				if (validationError.path) {
					(errors as any)[validationError.path] = validationError.message;
				}
			}

			return errors as Errors<T>;
		}
	};

	const handleSubmit = (
		onSubmit: (formValues: T) => void,
		onValidationError: (errors: Errors<T>, formValues: T) => void,
	) => {
		// Touch all fields
		internalTouched = createInitialTouched(initialValues, true);
		setTouched(internalTouched);

		const errors = internalValidate(values);

		setErrors(errors);
		setIsSubmitting(true);

		// Do we have any errors?
		const noErrors = Object.keys(errors).every(
			(errorKey) => (errors as any)[errorKey] === "",
		);

		if (noErrors) {
			// Async support here maybe?
			onSubmit(values);
		} else {
			//onError etc..
			onValidationError(errors, values);
		}

		setIsSubmitting(false);
	};

	useEffect(() => {
		setValues(initialValues);
		setErrors(getInitialErrors(initialValues, initialErrors));
		setTouched(getInitialTouched(initialValues, initialTouched));
	}, [
		// Stringify value on purpose to avoid re-renders issues while comparing instances.
		JSON.stringify(initialValues),
	]);

	return {
		handleChange,
		handleBlur,
		handleSubmit,
		resetForm,
		values,
		errors,
		touched,
		textFieldProps,
		currencyFieldProps,
		booleanFieldProps,
		dropDownFieldProps,
		phoneFieldProps,
		validateYup,
	};
};
