import { CSSObject } from "@emotion/react";
import { VehicleDetailsProps } from "./VehicleDetails";
import { NewVehicleDetailsProps } from "./NewVehicleDetails";
import { useDesignTokens } from "@bilar/ui";
import { colours } from "@bilar/common";

type VehicleDetailsStyles = {
	root: CSSObject;
	detailsList: {
		root: CSSObject;
		data: CSSObject;
		list: CSSObject;
		colour: CSSObject;
	};
	chip: {
		root: CSSObject;
		item: CSSObject;
	};
	grid: CSSObject;
	vehicleInfo: {
		root: CSSObject;
		nameHeading: CSSObject;
		priceHeading: CSSObject;
	};
	gallery: CSSObject;
	buttons: {
		root: CSSObject;
		contactSeller: CSSObject;
		favorite: CSSObject;
	};
	sellerCard: CSSObject;
};

const useAdDetailsStyles = (
	props: VehicleDetailsProps | NewVehicleDetailsProps, // TODO: Remove this when we switch to the new gallery
): VehicleDetailsStyles => {
	const designTokens = useDesignTokens();
	const {
		borders,
		mediaQueries,
		fontWeights,
		fontSizes,
		fontColors,
		palette,
		space,
		size,
		colors,
	} = designTokens;

	const colour =
		colours.find((c) => c.name === props.data.vehicleInfo.colour)?.hexValue ??
		"white";

	return {
		root: {
			[mediaQueries.tabletAndAbove]: {
				// NOTE: Fullscreen images don't work if this is set in mobile, works on tablets though.
				containerType: "inline-size",
				containerName: "vehicle-details",
			},
		},
		grid: {
			display: "grid",
			gap: space[6],
			gridTemplate: `
					"gallery"
					"details"
					"description"
				`,
			"@container vehicle-details (min-width: 768px)": {
				gridTemplate: `
					"gallery details"
					"description description"
				`,
				gridTemplateColumns: `61.538462% calc(38.461538% - ${space[6]})`,
				gridTemplateRows: "auto auto",
			},
		},
		chip: {
			root: {
				display: "flex",
				//display: "grid",
				//gridTemplateColumns: "repeat(3, auto)",
				flexWrap: "wrap",
				gap: "0.5rem",
			},
			item: {
				fontWeight: fontWeights.medium,
				fontSize: fontSizes.sm,
				padding: `${space[1]} ${space[2]}`,
				borderRadius: size(2),
				border: `1px solid ${colors.gray["400"]}`,
				display: "inline-flex",
				alignItems: "center",
			},
		},
		sellerCard: {
			backgroundColor: colors.gray["100"],
			padding: space[3],
			borderRadius: size(2),
			alignItems: "center",
		},
		vehicleInfo: {
			root: {
				[mediaQueries.tabletAndAbove]: {
					order: 20,
					padding: `0 ${space[6]}`,
				},
				"@container vehicle-details (max-width: 768px)": {
					padding: 0,
					marginBottom: space[4],
				},
			},
			nameHeading: {
				color: fontColors.light,
				fontWeight: fontWeights.light,
				fontSize: fontSizes["3xl"],
				marginBottom: space[2],
			},
			priceHeading: {
				color: palette.success.main.background,
				fontWeight: fontWeights.medium,
				fontSize: fontSizes["5xl"],
				margin: 0,
			},
		},
		detailsList: {
			root: {
				display: "grid",
				gridTemplateColumns: "repeat(2, 1fr)",
			},
			colour: {
				":before": {
					content: '""',
					display: "inline-block",
					width: space[5],
					height: space[5],
					borderRadius: 9999,
					backgroundColor: colour,
					border: "1px solid white",
				},
			},
			data: {
				display: "flex",
				alignItems: "center",
				justifyContent: "flex-end",
				padding: `${space[2]} 0`,
				fontWeight: fontWeights.medium,
			},
			list: {
				height: size(10),
				borderBottom: `1px dotted ${borders.color.main}`,
				padding: `${space[2]} 0`,

				"&:first-of-type": {
					borderTop: `1px dotted ${borders.color.main}`,
				},
			},
		},
		gallery: {
			gridArea: "gallery",
			[mediaQueries.tabletAndAbove]: {
				order: 10,
			},
		},
		buttons: {
			root: {
				margin: `0 0 ${space[4]} 0`,
				[mediaQueries.tabletAndAbove]: {
					margin: `0`,
					order: 30,
				},
			},
			contactSeller: {
				display: "inline-flex",
				alignItems: "center",
				justifyContent: "center",
				height: size(12),

				"&:hover": {
					textDecoration: "none",
				},
			},
			favorite: {
				height: size(12),
				backgroundColor: colors.gray["100"],
				color: colors.black["600"],

				"&:hover": {
					backgroundColor: colors.gray["400"],
					color: colors.black["600"],
				},
			},
		},
	};
};

export { useAdDetailsStyles };
