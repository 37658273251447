import { CSSObject } from "@emotion/react";
import { LanguageMenuProps } from "./LanguageMenu";
import { useDesignTokens } from "@bilar/ui";

type LanguageMenuStyles = {
	root: CSSObject;
	button: CSSObject;
	list: CSSObject;
	itemText: CSSObject;
	img: CSSObject;
};

const useLanguageMenuStyles = (
	props: LanguageMenuProps,
): LanguageMenuStyles => {
	const designTokens = useDesignTokens();
	const { radii, space, size, mediaQueries } = designTokens;
	const focusColor = "#fff";
	const heightUnit = 8;

	return {
		root: {
			display: "grid",
			justifyContent: "end",
			// position: "fixed",
			// top: size(appHeaderVariables.mobileHeightUnit / 2 - heightUnit / 2),
			// right: size(4),
			// [mediaQueries.tabletAndAbove]: {
			// 	position: "static",
			// 	top: "auto",
			// 	right: "auto",
			// },
		},
		button: {
			padding: `0 ${space[2]}`,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",

			"&:hover": {
				backgroundColor: "transparent",
				// ...styles.getFocusOutlineStyles({}, focusColor),
			},
		},
		list: {
			backgroundColor: "#E0E0E0",
		},
		img: {
			borderRadius: radii.md,
		},
		itemText: {
			padding: `0 8px`,
		},
	};
};

export { useLanguageMenuStyles };
