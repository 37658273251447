/** @jsxImportSource @emotion/react */
import { useSavingModalStyles } from "./savingModalStyles";
import { Heading, Paper, ProgressBar } from "@bilar/ui";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";

type SavingModalProps = {
	progressPercentage: number;
};

const SavingModal = (props: SavingModalProps) => {
	const { progressPercentage } = props;
	const [progress, setProgress] = useState(progressPercentage);
	const styles = useSavingModalStyles(props);
	const { t } = useTranslation();

	useEffect(() => {
		setProgress(progressPercentage);
	}, [progressPercentage]);

	return (
		<div css={styles.blur}>
			<Paper css={styles.root}>
				<Heading as="h1" size="regular" mb={4}>
					{t("saving")} ({progress}%)
				</Heading>

				<ProgressBar percentage={progress} />
			</Paper>
		</div>
	);
};

export { SavingModal };
export type { SavingModalProps };
