import { CSSObject } from "@emotion/react";
import { DesignTokens, SpacingSize, useBorderTokens } from "../../../styling";
import { RadioButtonProps } from "./RadioButton";
import { LabelPosition } from "@bilar/ui";

interface RadioButtonStyles {
	root: CSSObject; // the label tag
	radioWrapper: CSSObject;
	radioDot: CSSObject;
	input: CSSObject;
	text: CSSObject;
}

interface RadioButtonSizeValues {
	fontSize: string;
	wrapperSize: SpacingSize;
	dotSize: SpacingSize;
}

const getSizeValues = (
	props: RadioButtonProps,
	tokens: DesignTokens,
): RadioButtonSizeValues => {
	let values: RadioButtonSizeValues = {
		fontSize: tokens.fontSizes.md,
		wrapperSize: 6,
		dotSize: 3,
	};

	switch (props.size) {
		case "medium":
			values.fontSize = tokens.fontSizes.lg;
			values.wrapperSize = 8;
			values.dotSize = 4;
			break;
		case "large":
			values.fontSize = tokens.fontSizes.xl;
			values.wrapperSize = 10;
			values.dotSize = 5;
			break;
	}

	return values;
};

const getTextAlignmentStyles = (
	labelAlignment: LabelPosition,
	tokens: DesignTokens,
): CSSObject => {
	// if (labelAlignment === "left")
	// 	return {
	// 		paddingRight: tokens.size(2),
	// 	};

	return {
		paddingLeft: tokens.space[2],
	};
};

const getLayoutStyles = (
	props: RadioButtonProps,
	tokens: DesignTokens,
): CSSObject => {
	const { space, palette, radii } = tokens;
	const { checked } = props;
	if (props.layout === "regular") return {};

	return {
		display: "flex",
		width: "auto",
		padding: `${space[2]} ${space[4]}`,
		minWidth: space[36],
		minHeight: space[12],
		borderRadius: radii.md,
		outline: "2px solid transparent",
		backgroundColor: checked
			? palette.neutral.light.background
			: palette.neutral.lighter.background,

		"&:hover": {
			backgroundColor: palette.neutral.light.background,
		},

		"&:focus-within": {
			outlineColor: palette.neutral.main.background,
		},
	};
};
const getRootPropsStyles = (props: RadioButtonProps, tokens: DesignTokens) => {
	let disabledStyles = {};

	if (props.disabled) {
		disabledStyles = {
			opacity: 0.5,
			cursor: "not-allowed",
		};
	}

	return {
		...disabledStyles,
	};
};

const useRadioButtonStyles = (
	props: RadioButtonProps,
	tokens: DesignTokens,
): RadioButtonStyles => {
	const { borderShadow } = useBorderTokens();
	let wrapperOrder = 1;
	let textOrder = 2;
	const radioSize = getSizeValues(props, tokens);
	const { space, bordersNew, palette } = tokens;

	const radioButtonTokens = {
		radioButton: {
			borderWidth: 2,
			borderRadius: "round",
			borderStyle: "solid",
			borderColor: palette.neutral.dark.background,
			backgroundColor: palette.neutral.lighter.background,
			dotColor: palette.neutral.dark.background,
			focus: {
				...bordersNew.focus,
				borderColor: palette.neutral.darker.background,
			},
			hover: {
				...bordersNew.hover,
			},
		},
	};

	const {
		borderRadius,
		borderWidth,
		borderColor,
		borderStyle,
		dotColor,
		backgroundColor,
		hover,
		focus,
	} = radioButtonTokens.radioButton;

	return {
		root: {
			position: "relative", // Because we hide the "real" checkbox using position absolute with zero width and height, this must be set to relative
			cursor: "pointer",
			display: "flex",
			alignItems: "center",
			verticalAlign: "middle",
			fontSize: radioSize.fontSize,

			"&:hover": {
				".radio-wrapper": {
					borderWidth: props.disabled ? undefined : hover.borderWidth,
					borderColor: props.disabled ? undefined : hover.borderColor,
					boxShadow:
						!props.disabled && hover.outlineColor
							? borderShadow(hover.outlineColor, hover.outlineWidth)
							: undefined,
				},
			},
			"&:focus-within .radio-wrapper, input:focus+.radio-wrapper": {
				borderWidth: props.disabled ? undefined : focus.borderWidth,
				borderColor: props.disabled ? undefined : focus.borderColor,
				boxShadow:
					!props.disabled && focus.outlineColor
						? borderShadow(focus.outlineColor, focus.outlineWidth)
						: undefined,
			},
			...getLayoutStyles(props, tokens),
			...getRootPropsStyles(props, tokens),
		},
		radioWrapper: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			width: space[radioSize.wrapperSize],
			height: space[radioSize.wrapperSize],
			borderWidth,
			borderStyle,
			borderColor,
			borderRadius: "1000em",
			backgroundColor: backgroundColor,
			order: wrapperOrder,
			transition: `all ${bordersNew.animation.duration}ms ${bordersNew.animation.easing}`,
		},
		radioDot: {
			backgroundColor: dotColor,
			borderRadius: "1000em",
			width: space[radioSize.dotSize],
			height: space[radioSize.dotSize],
		},
		input: {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			cursor: "inherit",
			margin: 0,
			padding: 0,
			opacity: 0,
			zIndex: 1,
		},
		text: {
			order: textOrder,
			...getTextAlignmentStyles(props.labelPosition!, tokens),
		},
	};
};

export { useRadioButtonStyles, getSizeValues };
