import { FieldLabel, TextFieldAdornment, ValidationMessage } from "../..";
import { getHtmlProps, getTestId, useAutoId } from "../../../utils";
import { TextFieldDefaultProps, TextFieldProps } from "./textFieldTypes";
import { useTextFieldStyles } from "./textFieldStyles";
import { getAutoCompleteType } from "./autoCompleteTypes";
import { forwardRef } from "react";
import { useBoxStyles } from "@bilar/ui";

const defaultProps: TextFieldDefaultProps = {
	size: "regular",
};

const TextField = forwardRef((props: TextFieldProps, ref) => {
	const { startAdornment, endAdornment, wrapperRef, testId } = props;
	const styles = useTextFieldStyles(props);
	const id = useAutoId("text-field", props.id);
	const boxProps = useBoxStyles(props);

	const renderInput = () => {
		return (
			<input
				ref={ref}
				className="input"
				id={id}
				data-testid={getTestId(testId)}
				css={styles.input}
				disabled={props.disabled}
				onFocus={(event) => {
					if (props.onFocus) props.onFocus(event.target.value, event);
				}}
				onBlur={(event) => {
					if (props.onBlur) props.onBlur(event.target.value, event);
				}}
				type={props.type}
				value={props.value}
				onChange={(event) => {
					// Restrict length? This can be done with maxLength on the <input ... /> but doesn't work for type number etc
					const value =
						props.maxLength !== undefined
							? event.target.value.slice(0, props.maxLength)
							: event.target.value;

					if (props.onChange) props.onChange(value, event);
				}}
				maxLength={props?.maxLength}
				max={props.max}
				min={props.min}
				step={props.step}
				inputMode={props.inputMode}
				{...getAutoCompleteType(props.autoComplete)}
				{...getHtmlProps(props)}
			/>
		);
	};

	return (
		<div
			css={[styles.root, boxProps]}
			className={props.className}
			ref={wrapperRef}
		>
			{props.label && (
				<FieldLabel className="field-label" htmlFor={id}>
					{props.label}
				</FieldLabel>
			)}
			{props.multiline && (
				<textarea
					ref={ref}
					className="input"
					id={id}
					css={styles.input}
					rows={props.multilineRows ? props.multilineRows : 4}
					onFocus={(event) => {
						if (props.onFocus) props.onFocus(event.target.value, event);
					}}
					onBlur={(event) => {
						if (props.onBlur) props.onBlur(event.target.value, event);
					}}
					value={props.value}
					disabled={props.disabled}
					onChange={(event) => {
						// Restrict length? This can be done with maxLength on the <input ... /> but doesn't work for type number etc
						const value =
							props.maxLength !== undefined
								? event.target.value.slice(0, props.maxLength)
								: event.target.value;

						if (props.onChange) props.onChange(value, event);
					}}
					maxLength={props?.maxLength}
					{...getHtmlProps(props)}
				/>
			)}
			{!props.multiline && (
				<>
					{startAdornment || endAdornment ? (
						<div css={styles.adornment.container} data-valid={!props.error}>
							{startAdornment && (
								<div className="adornment-start" css={styles.adornment.start}>
									{typeof startAdornment === "string" ? (
										<TextFieldAdornment>{startAdornment}</TextFieldAdornment>
									) : (
										startAdornment
									)}
								</div>
							)}
							{renderInput()}
							{endAdornment && (
								<div className="adornment-end" css={styles.adornment.end}>
									{typeof endAdornment === "string" ? (
										<TextFieldAdornment>{endAdornment}</TextFieldAdornment>
									) : (
										endAdornment
									)}
								</div>
							)}
						</div>
					) : (
						renderInput()
					)}
				</>
			)}
			<ValidationMessage layoutMode="textField" error={props.error} />
		</div>
	);
});

TextField.displayName = "TextField";
TextField.defaultProps = defaultProps;

// Add all your exports here
export { TextField };
export type { TextFieldProps, TextFieldDefaultProps };
