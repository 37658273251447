import React, { forwardRef } from "react";
import ReactSelect, {
	ActionMeta as ReactSelectActionMeta,
	MultiValue,
	Props as ReactSelectProps,
	SingleValue,
} from "react-select";
import { DropdownOption } from "./selectTypes";
import { useSelectStyles } from "./selectStyles";
import {
	BaseProps,
	Box,
	BoxBaseProps,
	detectDevice,
	FieldLabel,
	getFieldMargin,
	getTestId,
	ResponsiveProp,
	Size,
	useAutoId,
	useBoxStyles,
	ValidationMessage,
} from "@bilar/ui";

// Define ReactSelectFilteredProps by omitting specific props from ReactSelectProps
type ReactSelectFilteredProps<IsMulti extends boolean> = Omit<
	ReactSelectProps<DropdownOption, IsMulti>,
	"onChange"
>;

export type ActionMeta<DataType> = ReactSelectActionMeta<DataType>;

export type DropdownOnChange<IsMulti extends boolean> = (
	value: IsMulti extends true
		? MultiValue<DropdownOption>
		: SingleValue<DropdownOption>,
	actionMeta: ActionMeta<DropdownOption>,
) => void;

type SelectProps<IsMulti extends boolean> =
	ReactSelectFilteredProps<IsMulti> & {
		onChange?: DropdownOnChange<IsMulti>;
		/**
		 * The Form label above the dropdown
		 */
		label?: string;
		/**
		 * Error message
		 */
		error?: string;
	} & Partial<SelectDefaultProps> &
		BoxBaseProps &
		BaseProps;

type SelectDefaultProps = {
	width: ResponsiveProp<Size>;
	hasDefaultMargins?: boolean;
	noBottomMargin?: boolean;
};

const defaultProps: SelectDefaultProps = {
	width: "100%",
	hasDefaultMargins: true,
	noBottomMargin: false,
};

const Select = forwardRef<
	// Ref<ReactSelect<DropdownOption, boolean>>,
	// ReactSelectProps<DropdownOption, any>,
	any,
	SelectProps<any>
>(
	(
		{
			options,
			onChange,
			value,
			isClearable = true,
			isMulti,
			label,
			testId,
			noBottomMargin,
			placeholder,
			error,
			...props
		},
		ref,
	) => {
		const styles = useSelectStyles(props);
		const boxStyles = useBoxStyles(props);
		const id = useAutoId("select-field", props.id);
		const fieldMargin = getFieldMargin("regular");

		const handleChange: DropdownOnChange<boolean> = (selected, actionMeta) => {
			if (onChange) {
				const { isRealMobile } = detectDevice();

				// We need to create a short delay here, so that we can trigger the onBlur event first. (only on mobile)
				// This is needed because react-select triggers the onBlur event after the onChange event on mobile devices.
				// Which causes validation errors to show up.
				// If we don't do this, the validation errors will always show up in mobile after the first selection.
				if (isRealMobile) {
					setTimeout(() => {
						onChange(selected, actionMeta);
					}, 10);
				} else {
					onChange(selected, actionMeta);
				}
			}
		};

		return (
			<Box css={boxStyles} mb={noBottomMargin ? 0 : fieldMargin}>
				{label && (
					<FieldLabel
						className="field-label"
						htmlFor={id}
						testId={getTestId(testId, "label")}
					>
						{label}
					</FieldLabel>
				)}
				<ReactSelect<DropdownOption, boolean>
					ref={ref}
					styles={styles}
					className={getTestId(testId)}
					data-testid={getTestId(testId)}
					inputId={id}
					placeholder={
						placeholder ? placeholder : "Select" // TODO: Add default translation
					}
					options={options}
					onChange={handleChange}
					value={value}
					isClearable={isClearable}
					isMulti={isMulti}
					menuPortalTarget={props.menuPortalTarget || document.body}
					{...props}
				/>
				{error && (
					<Box mt={5}>
						<ValidationMessage
							error={error}
							testId={getTestId(testId, "validation")}
						/>
					</Box>
				)}
			</Box>
		);
	},
);

Select.displayName = "Select";
Select.defaultProps = defaultProps;

export { Select };
export type { SelectProps };
