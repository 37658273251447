/** @jsxImportSource @emotion/react */
import { useMenuLinkStyles } from "./menuStyles";
import { Link, LinkProps } from "../links/Link";
import {
	MenuLink as ReachMenuLink,
	MenuLinkProps as ReachMenuLinkProps,
} from "@reach/menu-button";
import { BoxBaseProps, useBoxStyles } from "../layout/Box/boxStyles";
import { BaseProps } from "..";

type MenuItemLinkProps = {} & LinkProps &
	ReachMenuLinkProps &
	BoxBaseProps &
	BaseProps;

/**
 * The `<MenuItemLink>` component is used for links in menus.
 */
const MenuItemLink = (props: MenuItemLinkProps) => {
	const styles = useMenuLinkStyles();
	const boxStyles = useBoxStyles(props);

	return (
		<ReachMenuLink as={Link} css={[styles, boxStyles]} {...props}>
			{props.children}
		</ReachMenuLink>
	);
};

export { MenuItemLink };
export type { MenuItemLinkProps };
