import { CSSObject } from "@emotion/react";
import { BaseLayoutProps } from "./BaseLayout";
import { useDesignTokens } from "@bilar/ui";

type BaseLayoutStyles = {
	root: CSSObject;
	main: CSSObject;
	mainContainer: CSSObject;
};

const useBaseLayoutStyles = (props: BaseLayoutProps): BaseLayoutStyles => {
	const designTokens = useDesignTokens();
	const { space } = designTokens;
	return {
		root: {
			flex: "1",
			display: "grid",
			gridTemplateRows: "auto 1fr auto",
		},
		main: {
			paddingTop: space[8],
		},
		mainContainer: {
			// borderRadius: space[8],
			// backgroundColor: "#FAFFFC",
			// paddingTop: space[4],
			// paddingBottom: space[4],
		},
	};
};

export { useBaseLayoutStyles };
