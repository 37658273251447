import { CSSObject } from "@emotion/react";
import { VehicleImageGalleryProps } from "@bilar/features";
import { useDesignTokens } from "@bilar/ui";

type VehicleImageGalleryStyles = {
	root: CSSObject;
	loadingContainer: CSSObject;
};

const useVehicleImageGalleryStyles = (
	props: VehicleImageGalleryProps,
): VehicleImageGalleryStyles => {
	const designTokens = useDesignTokens();
	const { palette, radii, space, size, mediaQueries, breakpointWidths } =
		designTokens;
	return {
		root: {
			[mediaQueries.tabletAndAbove]: {
				// NOTE: Fullscreen images don't work if this is set in mobile, works on tablets though.
				containerType: "inline-size",
				containerName: "gallery",
			},

			".image-gallery-slide-wrapper": {
				minHeight: 200,

				"@container gallery (min-width: 640px)": {
					minHeight: 486,
				},
			},
			"&.loading .image-gallery-slide-wrapper": {
				display: "none",
			},
			".image-gallery-image": {
				borderRadius: radii.lg,
			},
			".image-gallery-index": {
				borderTopLeftRadius: radii.lg,
				right: "auto",
				left: 0,
			},
			".image-gallery-thumbnails-wrapper": {
				maxWidth: `calc(100dvw - ${size(21)})`,
			},
			".fullscreen .image-gallery-thumbnails-wrapper": {
				maxWidth: "100dvw",
			},
			".image-gallery-thumbnails": {
				paddingTop: space[4],
				paddingBottom: 0,
			},
			".image-gallery-thumbnail": {
				borderRadius: 4,
				borderColor: "#ccc",

				"&.active": {
					borderColor: palette.success.main.background,
				},

				"&:hover:not(.active)": {
					borderColor: palette.secondary.main.background,
				},

				"&.active .image-gallery-thumbnail-image, &:hover .image-gallery-thumbnail-image":
					{
						borderRadius: 0,
					},

				[mediaQueries.tabletAndAbove]: {
					width: 204,
					borderRadius: 4,
					marginRight: 16,
				},
			},
			".image-gallery-thumbnail-image": {
				borderRadius: 0,
			},
		},
		loadingContainer: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			minHeight: 250,

			"@container gallery (min-width: 640px)": {
				minHeight: 486,
			},
		},
	};
};

export { useVehicleImageGalleryStyles };
