import { CSSObject } from "@emotion/react";
import { MiniSearchProps } from "./MiniSearch";
import { SpacingSize, useDesignTokens } from "@bilar/ui";
import { appHeaderVariables } from "@bilar/layout";

type MiniSearchStyles = {
	root: CSSObject;
	button: CSSObject;
	label: CSSObject;
	icon: CSSObject;
};

const useMiniSearchStyles = (props: MiniSearchProps): MiniSearchStyles => {
	const designTokens = useDesignTokens();
	const { fontWeights, mediaQueries, palette, size, radii, zIndices } =
		designTokens;

	const { heightUnit: appHeaderHeightUnit } = appHeaderVariables;
	const height: SpacingSize = 12;
	const tableWidth: SpacingSize = 56;
	const desktopWidth: SpacingSize = 72;

	return {
		root: {
			display: "none",

			[mediaQueries.tabletAndAbove]: {
				position: "absolute",
				display: "block",
				backgroundColor: "#fff",
				borderRadius: radii.full,
				width: size(tableWidth),
				height: size(height),
				top: `${size(appHeaderHeightUnit / 2 - height / 2)}`,
				left: `calc(50% - ${size(tableWidth / 2)})`,
			},

			[mediaQueries.desktopAndAbove]: {
				width: size(desktopWidth),
				left: `calc(50% - ${size(desktopWidth / 2)})`,
			},
		},
		button: {
			gap: size(4),
			alignItems: "center",
			width: "100%",
			height: "100%",
			borderRadius: radii.full,
			border: "none",
			cursor: "pointer",
			backgroundColor: "transparent",
			display: "grid",
			justifyContent: "center",
			gridTemplateColumns: "1fr 1fr",
			padding: `0 ${size(4)}`,
		},
		label: {
			display: "inline-block",
			whiteSpace: "nowrap",
			marginRight: size(2),
			fontWeight: fontWeights.medium,
		},
		icon: {
			justifySelf: "end",
		},
	};
};

export { useMiniSearchStyles };
