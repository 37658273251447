import { MediaQueriesTokens } from "../styling/designTokens/mediaQueries";
import { useBrowserSize } from "./useBrowserSize";
import { BreakpointsWidths, useDesignTokens } from "@bilar/ui";

export type Device =
	| "mobile"
	| "tabletSmall"
	| "tablet"
	| "desktop"
	| "desktopWide";

type ResponsiveDeviceProps = {
	device: Device;
	isMobile: boolean;
	isTablet: boolean;
	isTabletSmall: boolean;
	isDesktop: boolean;
	isDesktopWide: boolean;
	minWidth: number;
	maxWidth?: number;
	values: BreakpointsWidths;
	cssBreakpoints: MediaQueriesTokens;
};

/**
 * Hook to get the current device and breakpoint information, not SSR safe since its dynamic
 */
export const useBreakpoints = (): ResponsiveDeviceProps => {
	const { width } = useBrowserSize();
	const { mediaQueries, breakpoints, breakpointWidths } = useDesignTokens();
	const deviceType: ResponsiveDeviceProps = {
		device: "mobile",
		isMobile: false,
		isTabletSmall: false,
		isTablet: false,
		isDesktop: false,
		isDesktopWide: false,
		minWidth: 0,
		maxWidth: breakpointWidths.tabletSmall,
		values: breakpointWidths,
		cssBreakpoints: mediaQueries,
	};

	if (width < breakpointWidths.tabletSmall) {
		deviceType.isMobile = true;
	}

	if (
		width >= breakpointWidths.tabletSmall &&
		width < breakpointWidths.tablet
	) {
		deviceType.device = "tabletSmall";
		deviceType.isTabletSmall = true;
		deviceType.minWidth = breakpointWidths.tabletSmall;
		deviceType.maxWidth = breakpointWidths.tablet;
	}

	if (width >= breakpointWidths.tablet && width < breakpointWidths.desktop) {
		deviceType.device = "tablet";
		deviceType.isTablet = true;
		deviceType.minWidth = breakpointWidths.tablet;
		deviceType.maxWidth = breakpointWidths.desktop;
	}

	if (
		width >= breakpointWidths.desktop &&
		width < breakpointWidths.desktopWide
	) {
		deviceType.device = "desktop";
		deviceType.isDesktop = true;
		deviceType.minWidth = breakpointWidths.desktop;
		deviceType.maxWidth = breakpointWidths.desktopWide;
	}

	if (width >= breakpointWidths.desktop) {
		deviceType.device = "desktopWide";
		deviceType.isDesktopWide = true;
		deviceType.minWidth = breakpointWidths.desktop;
		deviceType.maxWidth = undefined;
	}

	return deviceType;
};
