type LocalStorageFile = {
	base64: string;
	fileName: string;
};

/**
 * Get a query param value for NextJS router
 * @param queryParam
 */
const getQueryParamValue = (
	queryParam: string | string[] | undefined,
): string | undefined => {
	return queryParam
		? Array.isArray(queryParam)
			? queryParam[0]
			: queryParam
		: undefined;
};

/**
 * Transform a file into a base64 string
 * @param file
 */
const toBase64 = (file: File) =>
	new Promise<LocalStorageFile>((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () =>
			resolve({ base64: reader.result as string, fileName: file.name });
		reader.onerror = (error) => reject(error);
	});

/**
 * Parse the mimetype of a file from a base64 string
 * @param base64Data
 */
const parseMimeType = (base64Data: string) => {
	return base64Data.substring(
		"data:image/".length,
		base64Data.indexOf(";base64"),
	);
};

/**
 * Transform a base64 string into a file
 * @param dataUrl
 * @param fileName
 */
const base64ToFile = async (
	dataUrl: string,
	fileName: string,
): Promise<File> => {
	const res: Response = await fetch(dataUrl);
	const blob: Blob = await res.blob();
	return new File([blob], fileName, {
		type: `image/${parseMimeType(dataUrl)}`,
	});
};

export { getQueryParamValue, toBase64, base64ToFile };
export * from "./yearUtils";
export type { LocalStorageFile };
