import React, { Fragment, ReactNode, useEffect } from "react";
import { Dialog, Heading, Message, PostLoader, useAppContext } from "@bilar/ui";
import {
	useVehicleList,
	VehicleDetailsModal,
	VehicleListItem,
} from "@bilar/features";
import InfiniteScroll from "react-infinite-scroll-component";
import { ClassifiedVehicle } from "@bilar/models";
import { getConfig } from "@bilar/config";
import { useAppTranslation } from "@bilar/common";
import { useVehicleListAllStyles } from "./vehicleListAllStyles";

type VehicleListAllProps = {
	initialData: ClassifiedVehicle[];
	lastDocId: string;
	children?: ReactNode;
};

const VehiclesListAll = (props: VehicleListAllProps) => {
	const config = getConfig().public;
	const { adsList, setAdsList, getNextDataBatch, setStartDocId, hasMoreDocs } =
		useVehicleList(config.classifiedsPerPage, props.initialData);
	const { modalVehicleId } = useAppContext();
	const { t } = useAppTranslation();
	const styles = useVehicleListAllStyles();

	useEffect(() => {
		setAdsList(props.initialData);
		setStartDocId(props.lastDocId);
	}, []);

	const getDetailsData = (id: string): ClassifiedVehicle | undefined => {
		return adsList.find((item) => item.id === id);
	};

	return (
		<Fragment>
			{adsList.length > 0 && (
				<Fragment>
					<InfiniteScroll
						dataLength={adsList.length}
						next={getNextDataBatch}
						hasMore={hasMoreDocs}
						loader={<PostLoader />}
						endMessage={
							<Message palette="neutral" my={4}>
								<Heading as="h4" size="small">
									{t("noMoreRecords")}
								</Heading>
							</Message>
						}
					>
						<div css={styles.list}>
							{adsList.map((item, index) => (
								<VehicleListItem key={item.id} data={item} />
							))}
						</div>
					</InfiniteScroll>
				</Fragment>
			)}
			<Dialog
				isOpen={!!modalVehicleId}
				onDismiss={() => {
					window.history.back();
				}}
				size="xl"
				overrideStyles={styles.modal}
			>
				<VehicleDetailsModal
					id={modalVehicleId as string}
					data={getDetailsData(modalVehicleId as string)}
				/>
			</Dialog>
		</Fragment>
	);
};

export { VehiclesListAll };
export type { VehicleListAllProps };
