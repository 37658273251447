import { useEffect, useRef, useState } from "react";
import { Box, Heading } from "@bilar/ui";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import {
	OldGalleryImage,
	useDisplayFormat,
	useVehicleImageGalleryStyles,
} from "@bilar/features";
import { Loading, useAppTranslation } from "@bilar/common";
import { VehicleInfo } from "@bilar/models";

type VehicleImageGalleryProps = {
	images: OldGalleryImage[];
	vehicleInfo: VehicleInfo;
};

const VehicleImageGallery = (props: VehicleImageGalleryProps) => {
	const { images } = props;
	const [loading, setLoading] = useState<boolean>(true);
	const [galleryItems, setGalleryItems] = useState<ReactImageGalleryItem[]>([]);
	const galleryRef = useRef<any>(null);
	const styles = useVehicleImageGalleryStyles(props);
	const imagesLoaded = useRef<number>(0);
	const { t } = useAppTranslation();
	const { getBrandDisplay } = useDisplayFormat();

	useEffect(() => {
		mapImages();
	}, [images]);

	const mapImages = (): void => {
		const galleryImages = images.map((img, index) => {
			const newImg: ReactImageGalleryItem = {
				fullscreen: img.fullScreen.downloadUrl,
				original: img.medium.downloadUrl,
				thumbnail: img.thumbnail.downloadUrl,
				originalAlt: `${t("pictureOfWithNumber", { number: img.fullScreen.orderNumber ? img.fullScreen.orderNumber + 1 : index + 1 })}: ${getBrandDisplay(props.vehicleInfo)}`,
				thumbnailAlt: `${t("pictureOfWithNumber", { number: img.thumbnail.orderNumber ? img.thumbnail.orderNumber + 1 : index + 1 })}: ${getBrandDisplay(props.vehicleInfo)}`,
			};

			return newImg;
		});

		setGalleryItems(galleryImages);
	};

	if (galleryItems.length <= 0) {
		return <Heading as="h3">No images</Heading>;
	}

	// If we want to render each item ourselves, with NextImage for example, we could use something like this.
	// const renderItem = (item: ReactImageGalleryItem) => {
	// 	return (
	// 		<GalleryItem
	// 			description={item.description}
	// 			fullscreen={item.fullscreen}
	// 			handleImageLoaded={() => {}}
	// 			isFullscreen={isFullscreen}
	// 			onImageError={() => {}}
	// 			original={item.original}
	// 			originalAlt={item.originalAlt}
	// 			originalHeight={item.originalHeight}
	// 			originalWidth={item.originalWidth}
	// 			originalTitle={item.originalTitle}
	// 			sizes={item.sizes}
	// 			loading="lazy"
	// 			srcSet={item.srcSet}
	// 		/>
	// 	);
	// };

	return (
		<Box css={styles.root} className={loading ? "loading" : ""}>
			{loading && (
				<Box css={styles.loadingContainer}>
					<Loading variant="dots" centered={true} />
				</Box>
			)}
			<ImageGallery
				ref={galleryRef}
				items={galleryItems}
				showIndex={true}
				lazyLoad={false}
				onImageLoad={(img) => {
					// Note: This only works when we have lazyLoad={false}
					if (imagesLoaded.current === galleryItems.length - 1) {
						setLoading(false);
					}
					imagesLoaded.current += 1;
				}}
				onImageError={(event) => {
					console.error("Error loading image", event);
					// TODO: add error toastr or something
					setLoading(false);
				}}
				// When rendering each item ourselves, we might need to use something like this.
				// onScreenChange={(fullScreen) => {
				// 	setIsFullscreen(fullScreen);
				// }}
				// renderItem={renderItem}
				onClick={(event) => {
					//galleryRef?.current?.fullScreen();
					galleryRef?.current?.toggleFullScreen();
				}}
			/>
		</Box>
	);
};

export { VehicleImageGallery };
export type { VehicleImageGalleryProps };
