import { getClassifiedsForUser } from "@bilar/features";
import { ClassifiedVehicle, User } from "@bilar/models";
import { useState } from "react";

const useMyClassifieds = (user: User) => {
	const [myClassifieds, setMyClassifieds] = useState<
		ClassifiedVehicle[] | null
	>(null);

	/**
	 * Fetches the classifieds for the user and sets the state called myClassifieds
	 */
	const fetchMyClassifieds = async () => {
		const data = await getClassifiedsForUser(user);
		// console.log("data", data);

		setMyClassifieds(data);
	};

	return {
		myClassifieds,
		fetchMyClassifieds,
	};
};

export { useMyClassifieds };
