/** @jsxImportSource @emotion/react */
import * as React from "react";
import { VirtualItem } from "@tanstack/react-virtual";
import { useListBoxContext } from "./ListBoxContext";
import { useListBoxOptionStyles } from "./listBoxStyles";
import { CSSObject } from "@emotion/react";
import { Divider } from "@bilar/ui";

export type ListBoxOptionProps = {
	item: VirtualItem;
};

type VirtualizerItemProps = {
	"data-value": any;
	tabIndex: number;
	"aria-selected": boolean;
	"aria-setsize": number;
	"aria-posinset": number;
	ref: (node: HTMLElement | null) => void;
};

export type DividerItemProps = {
	css: CSSObject;
	"data-index": number;
} & VirtualizerItemProps;

export const ListBoxOption = (props: ListBoxOptionProps) => {
	const { item: virtualItem } = props;
	const {
		state,
		getDividerComponent,
		getOptionComponent,
		listElementsRef,
		interactions,
		virtualizer,
		handleSelect,
	} = useListBoxContext();
	const isActive = state.activeIndex === virtualItem.index;
	const isSelected = state.selectedIndex === virtualItem.index;
	const styles = useListBoxOptionStyles(isActive, isSelected);
	const listItem = state.items[virtualItem.index];

	const positionStyles: CSSObject = {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		border: "none",
		cursor: "default",
		transform: `translateY(${virtualItem.start}px)`,
	};

	const virtualizerItemProps: VirtualizerItemProps = {
		"data-value": listItem.value,
		tabIndex: -1,
		"aria-selected": state.activeIndex === virtualItem.index,
		// As the list is virtualized, this lets the assistive tech know
		// how many options there are total without looking at the DOM.
		"aria-setsize": state.items.length,
		"aria-posinset": virtualItem.index + 1,
		ref: (node) => {
			if (listElementsRef.current !== null) {
				listElementsRef.current[virtualItem.index] = node;
			}
			virtualizer?.measureElement(node);
		},
	};

	const dividerProps: DividerItemProps & VirtualizerItemProps = {
		...virtualizerItemProps,
		css: positionStyles,
		"data-index": virtualItem.index,
	};

	if (listItem.isDivider) {
		if (getDividerComponent) {
			return getDividerComponent(dividerProps);
		}

		return <Divider {...dividerProps} />;
	}

	/* eslint-disable jsx-a11y/role-has-required-aria-props */
	// We are already spreading the aria-selected in the virtualizerItemProps, the linter just doesn't recognize it.
	return (
		<button
			role="option"
			type="button"
			{...virtualizerItemProps}
			data-index={virtualItem.index}
			css={[positionStyles, styles]}
			{...interactions?.getItemProps({
				onClick: handleSelect,
			})}
		>
			{getOptionComponent?.(listItem, virtualItem) ?? listItem.label}
		</button>
	);
};
