import {
	createContext,
	Dispatch,
	FC,
	ReactNode,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from "react";
import {
	ArrayParam,
	DecodedValueMap,
	NumberParam,
	StringParam,
	useQueryParams,
} from "use-query-params";
import { useRouter } from "next/router";
import {
	getSearchPathWithParams,
	isSearchEmpty,
	mapQueryParamsToSearchState,
	mapSearchStateToQueryParams,
	SearchState,
	VehicleSearchQueryParamConfig,
} from "@bilar/features";
import { routes } from "@bilar/config";

const queryParamConfig: VehicleSearchQueryParamConfig = {
	brand: StringParam,
	model: StringParam,
	priceFrom: NumberParam,
	priceTo: NumberParam,
	vehicleBodyTypes: ArrayParam,
	fuelTypes: ArrayParam,
	maxAge: NumberParam,
	numberOfDoors: NumberParam,
};

interface VehicleSearchContext {
	searchState: SearchState;
	setSearchState: Dispatch<SetStateAction<SearchState>>;
	searchUrl: string | null;
	setSearchUrl: Dispatch<SetStateAction<string | null>>;
	queryParams: DecodedValueMap<VehicleSearchQueryParamConfig>;
	setQueryParams: (newQueryParams: Partial<SearchState>) => void;
	isOnSearchPage: boolean;
	searchOpen: boolean;
	setSearchOpen: Dispatch<SetStateAction<boolean>>;
}

const SearchContext = createContext<VehicleSearchContext | undefined>(
	undefined,
);

interface VehicleSearchProviderProps {
	children: ReactNode;
}

export const VehicleSearchProvider: FC<VehicleSearchProviderProps> = ({
	children,
}) => {
	const router = useRouter();
	const [queryParams, setQueryParams] = useQueryParams(queryParamConfig);
	const initialSearchState = mapQueryParamsToSearchState(queryParams);

	const [searchState, setSearchState] =
		useState<SearchState>(initialSearchState);
	const [searchUrl, setSearchUrl] = useState<string | null>(null);
	const [isOnSearchPage, setIsOnSearchPage] = useState<boolean>(
		router.pathname === routes.vehicleClassifieds.search,
	);
	const [searchOpen, setSearchOpen] = useState<boolean>(false);

	// New function to set query parameters
	const setQueryParamsHandler = (newSearchState: Partial<SearchState>) => {
		// Use the mapSearchStateToQueryParams function here
		const queryParamsToUpdate = mapSearchStateToQueryParams(newSearchState);

		// Set the updated query parameters
		setQueryParams(queryParamsToUpdate, "replace");
	};

	useEffect(() => {
		setIsOnSearchPage(router.pathname === routes.vehicleClassifieds.search);
	}, [router.pathname]);

	useEffect(() => {
		let newSearchUrl = null;
		if (!isSearchEmpty(searchState)) {
			newSearchUrl = getSearchPathWithParams(
				routes.vehicleClassifieds.search,
				searchState,
			);
		}

		setSearchUrl(newSearchUrl);

		if (isOnSearchPage) {
			setQueryParamsHandler(searchState);
		}
	}, [searchState]);

	useEffect(() => {
		const newSearchState = mapQueryParamsToSearchState(queryParams);
		setSearchState(newSearchState);
	}, [queryParams]);

	return (
		<SearchContext.Provider
			value={{
				searchState,
				setSearchState,
				searchUrl,
				setSearchUrl,
				queryParams,
				setQueryParams: setQueryParamsHandler,
				isOnSearchPage,
				searchOpen,
				setSearchOpen,
			}}
		>
			{children}
		</SearchContext.Provider>
	);
};

export const useSearch = (): VehicleSearchContext => {
	const context = useContext(SearchContext);
	if (context === undefined) {
		throw new Error("useSearch must be used within a SearchProvider");
	}
	return context;
};
