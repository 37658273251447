/** @jsxImportSource @emotion/react */
import { useFaIconStyles } from "./faIconStyles";

import { BaseFaIconProps } from "./faIconTypes";
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { useIconTransform } from "./useFaIconTransform";

// Fontawesome styles
import { AbstractElement, config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { createElement, ReactElement } from "react";
import { getTestId, useBoxStyles } from "@bilar/ui";

config.autoAddCss = false;

type FaIconProps = BaseFaIconProps &
	IconDefaultProps &
	Pick<FontAwesomeIconProps, "swapOpacity" | "transform">;

type IconDefaultProps = Partial<BaseFaIconProps>;

const defaultProps: IconDefaultProps = {
	// verticalAlign: "baseline",
};

const clsx = (...classes: (string | undefined | null | false)[]) =>
	classes.filter(Boolean).join(" ");
const buildClassName = (props: FaIconProps) =>
	clsx(
		"svg-inline--fa",
		props.hasBorder && "fa-border",
		(props.spinAnim || props.spinPulseAnim) && "fa-spin",
		props.fadeAnim && "fa-fade",
		props.beatAnim && "fa-beat",
		props.fadeAnim && props.beatAnim && "fa-beat-fade",
		props.spinPulseAnim && "fa-spin-pulse",
		props.bounceAnim && "fa-bounce",
		props.flipAnim && "fa-flip",
		props.shakeAnim && "fa-shake",
		props.isListItem && "fa-li",
		props.isFixedWidth && "fa-fw",
		props.pull && `fa-pull-${props.pull}`,
		props.swapOpacity && "fa-swap-opacity",
		props.className,
	);

const IconPath = (props: FaIconProps) => {
	const { icon, isPositionedInGrid } = props;
	let children = <path d={icon.icon[4].toString()} fill="currentColor" />;

	const transformStyles = useIconTransform(
		props,
		props.size!,
		isPositionedInGrid === false ? 1 : 8 * 4,
	);

	if (
		transformStyles.translateX ||
		transformStyles.translateY ||
		transformStyles.scaleX !== 1 ||
		transformStyles.scaleY !== 1 ||
		transformStyles.rotate
	) {
		// We need to group the path so that we can do the transforms from the center of the icon
		children = (
			<g transform={`translate(${icon.icon[0] / 2} ${icon.icon[1] / 2})`}>
				<g
					transform={`translate(${transformStyles.translateX} ${transformStyles.translateY}) scale(${transformStyles.scaleX} ${transformStyles.scaleY}) rotate(${transformStyles.rotate} 0 0)`}
				>
					<path
						fill="currentColor"
						d={icon.icon[4].toString()}
						transform={`translate(-${icon.icon[0] / 2} -${icon.icon[1] / 2})`}
					></path>
				</g>
			</g>
		);
	}

	return children;
};

function Tree2Element(tree: AbstractElement[]): ReactElement[] {
	return (
		tree &&
		tree.map((node, i) =>
			createElement(
				node.tag,
				{ key: i, ...node.attributes },
				Tree2Element(node.children || []),
			),
		)
	);
}

/**
 * Base component to use for icons, pass in the FontAwesome imported icon
 */
const FaIcon = (props: FaIconProps) => {
	const styles = useFaIconStyles(props);
	const boxStyles = useBoxStyles(props);
	const className = buildClassName(props);

	if (!props.icon) {
		console.error("The passed in icon is not defined");
		return null;
	}

	// TODO: better use this to transform the icon, it will help with the mask if we ever need it
	// https://fontawesome.com/docs/apis/javascript/methods#icon-icondefinition-params
	// import { icon as faIcon } from "@fortawesome/fontawesome-svg-core";
	// const i = faIcon(props.icon);
	// return Tree2Element(i.abstract, {
	// 	transform: {
	// 		size: 8, // starts at 16 so make it half
	// 		x: -4, // the same as left-4
	// 		y: 6, // the same as up-6
	// 		rotate: props.rotation || 0,
	// 		flipX: props.flip === "horizontal" || props.flip === "both",
	// 		flipY: props.flip === "vertical" || props.flip === "both",
	// 	},
	// });

	return (
		<svg
			css={[styles.root, boxStyles]}
			focusable="false"
			viewBox={[0, 0, props.icon.icon[0], props.icon.icon[1]].join(" ")}
			aria-hidden="true"
			role="presentation"
			className={className}
			data-testid={getTestId(props.testId)}
		>
			<IconPath {...props} />
		</svg>
	);
};

FaIcon.defaultProps = defaultProps;

export { FaIcon };
export type { FaIconProps };
