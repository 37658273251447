/** @jsxImportSource @emotion/react */
import { motion } from "framer-motion";
import React, { ReactNode } from "react";
import { BrandOptions, DesignOptions } from "@bilar/features";

type AdvancedSearchOptionsProps = {
	children?: ReactNode;
};

const AdvancedSearchOptions = (props: AdvancedSearchOptionsProps) => {
	return (
		<motion.div accessKey="advanced-options">
			<BrandOptions />
			<DesignOptions open={true} />
		</motion.div>
	);
};

export { AdvancedSearchOptions };
export type { AdvancedSearchOptionsProps };
