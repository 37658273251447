import { useState } from "react";
import { collections } from "@bilar/config";
import { getClassifiedsBaseQuery, vehicleDataConverter } from "@bilar/features";
import initFirebase from "@bilar/firebase/initFirebase";
import {
	collection,
	doc,
	getDoc,
	getDocs,
	getFirestore,
	query,
	startAfter,
} from "firebase/firestore";
import { ClassifiedVehicle } from "@bilar/models";

const useVehicleList = (adCount: number, initialData: ClassifiedVehicle[]) => {
	const [searchAdsList, setSearchAdsList] = useState<ClassifiedVehicle[]>([]);
	const [adsList, setAdsList] = useState<ClassifiedVehicle[]>(initialData);
	const [startDocId, setStartDocId] = useState<string>("");
	const [nextBatch, setNextBatch] = useState<ClassifiedVehicle[]>([]);

	// If the initial data count is lower than the adCount, we don't want to show the loader
	const initialDataCountIsLowerThanDisplayCount = initialData.length < adCount;
	const [hasMoreDocs, setHasMoreDocs] = useState(
		!initialDataCountIsLowerThanDisplayCount,
	);
	const firebaseApp = initFirebase();
	const db = getFirestore(firebaseApp);
	const adsRef = collection(db, collections.CLASSIFIEDS).withConverter(
		vehicleDataConverter,
	);

	// TODO: We probably want to have the ability to add the where query as param
	const getNextDataBatch = async () => {
		const startDocSnap = await getDoc(
			doc(db, collections.CLASSIFIEDS, startDocId).withConverter(
				vehicleDataConverter,
			),
		);

		const baseQuery = getClassifiedsBaseQuery(adsRef, adCount);
		let nextBatchQuery = query(baseQuery, startAfter(startDocSnap));

		const snapshot = await getDocs(nextBatchQuery);
		const data = snapshot.docs.map((doc) => doc.data());

		if (snapshot.docs.length <= 0) {
			setHasMoreDocs(false);
			return adsList;
		}

		const lastDocId = snapshot.docs[snapshot.docs.length - 1].id;
		setStartDocId(lastDocId);

		const combinedData = [...adsList, ...data];
		// console.log("combined data", combinedData);

		setAdsList(combinedData);

		return combinedData;
	};

	return {
		adsList,
		searchAdsList,
		setSearchAdsList,
		setAdsList,
		nextBatch,
		setNextBatch,
		startDocId,
		setStartDocId,
		hasMoreDocs,
		setHasMoreDocs,
		getNextDataBatch,
	};
};

export { useVehicleList };

// const init = () => {
// 	return query.onSnapshot((snapshot) => {
// 		const docs = snapshot.docs;
// 		const data = docs.map((doc) => doc.data() as ClassifiedAdDto);
// 		const changes = snapshot.docChanges();
// 		const dataChanged = changes.map(async (doc) => {
// 			if (doc.oldIndex != -1) {
// 				console.log("changes in doc", doc.doc.data());
// 			}
// 		});
//
// 		console.log("changes.length", changes.length);
//
// 		setAdsList(data);
// 	});
// };
//
// useEffect(() => {
// 	const unsubscribe = init();
//
// 	// Unsubscribe from realtime listener on unmount or it will create a memory leak
// 	return () => {
// 		console.log("unsubscribing...");
//
// 		unsubscribe();
// 	};
// }, []);

// useEffect(() => {
// }, [resetSearch]);
