import {
	ParsedPhoneNumber,
	parsePhoneNumber as parseAwesomePhoneNumber,
} from "awesome-phonenumber";
import { CountryCode, ValidationResult } from "../../../types";
import { parsePhoneNumber } from "./usePhoneField";
import {
	getCountries,
	phoneFieldDefaultProps,
	PhoneNumberType,
} from "@bilar/ui";

type PhoneFieldTranslations = {
	invalid: string;
	selectCountry: string;
	wrongCountry: string;
};

/**
 * Mobile phone validation that prioritizes strict rules for Nordax business-oriented countries (SE, NO, FI and DK for now):
 * Fallbacks to parseAwesomePhoneNumber() for any other country not part of regexRules map.
 * Note: Experimental.
 * @param countryCode
 * @param mobileNumber e164 format (e.g. +46712345678)
 */
export function validateMobileNumber(
	mobileNumber: string | undefined,
	countryCode: CountryCode,
): boolean {
	if (!mobileNumber) {
		return false;
	}

	const parsedNumber = parseAwesomePhoneNumber(mobileNumber || "", {
		regionCode: countryCode,
	});
	return parsedNumber.type === "mobile" && parsedNumber.valid;
}

type PhoneValidationResult = {
	phoneNumber?: ParsedPhoneNumber;
} & ValidationResult;

export type PhoneFieldValidationOptions = {
	type?: PhoneNumberType;
	countryCode?: CountryCode;
	allowedCountries?: CountryCode[];
	message?: Partial<PhoneFieldTranslations> | string;
};

export const phoneValidation = (
	value: string,
	options?: PhoneFieldValidationOptions,
): PhoneValidationResult => {
	const optionsWithDefaults = {
		...phoneFieldDefaultProps,
		...options,
	};
	const {
		type = phoneFieldDefaultProps.type,
		countryCode,
		allowedCountries,
		message,
	} = optionsWithDefaults;
	const allCountries = getCountries();
	const countries =
		allowedCountries || allCountries.map((country) => country.iso2);
	let selectedCountry = allCountries.find((c) => c.iso2 === countryCode);
	const invalidNumberMessage = "Invalid phone number";

	const messageTranslations: PhoneFieldTranslations = {
		invalid: invalidNumberMessage,
		selectCountry: invalidNumberMessage,
		wrongCountry: invalidNumberMessage,
	};

	if (typeof message === "string") {
		messageTranslations.invalid = message;
		messageTranslations.selectCountry = message;
		messageTranslations.wrongCountry = message;
	} else if (typeof message === "object") {
		messageTranslations.invalid = message?.invalid || invalidNumberMessage;
		messageTranslations.selectCountry =
			message?.selectCountry || invalidNumberMessage;
		messageTranslations.wrongCountry =
			message?.wrongCountry || invalidNumberMessage;
	}

	const validationResult: PhoneValidationResult = {
		isValid: false,
	};

	// Skip the validation if the user has not typed anything yet
	if (!value) {
		return validationResult;
	}

	// TODO: run Cypress tests here to see if anything broke, and add a missing case for this
	// There may be no selected country if there is no countryCode in the options,
	// see if we can get one from the value then
	if (value && !selectedCountry) {
		const phone = parsePhoneNumber(value, allCountries);
		selectedCountry = allCountries.find((c) => c.iso2 === phone?.country?.iso2);
	}

	if (value && !selectedCountry) {
		validationResult.errorMessage = messageTranslations.selectCountry;
		return validationResult;
	}

	const phone = parsePhoneNumber(value, allCountries, selectedCountry);
	validationResult.phoneNumber = phone?.phoneNumber;

	if (phone?.country?.iso2 && !countries.includes(phone?.country?.iso2)) {
		validationResult.errorMessage = messageTranslations.wrongCountry;
		return validationResult;
	}

	if (type === "mobile") {
		const isValid = validateMobileNumber(
			phone?.phoneNumber?.number?.e164,
			phone?.country?.iso2 as CountryCode,
		);
		if (!isValid) {
			validationResult.errorMessage = messageTranslations.invalid;
			return validationResult;
		}
	}

	if (
		type === "fixed-line" &&
		(phone?.phoneNumber?.typeIsMobile || !phone?.phoneNumber?.valid)
	) {
		validationResult.errorMessage = messageTranslations.invalid;
		return validationResult;
	}

	if (type === "fixed-line-or-mobile" && !phone?.phoneNumber?.valid) {
		validationResult.errorMessage = messageTranslations.invalid;
		return validationResult;
	}

	validationResult.isValid = true;

	return validationResult;
};
