import { CSSObject } from "@emotion/react";
import { ReactNode, useState } from "react";
import { BaseProps } from "..";
import { motion, Variants } from "framer-motion";

type ExpandAnimationProps = {
	children?: ReactNode;
	expanded?: boolean;
	role?: string;
	id?: string;
	ariaLabelledBy?: string; // Made this optional for now since it won't build otherwise
	overWriteCss?: CSSObject;
} & typeof defaultProps &
	BaseProps;

const defaultProps = {};

const getStyles = (): CSSObject => ({});

const variants: Variants = {
	collapsed: { height: 0, overflow: "hidden" },
	expanded: {
		height: "auto",
		overflow: "hidden",
		transitionEnd: {
			overflow: "visible",
		},
	},
};

const ExpandAnimation = (props: ExpandAnimationProps) => {
	const [initialExpanded] = useState(props.expanded);

	return (
		<motion.div
			css={props.overWriteCss}
			transition={{ ease: [0.4, 0, 0.2, 1] }}
			initial={initialExpanded ? "expanded" : "collapsed"}
			animate={props.expanded ? "expanded" : "collapsed"}
			variants={variants}
			id={props.id}
			// role: Provide keyboard navigation to ARIA region landmark.
			role={"region"}
			// aria-labelledby: Associate a region with the expander button.
			aria-labelledby={props.ariaLabelledBy}
			// aria-hidden: Adds/Removes the component from the accessibility tree.
			aria-hidden={props.expanded ? "false" : "true"}
			// aria-live: Set the priority with which screen reader should treat updates.
			aria-live={"polite"}
		>
			{props.children}
		</motion.div>
	);
};

ExpandAnimation.defaultProps = defaultProps;

// Add all your exports here
export { ExpandAnimation };
export type { ExpandAnimationProps };
