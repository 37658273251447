/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
	ButtonVariant,
	Country,
	CountryCode,
	PaletteOptions,
	parsePhoneNumber,
	Subset,
	TextFieldBaseProps,
	TextFieldDefaultProps,
	useAutoId,
	useCountries,
} from "@bilar/ui";
import { BaseProps, BoxBaseProps, PhoneResult, TextField } from "../..";

import { PhoneNumberFormat, PhoneNumberTypes } from "awesome-phonenumber";
import { CountrySelect, RenderCountryFlag } from "./CountrySelect";

export type PhoneNumberType = Subset<
	PhoneNumberTypes,
	"fixed-line" | "fixed-line-or-mobile" | "mobile"
>;

type PhoneFieldProps = {
	defaultCountry?: CountryCode;
	buttonVariant?: ButtonVariant;
	buttonPalette?: PaletteOptions;
	/**
	 * Override the country list
	 */
	countries?: CountryCode[];
	onChange?: (value: string) => void;
} & PhoneFieldDefaultProps &
	Omit<TextFieldBaseProps<string>, "startAdornment"> &
	Omit<TextFieldDefaultProps, "onChange"> &
	Omit<BoxBaseProps, "height"> &
	BaseProps;

export type PhoneFieldDefaultProps = {
	preferredCountries?: CountryCode[];
	/**
	 * Format of the returned value.
	 * @default "e164"
	 */
	format?: PhoneNumberFormat;
	/**
	 * Type of phone number to allow.
	 * @default "fixed-line-or-mobile"
	 */
	type?: PhoneNumberType;
};

export const phoneFieldDefaultProps: Required<PhoneFieldDefaultProps> = {
	preferredCountries: [],
	format: "e164",
	type: "fixed-line-or-mobile",
};

const findCountryFromNumber = (countries: Country[], number: string) => {
	const parsedNumber = parsePhoneNumber(number, countries);
	const country = parsedNumber.phoneNumber.regionCode;
	return countries.find((c) => c.iso2 === country);
};

/**
 * Phone input component that displays country flag icons and formats the input field with each country's format.
 */
const PhoneField = (
	props: PhoneFieldProps & { renderCountryFlag?: RenderCountryFlag },
) => {
	const {
		defaultCountry: defaultCountryCode,
		preferredCountries,
		onChange,
		onBlur,
		onFocus,
		testId,
		buttonVariant,
		buttonPalette,
		format = phoneFieldDefaultProps.format,
		countries: countriesOverride,
		renderCountryFlag,
		type,
		error,
		...filteredProps
	} = props;

	// Hooks
	const countries = useCountries(countriesOverride);
	const id = useAutoId("phone-field", props.id);
	const defaultCountry = useMemo(() => {
		// If there is only one country on the list, use that one as the default
		if (countries.length === 1) {
			return countries[0];
		}

		return countries.find((country) => country.iso2 === defaultCountryCode);
	}, [defaultCountryCode, countries]);

	// Refs
	const initialRenderRef = useRef(true);
	const inputRef = useRef<HTMLInputElement>(null);
	const inputWrapperRef = useRef<HTMLDivElement>(null);
	const userInputValueRef = useRef("");

	// States
	const [trackingValue, setTrackingValue] = useState<string | null>(
		props.value || null,
	);
	const [phone, setPhone] = useState<PhoneResult | undefined>();
	const [inputValue, setInputValue] = useState<string>("");
	const [selectedCountry, setSelectedCountry] = useState<Country | undefined>(
		() => {
			if (props.value && countries.length > 1) {
				const country = findCountryFromNumber(countries, props.value);

				if (country) {
					return country;
				}

				return parsePhoneNumber(props.value, countries, defaultCountry).country;
			}

			return defaultCountry;
		},
	);

	const internalValue = phone?.phoneNumber?.number?.national || "";
	const phoneNumber = phone?.phoneNumber?.number?.[format] || "";

	// Detect when the default country changes and update the selected country
	useEffect(() => {
		setSelectedCountry(defaultCountry);
	}, [defaultCountry]);

	/**
	 * This effect is used to update the phone input value when the value prop changes from the outside.
	 */
	useEffect(() => {
		valueChangedHandler(props.value || "", selectedCountry);
	}, [props.value]);

	useEffect(() => {
		initialRenderRef.current = false;
	}, []);

	const valueChangedHandler = (value: string, selectedCountry?: Country) => {
		const _phone = parsePhoneNumber(value, countries, selectedCountry);
		setInputValue(_phone.asYouTypeNumber);
		setPhone(_phone);
		setSelectedCountry(_phone.country);

		return _phone;
	};

	const onChangeHandler = (value: string, selectedCountry?: Country) => {
		const _phone = valueChangedHandler(value, selectedCountry);
		const number = _phone?.phoneNumber?.number?.[format] || "";

		onChange?.(number || value);
	};

	return (
		<TextField
			ref={inputRef}
			wrapperRef={inputWrapperRef}
			{...filteredProps}
			value={inputValue}
			id={id}
			testId={testId}
			error={error}
			startAdornment={
				<CountrySelect
					{...props}
					countries={countries}
					inputWrapperRef={inputWrapperRef}
					setSelectedCountry={(country) => {
						onChangeHandler(internalValue, country);
					}}
					setChangeIsFromInput={(isFromInput) => {
						if (isFromInput) {
							onChangeHandler(internalValue, phone?.country);
						}
					}}
					selectedCountry={selectedCountry}
					renderCountryFlag={renderCountryFlag}
				/>
			}
			inputMode="tel"
			autoComplete="phoneFull"
			maxLength={40}
			onChange={(value) => {
				userInputValueRef.current = value;
				onChangeHandler(value, selectedCountry);
			}}
			onFocus={(_, event) => {
				onFocus?.(phoneNumber, event);
			}}
			onBlur={(_, event) => {
				onBlur?.(phoneNumber, event);
			}}
		/>
	);
};

// Add all your exports here
export { PhoneField };
export type { PhoneFieldProps };
