import { useCallback, useEffect, useRef, useState } from "react";
import { fetchVehicleBySearch, useSearch } from "@bilar/features";
import { ClassifiedVehicle } from "@bilar/models";

type VehiclePostsSearchHook = {
	vehiclePosts: ClassifiedVehicle[];
	loading: boolean;
	totalPosts: number;
	hasMoreResults: boolean;
	fetchNextPage: () => void;
};

export const useVehiclePostsSearch = (
	postsPerPage: number = 12,
): VehiclePostsSearchHook => {
	const { searchState, queryParams } = useSearch();
	const allVehiclePostsRef = useRef<ClassifiedVehicle[]>([]);
	const [displayedVehiclePosts, setDisplayedVehiclePosts] = useState<
		ClassifiedVehicle[]
	>([]);
	const [currentPage, setCurrentPage] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);

	const fetchAllPosts = useCallback(async () => {
		setLoading(true);

		// Fetch all results based on the search criteria
		const results = await fetchVehicleBySearch(searchState);

		allVehiclePostsRef.current = results.data;
		// Display only the first 'postsPerPage' results initially
		setDisplayedVehiclePosts(results.data.slice(0, postsPerPage));
		setCurrentPage(0);
		setLoading(false);
	}, [searchState]);

	useEffect(() => {
		fetchAllPosts(); // Fetch all results when queryParams changes
	}, [queryParams, fetchAllPosts]);

	const fetchNextPage = () => {
		setCurrentPage((prevPage) => {
			const newPage = prevPage + 1;
			setDisplayedVehiclePosts(
				allVehiclePostsRef.current.slice(0, (newPage + 1) * postsPerPage),
			);
			return newPage;
		});
	};

	const hasMoreResults =
		allVehiclePostsRef.current.length > (currentPage + 1) * postsPerPage;
	const totalPosts = allVehiclePostsRef.current.length;

	return {
		vehiclePosts: displayedVehiclePosts,
		loading,
		totalPosts: totalPosts,
		hasMoreResults,
		fetchNextPage,
	};
};
