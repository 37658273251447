import { useRouter } from "next/router";
import { LanguageCode } from "@bilar/ui";

// Hook that converts next.js router to LanguageCode
const useRouterLocale = () => {
	const router = useRouter();
	const { locale } = router;

	const getPageUrlWithLocale = (url: string) => {
		if (locale === "en") {
			return `${url}/en`;
		} else {
			return `${url}/is`;
		}
	};

	return {
		locale: locale as LanguageCode,
		getPageUrlWithLocale,
	};
};

export { useRouterLocale };
