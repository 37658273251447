import React, { ReactNode } from "react";
import { HtmlTag } from "../../types";
import { BaseBoxProps, BaseProps, Icon, Tag } from "@bilar/ui";
import { useHeadingStyles } from "./headingStyles";

// TODO: Add responsive props
// TODO: Implement shade or palette

type HeadingProps = {
	children: ReactNode;
	as: HtmlTag;
	icon?: ReactNode;
} & BaseProps &
	BaseBoxProps &
	HeadingDefaultProps;

export type HeadingSize = "xs" | "small" | "regular" | "large" | "hero";
export type HeadingShade = "light" | "regular" | "dark";
export type HeadingWeight =
	| "hairline"
	| "thin"
	| "light"
	| "normal"
	| "medium"
	| "semiBold"
	| "bold"
	| "extraBold"
	| "black";

export type HeadingDefaultProps = {
	size?: HeadingSize;
	shade?: HeadingShade;
	weight?: HeadingWeight;
};

const defaultProps: HeadingDefaultProps = {
	size: "regular",
	shade: "regular",
	weight: "normal",
};

const Heading = (props: HeadingProps) => {
	const { as, ...filteredProps } = props;
	const styles = useHeadingStyles(props);

	return (
		<Tag as={props.as} css={styles} {...filteredProps}>
			{props.icon && <Icon icon={props.icon} mr={2} />}
			{props.children}
		</Tag>
	);
};

Heading.defaultProps = defaultProps;

export { Heading };
export type { HeadingProps };
