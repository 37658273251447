import { CSSObject } from "@emotion/react";
import { MyClassifiedsProps } from "./MyClassifieds";
import { useDesignTokens } from "@bilar/ui";

type EditClassifiedsStyles = {
	root: CSSObject;
};

const useEditClassifiedsStyles = (
	props: MyClassifiedsProps,
): EditClassifiedsStyles => {
	const designTokens = useDesignTokens();
	const { palette } = designTokens;
	return {
		root: {},
	};
};

export { useEditClassifiedsStyles };
