import { CSSObject } from "@emotion/react";
import { ButtonGroupProps } from "./ButtonGroup";

type ButtonGroupStyles = {
	root: CSSObject;
};

const useButtonGroupStyles = (props: ButtonGroupProps): ButtonGroupStyles => {
	//const designTokens = useDesignTokens()

	return {
		root: {
			display: "flex",
			".button:not(:first-of-type):not(:last-of-type)": {
				borderRadius: 0,
				borderLeft: 0,
			},
			".button:first-of-type": {
				borderTopRightRadius: 0,
				borderBottomRightRadius: 0,
			},
			".button:last-of-type": {
				borderTopLeftRadius: 0,
				borderBottomLeftRadius: 0,
			},
		},
	};
};
export { useButtonGroupStyles };
