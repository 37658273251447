import React from "react";
import { jsx, Theme } from "@emotion/react";

/**
 * Lets us clone an Emotion element and dynamically add props to it.
 * @param child
 * @param props
 */
export const emotionClone = (child: any, props: any) => {
	if (child.props.css) {
		return React.cloneElement(child, {
			...props,
			css: (theme: Theme) => [
				child.props.css instanceof Function
					? child.props.css(theme)
					: child.props.css,
				props.css,
			],
		});
	}
	return jsx(child.type, {
		key: child.key,
		...child.props,
		...props,
	});
};
