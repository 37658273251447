//------------------ Components ------------------
export * from "./Avatar/Avatar";
export * from "./Container/Container";
export * from "./Chip";
export * from "./Dialog";
export * from "./Divider/Divider";
export * from "./Heading";
export * from "./HideOnMobile/HideOnMobile";
export * from "./Panel";
export * from "./Paper";
export * from "./ProgressBar";
export * from "./Tag/Tag";
export * from "./message/Message";
export * from "./Gallery";

export * from "./buttons";
export * from "./icons";
export * from "./layout";
export * from "./forms";
export * from "./loaders";
export * from "./menu";
export * from "./links";
export * from "./animations";
export * from "./listbox";
export * from "./vehicles";
export * from "./VisuallyHidden";
export * from "./tooltip";
export * from "./visibility";

//------------------ Types ------------------
export type BaseProps = { id?: string; className?: string; testId?: string };

type BaseBoxProps = {
	py?: number;
	px?: number;
	pt?: number;
	pr?: number;
	pb?: number;
	pl?: number;
	my?: number;
	mx?: number;
	mt?: number;
	mr?: number;
	mb?: number;
	ml?: number;
};

export type { BaseBoxProps };
