import { useIconStyles } from "./iconStyles";
import { ReactNode } from "react";
import { PaletteProps, VerticalAlign } from "../../types";

type IconProps = {
	children?: ReactNode;
	icon: ReactNode;
	mr?: number;
	ml?: number;
} & IconDefaultProps;

type IconDefaultProps = {
	size?: number; //BaseSizes
	verticalAlign?: VerticalAlign;
} & PaletteProps;

const defaultProps: IconDefaultProps = {
	verticalAlign: "middle",
	size: 4,
	paletteShade: "main",
};

const Icon = (props: IconProps) => {
	const {
		icon,
		children,
		verticalAlign,
		palette,
		paletteShade,
		...filteredProps
	} = props;
	const styles = useIconStyles(props);

	return (
		<span aria-hidden css={styles.root} {...filteredProps}>
			{icon || children}
		</span>
	);
};

Icon.defaultProps = defaultProps;

export { Icon };
export type { IconProps };
