// TODO: Add more html attributes here.
/*
accept acceptCharset accessKey action allowFullScreen alt async autoComplete autoFocus autoPlay capture cellPadding cellSpacing challenge charSet checked cite classID className colSpan cols content contentEditable contextMenu controls controlsList coords crossOrigin data dateTime default defer dir disabled download draggable encType form formAction formEncType formMethod formNoValidate formTarget frameBorder headers height hidden high href hrefLang htmlFor httpEquiv icon id inputMode integrity is keyParams keyType kind label lang list loop low manifest marginHeight marginWidth max maxLength media mediaGroup method min minLength multiple muted name noValidate nonce open optimum pattern placeholder poster preload profile radioGroup readOnly rel required reversed role rowSpan rows sandbox scope scoped scrolling seamless selected shape size sizes span spellCheck src srcDoc srcLang srcSet start step style summary tabIndex target title type useMap value width wmode wrap
*/
export const getHtmlProps = (props: any) => {
	const htmlProps: any = {};

	Object.keys(props).forEach((key) => {
		if (
			key.startsWith("aria") ||
			key.startsWith("data") ||
			key.startsWith("role") ||
			key.startsWith("tabindex")
		) {
			htmlProps[key] = (props as any)[key];
		}
	});

	return htmlProps;
};
