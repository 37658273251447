import { AutoCompleteType } from "./textFieldTypes";

// http://ryan.mcgeary.org/files/autocomplete-types.pdf
const getAutoCompleteType = (autoComplete?: AutoCompleteType) => {
	if (!autoComplete) return {};

	switch (autoComplete) {
		case "fullName":
			return { autoComplete: "name", autocompletetype: "name-full" };
		case "firstName":
			return { autoComplete: "given-name", autocompletetype: "given-name" };
		case "middleName":
			return {
				autoComplete: "additional-name",
				autocompletetype: "middle-name",
			};
		case "middleInitial":
			return {
				autoComplete: "additional-name-initial",
				autocompletetype: "middle-initial",
			};
		case "lastName":
			return { autoComplete: "family-name", autocompletetype: "surname" };
		case "nickName":
			return { autoComplete: "nickname" };
		case "nameSuffix":
			return {
				autoComplete: "honorific-suffix",
				autocompletetype: "name-suffix",
			};
		case "namePrefix":
			return {
				autoComplete: "honorific-prefix",
				autocompletetype: "name-prefix",
			};

		// Address
		case "addressFull":
			return {
				autoComplete: "street-address",
				autocompletetype: "street-address",
			};
		case "addressLineOne":
			return {
				autoComplete: "address-line1",
				autocompletetype: "address-line1",
			};
		case "addressLineTwo":
			return {
				autoComplete: "address-line2",
				autocompletetype: "address-line2",
			};
		case "addressLineThree":
			return {
				autoComplete: "address-line3",
				autocompletetype: "address-line3",
			};
		case "addressLocality":
			return { autoComplete: "locality", autocompletetype: "locality" };
		case "addressCity":
			// We actually use locality here and not city more info here:
			// https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
			return { autoComplete: "locality", autocompletetype: "locality" };
		//return { autoComplete: "city", autocompletetype: "city" };
		case "addressAdministrativeArea":
			return {
				autoComplete: "administrative-area",
				autocompletetype: "administrative-area",
			};
		case "addressState":
			return { autoComplete: "state", autocompletetype: "state" };
		case "addressProvince":
			return { autoComplete: "province", autocompletetype: "province" };
		case "addressRegion":
			return { autoComplete: "region", autocompletetype: "region" };
		case "addressPostalCode":
			return { autoComplete: "postal-code", autocompletetype: "postal-code" };
		case "addressCountry":
			return { autoComplete: "country-name", autocompletetype: "country-name" };

		// Contact
		case "email":
			return { autoComplete: "email", autocompletetype: "email" };
		case "phoneCountryCode":
			return {
				autoComplete: "tel-country-code",
				autocompletetype: "phone-country-code",
			};
		case "phoneFull":
			return { autoComplete: "tel", autocompletetype: "phone-full" };
		case "phoneLocal":
			return { autoComplete: "tel-local", autocompletetype: "phone-local" };
		case "phoneNational":
			return {
				autoComplete: "tel-national",
				autocompletetype: "phone-national",
			};
		case "phoneAreaCode":
			return {
				autoComplete: "tel-area-code",
				autocompletetype: "phone-area-code",
			};

		// Credit cards
		case "creditCardName":
			return { autoComplete: "cc-name", autocompletetype: "cc-full-name" };
		case "creditCardNumber":
			return { autoComplete: "cc-number", autocompletetype: "cc-number" };
		case "creditCardExpMonth":
			return { autoComplete: "cc-exp-month", autocompletetype: "cc-exp-month" };
		case "creditCardExpYear":
			return { autoComplete: "cc-exp-year", autocompletetype: "cc-exp-year" };
		case "creditCardSecurityCode":
			return { autoComplete: "cc-csc", autocompletetype: "cc-csc" };

		// Other
		case "organization":
			return { autoComplete: "org", autocompletetype: "organization" };
		case "organizationJobTitle":
			return {
				autoComplete: "organization-title",
				autocompletetype: "organization-title",
			};
		case "gender":
			return { autoComplete: "sex", autocompletetype: "gender" };
		case "genderIdentity":
			return { autoComplete: "gender-identity" };
		case "language":
			return { autoComplete: "language", autocompletetype: "language" };

		case "off":
			// Apparently Google Chrome now ignores "off" unless it's on the form tag, this solves it :)
			return { autoComplete: "nope" };
	}
};

export { getAutoCompleteType };
