import { useEffect, useState } from "react";
import { isNil } from "ramda";
import { DropdownOption } from "./selectTypes";

const useSelectOptions = <T>(data: T[] | null) => {
	const [options, setOptions] = useState<T[]>([]);

	useEffect(() => {
		if (!isNil(data)) {
			setOptions(data);
		}
	}, [data]);

	return options;
};

const useDropdownOptions = <T>(
	data: T[] | null,
	mapToDropdownOption: (item: T) => DropdownOption,
) => {
	const [options, setOptions] = useState<DropdownOption[]>([]);

	useEffect(() => {
		if (!isNil(data)) {
			setOptions(data.map(mapToDropdownOption));
		}
	}, [data, mapToDropdownOption]);

	return options;
};

export { useSelectOptions, useDropdownOptions };
