import { CSSObject } from "@emotion/react";
import { forwardRef } from "react";
import { BaseProps } from "../";
import { Button, ButtonProps } from "./Button";
import { useDesignTokens } from "@bilar/ui";

type ToggleButtonProps = {
	children?: React.ReactNode;
	isActive?: boolean;
} & ButtonProps &
	BaseProps;

const useStyles = (props: ToggleButtonProps): CSSObject => {
	const { palette } = useDesignTokens();
	const { isActive } = props;

	return {
		color: isActive ? palette.white : palette.black,
		background: isActive ? palette.black : palette.white,
		border: isActive
			? `1px solid ${palette.neutral.lighter.contrast}`
			: `1px solid ${palette.neutral.lighter.contrast}`,
	};
};

export const ToggleButton = forwardRef((props: ToggleButtonProps, ref) => {
	const { isActive = false, ...rest } = props;
	const styles = useStyles({ ...props, isActive });

	return (
		<Button ref={ref} css={styles} {...rest}>
			{props.children}
		</Button>
	);
});

ToggleButton.displayName = "ToggleButton";
