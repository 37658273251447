import { ReactNode } from "react";
import { motion } from "framer-motion";

type RouteTransitionProps = {
	children?: ReactNode;
} & RouteTransitionDefaultProps;

type RouteTransitionDefaultProps = {};

const defaultProps: RouteTransitionDefaultProps = {};

const RouteTransition = (props: RouteTransitionProps) => {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
		>
			{props.children}
		</motion.div>
	);
};

RouteTransition.defaultProps = defaultProps;

export { RouteTransition };
export type { RouteTransitionProps };
