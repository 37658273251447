import { CSSObject } from "@emotion/react";
import { DialogProps } from "./Dialog";
import { DesignTokens, useDesignTokens } from "@bilar/ui";

// We need to set global styles on :root so we won't get warnings from @reachui
const baseDialogStyles = (): CSSObject => {
	return {
		":root": {
			"--reach-dialog": "1",
		},
	};
};

type DialogStyles = {
	overlay: CSSObject;
	content: DialogContentStyles;
	header: DialogHeaderStyles;
	footer: CSSObject;
	closeButton: CSSObject;
	closeIcon: CSSObject;
};

type DialogHeaderStyles = {
	root: CSSObject;
	heading: CSSObject;
};

type DialogContentStyles = {
	root: CSSObject;
	animationWrapper: CSSObject;
	inner: CSSObject;
};

type DialogOverrideStyles = {
	content?: Partial<DialogContentStyles>;
	header?: Partial<DialogHeaderStyles>;
	footer?: CSSObject;
} & Partial<DialogStyles>;

const getSizeStyles = (props: DialogProps, tokens: DesignTokens): CSSObject => {
	const { space, mediaQueries, breakpointWidths } = tokens;

	const defaultSizeStyles: CSSObject = {
		width: `calc(100vw - ${space[12]})`, // margins * 2
		margin: `${space[6]}`,
	};

	if (props.size) {
		switch (props.size) {
			case "small":
				return {
					...defaultSizeStyles,
					maxWidth: space[64],
				};
			case "regular":
				return {
					...defaultSizeStyles,
					maxWidth: space[128],
				};

			case "large":
				return {
					...defaultSizeStyles,
					maxWidth: space[192],
				};
			case "xl":
				return {
					...defaultSizeStyles,

					[mediaQueries.tabletAndAbove]: {
						maxWidth: breakpointWidths.desktopWide,
					},
				};
			case "max":
				return {
					...defaultSizeStyles,
				};
		}
	}

	return defaultSizeStyles;
};

const getPositionStyles = (
	props: DialogProps,
	tokens: DesignTokens,
): CSSObject => {
	if (props.position) {
		switch (props.position) {
			case "center":
				return {
					display: "flex",
					alignItems: "center",
				};
			case "bottom":
				return {
					display: "flex",
					alignItems: "flex-end",
				};
			case "stretch":
				return {
					display: "flex",
					alignItems: "stretch",
				};
			default:
				return {
					display: "flex",
					alignItems: "flex-start",
				};
		}
	}

	return {
		display: "flex",
		alignItems: "flex-start",
	};
};

const useDialogStyles = (props: DialogProps): DialogStyles => {
	const designTokens = useDesignTokens();
	const {
		space,
		palette,
		mediaQueries,
		breakpointWidths,
		borders,
		zIndices,
		radii,
	} = designTokens;

	const dialogPadding = space[4];
	const contentPadding = props.contentPadding ? dialogPadding : 0;

	return {
		overlay: {
			background: "hsla(0, 0%, 0%, 0.53)",
			position: "fixed",
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			overflow: "auto",
			zIndex: zIndices.overlay,
		},
		header: {
			root: {
				borderBottom: `1px solid ${borders.color.main}`,
				borderTopLeftRadius: radii.lg,
				borderTopRightRadius: radii.lg,
				paddingLeft: contentPadding,
				paddingRight: contentPadding,
				display: "flex",
				alignItems: "center",
				justifyItems: "space-between",
				minHeight: space[12],
			},
			heading: {
				margin: 0,
			},
		},
		content: {
			animationWrapper: {
				justifyContent: "center",
				...getPositionStyles(props, designTokens),
			},
			root: {
				position: "relative",
				display: "flex",
				flexDirection: "column",
				background: "white",
				outline: "none",
				borderRadius: radii.lg,
				zIndex: zIndices.modal,
				...getSizeStyles(props, designTokens),
			},
			inner: {
				flex: "1",
				overflow: "auto",
				padding: contentPadding,
			},
		},
		closeButton: {
			position: "absolute",
			top: contentPadding,
			right: contentPadding,
			zIndex: zIndices.modal + 1,
			padding: space[2],
			borderRadius: "50%",
			width: space[8],
			height: space[8],
			backgroundColor: palette.grey["800"].background,
			color: palette.grey["800"].contrast,

			display: "flex",
			alignItems: "center",
			justifyContent: "center",

			"&:hover": {
				backgroundColor: palette.grey["900"].background,
				color: palette.grey["900"].contrast,
			},
		},
		closeIcon: {
			fill: palette.neutral.lighter.contrast,
			":hover": { fill: palette.neutral.light.contrast },
		},
		footer: {
			background: palette.neutral.lighter.background,
			color: palette.neutral.lighter.contrast,
			borderTop: `1px solid ${palette.neutral.light.background}`,
			minHeight: space[12],
			display: "flex",
			justifyContent: "flex-end",
			alignItems: "center",
			padding: dialogPadding,
			borderBottomLeftRadius: radii.lg,
			borderBottomRightRadius: radii.lg,

			button: {
				marginLeft: space[3],
			},
		},
	};
};

export { baseDialogStyles, useDialogStyles };
export type { DialogStyles, DialogOverrideStyles };
