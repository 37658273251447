import { CSSObject } from "@emotion/react";
import { PaperProps } from "./Paper";
import { DesignTokens, useDesignTokens } from "@bilar/ui";

const usePaperStyles = (props: PaperProps): CSSObject => {
	const tokens = useDesignTokens();
	// NOTE: We can't put any default padding on this because Message component uses it around itself when elevation is used.
	return {
		background: tokens.palette.white,
		borderRadius: tokens.radii.lg,
		...getVariantStyles(props, tokens),
		...getElevationStyles(props, tokens),
	};
};

const getVariantStyles = (
	props: PaperProps,
	tokens: DesignTokens,
): CSSObject => {
	switch (props.variant) {
		case "regular":
			return {
				border: "none",
			};
		case "outlined":
			return {
				border: `1px solid ${tokens.borders.color.main}`,
			};
		default:
			return {};
	}
};

const getElevationStyles = (
	props: PaperProps,
	tokens: DesignTokens,
): CSSObject => {
	switch (props.elevation) {
		case "none":
			return {
				boxShadow: "none",
			};
		case "small":
			return {
				boxShadow:
					"0px 0 1px -1px rgba(0,0,0,0.2), " +
					"0px 0px 1px 0px rgba(0,0,0,0.14), " +
					"0px 0px 1px 0px rgba(0,0,0,0.12)",
			};

		case "medium":
			return {
				boxShadow:
					"0px 3px 3px -2px rgba(0,0,0,0.2), " +
					"0px 3px 4px 0px rgba(0,0,0,0.14), " +
					"0px 1px 8px 0px rgba(0,0,0,0.12)",
			};
		case "large":
			return {
				boxShadow:
					"0px 3px 5px -1px rgba(0,0,0,0.2), " +
					"0px 6px 10px 0px rgba(0,0,0,0.14), " +
					"0px 1px 18px 0px rgba(0,0,0,0.12)",
			};
		default:
			return {};
	}
};

export { usePaperStyles };
