import React, {
	createContext,
	Dispatch,
	ReactNode,
	SetStateAction,
	useEffect,
	useState,
} from "react";
import { CountryCode, useWindowHistoryState } from "@bilar/ui";
import { getConfig } from "@bilar/config";

type AppProviderProps = {
	children?: ReactNode;
};

type AppProviderContext = {
	countryCode: CountryCode;
	modalVehicleId: string | null;
	setModalVehicleId: Dispatch<SetStateAction<string | null>>;
};

const defaultContextValues: AppProviderContext = {
	modalVehicleId: null,
	setModalVehicleId(
		value: ((prevState: string | null) => string | null) | string | null,
	): void {},
	countryCode: getConfig().public.countryCode as CountryCode,
};

const AppProviderContext = createContext<AppProviderContext | undefined>(
	defaultContextValues,
);

const AppProvider = (props: AppProviderProps) => {
	const [modalVehicleId, setModalVehicleId] = useState<string | null>(null);

	// This is a hack to make sure that the modal is closed when the user navigates back
	const historyState = useWindowHistoryState();
	useEffect(() => {
		setModalVehicleId(null);
	}, [historyState]);

	return (
		<AppProviderContext.Provider
			value={{
				countryCode: defaultContextValues.countryCode,
				modalVehicleId,
				setModalVehicleId,
			}}
		>
			{props.children}
		</AppProviderContext.Provider>
	);
};

export { AppProvider, AppProviderContext };
export type { AppProviderProps };
