import { CSSObject } from "@emotion/react";
import { FeedbackButtonProps } from "./FeedbackButton";
import { useDesignTokens } from "@bilar/ui";

type FeedbackButtonStyles = {
	root: CSSObject;
	icon: CSSObject;
	text: CSSObject;
};

const useFeedbackButtonStyles = (
	props: FeedbackButtonProps,
): FeedbackButtonStyles => {
	const designTokens = useDesignTokens();
	const {
		palette,
		fontWeights,
		fontSizes,
		fonts,
		space,
		mediaQueries,
		zIndices,
	} = designTokens;
	return {
		root: {
			fontFamily: fonts.body,
			fontWeight: fontWeights.normal,
			fontSize: fontSizes.md,
			padding: `${space[1]} 0`,
			minHeight: 0,
			textAlign: "left",
			backgroundColor: "transparent",

			"&:hover": {
				backgroundColor: "transparent",
				textDecoration: "underline",
			},
			"&:active:focus": {
				boxShadow: "none",
				outline: "none",
			},
			[mediaQueries.desktopAndAbove]: {
				fontWeight: fontWeights.medium,
				background: palette.highlight.main.background,
				color: palette.highlight.main.contrast,
				position: "fixed",
				bottom: space[5],
				right: space[5],

				padding: 0,
				minHeight: space[10],
				minWidth: space[10],
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				borderRadius: "99999em",
				zIndex: zIndices.docked,

				"&:hover": {
					background: palette.highlight.dark.background,
					color: palette.highlight.dark.contrast,
					textDecoration: "none",
				},
			},
			[mediaQueries.desktopWideAndAbove]: {
				padding: `${space[2]} ${space[6]}`,
			},
		},
		icon: {
			marginRight: space[2],
			[mediaQueries.desktopAndAbove]: {
				margin: 0,
			},
			[mediaQueries.desktopWideAndAbove]: {
				marginRight: space[2],
			},
		},
		text: {
			[mediaQueries.desktopAndAbove]: {
				display: "none",
			},
			[mediaQueries.desktopWideAndAbove]: {
				display: "inline-block",
			},
		},
	};
};

export { useFeedbackButtonStyles };
