/** @jsxImportSource @emotion/react */
import { useAvatarStyles } from "./avatarStyles";
import React from "react";
import Image from "next/legacy/image";
import { BoxBaseProps, useBoxStyles } from "@bilar/ui";
import { User } from "@bilar/models";
import avatarPlaceHolder from "@bilar/assets/img/icon-white-circle.svg";
import { useAppTranslation } from "@bilar/common";

type AvatarProps = {
	user: User;
	src: any; //string | StaticImageData;
	avatarPlaceHolder?: any; //string | StaticImageData;
} & BoxBaseProps &
	AvatarDefaultProps;

type AvatarDefaultProps = {
	imgWidth?: number;
	imgHeight?: number;
	align?: "center" | "left" | "right";
};

const defaultProps: AvatarDefaultProps = {
	imgWidth: 32,
	imgHeight: 32,
	align: "left",
};

const Avatar = (props: AvatarProps) => {
	const styles = useAvatarStyles(props);
	const { t } = useAppTranslation();
	const boxStyles = useBoxStyles(props);
	const { src, imgWidth, imgHeight, user } = props;

	return (
		<figure css={[boxStyles, styles.root]}>
			<Image
				src={
					src
						? src
						: props.avatarPlaceHolder
							? props.avatarPlaceHolder
							: avatarPlaceHolder
				}
				css={[styles.img]}
				alt={`${t("pictureOf")}: ${user?.name.first ?? "user"}`}
				width={imgWidth}
				height={imgHeight}
				layout="fixed"
				data-rp-mask="true"
			/>
		</figure>
	);
};

Avatar.defaultProps = defaultProps;

export { Avatar };
export type { AvatarProps };
