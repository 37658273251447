/** @jsxImportSource @emotion/react */
import {
	FloatingFocusManager,
	FloatingPortal,
	useMergeRefs,
} from "@floating-ui/react";
import { useListBoxContext } from "./ListBoxContext";
import { forwardRef, HTMLAttributes, PropsWithChildren } from "react";
import { useListBoxStyles } from "./listBoxStyles";
import { CSSObject } from "@emotion/react";
import { BaseProps, BoxProps, removeBoxProps, useBoxStyles } from "@bilar/ui";

type ListBoxPopoverProps = PropsWithChildren<{}> &
	HTMLAttributes<HTMLDivElement> &
	// We remove the margin props from the component because we control the margin with the offset prop instead.
	Omit<BoxProps, "m" | "mb" | "mx" | "my" | "ml" | "mt" | "mr" | "as"> &
	BaseProps;

export const ListBoxPopover = forwardRef<HTMLDivElement, ListBoxPopoverProps>(
	({ children, ...props }, propRef) => {
		const { state, floating, popoverWidth } = useListBoxContext();
		const boxStyles = useBoxStyles(props);
		const styles = useListBoxStyles(popoverWidth);
		const propsWithoutBoxProps = removeBoxProps(props);
		const ref = useMergeRefs([floating?.refs.setFloating, propRef]);

		return (
			<FloatingPortal>
				{state.isPopoverOpen && floating && (
					<FloatingFocusManager context={floating.context} modal={false}>
						<div
							{...propsWithoutBoxProps}
							ref={ref}
							tabIndex={-1}
							css={[styles, boxStyles, floating?.floatingStyles as CSSObject]}
						>
							{children}
						</div>
					</FloatingFocusManager>
				)}
			</FloatingPortal>
		);
	},
);

ListBoxPopover.displayName = "ListBoxPopover";
