/** @jsxImportSource @emotion/react */
import {
	Box,
	Button,
	Divider,
	FieldLabel,
	InvisibleButton,
	Panel,
	RadioButton,
	Stack,
	useFormWithYup,
	ValidationMessage,
	VehicleBodyRadio,
} from "@bilar/ui";

import { useAppTranslation } from "@bilar/common";
import {
	ColourSelector,
	StepVehicleDesignFormValues,
	useFormButtonStyles,
	useStepVehicleDesignStyles,
	useStepVehicleDesignValidation,
	useVehicleFormContext,
} from "@bilar/features";
import { FormEvent } from "react";

type StepVehicleDesignProps = {
	onBack: () => void;
	onContinue: () => void;
};

const StepVehicleDesign = (props: StepVehicleDesignProps) => {
	const { t } = useAppTranslation();
	const { formValues, setStepVehicleDesignValues } = useVehicleFormContext();
	const { designValidationSchema } = useStepVehicleDesignValidation();

	const { errors, values, touched, handleChange, handleSubmit } =
		useFormWithYup<StepVehicleDesignFormValues>({
			initialValues: formValues.stepVehicleDesignValues,
			validationSchema: designValidationSchema,
		});

	const styles = useStepVehicleDesignStyles(props);
	const buttonStyles = useFormButtonStyles();

	const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		handleSubmit(
			async () => {
				setStepVehicleDesignValues(values);
				// console.log("Submit step vehicle design", values);
				props.onContinue();
			},
			(err) => {
				console.warn("Validation errors in vehicle design step", err);
			},
		);
	};

	return (
		<Panel.Body>
			<form onSubmit={onSubmit}>
				<FieldLabel htmlFor={undefined}>{t("vehicleBodyType")}</FieldLabel>
				<div css={styles.bodyStylesContainer}>
					<VehicleBodyRadio
						bodyType="micro"
						group="vehicleBodyType"
						text={t("bodyType_micro")}
						checked={values.vehicleBodyType === "micro"}
						onChange={(name, checked) => handleChange("vehicleBodyType", name)}
					/>
					<VehicleBodyRadio
						bodyType="coupe"
						group="vehicleBodyType"
						text={t("bodyType_coupe")}
						checked={values.vehicleBodyType === "coupe"}
						onChange={(name, checked) => handleChange("vehicleBodyType", name)}
					/>
					<VehicleBodyRadio
						bodyType="sedan"
						group="vehicleBodyType"
						text={t("bodyType_sedan")}
						checked={values.vehicleBodyType === "sedan"}
						onChange={(name, checked) => handleChange("vehicleBodyType", name)}
					/>
					<VehicleBodyRadio
						bodyType="hatchback"
						group="vehicleBodyType"
						text={t("bodyType_hatchback")}
						checked={values.vehicleBodyType === "hatchback"}
						onChange={(name, checked) => handleChange("vehicleBodyType", name)}
					/>
					<VehicleBodyRadio
						bodyType="liftback"
						group="vehicleBodyType"
						text={t("bodyType_liftback")}
						checked={values.vehicleBodyType === "liftback"}
						onChange={(name, checked) => handleChange("vehicleBodyType", name)}
					/>
					<VehicleBodyRadio
						bodyType="station-wagon"
						group="vehicleBodyType"
						text={t("bodyType_station-wagon")}
						checked={values.vehicleBodyType === "station-wagon"}
						onChange={(name, checked) => handleChange("vehicleBodyType", name)}
					/>
					<VehicleBodyRadio
						bodyType="crossover"
						group="vehicleBodyType"
						text={t("bodyType_crossover")}
						checked={values.vehicleBodyType === "crossover"}
						onChange={(name, checked) => handleChange("vehicleBodyType", name)}
					/>
					<VehicleBodyRadio
						bodyType="suv"
						group="vehicleBodyType"
						text={t("bodyType_suv")}
						checked={values.vehicleBodyType === "suv"}
						onChange={(name, checked) => handleChange("vehicleBodyType", name)}
					/>
					<VehicleBodyRadio
						bodyType="pickup"
						group="vehicleBodyType"
						text={t("bodyType_pickup")}
						checked={values.vehicleBodyType === "pickup"}
						onChange={(name, checked) => handleChange("vehicleBodyType", name)}
					/>
					<VehicleBodyRadio
						bodyType="minivan"
						group="vehicleBodyType"
						text={t("bodyType_minivan")}
						checked={values.vehicleBodyType === "minivan"}
						onChange={(name, checked) => handleChange("vehicleBodyType", name)}
					/>
					<VehicleBodyRadio
						bodyType="van"
						group="vehicleBodyType"
						text={t("bodyType_van")}
						checked={values.vehicleBodyType === "van"}
						onChange={(name, checked) => handleChange("vehicleBodyType", name)}
					/>
					<VehicleBodyRadio
						bodyType="camper"
						group="vehicleBodyType"
						text={t("bodyType_camper")}
						checked={values.vehicleBodyType === "camper"}
						onChange={(name, checked) => handleChange("vehicleBodyType", name)}
					/>
				</div>
				<RadioButton
					text={t("other")}
					group="vehicleBodyType"
					checked={values.vehicleBodyType === "other"}
					layout="box"
					onChange={() => handleChange("vehicleBodyType", "other")}
				/>
				{errors.vehicleBodyType && (
					<Box mt={6}>
						<ValidationMessage error={errors.vehicleBodyType} />
					</Box>
				)}
				<Divider variant="solid" my={2} />
				<Stack orientation={"vertical"} spacing={4}>
					<ColourSelector
						value={values.colour || undefined}
						errors={
							touched.colour && errors.colour
								? t("selectVehicleColour")
								: undefined
						}
						onChange={(selectedColour) =>
							handleChange("colour", selectedColour)
						}
					/>
				</Stack>

				<Divider variant="solid" my={4} />

				<div css={buttonStyles.root}>
					<InvisibleButton
						type="button"
						onClick={props.onBack}
						css={buttonStyles.backButton}
					>
						{t("goBack")}
					</InvisibleButton>
					<Button
						type="submit"
						palette="secondary"
						paletteShade="dark"
						css={buttonStyles.continueButton}
					>
						{t("continueToNextStep")}
					</Button>
				</div>
			</form>
		</Panel.Body>
	);
};

export { StepVehicleDesign };
export type { StepVehicleDesignProps };
