import { CSSObject } from "@emotion/react";
import { SavingModalProps } from "./SavingModal";
import { useDesignTokens } from "@bilar/ui";

type SavingModalStyles = {
	blur: CSSObject;
	root: CSSObject;
};

const useSavingModalStyles = (props: SavingModalProps): SavingModalStyles => {
	const designTokens = useDesignTokens();
	const { mediaQueries, space, palette, zIndices } = designTokens;
	return {
		blur: {
			position: "fixed",
			inset: 0,
			backgroundColor: "rgba(0, 0, 0, 0.7)",
			height: "100dvh",
			display: "grid",
			placeItems: "center",
			zIndex: zIndices.modal,
		},
		root: {
			width: `calc(100% - ${space[8]})`,
			minHeight: space[24],
			border: `3px solid ${palette.primary.main.background}`,
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			padding: `${space[8]} ${space[8]}`,

			[mediaQueries.tabletAndAbove]: {
				width: space[128],
			},
		},
	};
};

export { useSavingModalStyles };
