import { useAppHeaderStyles } from "./appHeaderStyles";
import React, { MouseEvent, ReactNode } from "react";
import Link from "next/link";
import { Container, useBreakpoints, VisuallyHidden } from "@bilar/ui";
import { useAuthUser } from "@bilar/auth";
import { useAppTranslation } from "@bilar/common";
import { LanguageMenu } from "@bilar/layout";
import {
	MiniSearch,
	SignInModal,
	useSignInModalContext,
} from "@bilar/features";
import { AppNav } from "../AppNav";

type AppHeaderProps = {
	children?: ReactNode;
} & AppHeaderDefaultProps;

type AppHeaderDefaultProps = {};

const defaultProps: AppHeaderDefaultProps = {};

const AppHeader = (props: AppHeaderProps) => {
	const { user } = useAuthUser();
	const styles = useAppHeaderStyles(props);
	const { t } = useAppTranslation();
	const { setShowLoginModal } = useSignInModalContext();
	const { isTablet, isTabletSmall, isMobile, cssBreakpoints } =
		useBreakpoints();
	const displayMobileMenu = isMobile || isTabletSmall;

	const handleLoginClick = (e?: MouseEvent<HTMLAnchorElement>) => {
		e?.preventDefault();

		// router.push(router.pathname, routes.signIn, { shallow: true });
		setShowLoginModal(true);
	};

	const handleLoginModalDismiss = () => {
		setShowLoginModal(false);
		// router.push("/");
	};

	return (
		<>
			<header css={styles.root}>
				<Container css={styles.container}>
					<div css={styles.logo.root}>
						<Link href="/" as={"/"} title={t("goToHomePage")}>
							<h1 css={styles.logo.img}>
								<VisuallyHidden>{`Bílar.is - ${t("titleHome")}`}</VisuallyHidden>
							</h1>
						</Link>
					</div>
					<nav css={styles.nav.root}>
						<MiniSearch />
						<LanguageMenu />
						<AppNav user={user} onLoginClick={handleLoginClick} />
					</nav>
				</Container>
			</header>

			<SignInModal onDismiss={handleLoginModalDismiss} />
		</>
	);
};

AppHeader.defaultProps = defaultProps;

export { AppHeader };
export type { AppHeaderProps };
