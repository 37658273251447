import { breakpointWidths } from "./breakpoints";

type MediaQueriesTokens = {
	tabletSmallAndAbove: string;
	tabletAndAbove: string;
	desktopAndAbove: string;
	desktopWideAndAbove: string;
};
const mediaQueries: MediaQueriesTokens = {
	//phoneAndAbove: undefined,
	//*** phoneLandscapeOnly: `@media (min-width: ${576}px)`,
	tabletSmallAndAbove: `@media (min-width: ${breakpointWidths.tabletSmall}px)`,
	tabletAndAbove: `@media (min-width: ${breakpointWidths.tablet}px)`,
	//*** tabletLandscapeOnly: `@media (min-width: ${768}px)`,
	desktopAndAbove: `@media (min-width: ${breakpointWidths.desktop}px)`,
	desktopWideAndAbove: `@media (min-width: ${breakpointWidths.desktopWide}px)`,
};

export default mediaQueries;
export type { MediaQueriesTokens };
