/** @jsxImportSource @emotion/react */
import { SerializedEditorState } from "lexical";
import { useEffect, useState } from "react";
import { CSSObject } from "@emotion/react";
import { useRichText } from "@bilar/ui";
import { useEditorStyles } from "./useEditorStyles";

type ConditionalProps =
	| {
			limit?: number;
			type?: "text";
	  }
	| {
			type: "html";
			limit?: never;
	  };

type Props = {
	value: SerializedEditorState | null;
	css?: CSSObject;
} & ConditionalProps;

const ERROR_MESSAGE = "Error parsing content";

const limitText = (text: string, limit?: number) => {
	if (limit && text.length >= limit) {
		return `${text.substring(0, limit)}...`;
	}

	return text;
};

export const RichTextView = (props: Props) => {
	const { value, type = "html", limit, ...rest } = props;
	const [content, setContent] = useState<string>();
	const [error, setError] = useState<string>();
	const styles = useEditorStyles();
	const { getText, getHtml } = useRichText();

	useEffect(() => {
		if (value) {
			if (type === "html") {
				getHtml(value)
					.then(setContent)
					.catch((error) => {
						console.error(error);
						setError(ERROR_MESSAGE);
					});
			} else
				getText(value)
					.then(setContent)
					.catch((error) => {
						console.error(error);
						setError(ERROR_MESSAGE);
					});
		}
	}, [value, type]);

	if (error) {
		return <>{error}</>;
	}

	if (!value || !content) {
		return null;
	}

	if (type === "text") {
		return <>{limitText(content, limit)}</>;
	}

	return (
		<div
			{...rest}
			css={styles.richText}
			dangerouslySetInnerHTML={{ __html: content }}
		/>
	);
};
