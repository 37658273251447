/** @jsxImportSource @emotion/react */
import { FlexGrid } from "@bilar/ui";
import { useAppTranslation } from "@bilar/common";
import { VehicleMiniItem } from "@bilar/features";
import { ClassifiedVehicle } from "@bilar/models";

type LatestVehiclesProps = {
	data: ClassifiedVehicle[];
} & LatestVehiclesDefaultProps;

type LatestVehiclesDefaultProps = {
	count?: number;
};

const defaultProps: LatestVehiclesDefaultProps = {
	count: 6,
};

const LatestVehicles = (props: LatestVehiclesProps) => {
	const { t } = useAppTranslation();
	const { data } = props;

	return (
		<div>
			<FlexGrid spacing={64} mb={4}>
				{data.map((item, index) => (
					<FlexGrid.Item key={item.id} width={["100%", "50%", "33%"]}>
						<VehicleMiniItem data={item} />
					</FlexGrid.Item>
				))}
			</FlexGrid>
		</div>
	);
};

LatestVehicles.defaultProps = defaultProps;

export { LatestVehicles };
export type { LatestVehiclesProps };
