import { CSSObject } from "@emotion/react";
import { useDesignTokens } from "@bilar/ui";
import { ImageUploadListProps } from "./ImageUploadList";

type ImageUploadListStyles = {
	root: CSSObject;
	dropzone: CSSObject;
	dropZoneButton: CSSObject;
	fileList: CSSObject;
	fileItem: {
		root: CSSObject;
		wrapper: CSSObject;
		topControls: CSSObject;
		editButton: CSSObject;
		editButtonText: CSSObject;
		closeButton: CSSObject;
		spinner: CSSObject;
	};
	button: CSSObject;
	dialog: CSSObject;
};

const useImageUploadListStyles = (
	props?: ImageUploadListProps,
): ImageUploadListStyles => {
	const { palette, space, mediaQueries, fontWeights } = useDesignTokens();

	return {
		root: {
			backgroundColor: palette.neutral.lighter.background,
			borderRadius: space[4],
			padding: space[4],
		},
		dropzone: {
			transition: "all 350ms ease",
			padding: space[4],
			textAlign: "center",
			// cursor: "pointer",
			borderRadius: space[2],
			border: `1px dashed ${palette.neutral.light.background}`,
			"&:hover": {
				borderColor: palette.neutral.main.background,
			},
			[mediaQueries.desktopAndAbove]: {
				minHeight: space[96],
			},
		},
		dropZoneButton: {
			"&:hover": {
				backgroundColor: palette.white,
			},
		},
		fileList: {
			position: "relative",
			overflow: "hidden",
			display: "grid",
			gap: space[4],
			marginTop: props?.files.length ? space[4] : 0,
			gridTemplateColumns: `repeat(auto-fill, 200px)`, // 200px is the width of the fileItem TODO: use the props
		},
		fileItem: {
			root: {
				"&.placeholder": {
					opacity: 0,
				},
				"&.dragged-item": {
					opacity: "1 !important",
					".wrapper": {
						boxShadow: `0 0 10px 0 #0000009e`,
					},
					"&.is-dragging .wrapper": {
						transform: "scale(1.05)",
					},
				},
			},
			wrapper: {
				transition: "all 350ms ease",
				color: palette.neutral.main.contrast,
				backgroundColor: palette.neutral.darker.background,
				margin: "auto",
				backgroundSize: "contain",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "center",
				position: "relative",
				borderRadius: space[2],
				overflow: "clip",
				cursor: "grab",
				fontSize: "0.75rem",
				lineHeight: "1rem",
				fontWeight: 500,
				"& fieldset": {
					height: "100%",
					width: "50%",
				},
				"& legend": {
					display: "block",
					whiteSpace: "nowrap",
					overflow: "hidden",
					textOverflow: "ellipsis",
					maxWidth: 150, // NOTE: using percentage here will break the layout because of white-space: nowrap
				},
			},
			topControls: {
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				paddingTop: space[2],
				paddingLeft: space[2],
				paddingRight: space[2],
				paddingBottom: space[6], // So that the gradient covers more of the image
				background: `linear-gradient(0deg, rgba(0,0,0,0) 0%, ${palette.primary.dark.background} 80%)`,
			},
			closeButton: {
				width: space[6],
				height: space[6],
				color: palette.white,
				border: "none",
				cursor: "pointer",
			},
			editButton: {
				height: space[8],
				position: "absolute",
				bottom: 0,
				left: 0,
				right: 0,
				borderRadius: 0,
				borderBottomLeftRadius: space[2],
				borderBottomRightRadius: space[2],
				color: palette.neutral.darker.contrast,
				textTransform: "uppercase",
				backgroundColor: "rgba(51, 78, 104, 0.7)",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				borderTop: "1px solid transparent",
				cursor: "pointer",

				"&:focus-visible": {
					outline: "none",
					borderTop: "1px solid #fff",
					backgroundColor: palette.neutral.darker.background,
				},

				"&:hover": {
					backgroundColor: palette.neutral.darker.background,
				},
			},
			editButtonText: {
				marginRight: space[2],
				fontWeight: fontWeights.medium,
			},
			spinner: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			},
		},
		button: {
			backgroundColor: "rgba(0,0,0,0.73)",
			borderRadius: 99999,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			transition: "box-shadow 0.2s ease",
			"&:not(:disabled):hover": {
				boxShadow: `0 0 0 2px ${palette.neutral.main.contrast}`,
			},
			"&:disabled": {
				cursor: "grab",
			},
		},

		dialog: {
			height: "100vh",
			width: "100vw",
			margin: 0,
			".close-button": {
				display: "none", // Hide the close button in the dialog because we will use Pintura's "done" button
			},
		},
	};
};

export { useImageUploadListStyles };
export type { ImageUploadListProps };
