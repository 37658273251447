import { Breakpoints, BreakpointsWidths } from "./types";

const breakpointWidths: BreakpointsWidths = {
	phone: 375,
	tabletSmall: 640,
	tablet: 768,
	desktop: 1024,
	desktopWide: 1280,
};

// TODO: We might need to do a change here for chakra update
// https://chakra-ui.com/docs/migration

const breakpoints: Breakpoints = [
	`${breakpointWidths.phone}px`,
	`${breakpointWidths.tablet}px`,
	`${breakpointWidths.desktop}px`,
	`${breakpointWidths.desktopWide}px`,
];

// aliases
// breakpoints.sm = breakpoints[0]
// breakpoints.md = breakpoints[1]
// breakpoints.lg = breakpoints[2]
// breakpoints.xl = breakpoints[3]

export default breakpoints;
export { breakpointWidths };
