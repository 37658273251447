/** @jsxImportSource @emotion/react */
import { ReactNode } from "react";
import { useDesignTokens } from "@bilar/ui";

type Props = {
	children: ReactNode;
};

export const Placeholder = (props: Props) => {
	const { size, palette } = useDesignTokens();

	return (
		<div
			css={{
				fontSize: 15,
				color: palette.neutral.main.background,
				overflow: "hidden",
				position: "absolute",
				textOverflow: "ellipsis",
				top: 2,
				left: size(3),
				right: size(3),
				userSelect: "none",
				whiteSpace: "nowrap",
				display: "inline-block",
				pointerEvents: "none",
			}}
		>
			{props.children}
		</div>
	);
};
