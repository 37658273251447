import { useDesignTokens } from "../../styling";
import { MessageDefaultProps, MessageProps } from "./Message";
import { CSSObject } from "@emotion/react";

type MessageStyles = {
	root: CSSObject;
	iconWrapper: CSSObject;
	heading: CSSObject;
	body: CSSObject;
};

const useMessageStyles = (
	props: MessageProps & Required<MessageDefaultProps>,
): MessageStyles => {
	const tokens = useDesignTokens();
	const { size, borders, radii, palette: paletteTokens } = tokens;

	const paletteMap = {
		neutral: {
			backgroundColor: paletteTokens.neutral.lighter.background,
			color: paletteTokens.neutral.lighter.contrast,
			borderColor: paletteTokens.neutral.light.background,
			iconColor: paletteTokens.neutral.main.background,
		},
		primary: {
			backgroundColor: paletteTokens.primary.lighter.background,
			color: paletteTokens.primary.lighter.contrast,
			borderColor: paletteTokens.primary.light.background,
			iconColor: paletteTokens.primary.main.background,
		},
		secondary: {
			backgroundColor: paletteTokens.secondary.lighter.background,
			color: paletteTokens.secondary.lighter.contrast,
			borderColor: paletteTokens.secondary.light.background,
			iconColor: paletteTokens.secondary.main.background,
		},
		highlight: {
			backgroundColor: paletteTokens.highlight.lighter.background,
			color: paletteTokens.highlight.lighter.contrast,
			borderColor: paletteTokens.highlight.light.background,
			iconColor: paletteTokens.highlight.main.background,
		},
		error: {
			backgroundColor: paletteTokens.error.lighter.background,
			color: paletteTokens.error.lighter.contrast,
			borderColor: paletteTokens.error.light.background,
			iconColor: paletteTokens.error.main.background,
		},
		warning: {
			backgroundColor: paletteTokens.warning.lighter.background,
			color: paletteTokens.warning.lighter.contrast,
			borderColor: paletteTokens.warning.light.background,
			iconColor: paletteTokens.warning.main.background,
		},
		info: {
			backgroundColor: paletteTokens.info.lighter.background,
			color: paletteTokens.info.lighter.contrast,
			borderColor: paletteTokens.info.light.background,
			iconColor: paletteTokens.info.main.background,
		},
		success: {
			backgroundColor: paletteTokens.success.lighter.background,
			color: paletteTokens.success.lighter.contrast,
			borderColor: paletteTokens.success.light.background,
			iconColor: paletteTokens.success.main.background,
		},
		grey: {
			backgroundColor: paletteTokens.grey.lighter.background,
			color: paletteTokens.grey.lighter.contrast,
			borderColor: paletteTokens.grey.light.background,
			iconColor: paletteTokens.grey.main.background,
		},
	};

	const palette = paletteMap[props.palette];

	const template = () => {
		// If there is an icon, no heading and only content, we display the icon on the left side of the content.
		if (props.icon && props.children && !props.heading) {
			return `
				'icon body'
			`;
		}

		return `
				'icon heading'
				'empty body'
			`;
	};

	return {
		root: {
			display: "grid",
			gridTemplate: template(),
			columnGap: props.icon ? size(4) : 0,
			rowGap: props.heading && props.children ? size(4) : 0,
			gridTemplateColumns: "min-content 1fr",
			gridTemplateRows: "auto 1fr",
			padding: size(4),
			borderRadius: radii.lg,
			borderTopWidth: size(1),
			borderStyle: "solid",
			borderColor: palette.borderColor,
			color: palette.color,
			backgroundColor: palette.backgroundColor,
		},
		iconWrapper: {
			gridArea: "icon",
			paddingTop: 6,
			svg: { fill: palette.iconColor },
		},
		heading: {
			gridArea: "heading",
			alignSelf: "center",
			color: palette.color,
		},
		body: {
			gridArea: "body",
			alignSelf: "center",
		},
	};
};

export { useMessageStyles };
