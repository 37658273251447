import {
	ClassifiedVehicle,
	ClassifiedVehicleDbModel,
	User,
	VehicleImage,
} from "@bilar/models";
import {
	deleteImagesFromDb,
	deleteVehicleFromDb,
	FirestoreRequestPromise,
	isExpiredInUtc,
	saveVehicleToDb,
	useVehicleFormContext,
	VehicleFormValues,
} from "@bilar/features";
import { Timestamp } from "firebase/firestore";
import {
	deleteObject,
	getDownloadURL,
	getMetadata,
	getStorage,
	listAll,
	ref,
	UploadTaskSnapshot,
} from "firebase/storage";
import { useCurrency } from "@bilar/ui";
import initFirebase from "@bilar/firebase/initFirebase";
import { useUploadFile } from "react-firebase-hooks/storage";
import { useEffect } from "react";
import { EditableImage, EditableImageType } from "@bilar/types";
import { convertToVehicleImage, getImageFileName } from "@bilar/utils";
import { addBreadcrumb } from "@sentry/core";

type UploadProgress = {
	name: string;
	totalBytes: number;
	totalTransferred: number;
};

let currentUploadProgress: UploadProgress[] = [];

const useVehicleClassifiedsDb = () => {
	const firebaseApp = initFirebase();
	const { getCurrencyUnit, getCurrencyCode } = useCurrency();
	const storage = getStorage(firebaseApp);
	const [uploadFile, uploading, snapshot, error] = useUploadFile();
	const { uploadProgress, setUploadProgress } = useVehicleFormContext();

	const initCurrentUploadProgress = (images: EditableImage<File>[]) => {
		currentUploadProgress = [];
		let totalBytes = 0;
		let numberOfFiles = 0;

		for (const image of images) {
			currentUploadProgress.push({
				name: getImageFileName(image, "original"),
				totalBytes: image.original.size,
				totalTransferred: 0,
			});

			numberOfFiles++;

			if (image.processed) {
				currentUploadProgress.push({
					name: getImageFileName(image, "processed"),
					totalBytes: image.processed.size,
					totalTransferred: 0,
				});
				numberOfFiles++;
			}

			totalBytes = totalBytes + image.original.size + image.processed!.size;
		}

		setUploadProgress({
			numberOfFiles: numberOfFiles,
			totalBytes: totalBytes,
			bytesTransferred: 0,
		});
	};

	const updateProgress = (snapshot: UploadTaskSnapshot) => {
		// TODO: we now have separate filenames for original and processed images, so we might not need the metadata anymore
		const org = currentUploadProgress.find(
			(p) => p.name === snapshot.metadata.customMetadata?.name,
		);

		const processed = currentUploadProgress.find(
			(p) => p.name === snapshot.metadata.customMetadata?.name,
		);

		if (snapshot.totalBytes === org?.totalBytes) {
			setUploadProgress({
				numberOfFiles: uploadProgress.numberOfFiles,
				totalBytes: uploadProgress.totalBytes,
				bytesTransferred:
					uploadProgress.bytesTransferred +
					(snapshot.bytesTransferred - org.totalTransferred),
			});

			org.totalTransferred = snapshot.bytesTransferred;
		} else if (snapshot.totalBytes === processed?.totalBytes) {
			setUploadProgress({
				numberOfFiles: uploadProgress.numberOfFiles,
				totalBytes: uploadProgress.totalBytes,
				bytesTransferred:
					uploadProgress.bytesTransferred +
					(snapshot.bytesTransferred - processed.totalTransferred),
			});

			processed.totalTransferred = snapshot.bytesTransferred;
		}
	};

	const deleteFile = async (path: string) => {
		const storage = getStorage();
		const desertRef = ref(storage, path);

		return deleteObject(desertRef);
	};

	const deleteAllFilesWithId = async (path: string, fileId: string) => {
		addBreadcrumb({
			category: "useVehicleClassifiedsDb:deleteAllFilesWithId",
			message: `Deleting all files in path ${path} with id ${fileId}`,
			level: "info",
		});

		const storage = getStorage();
		const folderRef = ref(storage, path);

		// List all files in the directory
		const res = await listAll(folderRef);

		// Filter files that start with the fileId
		const filesToDelete = res.items.filter((item) => {
			return item.name.startsWith(fileId);
		});

		// Delete each file
		for (const fileRef of filesToDelete) {
			await deleteObject(fileRef);
		}
	};

	useEffect(() => {
		// console.log("uploading...", uploading);
	}, [uploading]);

	useEffect(() => {
		if (snapshot && snapshot.state === "running") {
			updateProgress(snapshot);
		}
	}, [snapshot]);

	const uploadImage = async (
		imageId: string,
		file: File,
		fullPath: string,
		namePostfix: EditableImageType,
	) => {
		const fileRef = ref(storage, fullPath);

		await uploadFile(fileRef, file, {
			customMetadata: {
				// This is used only for the upload progress
				name: imageId + "-" + namePostfix,
			},
		});
		const metaData = await getMetadata(fileRef);
		const downloadUrl = await getDownloadURL(fileRef);

		return {
			downloadUrl,
			metaData,
		};
	};

	const saveVehicle = async (
		model: ClassifiedVehicleDbModel,
	): Promise<FirestoreRequestPromise> => {
		return new Promise<FirestoreRequestPromise>(async (resolve, reject) => {
			saveVehicleToDb(model)
				.then((res) => resolve(res))
				.catch((res) => reject(res));
		});
	};

	const deleteVehicle = async (user: User, vehicle: ClassifiedVehicle) => {
		return new Promise<boolean>(async (resolve, reject) => {
			// Primary deletion, we don't wrap this in a try catch
			await deleteVehicleFromDb(vehicle.id);

			// Secondary deletion, we don't want to block the user if this fails
			try {
				// Delete images from db
				await deleteImagesFromDb(
					vehicle.id,
					vehicle.images.map((i) => i.id),
				);

				// Delete images from storage
				const vehicleImgFolder = getVehicleImagePath(user, vehicle.id);
				for (const image of vehicle.images) {
					await deleteFile(`${vehicleImgFolder}/${image.id}`);
				}
			} catch (e) {
				console.log("error deleting", e);
			} finally {
				resolve(true);
			}
		});
	};

	const getVehicleImagePath = (user: User, classifiedAdId: string) => {
		return `${user.userId}/images/${classifiedAdId}`;
	};

	const uploadImagesToStorage = async (
		classifiedAdId: string,
		images: EditableImage<File>[],
		user: User,
	): Promise<VehicleImage[]> => {
		if (images.length <= 0) return [];

		const vehicleImages: VehicleImage[] = [];

		let orderNumber = 0;
		initCurrentUploadProgress(images);

		for (const image of images) {
			const folderPath = getVehicleImagePath(user, classifiedAdId);

			const originalUpload = await uploadImage(
				image.id,
				image.original,
				`${folderPath}/${getImageFileName(image, "original")}`,
				"original",
			);
			const processedUpload = await uploadImage(
				image.id,
				image.processed!,
				`${folderPath}/${getImageFileName(image, "processed")}`,
				"processed",
			);

			const dbImage = convertToVehicleImage(
				image,
				folderPath,
				orderNumber,
				{
					url: originalUpload.downloadUrl,
					contentType: originalUpload.metaData.contentType,
				},
				{
					url: processedUpload.downloadUrl,
					contentType: processedUpload.metaData.contentType,
				},
			);

			orderNumber++;
			vehicleImages.push(dbImage);
		}

		// Sort method to make sure orderNumber starts with 0
		// Just to make sure the orderNumber is correct if the images were uploaded during various times.
		const sortMethod = (a: VehicleImage, b: VehicleImage) => {
			if (a.orderNumber! > b.orderNumber!) return 1;
			if (a.orderNumber! < b.orderNumber!) return -1;
			return 0;
		};

		return vehicleImages.sort(sortMethod);
	};

	const toVehicleDbModel = (
		classifiedId: string,
		formValues: VehicleFormValues,
		images: VehicleImage[],
		user: User,
	): ClassifiedVehicleDbModel => {
		const isNewAd = !formValues.globalValues.classifiedAd?.id;
		const createdAt = Timestamp.now().toDate();

		// TODO: Set a date depending on how much long the user buys the ad for.
		let isExpired = false;
		let expiresDateJs: Date = new Date(createdAt);
		expiresDateJs.setDate(expiresDateJs.getDate() + 365);

		if (!isNewAd) {
			expiresDateJs = new Date(
				formValues.globalValues.classifiedAd!.expiresAt!,
			);
			isExpired = isExpiredInUtc(expiresDateJs.toISOString());
		}

		const {
			stepBasicInfoValues,
			stepVehicleDesignValues,
			stepVehicleDetailsValues,
		} = formValues;
		const { vehicleModel, vehicleBrand, vehicleYear, price, willingToTrade } =
			stepBasicInfoValues;

		const { colour, vehicleBodyType } = stepVehicleDesignValues;

		const {
			vehicleFuelType,
			vehicleTransmissionType,
			vehicleInspected,
			vehicleInspectedYear,
			vehicleInspectedMonth,
			vehicleNumberOfDoors,
			vehicleMileage,
			vehicleNumberOfSeats,
			vehicleDescription,
		} = stepVehicleDetailsValues;

		let vehicleYearNumber = 0;
		if (vehicleYear) {
			vehicleYearNumber = parseInt(vehicleYear.value);
		}

		const dbModel: ClassifiedVehicleDbModel = {
			id: classifiedId,
			user: {
				userId: user.userId,
				phone: user.phone,
				email: user.email,
				name: user.name,
				profileImageUrl: user.profileImageUrl,
			},
			contactEmail: user.email, // TODO: Get from form values
			contactPhone: user.phone, // TODO: Get from form values
			hidePhoneNumber: false,
			hideEmail: true,
			updatedAt: createdAt.toString(),
			expiresAt: expiresDateJs.toString(),
			expired: isExpired,
			published: true,
			description: vehicleDescription ?? null,
			mainImageUrl: images[0]?.processed.downloadUrl,
			images: images.length > 0 ? images : [],
			price: {
				amount: getCurrencyUnit(price?.toString() ?? ""),
				currency: getCurrencyCode(),
			},
			willingToTrade: willingToTrade,
			vehicleInfo: {
				// TODO: Maybe we should use a translation key if vehicleBrand is null?
				brandId: vehicleBrand ? vehicleBrand.value : "",
				brandName: vehicleBrand ? vehicleBrand.label : "",
				modelId: vehicleModel ? vehicleModel.value : "",
				modelName: vehicleModel ? vehicleModel.label : "",
				colour: colour ? colour.name : null,
				mileage: vehicleMileage
					? {
							mileage: vehicleMileage,
							metricName: "km",
						}
					: null,
				year: vehicleYearNumber,
				fuelType: vehicleFuelType ?? null,
				transmissionType: vehicleTransmissionType ?? null,
				numberOfDoors: vehicleNumberOfDoors ?? null,
				numberOfSeats: vehicleNumberOfSeats ?? null,
				bodyType: vehicleBodyType ?? null,
				inspected:
					vehicleInspected && vehicleInspectedYear?.value
						? {
								year: parseInt(vehicleInspectedYear?.value),
								month: vehicleInspectedMonth
									? parseInt(vehicleInspectedMonth.value)
									: null,
							}
						: null,
			},
		};

		if (isNewAd) {
			dbModel.createdAt = createdAt.toString();
		}

		return dbModel;
	};

	return {
		saveVehicle,
		deleteVehicle,
		toVehicleDbModel,
		uploadImagesToStorage,
		deleteFile,
		deleteAllFilesWithId,
		getVehicleImagePath,
	};
};

export { useVehicleClassifiedsDb };
