import { uuid } from "short-uuid";
import {
	ClassifiedVehicle,
	ClassifiedVehicleUser,
	FuelType,
	VehicleBodyType,
	VehiclePrice,
} from "@bilar/models";
import { isExpiredInUtc } from "@bilar/features";

// User generation function
const createUser = (): ClassifiedVehicleUser => {
	const names = ["John", "Jane", "Bob", "Alice", "Charlie", "Sophia"];
	const middleNames = ["L.", "M.", "N."];
	const lastNames = ["Doe", "Smith", "Johnson", "Brown", "Taylor"];

	const name = {
		first: names[Math.floor(Math.random() * names.length)],
		middle: middleNames[Math.floor(Math.random() * middleNames.length)],
		last: lastNames[Math.floor(Math.random() * lastNames.length)],
	};

	return {
		userId: uuid(),
		name: name,
		phone: {
			countryCode: "+1",
			internationalNumber: "+11234567890",
			nationalNumber: "1234567890",
			isVerified: Math.random() > 0.5,
		},
		email: {
			address: `${name.first.toLowerCase()}.${name.last.toLowerCase()}@example.com`,
			isVerified: Math.random() > 0.5,
		},
		// profileImageUrl: `https://example.com/path/to/image/${uuid()}.jpg`,
	};
};

const generateRandomImageUrl = (): string => {
	return `https://firebasestorage.googleapis.com/v0/b/bilar-staging.appspot.com/o/${uuid()}%2Fimages%2F${uuid()}%2F${uuid()}-processed?alt=media&token=${uuid()}`;
};

const createVehiclePrice = (): VehiclePrice => {
	return {
		amount: Math.floor(Math.random() * (20000000 - 100000) + 100000),
		currency: "ISK",
		offer: Math.random() > 0.5,
	};
};

type VehicleMileage = {
	mileage: number;
	metricName: string | null; // km or miles
} | null;

const createVehicleMileage = (): VehicleMileage => {
	if (Math.random() > 0.9) {
		// 10% chance of null mileage
		return null;
	}
	return {
		mileage: Math.floor(Math.random() * 100000),
		metricName: Math.random() > 0.5 ? "km" : "miles",
	};
};

const createInspectedDate = (): {
	year: number;
	month: number | null;
} | null => {
	if (Math.random() < 0.7) {
		// Let's assume a 70% chance the vehicle has been inspected.
		return {
			year: Math.floor(Math.random() * (2023 - 2000) + 2000), // Random year between 2000 and 2023
			month: Math.random() > 0.1 ? Math.floor(Math.random() * 12) + 1 : null, // 10% chance for month to be null
		};
	} else {
		return null;
	}
};

const createBodyType = (): string | null => {
	const bodyTypes = ["sedan", "suv", "coupe", "convertible", "wagon"];

	// We can adjust this percentage if needed
	if (Math.random() > 0.9) {
		// 10% chance to return null
		return null;
	} else {
		return bodyTypes[Math.floor(Math.random() * bodyTypes.length)];
	}
};

const createExpiresAt = (): string => {
	const today = new Date();
	// Determine a random number of days to add/subtract (let's say between 1 and 30 days for this example)
	const randomDays = Math.floor(Math.random() * 30) + 1;

	if (Math.random() > 0.5) {
		// 50% chance to set a future date
		today.setDate(today.getDate() + randomDays);
	} else {
		// 50% chance to set a past date (expired)
		today.setDate(today.getDate() - randomDays);
	}

	return today.toISOString();
};

// ClassifiedVehicle generation function
const createClassifiedVehicle = (): ClassifiedVehicle => {
	const brands = [
		{ name: "Mercedes Benz", id: "mercedes-benz" },
		{ name: "Honda", id: "honda" },
		{ name: "Toyota", id: "toyota" },
	];
	const models = [
		{ name: "Accord", id: "accord" },
		{ name: "CLS", id: "cls" },
		{ name: "Camry", id: "camry" },
	];
	const selectedBrand = brands[Math.floor(Math.random() * brands.length)];
	const selectedModel = models[Math.floor(Math.random() * models.length)];

	const user = createUser();

	const fuelTypes: FuelType[] = [
		"gasoline",
		"diesel",
		"hybrid",
		"electric",
		"plug-in-hybrid",
		"methane",
		"hydrogen",
		"other",
	];

	const expirationDate = createExpiresAt();

	return {
		id: uuid(),
		user: user,
		contactEmail: user.email,
		contactPhone: user.phone,
		hideEmail: Math.random() > 0.5,
		hidePhoneNumber: Math.random() > 0.5,
		createdAt: new Date().toISOString(),
		updatedAt: new Date().toISOString(),
		expiresAt: expirationDate,
		expired: isExpiredInUtc(new Date(expirationDate).toISOString()),
		price: createVehiclePrice(),
		willingToTrade: Math.random() > 0.5,
		description: null, // If you want to add random generation for description, replace this
		mainImageUrl: generateRandomImageUrl(),
		images: [], // If you want to add random generation for images, replace this
		vehicleInfo: {
			brandId: selectedBrand.id,
			brandName: selectedBrand.name,
			modelName: selectedModel.name,
			modelId: selectedModel.id,
			year: Math.floor(Math.random() * (2023 - 2000) + 2000),
			mileage: createVehicleMileage(),
			fuelType: fuelTypes[Math.floor(Math.random() * 4)],
			transmissionType: ["manual", "automatic"][Math.floor(Math.random() * 2)],
			numberOfSeats: [2, 4, 5, 7][Math.floor(Math.random() * 4)],
			numberOfDoors: [2, 4, 5][Math.floor(Math.random() * 3)],
			bodyType: createBodyType() as VehicleBodyType,
			colour: ["black", "white", "red", "blue", "silver", "grey", "green"][
				Math.floor(Math.random() * 7)
			],
			inspected: createInspectedDate(),
		},
		published: Math.random() > 0.5,
		sold: Math.random() > 0.5,
	};
};

// Generate seed data
const users = Array(10)
	.fill(null)
	.map(() => createUser());

export const createDummyVehicles = (count: number) => {
	return Array(count)
		.fill(null)
		.map(() => createClassifiedVehicle());
};
