import { CSSObject } from "@emotion/react";
import { VehicleBodyProps } from "./VehicleBody";
import { useDesignTokens } from "@bilar/ui";
import coupe from "../../assets/img/vehicle-types/coupe.svg";
import crossover from "../../assets/img/vehicle-types/crossover.svg";
import cabriolet from "../../assets/img/vehicle-types/cabriolet.svg";
import hatchback from "../../assets/img/vehicle-types/hatchback.svg";
import liftback from "../../assets/img/vehicle-types/liftback.svg";
import suv from "../../assets/img/vehicle-types/suv.svg";
import van from "../../assets/img/vehicle-types/van.svg";
import camper from "../../assets/img/vehicle-types/camper.svg";
import micro from "../../assets/img/vehicle-types/micro.svg";
import minivan from "../../assets/img/vehicle-types/minivan.svg";
import sedan from "../../assets/img/vehicle-types/sedan.svg";
import stationWagon from "../../assets/img/vehicle-types/station-wagon.svg";
import pickUp from "../../assets/img/vehicle-types/pickup.svg";

type VehicleBodyStyles = {
	root: CSSObject;
	image: CSSObject;
};

const getImage = (props: VehicleBodyProps) => {
	const { bodyType } = props;
	switch (bodyType) {
		case "micro":
			return micro;
		case "coupe":
			return coupe;
		case "crossover":
			return crossover;
		case "convertible":
			return cabriolet;
		case "hatchback":
			return hatchback;
		case "liftback":
			return liftback;
		case "suv":
			return suv;
		case "van":
			return van;
		case "minivan":
			return minivan;
		case "sedan":
			return sedan;
		case "station-wagon":
			return stationWagon;
		case "pickup":
			return pickUp;
		case "camper":
			return camper;
		default:
			return sedan;
	}
};

const useVehicleBodyStyles = (props: VehicleBodyProps): VehicleBodyStyles => {
	const {
		palette = "secondary",
		paletteShade = "main",
		size = 8,
		align = "left",
	} = props;
	const designTokens = useDesignTokens();
	const { space, palette: dtPalette } = designTokens;

	const img = getImage(props);
	const getHorizontalAlignment = () => {
		switch (align) {
			case "left":
				return "0%";
			case "center":
				return "50%";
			case "right":
				return "100%";
			default:
				return "0%";
		}
	};

	const maskPositionX = getHorizontalAlignment();

	return {
		root: {
			position: "relative",
			display: "inline-flex",
			minWidth: `calc(${space[size]} * 3)`,
		},
		image: {
			maskImage: `url(${img}) no-repeat ${maskPositionX} 50%`,
			mask: `url(${img}) no-repeat ${maskPositionX} 50%`,
			maskSize: "contain",
			backgroundColor: dtPalette[palette][paletteShade].background,
			height: space[size],
			width: "100%",
		},
	};
};

export { useVehicleBodyStyles };
