/** @jsxImportSource @emotion/react */
import { useMobileNavStyles, useProfileMenuStyles } from "./mobileNavStyles";
import React, { MouseEvent, useEffect } from "react";
import { User } from "@bilar/models";
import {
	Avatar,
	Divider,
	FaIcon,
	Heading,
	Icon,
	Menu,
	NextLink,
	SpacingSize,
	Stack,
	useBoundedScroll,
	useBreakpoints,
} from "@bilar/ui";
import { routes } from "@bilar/config";
import { useAppTranslation } from "@bilar/common";
import {
	faCirclePlus,
	faHouse,
	faMagnifyingGlass,
} from "@fortawesome/pro-regular-svg-icons";
import { useRouter } from "next/router";
import { CSSObject } from "@emotion/react";
import { faBars, faCars, faLock } from "@fortawesome/pro-solid-svg-icons";
import avatarPlaceHolder from "@bilar/assets/img/logo-green-car-only.svg";
import { MdPowerSettingsNew } from "react-icons/md";
import { useAuthManagement } from "@bilar/auth";
import { useSearch } from "@bilar/features";
import { motion, useTransform } from "framer-motion";

type MobileNavProps = {
	user?: User;
	onLoginClick: (e?: MouseEvent<HTMLAnchorElement>) => void;
};

const MobileNav = (props: MobileNavProps) => {
	const { user, onLoginClick } = props;
	const styles = useMobileNavStyles(props);
	const profileStyles = useProfileMenuStyles();
	const { t } = useAppTranslation();
	const { pathname, push } = useRouter();
	const { signOut } = useAuthManagement();
	const { searchOpen, setSearchOpen } = useSearch();
	const { isTabletSmall, isMobile } = useBreakpoints();
	const [shouldRender, setShouldRender] = React.useState(true);
	const iconSize: SpacingSize = 6;
	let { scrollYBoundedProgress } = useBoundedScroll(300);
	let scrollYBoundedProgressThrottled = useTransform(
		scrollYBoundedProgress,
		[0, 0.3, 1],
		[0, 0, 1],
	);

	const navPixelSize = 72;
	let height = useTransform(
		scrollYBoundedProgressThrottled,
		[0, 1],
		[navPixelSize, 0],
	);
	let opacity = useTransform(scrollYBoundedProgressThrottled, [0, 1], [1, 0]);

	useEffect(() => {
		if (isTabletSmall || isMobile) {
			setShouldRender(true);
		} else {
			setShouldRender(false);
		}
	}, [isTabletSmall, isMobile]);

	const getActiveStyles = (route: string): CSSObject | undefined => {
		return pathname === route ? styles.linkActive : undefined;
	};

	const handleToggleSearch = (e: any) => {
		e.preventDefault();
		setSearchOpen(!searchOpen);
	};

	if (!shouldRender) return null;

	return (
		<motion.div css={styles.root} style={{ height, opacity }}>
			<ul css={styles.list}>
				<li css={[styles.listItem]}>
					<NextLink href={routes.home} onClick={() => setSearchOpen(false)}>
						<span css={[styles.link, getActiveStyles(routes.home)]}>
							<FaIcon icon={faHouse} css={styles.icon} size={iconSize} />
							<span>{t("navHome")}</span>
						</span>
					</NextLink>
				</li>
				<li css={styles.listItem}>
					<NextLink
						href={routes.vehicleClassifieds.search}
						onClick={handleToggleSearch}
					>
						<span
							css={[
								styles.link,
								getActiveStyles(routes.vehicleClassifieds.search),
							]}
						>
							<FaIcon
								icon={faMagnifyingGlass}
								css={styles.icon}
								size={iconSize}
							/>
							<span>{t("navSearch")}</span>
						</span>
					</NextLink>
				</li>
				<li css={styles.listItem}>
					<NextLink
						href={routes.vehicleClassifieds.new}
						onClick={() => setSearchOpen(false)}
					>
						<span
							css={[
								styles.link,
								getActiveStyles(routes.vehicleClassifieds.new),
							]}
						>
							<FaIcon icon={faCirclePlus} css={styles.icon} size={iconSize} />
							<span>{t("navNewAd")}</span>
						</span>
					</NextLink>
				</li>
				<li css={[styles.listItem, styles.listProfileItem]}>
					{user === undefined ? (
						<Menu id="mainMenu">
							<Menu.ButtonInvisible aria-label={t("menu")} css={styles.link}>
								<FaIcon icon={faBars} css={styles.icon} size={iconSize} />
								<span>{t("menu")}</span>
							</Menu.ButtonInvisible>
							<Menu.List css={profileStyles.root}>
								<Menu.List.TextItem
									onSelect={() => onLoginClick()}
									css={profileStyles.item}
								>
									<NextLink
										href={routes.signIn}
										css={profileStyles.itemLink}
										title={t("signInOrSignup")}
										onClick={onLoginClick}
									>
										<Stack
											orientation="horizontal"
											horizontalAlignment="center"
										>
											<FaIcon icon={faLock} size={6} mr={6} />
											{t("signInSignup")}
										</Stack>
									</NextLink>
								</Menu.List.TextItem>
							</Menu.List>
						</Menu>
					) : (
						<Menu id="mainMenu">
							<Menu.ButtonInvisible aria-label={t("menu")} css={styles.link}>
								<Avatar
									src={user.profileImageUrl}
									avatarPlaceHolder={avatarPlaceHolder}
									user={user}
									imgWidth={24}
									imgHeight={24}
									align="center"
								/>

								<span>{t("menu")}</span>
							</Menu.ButtonInvisible>
							<Menu.List css={profileStyles.root}>
								<Menu.List.TextItem
									onSelect={() => push(routes.profile.index)}
									css={profileStyles.item}
								>
									<NextLink
										href={routes.profile.index}
										css={profileStyles.itemLink}
									>
										<div css={profileStyles.profileButton}>
											<Avatar
												src={user.profileImageUrl}
												user={user}
												imgWidth={48}
												imgHeight={48}
											/>
											<div>
												<Heading
													as="span"
													size="small"
													css={{ whiteSpace: "nowrap" }}
													my={0}
													data-rp-mask="true"
												>
													{`${user.name.first} ${user.name.last}`}
												</Heading>
												<p css={{ whiteSpace: "nowrap", margin: 0 }}>
													{t("seeYourProfile")}
												</p>
											</div>
										</div>
									</NextLink>
								</Menu.List.TextItem>
								<Divider my={2} />
								<Menu.List.TextItem
									onSelect={() => push(routes.profile.classifieds)}
									css={styles.listItem}
								>
									<NextLink
										href={routes.profile.classifieds}
										css={profileStyles.itemLink}
									>
										<Stack
											orientation="horizontal"
											horizontalAlignment="center"
										>
											<FaIcon icon={faCars} size={6} mr={6} />
											{t("myClassifieds")}
										</Stack>
									</NextLink>
								</Menu.List.TextItem>
								<Divider my={2} />
								<Menu.List.Item
									as="button"
									css={[profileStyles.item, profileStyles.signOutButton]}
									onSelect={() => signOut()}
								>
									<span>
										<Icon icon={<MdPowerSettingsNew />} size={6} mr={2} />
										{t("signOut")}
									</span>
								</Menu.List.Item>
							</Menu.List>
						</Menu>
					)}
				</li>
			</ul>
		</motion.div>
	);
};

export { MobileNav };
export type { MobileNavProps };
