import { CSSObject } from "@emotion/react";
import { VehicleSearchProps } from "./VehicleSearch";
import { SpacingSize, useDesignTokens } from "@bilar/ui";

type VehicleSearchStyles = {
	root: CSSObject;
	innerWrapper: CSSObject;
	header: CSSObject;
	tabs: CSSObject;
	tabItem: CSSObject;
	tabItemActive: CSSObject;
	form: CSSObject;
	content: CSSObject;
	footer: CSSObject;
	clearButton: CSSObject;
	closeButton: CSSObject;
	searchButton: CSSObject;
};

const useVehicleSearchStyles = (
	props: VehicleSearchProps,
): VehicleSearchStyles => {
	const designTokens = useDesignTokens();
	const {
		fontWeights,
		radii,
		size,
		palette,
		zIndices,
		mediaQueries,
		bordersNew,
	} = designTokens;

	const padding: SpacingSize = 6;
	const tabsHeight: SpacingSize = 16;
	const sideMargin: SpacingSize = 4;

	return {
		root: {
			position: "fixed",
			inset: 0,
			zIndex: zIndices.popover,
			backgroundColor: palette.grey["100"].background,
			margin: "0 auto",
			borderRadius: radii.md,
			display: "flex",
			justifyContent: "center",
			overflowY: "auto",
		},
		innerWrapper: {
			display: "grid",
			gridTemplateRows: `${size(tabsHeight)} auto`,
			flex: 1,
			[mediaQueries.tabletAndAbove]: {
				maxWidth: 768,
			},
			[mediaQueries.desktopAndAbove]: {
				maxWidth: 960,
			},
			[mediaQueries.desktopWideAndAbove]: {
				maxWidth: 1136,
			},
		},
		header: {
			display: "grid",
			gridTemplateColumns: `auto ${size(24)}`,
			alignItems: "center",
			paddingLeft: size(sideMargin),
		},
		tabs: {
			display: "grid",
			gridTemplateColumns: "auto auto",
			gap: size(4),
			width: "auto",
			// border: "1px solid red",
		},
		tabItem: {
			border: "none",
			cursor: "pointer",
			height: size(8),
			backgroundColor: palette.grey["200"].background,
			borderRadius: size(2),
		},
		tabItemActive: {
			fontWeight: fontWeights.bold,
			borderBottom: `2px solid ${palette.primary.main.background}`,
		},
		closeButton: {
			border: "none",
			padding: 0,
			justifySelf: "end",
			marginRight: size(sideMargin),
			cursor: "pointer",
			fontWeight: fontWeights.bold,

			background: "transparent",
			color: palette.grey["800"].background,

			"&:hover": {
				background: "transparent",
				color: palette.grey["900"].background,
			},
		},
		form: {
			display: "grid",
			gridTemplateRows: "1fr auto",
			height: `calc(100dvh - ${size(
				tabsHeight,
			)} - env(safe-area-inset-bottom))`,

			[mediaQueries.tabletAndAbove]: {
				height: "auto",
			},
		},
		content: {
			overflowY: "auto",
			margin: `0 ${size(sideMargin)}`,
			paddingTop: size(2),
			paddingBottom: size(2),
			paddingLeft: size(padding),
			paddingRight: size(padding),

			[mediaQueries.tabletAndAbove]: {
				overflowY: "visible",
			},
		},
		footer: {
			alignSelf: "end",
			display: "grid",
			gridTemplateColumns: "auto 1fr",
			gap: size(4),
			padding: size(4),
			// backgroundColor: "rgba(0, 0, 0, 0.2)", // tmp

			// backgroundColor: palette.grey[50].background,
			// borderTop: `1px solid ${palette.grey[200].background}`,

			[mediaQueries.tabletAndAbove]: {
				backgroundColor: "transparent",
				border: "none",
				gridTemplateRows: "auto",
				gridTemplateColumns: "repeat(3, auto)",
				justifyContent: "end",
			},
		},
		clearButton: {
			order: 0,
		},

		searchButton: {
			order: 2,
		},
	};
};

export { useVehicleSearchStyles };
