import initFirebase from "@bilar/firebase/initFirebase";
import {
	doc,
	DocumentSnapshot,
	FirestoreError,
	getFirestore,
	onSnapshot,
} from "firebase/firestore";
import { collections } from "@bilar/config";

const firebaseApp = initFirebase();
const db = getFirestore(firebaseApp);

const streamFirestoreUser = (
	userId: string,
	next: (snapshot: DocumentSnapshot) => void,
	error?: (error: FirestoreError) => void,
) => {
	const itemsColRef = doc(db, collections.USERS, userId);
	return onSnapshot(itemsColRef, next, error);
};

export { streamFirestoreUser };
