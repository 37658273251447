/** @jsxImportSource @emotion/react */
import { useFormHeaderStyles } from "./formHeaderStyles";
import { Heading } from "@bilar/ui";

type FormHeaderProps = {
	titles: string[];
	step: number;
	numberOfSteps: number;
} & FormHeaderDefaultProps;

type FormHeaderDefaultProps = {};

const defaultProps: FormHeaderDefaultProps = {};

const FormHeader = (props: FormHeaderProps) => {
	const styles = useFormHeaderStyles(props);
	const { titles, step } = props;

	return (
		<header css={styles.root}>
			<Heading as="h2" size="regular" css={styles.heading}>
				{titles[step - 1]}
			</Heading>
			<hr css={styles.line} />
		</header>
	);
};

FormHeader.defaultProps = defaultProps;

export { FormHeader };
export type { FormHeaderProps };
