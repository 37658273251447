/** @jsxImportSource @emotion/react */
import {
	searchVariants,
	useMiniSearchStyles,
	useSearch,
} from "@bilar/features";
import { motion } from "framer-motion";
import { FaIcon, useBreakpoints } from "@bilar/ui";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { useAppTranslation } from "@bilar/common";
import React, { useEffect } from "react";

type MiniSearchProps = {};

const MiniSearch = (props: MiniSearchProps) => {
	let styles = useMiniSearchStyles(props);
	const { t } = useAppTranslation("common");
	const { searchOpen, setSearchOpen } = useSearch();
	const { isTabletSmall, isMobile, device } = useBreakpoints();
	const [shouldRender, setShouldRender] = React.useState(true);

	useEffect(() => {
		if (!isTabletSmall && !isMobile) {
			setShouldRender(true);
		} else {
			setShouldRender(false);
		}
	}, [isTabletSmall, isMobile, device]);

	if (!shouldRender) return null;

	return (
		<motion.div key="miniSearch">
			<motion.div
				layoutId="searchWrapper"
				variants={searchVariants}
				initial="initial"
				animate="animate"
				exit="exit"
				css={styles.root}
			>
				<motion.button
					layoutId="searchButton"
					css={styles.button}
					onClick={() => setSearchOpen(!searchOpen)}
					data-testid="btn-mini-search"
				>
					<motion.span css={styles.label} layoutId="label">
						{t("startSearchHere")}
					</motion.span>
					<FaIcon icon={faSearch} css={styles.icon} />
				</motion.button>
			</motion.div>
		</motion.div>
	);
};

export { MiniSearch };
export type { MiniSearchProps };
