import { useDialogStyles } from "./dialogStyles";
import { ReactNode } from "react";

type DialogFooterProps = {
	children?: ReactNode;
} & DialogFooterDefaultProps;

type DialogFooterDefaultProps = {};

const defaultProps: DialogFooterDefaultProps = {};

const DialogFooter = (props: DialogFooterProps) => {
	const styles = useDialogStyles(props);

	return (
		<footer css={styles.footer} {...props}>
			{props.children}
		</footer>
	);
};

DialogFooter.defaultProps = defaultProps;

export { DialogFooter };
export type { DialogFooterProps };
