import { builder } from "@invertase/image-processing-api";
import { VehicleImage } from "@bilar/models";
import { EditableImage, EditableImageType } from "@bilar/types";

type AspectRatio = "normal" | "wide" | "tall" | "social";

const getHeightByAspectRatio = (
	aspectRatio: AspectRatio,
	width: number,
): number => {
	switch (aspectRatio) {
		case "normal": // 4:3
			return Math.round(width * 0.75);
		case "wide": // 16:9
			return Math.round(width * 0.5625);
		case "tall": // 3:4
			return Math.round(width * 1.3333333333333333);
		case "social": // 1.91:1
			return Math.round(width / 1.91);
	}
};
export const getProcessedImageUrl = (
	imgSourceUrl: string,
	width: number,
	aspectRatio: AspectRatio = "normal",
) => {
	const URL = `${process.env.NEXT_PUBLIC_IMG_PROCESS_URL}?operations=`;

	const options = builder()
		.input({
			type: "url",
			url: encodeURI(imgSourceUrl),
		})
		.resize({
			width: width,
			height: getHeightByAspectRatio(aspectRatio, width),
		})
		.output({
			webp: {
				quality: 100,
				lossless: true,
			},
		})
		.toEncodedString();

	return `${URL}${options}`;
};

export const imageSizeConfig = {
	xs: {
		width: 140,
		height: getHeightByAspectRatio("normal", 140),
	},
	thumbnail: {
		width: 200,
		height: getHeightByAspectRatio("normal", 200),
	},
	openGraph: {
		width: 1200,
		height: getHeightByAspectRatio("social", 1200),
	},
	original: {
		width: 640,
		height: getHeightByAspectRatio("normal", 640),
	},
	fullScreen: {
		width: 1280,
		height: getHeightByAspectRatio("normal", 1280),
	},
};

export const getImageFileName = (
	image: EditableImage<File>,
	type: EditableImageType,
) => {
	return image.id + "-" + type;
};

export const convertToVehicleImage = (
	image: EditableImage<File>,
	folderPath: string,
	orderNumber: number,
	original: {
		url: string;
		contentType: string | undefined;
	},
	processed: {
		url: string;
		contentType: string | undefined;
	},
) => {
	const dbImage: VehicleImage = {
		id: image.id,
		original: {
			fileName: getImageFileName(image, "original"),
			originalFileName: image.original.name,
			folderPath,
			downloadUrl: original.url,
			previewUrl: original.url, // TODO: For now, let's get a smaller one soon
			size: image.original.size,
			mimeType: original.contentType ?? "image/jpeg",
		},
		processed: {
			fileName: getImageFileName(image, "processed"),
			originalFileName: image.processed!.name,
			folderPath,
			downloadUrl: processed.url,
			previewUrl: processed.url, // TODO: For now, let's get a smaller one soon
			size: image.processed!.size,
			mimeType: processed.contentType ?? "image/jpeg",
		},
		orderNumber,
		imageState: image.imageState!,
	};
	return dbImage;
};
