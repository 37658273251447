import { getApps, initializeApp } from "firebase/app";
import { getConfig } from "@bilar/config";

const appConfig = getConfig();

const config = {
	apiKey: appConfig.public.firebaseApiKey,
	authDomain: appConfig.public.firebaseAuthDomain,
	databaseURL: appConfig.public.firebaseDatabaseUrl,
	projectId: appConfig.public.firebaseProjectId,
	storageBucket: appConfig.public.firebaseStorageBucket,
	messagingSenderId: appConfig.public.firebaseMessagingSenderId,
	measurementId: appConfig.public.firebaseMeasurementId,
	appId: appConfig.public.firebaseAppId,
};

const initFirebase = () => {
	const apps = getApps();
	if (!apps.length) {
		return initializeApp(config);
	}

	return apps[0];
};

// TODO: Do we need to initializeAuth from firebase?
// https://www.youtube.com/watch?v=wR_iBb12gQs&t=1228

// NOTE: This needs to be export default initFirebase; otherwise, it won't work.
export default initFirebase;
