import { CSSObject } from "@emotion/react";
import { VehicleInfoChipsProps } from "./VehicleInfoChips";
import { useDesignTokens } from "@bilar/ui";
import { colours } from "@bilar/common";

type VehicleInfoChipsStyles = {
	root: CSSObject;
	item: CSSObject;
	itemWithColour: CSSObject;
};

const useVehicleInfoChipsStyles = (
	props: VehicleInfoChipsProps,
): VehicleInfoChipsStyles => {
	const designTokens = useDesignTokens();
	const { colors, fontSizes, fontWeights, space, size } = designTokens;

	const colour =
		colours.find((c) => c.name === props.data.colour)?.hexValue ?? "white";

	return {
		root: {
			display: "flex",
			flexWrap: "wrap",
			gap: "0.5rem",
		},
		item: {
			fontWeight: fontWeights.medium,
			fontSize: fontSizes.sm,
			padding: `${space[1]} ${space[2]}`,
			borderRadius: size(2),
			border: `1px solid ${colors.gray["400"]}`,
			display: "inline-flex",
			alignItems: "center",
		},
		itemWithColour: {
			":before": {
				content: '""',
				display: "inline-block",
				width: space[5],
				height: space[5],
				borderRadius: 9999,
				backgroundColor: colour,
				border: "1px solid white",
			},
		},
	};
};

export { useVehicleInfoChipsStyles };
