/** @jsxImportSource @emotion/react */
import { ButtonVariant, InvisibleButton } from "../../buttons";
import { Country, PaletteOptions } from "../../../types";
import React, { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { PhoneFieldStyles, usePhoneFieldStyles } from "./phoneFieldStyles";
import { useBrowserSize } from "../../../hooks";
import { useListBoxStyles } from "../../listbox/listBoxStyles";
import { TextField, TextFieldAdornment } from "../TextField";
import {
	ListBox,
	ListBoxButton,
	ListBoxList,
	ListBoxPopover,
} from "../../listbox";

import { getTestId } from "../../../utils";
import { phoneFieldDefaultProps, PhoneFieldProps } from "./PhoneField";
import { FlipAnimation } from "../../animations/FlipAnimation";
import { Divider, FaIcon, Stack } from "../..";
import { faChevronDown, faGlobe } from "@fortawesome/pro-solid-svg-icons";
import { gridSystem, SpacingSize } from "@bilar/ui";
import * as Flags from "country-flag-icons/react/3x2";

export type RenderCountryFlag = (country: string) => JSX.Element;

type CountrySelectProps = {
	/**
	 * @deprecated we changed to invisible button, so this is not needed anymore
	 */
	buttonVariant?: ButtonVariant;
	buttonPalette?: PaletteOptions;
	selectedCountry?: Country;
	countries: Country[];
	inputWrapperRef: RefObject<HTMLDivElement>;
	setSelectedCountry: (country?: Country) => void;
	setChangeIsFromInput: (changeIsFromInput: boolean) => void;
	renderCountryFlag?: RenderCountryFlag;
} & Omit<PhoneFieldProps, "countries">;

type SelectedCountryAdornment = {
	styles: PhoneFieldStyles;
	isExpanded: boolean;
	showChevron: boolean;
	country?: Country;
	testId?: string;
	renderCountryFlag: RenderCountryFlag;
};

const SelectedCountryAdornment = (props: SelectedCountryAdornment) => {
	const {
		country,
		styles,
		testId,
		isExpanded,
		showChevron,
		renderCountryFlag,
	} = props;
	return (
		<Stack verticalAlignment="center" orientation="horizontal">
			{country && (
				<Stack verticalAlignment="center" orientation="horizontal">
					<span
						css={styles.adornment.flag}
						aria-label={country.name}
						data-testid={getTestId(testId, "menu-country-code")}
						data-testvalue={country.iso2}
					>
						{renderCountryFlag(country.iso2)}
					</span>
				</Stack>
			)}
			{!country && <FaIcon icon={faGlobe} />}
			{showChevron && (
				<div css={styles.adornment.chevron} aria-hidden={true}>
					<FlipAnimation flipped={isExpanded}>
						<FaIcon icon={faChevronDown} size={3} />
					</FlipAnimation>
				</div>
			)}
			{country && (
				<div
					css={styles.adornment.countryCode}
					data-testid={getTestId(testId, "menu-calling-code")}
				>
					+{country.callingCode}
				</div>
			)}
		</Stack>
	);
};

const getFilteredCountries = (countries: Country[], value: string) => {
	return (
		countries
			// Filter out countries that don't match the search
			.filter(
				(country) =>
					country.name.toLowerCase().includes(value.toLowerCase()) ||
					country.callingCode.toString().includes(value),
			)
	);
};

export const flagRenderer = (country: string) => {
	// Lazily load the flag icon
	// @ts-ignore
	const Flag = Flags[country] as Flags.FlagComponent;
	return <Flag />;
};

export const CountrySelect = (props: CountrySelectProps) => {
	const initialPopupWidth = 464;
	const {
		buttonPalette,
		selectedCountry,
		preferredCountries = phoneFieldDefaultProps.preferredCountries,
		countries,
		testId,
		inputWrapperRef,
		error,
		setSelectedCountry,
		setChangeIsFromInput,
		renderCountryFlag = flagRenderer,
		disabled,
	} = props;
	const listRef = useRef<HTMLUListElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const [isMenuExpanded, setIsMenuExpanded] = useState(false);
	const styles = usePhoneFieldStyles(props, error);
	const [filter, setFilter] = useState("");
	const [popupWidth, setPopupWidth] = useState<SpacingSize>();
	const [activeIndex, setActiveIndex] = useState<number>();
	const browserSize = useBrowserSize();
	const listBoxStyles = useListBoxStyles(popupWidth ?? initialPopupWidth); // Only used to get the listbox border styles

	const filteredCountries = useMemo(() => {
		const preferred = preferredCountries
			.filter((code) => countries.find((c) => c.iso2 === code)) // Check that we actually have the country in the list
			.map((code) => countries.find((c) => c.iso2 === code)) as Country[];

		const countriesWithoutPreferred = countries.filter(
			(country) => !preferred.includes(country),
		);

		const filteredPreferred = getFilteredCountries(preferred, filter);
		const filteredCountriesWithoutPreferred = getFilteredCountries(
			countriesWithoutPreferred,
			filter,
		);

		return {
			items: [...filteredPreferred, ...filteredCountriesWithoutPreferred].map(
				(country) => ({
					value: country.iso2,
					label: country.name,
				}),
			),
			dividerIndex: filteredPreferred.length - 1,
		};
	}, [countries, preferredCountries, filter]);

	const onCountrySelect = (country?: Country) => {
		setSelectedCountry(country);
		setChangeIsFromInput(false);
	};

	const onWrapperKeyUpHandler = (event: React.KeyboardEvent) => {
		// Navigate to the first item when the user presses the tab key
		if (event.code === "Tab" && !event.shiftKey) {
			setActiveIndex(0);
		}
	};

	const onInputKeyUpHandler = (event: React.KeyboardEvent) => {
		// NOTE: It would be nice to detect "ArrowDown" here also and select the first element,
		// but it interferes with the listbox's own open event, causing it to always select the first item
		// and not being able to navigate with the arrow keys.
		// Trying to blur out of the element will cause other side effects, so we only handle the "ArrowUp" key here.
		if (event.code === "ArrowUp") {
			setIsMenuExpanded(false);
		}
	};

	// Because there are very long country names, we need to set the width
	// of the popover dynamically on mobile devices or else we will get a horizontal scrollbar
	useEffect(() => {
		if (inputWrapperRef.current) {
			let width = initialPopupWidth;
			if (inputWrapperRef.current.offsetWidth < initialPopupWidth) {
				// We need to remove the listbox popover's borders from the calculation
				const borderWidth = (listBoxStyles.borderWidth as number) * 2;
				width = inputWrapperRef.current.offsetWidth - borderWidth;
			}
			setPopupWidth(Math.ceil(width / gridSystem.size) as SpacingSize);
		}
	}, [inputWrapperRef, browserSize]);

	if (countries.length === 1) {
		return (
			<TextFieldAdornment pl={2} pr={2} pt={0} pb={0}>
				<SelectedCountryAdornment
					testId={testId}
					country={selectedCountry}
					styles={styles}
					isExpanded={isMenuExpanded}
					showChevron={false}
					renderCountryFlag={renderCountryFlag}
				/>
			</TextFieldAdornment>
		);
	}

	return (
		<TextFieldAdornment p={0}>
			<ListBox
				isOpen={isMenuExpanded}
				onOpenChange={(isOpen) => setIsMenuExpanded(isOpen)}
				activeIndex={activeIndex}
				value={selectedCountry?.iso2}
				items={filteredCountries.items}
				disabled={disabled}
				popoverWidth={popupWidth}
				dividers={[filteredCountries.dividerIndex]}
				focusItemOnOpen={false}
				onSelect={(value) =>
					onCountrySelect(countries.find((country) => country.iso2 === value))
				}
				getOptionComponent={(listItem) => {
					const country = countries.find(
						(country) => country.iso2 === listItem.value,
					);
					// Not really sure how this could happen, but just in case. Also, TS is complaining.
					if (!country) {
						return <>{listItem.label}</>;
					}

					return (
						<div css={styles.listItem.root}>
							<span css={styles.listItem.flag} aria-hidden={true}>
								{renderCountryFlag(country.iso2)}
							</span>
							<span css={styles.listItem.name}>{country.name}</span>
							<span css={styles.listItem.callingCode}>
								+{country.callingCode}
							</span>
						</div>
					);
				}}
				getDividerComponent={({ css, ...props }) => (
					<Divider {...props} css={[css, styles.countryDivider]} />
				)}
			>
				<ListBoxButton css={{ height: "100%" }}>
					<InvisibleButton
						palette={error ? "error" : buttonPalette}
						css={styles.adornment.button}
						testId={getTestId(testId, `menu-button`)}
					>
						<SelectedCountryAdornment
							testId={testId}
							country={selectedCountry}
							styles={styles}
							isExpanded={isMenuExpanded}
							showChevron={true}
							renderCountryFlag={renderCountryFlag}
						/>
					</InvisibleButton>
				</ListBoxButton>
				<ListBoxPopover p={0} pb={2}>
					<TextField
						ref={inputRef}
						size="small"
						testId={getTestId(testId, "filter-input")}
						value={filter}
						css={styles.countryFilter}
						onChange={setFilter}
						onFocus={() => setActiveIndex(undefined)}
						onKeyUp={onInputKeyUpHandler}
					/>
					<ListBoxList
						ref={listRef}
						testId={getTestId(testId, "country-list")}
						onKeyUp={onWrapperKeyUpHandler}
					/>
				</ListBoxPopover>
			</ListBox>
		</TextFieldAdornment>
	);
};
