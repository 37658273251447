/** @jsxImportSource @emotion/react */
import { useTextFieldAdornmentStyles } from "./textFieldStyles";
import { ReactNode } from "react";
import { BoxBaseProps, useBoxStyles } from "@bilar/ui";

type TextFieldAdornmentProps = {
	children?: ReactNode;
} & BoxBaseProps &
	TextFieldAdornmentDefaultProps;

type TextFieldAdornmentDefaultProps = {
	borders?: boolean;
};

const defaultProps: TextFieldAdornmentDefaultProps = {
	borders: true,
};

const TextFieldAdornment = (props: TextFieldAdornmentProps) => {
	const styles = useTextFieldAdornmentStyles();
	const boxStyles = useBoxStyles(props);

	return <div css={[styles.root, boxStyles]}>{props.children}</div>;
};

TextFieldAdornment.defaultProps = defaultProps;

export { TextFieldAdornment };
export type { TextFieldAdornmentProps };
