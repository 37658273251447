/** @jsxImportSource @emotion/react */
import { useVehicleBodyCheckboxStyles } from "./vehicleBodyRadioStyles";
import { VehicleBody } from "../index";
import { Box, Checkbox, CheckboxProps, SpacingSize } from "@bilar/ui";
import { VehicleBodyType } from "@bilar/models";

type VehicleBodyCheckboxProps = {
	bodyType: VehicleBodyType;
	size?: SpacingSize;
	text: string;
	onChange: (name: VehicleBodyType, checked: boolean) => void;
} & Omit<CheckboxProps, "layout" | "text" | "onChange">;

const VehicleBodyCheckbox = (props: VehicleBodyCheckboxProps) => {
	const styles = useVehicleBodyCheckboxStyles(props);
	const { bodyType, size, text, onChange, checked, ...checkboxProps } = props;

	return (
		<div css={styles.root}>
			<div
				css={styles.iconWrapper}
				onClick={() => onChange(bodyType, !checked)}
			>
				<VehicleBody bodyType={props.bodyType} align="center" />
			</div>
			<Box m={1}>
				<Checkbox
					{...checkboxProps}
					checked={checked}
					size="small"
					onChange={(checked) => onChange(bodyType, checked)}
					// layout="box"
					label={text}
				/>
			</Box>
		</div>
	);
};

export { VehicleBodyCheckbox };
export type { VehicleBodyCheckboxProps };
