import { CSSObject } from "@emotion/react";
import { FrontPageHeroProps } from "@bilar/layout";
import { useButtonStyles, useDesignTokens } from "@bilar/ui";
import image from "@bilar/assets/img/hero/hero-1.svg";

type FrontPageHeroStyles = {
	root: CSSObject;
	content: CSSObject;
	ctaButton: CSSObject;
	ctaButtonText: CSSObject;
};

const useFrontpageHeroStyles = (
	props: FrontPageHeroProps,
): FrontPageHeroStyles => {
	const designTokens = useDesignTokens();
	const buttonStyles = useButtonStyles({
		...props,
		palette: "secondary",
		layout: "circular",
	});

	const { mediaQueries, palette, space } = designTokens;
	const backgroundSizePercentage = 65;
	const backgroundPositionPercentageY = 90;
	const imgHeight = 349;
	const borderWidth = 52;
	const borderRatioFromImage = (borderWidth / imgHeight) * 100;

	// NOTE: the 1.4 number I pulled out of my A**
	// I couldn't figure out how to calculate it with the additional backgroundPositionPercentageY not being 100.
	const borderHeight =
		borderRatioFromImage * (backgroundSizePercentage / 100) * 1.4;

	return {
		root: {
			position: "relative",
			borderBottom: `1px solid ${palette.neutral.main.background}`,

			"a:hover": {
				textDecoration: "none",
			},

			[mediaQueries.desktopAndAbove]: {
				// borderBottom: "none",
				"&:after": {
					content: '""',
					position: "absolute",
					bottom: 0,
					left: 0,
					right: 0,
					// Based on the height of the svg and the bottom border is 23.782234957% of the total height.
					// Since background-size is 50%, we divide by 2.
					// height: `${83 * (backgroundPercentage / 100)}%`,
					height: `${borderHeight}%`,
					backgroundColor: palette.neutral.light.background,
					zIndex: "-1",
				},
			},
		},
		content: {
			[mediaQueries.desktopAndAbove]: {
				backgroundImage: `url(${image})`,
				backgroundPosition: `100% ${backgroundPositionPercentageY}%`,
				backgroundRepeat: "no-repeat",
				backgroundSize: `auto ${backgroundSizePercentage}%`,
				paddingTop: space[10],
				paddingBottom: space[10],
				minHeight: space[96],
			},
		},
		ctaButton: {
			...buttonStyles,
			display: "inline-flex",
			alignItems: "center",
			width: "100%",
			justifyContent: "center",

			[mediaQueries.tabletAndAbove]: {
				width: "auto",
				justifyContent: "flex-start",
			},

			// Next.js span wrapper
			"&>span": {
				display: "flex",
				alignItems: "center",
			},
		},
		ctaButtonText: {
			marginTop: "-0.2em",
		},
	};
};

export { useFrontpageHeroStyles };
