import { faCircleNotch } from "@fortawesome/sharp-solid-svg-icons";
import { FaIcon, useDesignTokens } from "@bilar/ui";
import React from "react";
import { BaseLoadingProps } from "./index";
import { motion } from "framer-motion";
import { CSSObject } from "@emotion/react";

const variants = {
	spinner: {
		rotate: 360,
		transition: {
			repeat: Infinity,
			duration: 1,
			ease: "linear",
		},
	},
};

type CircleSpinnerStyles = {
	icon: CSSObject;
};

const useCircleSpinnerStyles = (
	props: CircleSpinnerProps,
): CircleSpinnerStyles => {
	const { palette } = useDesignTokens();
	return {
		icon: {
			color: palette.primary.main.background,
		},
	};
};

type CircleSpinnerProps = {} & BaseLoadingProps;

const CircleSpinner = (props: CircleSpinnerProps) => {
	const { size } = props;
	const styles = useCircleSpinnerStyles(props);

	return (
		<motion.div variants={variants} animate="spinner">
			<FaIcon icon={faCircleNotch} size={size} css={styles.icon} />
		</motion.div>
	);
};

export { CircleSpinner };
