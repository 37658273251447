/** @jsxImportSource @emotion/react */
import { ReactNode } from "react";
import {
	BaseBoxProps,
	BaseProps,
	FontAlignment,
	Heading,
	HtmlTag,
} from "@bilar/ui";
import { useHeadingStyles } from "./headingStyles";

type CommonHeadingProps = {
	children?: ReactNode;
	as: HtmlTag;
	textAlign?: FontAlignment;
} & BaseProps &
	BaseBoxProps;

const CommonHeading = (props: CommonHeadingProps) => {
	const { as, children, textAlign = "left", ...rest } = props;
	const styles = useHeadingStyles(textAlign);

	return (
		<Heading as={as} css={styles.root} {...rest} weight="light">
			{children}
		</Heading>
	);
};

export { CommonHeading };
export type { CommonHeadingProps };
