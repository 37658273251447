/** @jsxImportSource @emotion/react */
import { useVehicleBodyRadioStyles } from "./vehicleBodyRadioStyles";
import { VehicleBody } from "../index";
import { RadioButton, RadioButtonProps, SpacingSize } from "@bilar/ui";
import { VehicleBodyType } from "@bilar/models";

type VehicleBodyRadioProps = {
	bodyType: VehicleBodyType;
	size?: SpacingSize;
	text: string;
	onChange: (name: VehicleBodyType, checked: boolean) => void;
} & Omit<RadioButtonProps, "layout" | "text" | "onChange">;

const VehicleBodyRadio = (props: VehicleBodyRadioProps) => {
	const styles = useVehicleBodyRadioStyles(props);
	const { bodyType, size, text, onChange, checked, ...radioButtonProps } =
		props;

	return (
		<div css={styles.root}>
			<div
				css={styles.iconWrapper}
				onClick={() => onChange(bodyType, !checked)}
			>
				<VehicleBody bodyType={props.bodyType} align="center" />
			</div>
			<RadioButton
				{...radioButtonProps}
				checked={checked}
				onChange={(checked) => onChange(bodyType, checked)}
				layout="box"
				text={text}
			/>
		</div>
	);
};

export { VehicleBodyRadio };
export type { VehicleBodyRadioProps };
