import { useState } from "react";
import { AnimatePresence, motion, MotionConfig, PanInfo } from "framer-motion";
import { FaIcon } from "../icons";
import {
	faChevronCircleLeft,
	faChevronCircleRight,
} from "@fortawesome/pro-solid-svg-icons";
import { GalleryImage, useGalleryStyles, useKeyDown } from "@bilar/ui";
import Image from "next/image";

let collapsedAspectRatio = 1 / 3;
let fullAspectRatio = 3 / 2;
let gap = 2;
let margin = 12;

type GalleryProps = {
	images: GalleryImage[];
};

const Gallery = (props: GalleryProps) => {
	const { images } = props;
	let [index, setIndex] = useState(0);
	const styles = useGalleryStyles(props);
	const defaultWidth = 1280;
	const defaultHeight = 960;

	useKeyDown("ArrowRight", () => {
		if (index + 1 < images.length) {
			setIndex(index + 1);
		}
	});

	useKeyDown("ArrowLeft", () => {
		if (index > 0) {
			setIndex((i) => i - 1);
		}
	});

	const onDragEnd = (
		event: MouseEvent | TouchEvent | PointerEvent,
		info: PanInfo,
	) => {
		const swipeThreshold = 50; // Minimum distance (in pixels) to consider it a swipe
		const velocityThreshold = 500; // Minimum velocity (in pixels per second) to consider it a swipe
		const direction = info.velocity.x > 0 ? -1 : 1; // Determine direction based on velocity

		// Check if swipe distance or velocity exceeds thresholds
		if (
			Math.abs(info.offset.x) > swipeThreshold ||
			Math.abs(info.velocity.x) > velocityThreshold
		) {
			let newIndex = index + direction;
			if (newIndex >= 0 && newIndex < images.length) {
				setIndex(newIndex); // Set new index if within bounds
			}
		}
	};

	console.log("images", images);

	return (
		<MotionConfig transition={{ duration: 0.7, ease: [0.32, 0.72, 0, 1] }}>
			<div css={styles.root}>
				<div css={styles.outerContainer}>
					<div css={styles.innerContainer}>
						<motion.div
							animate={{ x: `-${index * 100}%` }}
							drag="x"
							dragConstraints={{ left: 0, right: 0 }}
							dragElastic={1} // lower values making the drag feel stiffer.
							onDragEnd={onDragEnd}
							css={styles.animationContainer}
						>
							{images.map((image, i) => (
								<Image
									key={image.orderNumber}
									alt={image.alt || ""}
									src={image.url}
									width={image.width || defaultWidth}
									height={image.height || defaultHeight}
									sizes="100vw"
									// sizes="(min-width: 1280px) 649px, (min-width: 1040px) 540px, (min-width: 820px) 686px, (min-width: 360px) calc(92.73vw - 56px), calc(50vw + 88px)"
									priority={true}
									placeholder="blur"
									blurDataURL={image.blurDataUrl || "/blur.png"}
									css={styles.image}
								/>
							))}
						</motion.div>
						<AnimatePresence initial={false}>
							{index > 0 && (
								<motion.button
									type="button"
									initial={{ opacity: 0 }}
									animate={{ opacity: 0.7 }}
									exit={{ opacity: 0, pointerEvents: "none" }}
									whileHover={{ opacity: 1 }}
									css={[styles.navButton, styles.navButtonLeft]}
									onClick={() => setIndex(index - 1)}
								>
									<FaIcon
										icon={faChevronCircleLeft}
										css={styles.navButtonIcon}
									/>
								</motion.button>
							)}
						</AnimatePresence>

						<AnimatePresence initial={false}>
							{index + 1 < images.length && (
								<motion.button
									type="button"
									initial={{ opacity: 0 }}
									animate={{ opacity: 0.7 }}
									exit={{ opacity: 0, pointerEvents: "none" }}
									whileHover={{ opacity: 1 }}
									css={[styles.navButton, styles.navButtonRight]}
									onClick={() => setIndex(index + 1)}
								>
									<FaIcon
										icon={faChevronCircleRight}
										css={styles.navButtonIcon}
									/>
								</motion.button>
							)}
						</AnimatePresence>
					</div>

					<div css={styles.thumbnailsRoot}>
						<motion.div
							initial={false}
							animate={{
								x: `-${index * 100 * (collapsedAspectRatio / fullAspectRatio) + index * gap + margin}%`,
							}}
							style={{ aspectRatio: fullAspectRatio, gap: `${gap}%` }}
							css={styles.thumbnailsWrapper}
						>
							{images.map((image, i) => (
								<motion.button
									type="button"
									key={image.orderNumber}
									css={styles.thumbnailsButton}
									onClick={() => setIndex(i)}
									whileHover={{ opacity: 1 }}
									initial={false}
									animate={i === index ? "active" : "inactive"}
									variants={{
										active: {
											marginLeft: `${margin}%`,
											marginRight: `${margin}%`,
											opacity: 1,
											aspectRatio: fullAspectRatio,
										},
										inactive: {
											marginLeft: "0%",
											marginRight: "0%",
											opacity: 0.5,
											aspectRatio: collapsedAspectRatio,
										},
									}}
								>
									<Image
										alt={image.alt || ""}
										src={image.url}
										width={85}
										height={64}
										sizes="100vw"
										placeholder="blur"
										blurDataURL={image.blurDataUrl || "/blur.png"}
										css={styles.thumbnailsImage}
									/>
								</motion.button>
							))}
						</motion.div>
					</div>
				</div>
			</div>
		</MotionConfig>
	);
};

export { Gallery };
export type { GalleryProps };
