import { ZIndices } from "./types";

const zIndices: ZIndices = {
	hide: -1,
	auto: "auto",
	base: 0,
	docked: 10,
	dropdown: 100,
	sticky: 200,
	banner: 300,
	overlay: 400,
	modal: 500,
	popover: 600,
	skipLink: 700,
	toast: 800,
	tooltip: 900,
};

export default zIndices;
