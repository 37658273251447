/** @jsxImportSource @emotion/react */
import React, { FormEvent, useEffect, useState } from "react";
import {
	ContactConfirmationBox,
	ContactFormValues,
	deleteImagesFromDb,
	formUploadProgressInitialValues,
	getOldGalleryImages,
	useVehicleClassifiedsDb,
	useVehicleFormContext,
	VehicleDetails,
} from "@bilar/features";
import {
	Box,
	Button,
	Divider,
	Heading,
	InvisibleButton,
	Message,
	Panel,
	Stack,
} from "@bilar/ui";
import { useAppTranslation } from "@bilar/common";
import {
	ClassifiedVehicle,
	ClassifiedVehicleDbModel,
	VehicleImage,
} from "@bilar/models";
import { useAuthUser } from "@bilar/auth";
import { parsePhoneNumber } from "libphonenumber-js";
import { useRouter } from "next/router";
import { routes } from "@bilar/config";
import { convertToVehicleImage, crudItemsFilter } from "@bilar/utils";
import { SavingModal } from "./SavingModal/SavingModal";
import { uuid } from "short-uuid";
import { deepEqual } from "@firebase/util";
import { addBreadcrumb } from "@sentry/core";
import { StringParam, useQueryParam } from "use-query-params";

// tmp for debugging
//import dynamic from "next/dynamic";
//const JsonView = dynamic(import("react-json-view"), { ssr: false });

class ClientError extends Error {
	constructor(message: string) {
		super(message);
		this.name = "ClientError";
	}
}

type StepSummaryProps = {
	onBack: () => void;
	onContinue: () => void;
};

const StepSummary = (props: StepSummaryProps) => {
	const { t } = useAppTranslation();
	const {
		formValues,
		originalFormValues,
		setStepSummaryValues,
		clearFormValues,
		uploadProgress,
		setUploadProgress,
	} = useVehicleFormContext();
	const { user } = useAuthUser();
	const {
		saveVehicle,
		toVehicleDbModel,
		uploadImagesToStorage,
		deleteAllFilesWithId,
		getVehicleImagePath,
	} = useVehicleClassifiedsDb();
	const triggerState = useState(false);
	const router = useRouter();
	const [preview, setPreview] = useState<ClassifiedVehicle | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);
	const [createdId, setCreatedId] = useQueryParam("createdId", StringParam);

	useEffect(() => {}, [uploadProgress]);

	useEffect(() => {
		if (user) {
			const vehicleImages = getPreviewImages();

			const pvData = toVehicleDbModel(
				"preview",
				formValues,
				vehicleImages,
				user,
			);

			setPreview(pvData as ClassifiedVehicle);
		}
	}, [user]);

	useEffect(() => {
		if (error) {
			setLoading(false);
			setUploadProgress(formUploadProgressInitialValues);
		}
	}, [error]);

	// NOTE: To be able to trigger "submit" on ContactConfirmationBox to get the values from there.
	const [_, setTriggerSync] = triggerState;

	/**
	 * Convert formValue images to VehicleImages including orderId for preview purposes
	 */
	const getPreviewImages = () => {
		const vehicleImages: VehicleImage[] =
			formValues.stepImagesValues.images.map((image, index) => {
				// Get preview url from EditableImage<File> object
				const originalUrl = URL.createObjectURL(image.original);
				const processedUrl = image.processed
					? URL.createObjectURL(image.processed)
					: "";

				return convertToVehicleImage(
					image,
					"",
					index,
					{
						url: originalUrl,
						contentType: image.original.type,
					},
					{
						url: processedUrl,
						contentType: image.processed?.type,
					},
				);
			});
		return vehicleImages;
	};

	const onContactConfirmSubmit = async (contactValues: ContactFormValues) => {
		if (!user) {
			setError("No user logged in!");
			throw new Error("No user logged in!");
		}

		setLoading(true);

		// Just to keep it, if the save fails and the user goes back a step and comes back.
		setStepSummaryValues(contactValues);

		// Reset upload progress if any from before
		setUploadProgress(formUploadProgressInitialValues);

		const phoneNumber = parsePhoneNumber(contactValues.phoneNumber);
		const isNewAd = !formValues.globalValues.classifiedAd?.id;

		// If we have an id then we're editing an existing ad, otherwise we're creating a new one.
		const id = formValues.globalValues.classifiedAd?.id ?? uuid();

		// Get the images to be created, updated and deleted.
		const imagesCrud = crudItemsFilter(
			originalFormValues?.stepImagesValues.images ?? [],
			formValues.stepImagesValues.images,
			(oldImage, newImage) => {
				const imageHasNotChanged = deepEqual(
					oldImage.imageState || {},
					newImage.imageState || {},
				); // If the imageState is the same, then it's the same image.
				return !imageHasNotChanged;
			},
		);

		// Upload images and get the info to be converted to a db model.
		const uploadedImages = await uploadImagesToStorage(
			id,
			[...imagesCrud.createItems, ...imagesCrud.updateItems],
			user,
		);

		// Get the final images so that we don't lose the order.
		const finalImages: VehicleImage[] = formValues.stepImagesValues.images.map(
			(image, index) => {
				const imageInfo = uploadedImages.find((i) => i.id === image.id);

				// "new" and "updated" images
				if (imageInfo) {
					return { ...imageInfo, orderNumber: index };
				}

				// Get the original images and set the new order number
				const unManipulatedImage =
					formValues.globalValues.classifiedAd?.images?.find(
						(i) => i.id === image.id,
					);

				if (unManipulatedImage) {
					return { ...unManipulatedImage, orderNumber: index };
				}

				setError("Image not found!");
				throw new Error("Image not found!");
			},
		);

		const dbVehicleModel = toVehicleDbModel(id, formValues, finalImages, user);
		const dbModel: ClassifiedVehicleDbModel = {
			...dbVehicleModel,
			contactEmail: {
				address: contactValues.email,
				// If it's the same as the user's email then it's verified
				isVerified: contactValues.email.toLowerCase() === user?.email.address,
			},
			contactPhone: {
				countryCode: phoneNumber.country,
				internationalNumber: phoneNumber.formatInternational(),
				nationalNumber: phoneNumber.nationalNumber,

				// TODO: If it's the same as the user's phone then it's verified
				//  right now there are two different Phone types so we can't compare international number.
				//  Once we switch out the PhoneField component we should use the same type. (probably)
				isVerified: false,
			},
			hideEmail: contactValues.hideEmail,
			hidePhoneNumber: contactValues.hidePhoneNumber,
		};

		try {
			addBreadcrumb({
				category: "StepSummary:onContactConfirmSubmit",
				message: `Going to save vehicle with id: ${dbModel.id}`,
				level: "info",
			});
			// Save the vehicle to db
			const res = await saveVehicle(dbModel);

			if (!res.docId) {
				throw new ClientError("Could not save vehicle!");
			}

			if (!isNewAd) {
				try {
					// Delete images from db
					await deleteImagesFromDb(
						id,
						imagesCrud.deleteItems.map((i) => i.id),
					);

					// Delete images from storage
					const vehicleImgFolder = getVehicleImagePath(user, id);
					for (const image of imagesCrud.deleteItems) {
						await deleteAllFilesWithId(vehicleImgFolder, image.id);
					}
				} catch (error) {
					console.error("Error deleting images", error);
					throw new ClientError("Error deleting images");
				}
			}

			if (isNewAd) {
				clearFormValues();
				setCreatedId(res.docId);

				return;
			}

			// TODO: Display a success message
			await router.push(routes.profile.classifieds);
		} catch (error) {
			console.error("Error saving vehicle", error);
			setError("Error saving vehicle");

			if (error instanceof ClientError) {
				setError(error.message);
			}
		} finally {
			setLoading(false);
		}
	};

	const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		// console.log("triggering sync");

		// This will trigger submit on ContactConfirmationBox which in turn calls on "onContactConfirmSubmit" which will be our main save method.
		setTriggerSync(true);
	};

	const vehicleImages = preview?.images ?? [];
	const galleryImages = getOldGalleryImages(vehicleImages, false);

	// New gallery
	// const galleryImages = getGalleryImages(vehicleImages);

	const uploadProgressPercentage =
		uploadProgress.totalBytes > 0
			? Math.floor(
					(uploadProgress.bytesTransferred / uploadProgress.totalBytes) * 100,
				)
			: 0;

	return (
		<Panel.Body>
			{error && (
				<Message heading={t("unknownError")} palette="error" mb={4}>
					{t("errorPleaseTryAgain")}
				</Message>
			)}
			<ContactConfirmationBox
				heading={t("contactInformation")}
				subHeading={t("verifyInformationIsCorrect")}
				initialValues={{
					email: user?.email.address || "",
					hideEmail: true,
					phoneNumber:
						formValues.stepSummaryValues.phoneNumber ||
						user?.phone.internationalNumber ||
						"",
					hidePhoneNumber: false,
				}}
				disabled={loading}
				triggerSyncState={triggerState}
				onSubmit={onContactConfirmSubmit}
			/>
			<form onSubmit={onSubmit}>
				<Divider my={4} variant="dotted" />
				{preview && (
					<>
						<Heading as={"h2"}>{t("preview")}</Heading>
						<VehicleDetails
							previewMode={true}
							data={preview}
							images={galleryImages}
						/>
					</>
				)}
				<Divider variant="solid" my={4} />
				{/*{preview && <JsonView src={preview} />}*/}
				{/*<Divider my={4} variant="dotted" />*/}
				<Stack
					orientation="horizontal"
					verticalAlignment="center"
					horizontalAlignment="right"
				>
					<Box mr={[0, 4]}>
						<InvisibleButton type="button" onClick={props.onBack}>
							{t("goBack")}
						</InvisibleButton>
					</Box>
					<Button type="submit" palette="success" disabled={loading}>
						{t("publishClassifiedAd")}
					</Button>
				</Stack>
			</form>
			{loading && <SavingModal progressPercentage={uploadProgressPercentage} />}
		</Panel.Body>
	);
};

export { StepSummary };
export type { StepSummaryProps };
