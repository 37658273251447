import { useEffect } from "react";
import { useVehicleModels } from "./index";
import {
	DropdownOnChange,
	filterByLabel,
	Select,
	SelectProps,
	useDropdownOptions,
} from "@bilar/ui";
import { useAppTranslation } from "@bilar/common";
import { VehicleModel } from "@bilar/models";

type BaseSelProps = Omit<SelectProps<false>, "options" | "value">;

type VehicleModelSelectProps = {
	brandId: string | undefined;
	selectedModelId: string | undefined;
} & BaseSelProps;

const VehicleModelSelect = (props: VehicleModelSelectProps) => {
	const { t } = useAppTranslation();
	const { onChange, brandId, selectedModelId, ...filteredProps } = props;
	const {
		brandModels,
		selectedModel,
		setSelectedModel,
		getVehicleModels,
		mapToDropdownOption,
	} = useVehicleModels();
	const options = useDropdownOptions<VehicleModel>(
		brandModels,
		mapToDropdownOption,
	);

	useEffect(() => {
		if (brandId) {
			getVehicleModels(brandId);

			if (selectedModel?.vehicleBrandId !== brandId) {
				setSelectedModel(null);
			}
		} else {
			setSelectedModel(null);
		}
	}, [brandId]);

	useEffect(() => {
		if (brandModels.length > 0 && selectedModelId) {
			setSelectedModel(selectedModelId);
		}
	}, [selectedModelId, props.selectedModelId]);

	useEffect(() => {
		if (!selectedModelId) {
			setSelectedModel(null);
		} else {
			setSelectedModel(selectedModelId);
		}
	}, [selectedModelId, brandModels]);

	const handleChange: DropdownOnChange<false> = (selected, actionMeta) => {
		setSelectedModel(selected?.value ?? null);

		if (onChange) {
			onChange(selected, actionMeta);
		}
	};

	return (
		<Select
			isDisabled={!brandId}
			label={t("vehicleModel")}
			placeholder={t("selectVehicleModel")}
			aria-label={t("selectVehicleModel")}
			options={options}
			isSearchable={true}
			isClearable={true}
			autoFocus={false}
			onChange={handleChange}
			filterOption={filterByLabel}
			{...filteredProps}
			value={selectedModel ? mapToDropdownOption(selectedModel) : null}
		/>
	);
};

export { VehicleModelSelect };
export type { VehicleModelSelectProps };
