/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import {
	Box,
	Container,
	FlexGrid,
	Heading,
	Icon,
	NextLink,
	Stack,
} from "@bilar/ui";
import { routes } from "@bilar/config";
import { MdChevronRight } from "react-icons/md";
import { useFrontpageHeroStyles } from "./frontpageHeroStyles";
import { useAppTranslation, useRouterLocale } from "@bilar/common";
import { metrics } from "@sentry/nextjs";
import { useAuthUser } from "@bilar/auth";
import posthog from "posthog-js";

type FrontPageHeroProps = {
	children?: ReactNode;
};

const FrontPageHero = (props: FrontPageHeroProps) => {
	const styles = useFrontpageHeroStyles(props);
	const { getPageUrlWithLocale } = useRouterLocale();
	const { t } = useAppTranslation();
	const { user } = useAuthUser();

	const onCtaClick = () => {
		const isLoggedIn = !!user;
		metrics.increment("hero_cta_click", 1, {
			tags: { isLoggedIn },
		});
		posthog.capture("Hero Cta Button Click", { isLoggedIn });
	};

	return (
		<div css={styles.root}>
			<Container>
				<Box css={styles.content}>
					<FlexGrid width={["100%", "75%", "66%"]}>
						<Box>
							<Heading as="h1" size="hero" mb={4} weight="hairline">
								{t("heroHeading")}
							</Heading>
							<Heading as="h2" size="regular" mb={8} weight="light">
								{t("heroSubHeading")}
							</Heading>
						</Box>
						<Stack
							orientation={["vertical", "horizontal"]}
							mb={8}
							width={["100%", undefined]}
						>
							<Box mr={[0, 4]} mb={[4, 0]}>
								<NextLink
									href={routes.vehicleClassifieds.new}
									title={t("postNewAd")}
									css={styles.ctaButton}
									onClick={onCtaClick}
									data-testid="lnk-hero-cta"
								>
									<span css={styles.ctaButtonText}>{t("postNewAd")}</span>
									<Icon
										ml={2}
										verticalAlign="middle"
										icon={<MdChevronRight size={32} />}
									/>
								</NextLink>
							</Box>

							<Box
								verticalAlignment={[undefined, "center"]}
								horizontalAlignment={["center", undefined]}
							>
								<NextLink
									href={getPageUrlWithLocale("/how-it-works")}
									underline={true}
								>
									{t("howDoesItWork")}?
								</NextLink>
							</Box>
						</Stack>
					</FlexGrid>
				</Box>
			</Container>
		</div>
	);
};

export { FrontPageHero };
export type { FrontPageHeroProps };
