import { CSSObject } from "@emotion/react";

import { FaIconProps } from "./FaIcon";
import {
	BeatConfigProps,
	BounceConfigProps,
	FadeConfigProps,
	FlipConfigProps,
} from "./faIconTypes";
import { ICON_INLINE_HEIGHT } from "./";
import { useDesignTokens } from "@bilar/ui";

type IconStyles = {
	root: CSSObject;
};

const getAnimConfig = <T>(
	props: FaIconProps,
	prop: keyof FaIconProps,
): T | undefined => {
	let bounceProps;
	if (typeof props[prop] !== "boolean") {
		bounceProps = props[prop];
	}

	// @ts-ignore // Ignores: TS2590: Expression produces a union type that is too complex to represent.
	return bounceProps;
};

const useFaAnimationStyles = (props: FaIconProps) => {
	const bounceProps = getAnimConfig<BounceConfigProps>(props, "bounceAnim");
	const beatProps = getAnimConfig<BeatConfigProps>(props, "beatAnim");
	const fadeProps = getAnimConfig<FadeConfigProps>(props, "fadeAnim");
	const beatFadeProps = getAnimConfig<FadeConfigProps & BeatConfigProps>(
		props,
		"fadeAnim",
	);
	const flipProps = getAnimConfig<FlipConfigProps>(props, "flip");

	const style: CSSObject = {
		"--fa-animation-delay": props.animationDelay,
		"--fa-animation-direction": props.animationDirection,
		"--fa-animation-duration": props.animationDuration,
		"--fa-animation-iteration-count": props.animationIterationCount,
		"--fa-animation-timing": props.animationTimingFunction,
		"--fa-beat-scale": beatProps?.scale,
		"--fa-fade-opacity": fadeProps?.opacity,
		"--fa-beat-fade-opacity": beatFadeProps?.opacity,
		"--fa-beat-fade-scale": beatFadeProps?.scale,
		"--fa-bounce": props.bounceAnim,
		"--fa-bounce-rebound": bounceProps?.rebound,
		"--fa-bounce-height": bounceProps?.height,
		"--fa-bounce-start-scale-x": bounceProps?.startScaleX,
		"--fa-bounce-start-scale-y": bounceProps?.startScaleY,
		"--fa-bounce-jump-scale-x": bounceProps?.jumpScaleX,
		"--fa-bounce-jump-scale-y": bounceProps?.jumpScaleY,
		"--fa-bounce-land-scale-x": bounceProps?.landScaleX,
		"--fa-bounce-land-scale-y": bounceProps?.landScaleY,
		"--fa-flip-x": flipProps?.x,
		"--fa-flip-y": flipProps?.y,
		"--fa-flip-z": flipProps?.z,
		"--fa-flip-angle": flipProps?.angle,
	};
	return style;
};

const useFaIconStyles = (props: FaIconProps): IconStyles => {
	const animationStyles = useFaAnimationStyles(props);
	const designTokens = useDesignTokens();
	const { size } = designTokens;
	const height = props.size ? size(props.size) : ICON_INLINE_HEIGHT;

	return {
		root: {
			display: "inline-block",
			height,
			verticalAlign: props.verticalAlign ?? undefined,
			overflow: "visible",
			...animationStyles,
		},
	};
};

export { useFaIconStyles };
