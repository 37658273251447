import {
	colours,
	FormUploadProgress,
	GlobalValues,
	StepBasicInfoFormValues,
	StepImagesFormValues,
	StepSummaryFormValues,
	StepVehicleDesignFormValues,
	StepVehicleDetailsFormValues,
	TransmissionType,
	VehicleFormValues,
} from "@bilar/features";
import {
	formUploadProgressInitialValues,
	globalInitialValues,
	stepBasicInfoInitialValues,
	stepImagesInitialValues,
	stepSummaryInitialValues,
	stepVehicleDesignInitialValues,
	stepVehicleDetailsInitialValues,
} from "./initialValues";
import {
	createContext,
	Dispatch,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from "react";
import { useIndexedDB, useMonthSelectOptions } from "@bilar/common";
import { EditableImage, Image, SerializedImageState } from "@bilar/types";
import { ClassifiedVehicle, FuelType, VehicleImage } from "@bilar/models";
import { E164Number } from "libphonenumber-js/types";
import { getBlob, getStorage, ref } from "firebase/storage";
import initFirebase from "@bilar/firebase/initFirebase";

/***** Context *****/
type VehicleFormContextValues = {
	formValues: VehicleFormValues;
	originalFormValues: VehicleFormValues | undefined;
	setGlobalValues: Dispatch<GlobalValues>;
	setStepBasicInfoValues: Dispatch<StepBasicInfoFormValues>;
	setStepVehicleDesignValues: Dispatch<StepVehicleDesignFormValues>;
	setStepVehicleDetailsValues: Dispatch<StepVehicleDetailsFormValues>;
	setStepImagesValues: Dispatch<StepImagesFormValues>;
	setStepSummaryValues: Dispatch<StepSummaryFormValues>;
	clearFormValues: () => void;
	uploadProgress: FormUploadProgress;
	setUploadProgress: Dispatch<FormUploadProgress>;
};

type SerializedValues = {
	stepImagesValues: {
		images: SerializedImageState[];
	} & Omit<StepImagesFormValues, "images">;
} & Omit<VehicleFormValues, "stepImagesValues">;

const formSerializer = (formValues: VehicleFormValues): SerializedValues => {
	return {
		...formValues,
		stepImagesValues: {
			...formValues.stepImagesValues,
			// We need to serialize the image data to be able to store more data than a Blob
			images: formValues.stepImagesValues.images.map((file) => ({
				original: {
					name: file.original.name,
					blob: file.original,
					type: file.original.type,
				},
				processed: file.processed
					? {
							name: file.processed.name,
							blob: file.processed,
							type: file.processed.type,
						}
					: undefined,
				imageState: file.imageState,
				id: file.id,
			})),
		},
	};
};

const formDeserializer = (formValues: SerializedValues): VehicleFormValues => {
	return {
		...formValues,
		stepImagesValues: {
			...formValues.stepImagesValues,
			images: formValues.stepImagesValues.images.map(
				({ original, processed, ...rest }) => {
					return {
						original: new File([original.blob], original.name, {
							type: original.blob.type ?? original.type ?? "image/jpeg",
						}),
						processed: processed
							? new File([processed.blob], processed.name, {
									type: processed.blob.type ?? processed.type ?? "image/jpeg",
								})
							: undefined,
						...rest,
					};
				},
			),
		},
	};
};

const urlToBlob = async (image: Image): Promise<Blob> => {
	const firebaseApp = initFirebase();
	const storage = getStorage(firebaseApp);
	const fileRef = ref(storage, `${image.folderPath}/${image.fileName}`);
	return getBlob(fileRef);
};

const classifiedImageToEditableImage = async (
	classifiedImage: VehicleImage,
): Promise<EditableImage<File>> => {
	const originalBlob = await urlToBlob(classifiedImage.original);
	const processedBlob = await urlToBlob(classifiedImage.processed);

	const originalFile = new File(
		[originalBlob],
		classifiedImage.original.fileName,
		{
			type: "image/jpeg",
		},
	);
	const processedFile = new File(
		[processedBlob],
		classifiedImage.processed!.fileName,
		{
			type: "image/jpeg",
		},
	);
	return {
		original: originalFile,
		processed: processedFile,
		imageState: classifiedImage.imageState,
		id: classifiedImage.id,
	};
};

const useClassifiedVehicleToFormValues = () => {
	const { getMonthName } = useMonthSelectOptions();
	const classifiedVehicleToFormValues = async (
		classified: ClassifiedVehicle,
	): Promise<VehicleFormValues> => {
		let colour = colours.find(
			(colour) => colour.name === classified.vehicleInfo.colour,
		);

		const images: EditableImage<File>[] = [];

		// Images seem to be missing initially
		if (classified.images) {
			for (const image of classified.images) {
				try {
					const editableImage = await classifiedImageToEditableImage(image);
					images.push(editableImage);
				} catch (error) {
					console.error(error);
				}
			}
		}

		return {
			globalValues: {
				classifiedAd: classified,
				stepsValid: 0,
			},
			stepBasicInfoValues: {
				vehicleBrand: {
					label: classified.vehicleInfo.brandName,
					value: classified.vehicleInfo.brandId,
				},
				vehicleModel: {
					label: classified.vehicleInfo.modelName,
					value: classified.vehicleInfo.modelId,
				},
				vehicleYear: {
					label: classified.vehicleInfo.year!.toString(),
					value: classified.vehicleInfo.year!.toString(),
				},
				willingToTrade: classified.willingToTrade,
				price: classified.price.amount ?? null,
			},
			stepVehicleDesignValues: {
				colour: colour!, // Will the form break if no colour is found?
				vehicleBodyType: classified.vehicleInfo.bodyType,
			},
			stepVehicleDetailsValues: {
				vehicleInspected: !!classified.vehicleInfo.inspected,
				vehicleFuelType: classified.vehicleInfo.fuelType as FuelType,
				vehicleMileage: classified.vehicleInfo.mileage?.mileage ?? null,
				vehicleInspectedMonth: {
					label: getMonthName(classified.vehicleInfo.inspected?.month),
					value: classified.vehicleInfo.inspected?.month?.toString() ?? "",
				},
				vehicleInspectedYear: {
					label: classified.vehicleInfo.inspected?.year?.toString() ?? "",
					value: classified.vehicleInfo.inspected?.year?.toString() ?? "",
				},
				vehicleNumberOfDoors: classified.vehicleInfo.numberOfDoors,
				vehicleNumberOfSeats: classified.vehicleInfo.numberOfSeats,
				vehicleTransmissionType: classified.vehicleInfo
					.transmissionType as TransmissionType,
				vehicleDescription: classified.description,
			},
			stepImagesValues: {
				images,
			},
			stepSummaryValues: {
				email: classified.contactEmail.address,
				hideEmail: classified.hideEmail,
				phoneNumber: classified.contactPhone.internationalNumber as E164Number,
				hidePhoneNumber: classified.hidePhoneNumber,
			},
		};
	};
	return {
		classifiedVehicleToFormValues,
	};
};

const VehicleFormContext = createContext<VehicleFormContextValues>({
	formValues: {
		globalValues: globalInitialValues,
		stepBasicInfoValues: stepBasicInfoInitialValues,
		stepVehicleDesignValues: stepVehicleDesignInitialValues,
		stepVehicleDetailsValues: stepVehicleDetailsInitialValues,
		stepImagesValues: stepImagesInitialValues,
		stepSummaryValues: stepSummaryInitialValues,
	},
	originalFormValues: undefined,
	uploadProgress: formUploadProgressInitialValues,
	setUploadProgress(value: FormUploadProgress): void {},

	setGlobalValues(value: GlobalValues): void {},
	setStepBasicInfoValues(value: StepBasicInfoFormValues): void {},
	setStepVehicleDesignValues(value: StepVehicleDesignFormValues): void {},
	setStepVehicleDetailsValues(value: StepVehicleDetailsFormValues): void {},
	setStepImagesValues(value: StepImagesFormValues): void {},
	setStepSummaryValues(value: StepSummaryFormValues): void {},
	clearFormValues(): void {},
});

const useVehicleFormContext = () => {
	return useContext(VehicleFormContext);
};

/***** Provider *****/
type VehicleFormProviderProps = {
	children: ReactNode;
	classified?: ClassifiedVehicle;
};

const STORE_NAME = "vehicleFormValues";

const VehicleFormProvider = (props: VehicleFormProviderProps) => {
	// This should only store an unmodified version of the form values, example: the one queried from the database
	const [originalFormValues, setOriginalFormValues] = useState<
		VehicleFormValues | undefined
	>();
	const [contextFormValues, setContextFormValues] = useState<VehicleFormValues>(
		{
			globalValues: globalInitialValues,
			stepBasicInfoValues: stepBasicInfoInitialValues,
			stepVehicleDesignValues: stepVehicleDesignInitialValues,
			stepVehicleDetailsValues: stepVehicleDetailsInitialValues,
			stepImagesValues: stepImagesInitialValues,
			stepSummaryValues: stepSummaryInitialValues,
		},
	);

	const [uploadProgress, setUploadProgress] = useState<FormUploadProgress>(
		formUploadProgressInitialValues,
	);

	const { classifiedVehicleToFormValues } = useClassifiedVehicleToFormValues();

	// Flag to check if we have already queried the form from the database,
	// or else it will insert a new entry on every page refresh
	const [isFormQueried, setIsFormQueried] = useState(false);

	const { database, put, getAll } = useIndexedDB({
		name: "bilar",
		version: 1,
		schema: {
			name: STORE_NAME,
			options: {
				keyPath: "id",
				autoIncrement: true,
			},
			columns: [
				{
					name: "blob",
					options: { unique: false },
				},
			],
		},
	});

	const getGlobalValuesWithStepValid = (currentStep: number): GlobalValues => {
		return {
			...contextFormValues.globalValues,
			stepsValid:
				currentStep > contextFormValues.globalValues.stepsValid
					? currentStep
					: contextFormValues.globalValues.stepsValid,
		};
	};

	const contextValues: VehicleFormContextValues = {
		formValues: contextFormValues,
		uploadProgress: uploadProgress,
		originalFormValues: originalFormValues,
		setUploadProgress: (newValue: FormUploadProgress) => {
			setUploadProgress(newValue);
		},
		setGlobalValues: (newValues: GlobalValues) => {
			setContextFormValues({
				...contextFormValues,
				globalValues: newValues,
			});
		},
		setStepBasicInfoValues: (newValues: StepBasicInfoFormValues) => {
			setContextFormValues({
				...contextFormValues,
				stepBasicInfoValues: newValues,
				globalValues: getGlobalValuesWithStepValid(1),
			});
		},
		setStepVehicleDesignValues: (newValues: StepVehicleDesignFormValues) => {
			setContextFormValues({
				...contextFormValues,
				stepVehicleDesignValues: newValues,
				globalValues: getGlobalValuesWithStepValid(2),
			});
		},
		setStepVehicleDetailsValues: (newValues: StepVehicleDetailsFormValues) => {
			setContextFormValues({
				...contextFormValues,
				stepVehicleDetailsValues: newValues,
				globalValues: getGlobalValuesWithStepValid(3),
			});
		},
		setStepImagesValues: (newValues: StepImagesFormValues) => {
			setContextFormValues({
				...contextFormValues,
				stepImagesValues: newValues,
				globalValues: getGlobalValuesWithStepValid(4),
			});
		},
		setStepSummaryValues: (newValues: StepSummaryFormValues) => {
			setContextFormValues({
				...contextFormValues,
				stepSummaryValues: newValues,
			});
		},
		clearFormValues: () => {
			setContextFormValues({
				globalValues: globalInitialValues,
				stepBasicInfoValues: stepBasicInfoInitialValues,
				stepVehicleDesignValues: stepVehicleDesignInitialValues,
				stepVehicleDetailsValues: stepVehicleDetailsInitialValues,
				stepImagesValues: stepImagesInitialValues,
				stepSummaryValues: stepSummaryInitialValues,
			});
			setUploadProgress(formUploadProgressInitialValues);
		},
	};

	useEffect(() => {
		if (database && !props.classified) {
			getAll<SerializedValues>(STORE_NAME)
				.then((values) => {
					// Get the last value, since that is the only one we are using for this purpose
					if (values.length > 0) {
						setContextFormValues(formDeserializer(values[values.length - 1]));
					}
				})
				.catch((err) => {
					console.error("err", err);
				})
				.finally(() => {
					setIsFormQueried(true);
				});
		}
	}, [database, props.classified]);

	useEffect(() => {
		if (database && isFormQueried) {
			// console.log("putting", contextFormValues.id, isFormQueried);
			// Insert or update the new form values into the database
			put(STORE_NAME, formSerializer(contextFormValues))
				.then((id) => {
					// If there is no id then it's a new entry, and we need to update the id
					// or else it will create a new entry on every state update
					if (!contextFormValues.id) {
						setContextFormValues({ ...contextFormValues, id });
					}
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}, [contextFormValues, database, isFormQueried]);

	useEffect(() => {
		if (props.classified) {
			classifiedVehicleToFormValues(props.classified).then((values) => {
				setContextFormValues(values);
				setOriginalFormValues(values);
			});
		}
	}, [props.classified]);

	return (
		<VehicleFormContext.Provider value={contextValues}>
			{props.children}
		</VehicleFormContext.Provider>
	);
};

export { useVehicleFormContext, VehicleFormProvider };
