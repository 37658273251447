/** @jsxImportSource @emotion/react */
import { ImageUploadList, useAppTranslation } from "@bilar/common";
import { useFormButtonStyles, useVehicleFormContext } from "@bilar/features";
import {
	Button,
	Divider,
	InvisibleButton,
	Panel,
	ValidationMessage,
} from "@bilar/ui";
import { useEffect, useState } from "react";
import { EditableImage } from "@bilar/types";
import { imageSizeConfig } from "@bilar/utils";

type StepImagesProps = {
	onBack: () => void;
	onContinue: (images: File[]) => void;
};

const validateFiles = (images: EditableImage<File>[]) => images.length > 0;

const StepImages = (props: StepImagesProps) => {
	const { t } = useAppTranslation();
	const { t: vt } = useAppTranslation("validation");
	const { setStepImagesValues, formValues, setGlobalValues } =
		useVehicleFormContext();
	const [error, setError] = useState<string>();
	const [readyToContinue, setReadyToContinue] = useState<boolean>(
		validateFiles(formValues.stepImagesValues.images),
	);
	const buttonStyles = useFormButtonStyles();

	const continueHandler = () => {
		setError(undefined);

		if (formValues.stepImagesValues.images.length === 0) {
			setError(vt("uploadAtLeastOneImage"));
			return;
		}

		props.onContinue(
			formValues.stepImagesValues.images.map((i) => i.processed!),
		);
	};

	useEffect(() => {
		const allowedToContinue = validateFiles(formValues.stepImagesValues.images);
		setReadyToContinue(allowedToContinue);

		if (!allowedToContinue) {
			setGlobalValues({
				...formValues.globalValues,
				stepsValid: 3,
			});
		}

		if (formValues.stepImagesValues.images.length !== 0) {
			setError(undefined);
		}
	}, [formValues.stepImagesValues.images]);

	return (
		<Panel.Body>
			<ImageUploadList
				files={formValues.stepImagesValues.images}
				onFilesAdded={(images) => {
					const imageStates = [
						...images,
						...formValues.stepImagesValues.images,
					];

					// Add the files to state
					setStepImagesValues({
						images: imageStates,
					});
				}}
				onFilesReordered={(images) => {
					// Update the files in state
					setStepImagesValues({
						images,
					});
				}}
				onFileEdited={(image) => {
					// Find the file in the state and update it
					const index = formValues.stepImagesValues.images.findIndex(
						(f) => f.id === image.id,
					);

					if (index !== -1) {
						const newImages = [...formValues.stepImagesValues.images];
						newImages[index] = image;

						setStepImagesValues({
							images: newImages,
						});
					} else {
						setStepImagesValues({
							images: [...formValues.stepImagesValues.images, image],
						});
					}
				}}
				onFileRemoved={(image) => {
					const imageStates = formValues.stepImagesValues.images.filter(
						(f) => f.id !== image.id,
					);

					// console.log("imageStates", imageStates);

					// remove the file from state
					setStepImagesValues({
						images: imageStates,
					});
				}}
				onFilesProcessed={(processedImages) => {
					// Find the processed files in the state and update them
					const images = formValues.stepImagesValues.images.map((image) => {
						const processedImage = processedImages.find(
							(f) => f.id === image.id,
						);
						return processedImage ? processedImage : image;
					});

					setStepImagesValues({
						images,
					});
				}}
				targetWidth={imageSizeConfig.fullScreen.width}
				targetHeight={imageSizeConfig.fullScreen.height}
			/>
			<ValidationMessage error={error} />
			<Divider variant="solid" my={4} />
			<div css={buttonStyles.root}>
				<InvisibleButton
					type="button"
					onClick={props.onBack}
					css={buttonStyles.backButton}
				>
					{t("goBack")}
				</InvisibleButton>

				<Button
					disabled={!readyToContinue}
					type="submit"
					palette="secondary"
					onClick={continueHandler}
					css={buttonStyles.continueButton}
				>
					{t("continueToNextStep")}
				</Button>
			</div>
		</Panel.Body>
	);
};

export { StepImages };
export type { StepImagesProps };
