/** @jsxImportSource @emotion/react */
import {
	MyClassifiedItem,
	useEditClassifiedsStyles,
	useMyClassifieds,
} from "@bilar/features";
import { User } from "@bilar/models";
import React, { useEffect } from "react";
import { Loading, useAppTranslation } from "@bilar/common";
import {
	FaIcon,
	Message,
	NextLink,
	Paper,
	Stack,
	useButtonStyles,
} from "@bilar/ui";
import { routes } from "@bilar/config";
import { faPlusSquare } from "@fortawesome/pro-solid-svg-icons";

type MyClassifiedsProps = {
	user: User;
};

const MyClassifieds = (props: MyClassifiedsProps) => {
	const { user } = props;
	const styles = useEditClassifiedsStyles(props);
	const [loading, setLoading] = React.useState(true);
	const { fetchMyClassifieds, myClassifieds } = useMyClassifieds(user);
	const { t } = useAppTranslation();
	const buttonStyles = useButtonStyles({});

	const fetchClassifieds = async () => {
		setLoading(true);
		fetchMyClassifieds().finally(() => setLoading(false));
	};

	useEffect(() => {
		fetchClassifieds();
	}, []);

	if (myClassifieds === null) {
		return (
			<Paper p={4}>
				<Loading centered={true} variant="dots" />
			</Paper>
		);
	}

	return (
		<div css={styles.root}>
			<Stack spacing={4}>
				{myClassifieds.map((classified) => (
					<MyClassifiedItem
						key={classified.id}
						data={classified}
						onDeleted={fetchClassifieds}
						onSold={fetchClassifieds}
						onPublish={fetchClassifieds}
					/>
				))}
				{loading && <Loading centered={true} />}
				{!loading && myClassifieds.length === 0 && (
					<Message heading={t("noClassifiedsFound")}>
						<p>{t("noMyClassifiedsFoundMessage")}</p>
						<NextLink href={routes.vehicleClassifieds.new} css={buttonStyles}>
							<a title={t("postNewAd")}>
								<Stack orientation="horizontal" horizontalAlignment="center">
									<FaIcon icon={faPlusSquare} size={6} mr={6} />
									{t("postNewAd")}
								</Stack>
							</a>
						</NextLink>
					</Message>
				)}
			</Stack>
		</div>
	);
};

export { MyClassifieds };
export type { MyClassifiedsProps };
