import {
	Currency,
	dinero,
	Dinero,
	toFormat,
	toUnit,
	Transformer,
} from "dinero.js";
import getSymbolFromCurrency from "currency-symbol-map";
import { parseStringToInt } from "@bilar/utils";
import { CountryCode } from "@bilar/ui";
import { EUR, ISK, NOK, SEK } from "@dinero.js/currencies";

export const getLanguageFromCurrency = (currency: Currency<number>) => {
	switch (currency.code) {
		case "ISK":
			return "is";
		case "SEK":
			return "sv";
		default:
			return "is";
	}
};

export const getCountryCurrency = (
	countryCode: CountryCode,
): Currency<number> => {
	switch (countryCode) {
		case "IS":
			return ISK;
		case "SE":
			return SEK;
		case "NO":
			return NOK;
		default:
			return EUR;
	}
};

// getCurrencyByString
export const getCurrencyByString = (currencyCode: string): Currency<number> => {
	switch (currencyCode) {
		case "ISK":
			return ISK;
		case "EUR":
			return EUR;
		case "SEK":
			return SEK;
		case "NOK":
			return NOK;
		default:
			return ISK;
	}
};

export const formatCurrencyString = (
	moneyString: string,
	locale: string,
	currency: Currency<number>,
	includeSymbol = true,
) => {
	// Gets the number of fraction digits for the language
	const currencyFractionDigits = new Intl.NumberFormat(locale, {
		style: "currency",
		currency: currency.code,
	}).resolvedOptions().maximumFractionDigits;

	// Returns a formatted number with separators (ex: 100.000)
	const getNationalNumberFormat = (amount: number) => {
		let formattedAmount = Intl.NumberFormat(locale, {
			maximumFractionDigits: currencyFractionDigits,
		}).format(amount);

		if (amount >= 1000000 && locale === "is") {
			// Split the string into the million and thousand parts
			let parts = formattedAmount.split(".");
			// Join the parts with a comma
			formattedAmount = parts.join(",");
			// Replace the last comma with a dot
			formattedAmount = formattedAmount.replace(/,([^,]*)$/, ".$1");
		} else if (locale === "is") {
			// Replace the comma with a dot for lower numbers than a million
			formattedAmount = formattedAmount.replace(/,/g, ".");
		}

		return formattedAmount;
	};

	const transformerISK: Transformer<number> = ({ amount, currency }) => {
		const value = getNationalNumberFormat(amount);

		if (includeSymbol) {
			return `${value} ${getSymbolFromCurrency(currency.code)}.`;
		}

		return value;
	};
	const transformerSEK: Transformer<number> = ({ amount, currency }) => {
		const value = getNationalNumberFormat(amount);

		if (includeSymbol) {
			return `${value} ${getSymbolFromCurrency(currency.code)}`;
		}

		return value;
	};

	const transformerOther: Transformer<number> = ({ amount, currency }) => {
		if (includeSymbol) {
			return `${getSymbolFromCurrency(currency.code)} ${amount}`;
		}

		return amount.toString();
	};

	// Awesome name, I know :D
	const getTransformer = () => {
		switch (currency.code) {
			case "ISK":
				return transformerISK;
			case "SEK":
				return transformerSEK;
			default:
				return transformerOther;
		}
	};
	let money: Dinero<number>;
	const moneyNumber = parseStringToInt(moneyString);

	if (isNaN(moneyNumber)) {
		money = dinero({
			amount: 0,
			currency: currency,
		});

		return toFormat(money, getTransformer());
	}

	money = dinero({
		amount: moneyNumber,
		currency: currency,
	});

	return toFormat(money, getTransformer());
};

export const formatCurrency = (
	money: string | number | null | undefined,
	locale: string,
	currency: Currency<number>,
	includeSymbol = true,
): string => {
	if (money === null || money === undefined) return "";

	// Keep only digits, dots and commas (replaceAll is not supported some older browsers, we had bugs from Chrome v81)
	// const value = money?.toString().replaceAll(/[^\d,.]/g, "");
	const value = money?.toString().replace(/[^\d,.]/g, "");

	if (value === "") return "";

	return formatCurrencyString(value, locale, currency, includeSymbol);
};

export const getCurrencyUnit = (
	moneyString: string,
	currency: Currency<number>,
): number => {
	if (!moneyString) return 0;
	const moneyNumber = parseStringToInt(moneyString);

	if (isNaN(moneyNumber)) return 0;

	const money = dinero({ amount: moneyNumber, currency: currency });

	return toUnit(money);
};
