/** @jsxImportSource @emotion/react */
import { useVehicleInfoChipsStyles } from "./vehicleInfoChipsStyles";
import { useDisplayFormat } from "@bilar/features";
import {
	carDoorIcon,
	FaIcon,
	Stack,
	Tooltip,
	TooltipContent,
	TooltipTrigger,
	VehicleBody,
} from "@bilar/ui";
import { capitalizeFirstLetter } from "@bilar/utils";
import {
	faGasPump,
	faMapPin,
	faPersonSeatReclined,
} from "@fortawesome/pro-solid-svg-icons";
import { useAppTranslation } from "@bilar/common";
import { VehicleInfo } from "@bilar/models";

type VehicleInfoChipsProps = {
	data: VehicleInfo;
};

const VehicleInfoChips = (props: VehicleInfoChipsProps) => {
	const styles = useVehicleInfoChipsStyles(props);
	const { t } = useAppTranslation();
	const { getFuelTypeLabel } = useDisplayFormat();
	const { data } = props;

	return (
		<div css={styles.root}>
			{data.year && (
				<Tooltip>
					<TooltipTrigger>
						<Stack orientation="horizontal" spacing={1} css={styles.item}>
							{data.year}
						</Stack>
					</TooltipTrigger>
					<TooltipContent>{t("vehicleYear")}</TooltipContent>
				</Tooltip>
			)}
			{data.colour && (
				<Tooltip>
					<TooltipTrigger>
						<Stack
							orientation="horizontal"
							spacing={1}
							css={[styles.item, styles.itemWithColour]}
						>
							{t(`colour${capitalizeFirstLetter(data.colour!)}`)}
						</Stack>
					</TooltipTrigger>
					<TooltipContent>{t("colour")}</TooltipContent>
				</Tooltip>
			)}
			{data.bodyType && (
				<Tooltip>
					<TooltipTrigger>
						<Stack orientation="horizontal" spacing={1} css={styles.item}>
							<VehicleBody bodyType={data.bodyType!} size={4} align="center" />
							{t(`bodyType_${data.bodyType}`)}
						</Stack>
					</TooltipTrigger>
					<TooltipContent>{t("vehicleBodyType")}</TooltipContent>
				</Tooltip>
			)}

			{data.transmissionType && (
				<Tooltip>
					<TooltipTrigger>
						<Stack orientation="horizontal" spacing={1} css={styles.item}>
							<FaIcon icon={faMapPin} />
							{data.transmissionType === "manual"
								? t("transmissionManual")
								: t("transmissionAutomatic")}
						</Stack>
					</TooltipTrigger>
					<TooltipContent>{t("vehicleTransmission")}</TooltipContent>
				</Tooltip>
			)}

			{data.fuelType && (
				<Tooltip>
					<TooltipTrigger>
						<Stack orientation="horizontal" spacing={1} css={styles.item}>
							<FaIcon icon={faGasPump} />
							{getFuelTypeLabel(data.fuelType)}
						</Stack>
					</TooltipTrigger>
					<TooltipContent>{t("fuelType")}</TooltipContent>
				</Tooltip>
			)}

			{data.numberOfSeats && (
				<Tooltip>
					<TooltipTrigger>
						<Stack orientation="horizontal" spacing={1} css={styles.item}>
							<FaIcon icon={faPersonSeatReclined} />
							{data.numberOfSeats} {t("seats")}
						</Stack>
					</TooltipTrigger>
					<TooltipContent>{t("numberOfSeats")}</TooltipContent>
				</Tooltip>
			)}

			{data.numberOfDoors && (
				<Tooltip>
					<TooltipTrigger>
						<Stack orientation="horizontal" spacing={1} css={styles.item}>
							<FaIcon icon={carDoorIcon} flip="horizontal" />
							{data.numberOfDoors} {t("doors")}
						</Stack>
					</TooltipTrigger>
					<TooltipContent>{t("numberOfDoors")}</TooltipContent>
				</Tooltip>
			)}
		</div>
	);
};

export { VehicleInfoChips };
export type { VehicleInfoChipsProps };
