/** @jsxImportSource @emotion/react */
import { useContactConfirmationBoxStyles } from "@bilar/features";
import React, {
	Dispatch,
	FormEvent,
	SetStateAction,
	useEffect,
	useRef,
} from "react";
import {
	Box,
	Button,
	Checkbox,
	Heading,
	Icon,
	Panel,
	PhoneField,
	Stack,
	TextField,
	useFormWithYup,
	ValidationMessage,
} from "@bilar/ui";
import { MdInfoOutline } from "react-icons/md";
import { useAppTranslation } from "@bilar/common";
import * as yup from "yup";
import { ObjectSchema } from "yup";

type ContactConfirmationBoxProps = {
	heading: string;
	subHeading: string;
	onSubmit: (values: ContactFormValues) => void;
	initialValues: ContactFormValues;
	triggerSyncState: [boolean, Dispatch<SetStateAction<boolean>>];
	disabled?: boolean;
};

type ContactFormValues = {
	email: string;
	phoneNumber: string;
	hidePhoneNumber: boolean;
	hideEmail: boolean;
	checkHiddenContactInfo?: boolean;
};

const useSchema = (): ObjectSchema<ContactFormValues> => {
	const { t: vt } = useAppTranslation("validation");

	return yup.object({
		email: yup.string().email().required(vt("required")),
		phoneNumber: yup
			.string()
			.phone(vt("invalidPhoneNumber"))
			.required(vt("required")),
		hideEmail: yup.boolean().defined(),
		hidePhoneNumber: yup.boolean().defined(),
		checkHiddenContactInfo: yup
			.boolean()
			.test(
				"checkHiddenContactInfo",
				vt("checkHiddenContactInfo"),
				function (value, context) {
					// Accessing the parent object to get hideEmail and hidePhoneNumber values
					const { hideEmail, hidePhoneNumber } = context.parent;
					// Count how many of the fields are true (checked)
					const checkedCount = [hideEmail, hidePhoneNumber].filter(
						Boolean,
					).length;
					// Valid if 0 or 1 of the fields are checked
					return checkedCount <= 1;
				},
			),
	});
};

const ContactConfirmationBox = (props: ContactConfirmationBoxProps) => {
	const styles = useContactConfirmationBoxStyles(props);
	const { disabled, heading, subHeading, initialValues, onSubmit } = props;
	const { t } = useAppTranslation();
	const schema = useSchema();
	const rootRef = useRef<HTMLDivElement>(null);
	const [triggerSync, setTriggerSync] = props.triggerSyncState;

	useEffect(() => {
		if (triggerSync) {
			handleSubmit(
				() => {
					onSubmit(values);
					setTriggerSync(false);
				},
				() => {
					setTriggerSync(false);
				},
			);
		}
	}, [triggerSync]);

	const {
		textFieldProps,
		phoneFieldProps,
		booleanFieldProps,
		values,
		handleChange,
		handleSubmit,
		errors,
	} = useFormWithYup<ContactFormValues>({
		initialValues,
		validationSchema: schema,
	});

	const onLocalSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		handleSubmit(
			() => {
				onSubmit(values);
			},
			() => {
				rootRef.current?.scrollIntoView({
					behavior: "smooth",
				});
			},
		);
	};

	return (
		<div ref={rootRef}>
			<Panel css={styles.root}>
				<form onSubmit={onLocalSubmit}>
					<Panel.Body>
						<Stack orientation="horizontal">
							<Icon
								size={12}
								mr={4}
								icon={<MdInfoOutline />}
								palette="neutral"
								paletteShade="dark"
							/>
							<div>
								<Heading as="h3">{heading}</Heading>
								<p>{subHeading}</p>
							</div>
						</Stack>
						<Stack orientation={["vertical", "vertical", "horizontal"]}>
							<Box mr={[0, 2]} mb={[4]}>
								<PhoneField
									label={t("phone")}
									defaultCountry={"IS"}
									{...phoneFieldProps("phoneNumber")}
									data-rp-mask="true"
								/>
							</Box>
							<Box ml={[0, 2]} mb={[4]}>
								<TextField
									label={t("email")}
									{...textFieldProps("email")}
									autoComplete="email"
									disabled={disabled}
									data-rp-mask="true"
								/>
							</Box>
						</Stack>
						<Stack>
							<Stack orientation={["vertical", "horizontal"]}>
								<Box width={["100%", "50%"]} pr={[0, 2]} mb={[4, 0]}>
									<Checkbox
										label={"Hide my phone number"}
										{...booleanFieldProps("hidePhoneNumber")}
										onChange={(checked) => {
											handleChange([
												["hidePhoneNumber", checked],
												["hideEmail", !checked],
											]);
										}}
									/>
								</Box>
								<Box width={["100%", "50%"]} pl={[0, 2]}>
									<Checkbox
										label={"Hide my email"}
										{...booleanFieldProps("hideEmail")}
										onChange={(checked) => {
											handleChange([
												["hideEmail", checked],
												["hidePhoneNumber", !checked],
											]);
										}}
									/>
								</Box>
							</Stack>
							{errors.checkHiddenContactInfo && (
								<Box mt={5}>
									<ValidationMessage error={errors.checkHiddenContactInfo} />
								</Box>
							)}
						</Stack>
					</Panel.Body>
					<Panel.Footer css={styles.footer}>
						<Button type="submit" palette="success" disabled={disabled}>
							{t("publishClassifiedAd")}
						</Button>
					</Panel.Footer>
				</form>
			</Panel>
		</div>
	);
};

export { ContactConfirmationBox };
export type { ContactConfirmationBoxProps, ContactFormValues };
