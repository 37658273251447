import React, { Fragment, useEffect } from "react";
import { BaseLayout } from "@bilar/layout";
import NProgress from "nprogress";
import { Router } from "next/router";
import "nprogress/nprogress.css";
import {
	AppProvider,
	AutoIdContext,
	designTokens,
	DesignTokensProvider,
} from "@bilar/ui";
import { AuthProvider } from "@bilar/auth";
import "@pqina/pintura/pintura.css";
import "react-image-gallery/styles/css/image-gallery.css";
import Head from "next/head";
import { appWithTranslation } from "next-i18next";
import { AppProps } from "next/app";
import { SignInModalProvider, VehicleSearchProvider } from "@bilar/features";
import { AnimatePresence, motion, MotionConfig, Variants } from "framer-motion";
import { QueryParamProvider } from "use-query-params";
import NextAdapterPages from "next-query-params";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/next";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { getConfig } from "@bilar/config";

NProgress.configure({ showSpinner: false });

Router.events.on("routeChangeStart", () => {
	NProgress.start();
});

Router.events.on("routeChangeComplete", () => {
	NProgress.done();
});

Router.events.on("routeChangeError", () => {
	NProgress.done();
});

if (typeof window !== "undefined") {
	const { postHog } = getConfig().public;
	// checks that we are client-side
	posthog.init(postHog.apiKey, {
		api_host: "/ph",
		ui_host: postHog.host,
		loaded: (posthog) => {
			if (process.env.NODE_ENV === "development") {
				posthog.debug(); // debug mode in development
			}
		},
	});
}

// type AppProps = {
// 	children: ReactNode;
// 	Component: any;
// 	pageProps: any;
// 	router: any;
// };

// Will be called once for every metric that has to be reported.
// export function reportWebVitals(metric: any) {
// 	// These metrics can be sent to any analytics service
// 	console.log(metric);
// }

export const App = ({ Component, pageProps, router }: AppProps) => {
	const { baseUrl: configBaseUrl } = getConfig().public;
	const { locale, asPath } = router;

	let baseUrl = configBaseUrl;

	// // Ensure it starts with "www"
	// if (!baseUrl.startsWith("www.")) {
	// 	baseUrl = baseUrl.replace("//", "//www.");
	// }

	const alternateUrls = {
		is: `${baseUrl}${asPath}`,
		en: `${baseUrl}/en${asPath}`,
	};

	useEffect(() => {
		// Track page views
		const handleRouteChange = () => posthog?.capture("$pageview");
		router.events.on("routeChangeComplete", handleRouteChange);

		return () => {
			router.events.off("routeChangeComplete", handleRouteChange);
		};
	}, []);

	const betweenDuration = 0.3;

	const pageVariant: Variants = {
		pageInitial: {
			opacity: 0,
			x: 0,
		},
		pageAnimate: {
			opacity: 1,
			transition: {
				duration: 0.5,
			},
		},
		pageExit: {
			opacity: 0,
			// x: "-100vw",
			transition: {
				duration: betweenDuration,
				// when: "beforeChildren",
			},
		},
	};

	return (
		<Fragment>
			<Head>
				<title>Bílar.is - smáauglýsingar fyrir ökutæki</title>
				<meta
					name="description"
					content="Seldu bílinn sjálfur með Bílar.is"
					key="meta-description"
				/>
				<meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
				{/*<meta name="viewport" content="initial-scale=1, viewport-fit=cover" />*/}
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, viewport-fit=cover"
				/>
				<meta
					name="theme-color"
					content="#4CAF50"
					// media="(prefers-color-scheme: light)"
				/>
				<link
					rel="canonical"
					href={`${baseUrl}${locale === "en" ? "/en" : ""}${asPath}`}
					key="canonical"
				/>
				<link
					rel="alternate"
					hrefLang="is"
					href={alternateUrls.is}
					key="alternate-is"
				/>
				<link
					rel="alternate"
					hrefLang="en"
					href={alternateUrls.en}
					key="alternate-en"
				/>
				<link
					rel="alternate"
					hrefLang="x-default"
					href={alternateUrls.is}
					key="alternate-x-default"
				/>
			</Head>
			<PostHogProvider client={posthog}>
				<AppProvider>
					<AuthProvider>
						<DesignTokensProvider designTokens={designTokens}>
							<AutoIdContext.Provider value={{ count: 0 }}>
								<SignInModalProvider>
									<MotionConfig transition={{ duration: 0.3 }}>
										<QueryParamProvider adapter={NextAdapterPages}>
											<VehicleSearchProvider>
												<BaseLayout>
													<AnimatePresence mode="wait">
														<motion.div
															key={router.route}
															variants={pageVariant}
															initial="pageInitial"
															animate="pageAnimate"
															exit="pageExit"
														>
															<Component {...pageProps} key={router.route} />
														</motion.div>
													</AnimatePresence>
												</BaseLayout>
											</VehicleSearchProvider>
										</QueryParamProvider>
									</MotionConfig>
								</SignInModalProvider>
							</AutoIdContext.Provider>
						</DesignTokensProvider>
					</AuthProvider>
				</AppProvider>
			</PostHogProvider>
			<Analytics />
			<SpeedInsights />
		</Fragment>
	);
};

export default appWithTranslation(App);
