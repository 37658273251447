/** @jsxImportSource @emotion/react */
import { useVehicleBodyStyles } from "./vehicleBodyStyles";
import { PaletteOptions, PaletteShadeOptions, SpacingSize } from "@bilar/ui";
import { VehicleBodyType } from "@bilar/models";
// import micro from "../../assets/img/vehicle-types/micro.svg";

type VehicleBodyProps = {
	bodyType: VehicleBodyType;
	palette?: PaletteOptions;
	paletteShade?: PaletteShadeOptions;
	size?: SpacingSize;
	align?: "left" | "center" | "right";
};

const VehicleBody = (props: VehicleBodyProps) => {
	const styles = useVehicleBodyStyles(props);

	return (
		<div css={styles.root}>
			<div css={styles.image}></div>
		</div>
	);
};

export { VehicleBody };
export type { VehicleBodyProps };
