/**
 * Function to detect if the device has touch support
 * and is actually a mobile device (not a desktop with touch support).
 */
export const detectDevice = () => {
	const isClient = typeof window === "object";

	const hasTouch = isClient
		? "ontouchstart" in window || navigator.maxTouchPoints > 0
		: false;
	const isMobile = isClient
		? /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent,
			)
		: false;
	const isRealMobile = hasTouch && isMobile;

	return { isRealMobile };
};
