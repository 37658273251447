/** @jsxImportSource @emotion/react */
import { ChangeEvent, FocusEvent, Fragment, ReactNode, useState } from "react";
import { BaseProps, Box, ValidationMessage } from "../../index";
import { getCheckboxSize, useCheckboxStyles } from "./checkboxStyles";
import { getTestId } from "../../../utils";
import { motion, useMotionValue, useTransform } from "framer-motion";
import { useDesignTokens } from "../../../styling";

type CheckboxProps = {
	label?: string | ReactNode;
	error?: string;
	disabled?: boolean;
	onChange?: (checked: boolean) => void;
} & CheckboxDefaultProps &
	BaseProps;

type LabelPosition = "right"; // "left"
type CheckboxSize = "small" | "regular" | "large";

type CheckboxDefaultProps = {
	layout?: "regular" | "box";
	checked?: boolean;
	labelPosition?: LabelPosition;
	size?: CheckboxSize;
};

const defaultProps: CheckboxDefaultProps = {
	layout: "regular",
	checked: false,
	labelPosition: "right",
	size: "regular",
};

/**
 * TODO add notes about this component.
 */
const Checkbox = (props: CheckboxProps) => {
	const { checked, disabled, testId } = props;
	const pathLength = useMotionValue(0);
	const opacity = useTransform(pathLength, [0.05, 0.15], [0, 1]);
	const checkboxSize = getCheckboxSize(props.size!);
	const { palette, ...tokens } = useDesignTokens();
	const delay = 0.05;

	// Focus and blur used in styles
	const [focused, setFocused] = useState(false);
	const styles = useCheckboxStyles(props, focused);

	const tickVariants = {
		pressed: (isChecked: boolean) => ({ pathLength: isChecked ? 0.85 : 0.15 }),
		checked: { pathLength: 1, transition: { delay } },
		unchecked: { pathLength: 0 },
	};

	const boxVariants = {
		hover: { scale: 1.05, strokeWidth: 60 },
		pressed: { scale: 0.95, strokeWidth: 35 },
		checked: {
			backgroundColor: palette.success.main.background,
		},
		unchecked: {
			backgroundColor: palette.white,
			transition: { delay },
		},
	};

	const onChangeLocally = (event: ChangeEvent<HTMLInputElement>) => {
		if (props.onChange) props.onChange(event.target.checked);
	};

	const onFocus = (event: FocusEvent<HTMLInputElement>) => {
		setFocused(true);
	};

	const onBlur = (event: FocusEvent<HTMLInputElement>) => {
		setFocused(false);
	};

	return (
		<Fragment>
			<label css={styles.root} data-testid={getTestId(testId, "label")}>
				<span className="check-wrapper" css={styles.checkWrapper}>
					<input
						data-testid={getTestId(testId)}
						type="checkbox"
						css={styles.checkboxInput}
						checked={checked}
						disabled={disabled}
						onChange={onChangeLocally}
						onFocus={onFocus}
						onBlur={onBlur}
					/>

					<motion.div
						variants={boxVariants}
						css={styles.checkBackground}
						animate={checked ? "checked" : "unchecked"}
					/>

					<svg width={checkboxSize} height={checkboxSize} viewBox="0 0 440 440">
						<motion.path
							d="M 0 128.666 L 128.658 257.373 L 341.808 0"
							transform="translate(80 100) rotate(-4 170.904 128.687) scale(0.7)"
							fill="transparent"
							strokeWidth="65"
							stroke={palette.white}
							strokeLinecap="square"
							strokeLinejoin="miter"
							variants={tickVariants}
							style={{ pathLength, opacity }}
							animate={checked ? "checked" : "unchecked"}
						/>
					</svg>
				</span>
				{props.label && (
					<span css={styles.text}>
						{typeof props.label === "string" ? (
							<span>{props.label}</span>
						) : (
							<Fragment>{props.label}</Fragment>
						)}
					</span>
				)}
			</label>
			{props.error && (
				<Box mt={5}>
					<ValidationMessage
						testId={getTestId(testId, "validation")}
						error={props.error}
					/>
				</Box>
			)}
		</Fragment>
	);
};

Checkbox.defaultProps = defaultProps;

export { Checkbox };
export type { CheckboxProps, CheckboxSize, LabelPosition };
