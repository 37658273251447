import { Radii } from "./types";

const radii: Radii = {
	none: "0",
	sm: "0.125rem",
	md: "0.25rem",
	lg: "0.5rem",
	full: "9999px",
};

export default radii;
