import { DisplayImage } from "@bilar/models";

export * from "./VehicleImageGallery";
export * from "./vehicleImageGalleryStyles";
export * from "./imageUtils";

export type OldGalleryImage = {
	thumbnail: DisplayImage;
	medium: DisplayImage;
	fullScreen: DisplayImage;
};
