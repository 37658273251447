import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import React, {
	ReactNode,
	RefObject,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import {
	$createParagraphNode,
	$getSelection,
	$isRangeSelection,
	DEPRECATED_$isGridSelection,
	FORMAT_ELEMENT_COMMAND,
	LexicalEditor,
	SELECTION_CHANGE_COMMAND,
} from "lexical";
import { $setBlocksType } from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
	$isListNode,
	INSERT_ORDERED_LIST_COMMAND,
	INSERT_UNORDERED_LIST_COMMAND,
	ListNode,
	REMOVE_LIST_COMMAND,
} from "@lexical/list";
import {
	$createHeadingNode,
	$isHeadingNode,
	HeadingTagType,
} from "@lexical/rich-text";
import { FaIcon, Menu, Stack, ToggleButton } from "@bilar/ui";
import {
	faAlignCenter,
	faAlignJustify,
	faAlignLeft,
	faAlignRight,
	faH1,
	faH2,
	faH3,
	faH4,
	faH5,
	faH6,
	faListOl,
	faListUl,
	faParagraph,
	faQuotes,
} from "@fortawesome/pro-solid-svg-icons";
import { useAppTranslation } from "@bilar/common";

const LowPriority = 1;

type BlockType = "paragraph" | "quote" | "ul" | "ol" | HeadingTagType;

const blockTypesDropDown = new Set<BlockType>([
	"paragraph",
	"quote",
	"h1",
	"h2",
]);

// Translation keys
const blockTypeToBlockName: Record<BlockType, string> = {
	h1: "richTextEditorBlockH1",
	h2: "richTextEditorBlockH2",
	h3: "richTextEditorBlockH3",
	h4: "richTextEditorBlockH4",
	h5: "richTextEditorBlockH5",
	h6: "richTextEditorBlockH6",
	ol: "richTextEditorBlockOl",
	ul: "richTextEditorBlockUl",
	paragraph: "richTextEditorBlockP",
	quote: "richTextEditorBlockQuote",
};

type BlockOptionsDropdownListProps = {
	editor: LexicalEditor;
	blockType: BlockType;
	toolbarRef: RefObject<HTMLDivElement>;
};

const getBlockIcon = (type: BlockType): ReactNode => {
	const components = {
		h1: <FaIcon icon={faH1} />,
		h2: <FaIcon icon={faH2} />,
		h3: <FaIcon icon={faH3} />,
		h4: <FaIcon icon={faH4} />,
		h5: <FaIcon icon={faH5} />,
		h6: <FaIcon icon={faH6} />,
		ol: <FaIcon icon={faListOl} />,
		paragraph: <FaIcon icon={faParagraph} />,
		quote: <FaIcon icon={faQuotes} />,
		ul: <FaIcon icon={faListUl} />,
	};

	return components[type];
};

const BlockOptionsMenu = ({
	editor,
	blockType,
}: BlockOptionsDropdownListProps) => {
	const { t } = useAppTranslation("editor");

	const formatParagraph = () => {
		if (blockType !== "paragraph") {
			editor.update(() => {
				const selection = $getSelection();

				if (
					$isRangeSelection(selection) ||
					DEPRECATED_$isGridSelection(selection)
				) {
					$setBlocksType(selection, () => $createParagraphNode());
				}
			});
		}
	};

	const formatHeading = (headingSize: HeadingTagType) => {
		if (blockType !== headingSize) {
			editor.update(() => {
				const selection = $getSelection();
				if (
					$isRangeSelection(selection) ||
					DEPRECATED_$isGridSelection(selection)
				) {
					$setBlocksType(selection, () => $createHeadingNode(headingSize));
				}
			});
		}
	};

	const actionLookup = new Map<BlockType, (block: HeadingTagType) => void>([
		["paragraph", formatParagraph],
		["h1", formatHeading],
		["h2", formatHeading],
		["h3", formatHeading],
		["h4", formatHeading],
		["h5", formatHeading],
		["h6", formatHeading],
	]);

	return (
		<Menu>
			<Menu.Button variant="outlined">
				<Stack
					orientation="horizontal"
					spacing={3}
					verticalAlignment="center"
					css={{ whiteSpace: "nowrap" }}
				>
					{getBlockIcon(blockType)}
					{t(blockTypeToBlockName[blockType])}
				</Stack>
			</Menu.Button>
			<Menu.List>
				{Array.from(blockTypesDropDown).map((type) => (
					<Menu.List.TextItem
						key={type}
						onSelect={() => actionLookup.get(type)?.(type as HeadingTagType)}
						px={4}
					>
						<Stack
							orientation="horizontal"
							spacing={3}
							verticalAlignment="center"
						>
							{getBlockIcon(type)}
							<div>{t(blockTypeToBlockName[type])}</div>
						</Stack>
					</Menu.List.TextItem>
				))}
			</Menu.List>
		</Menu>
	);
};

export default function ToolbarPlugin() {
	const [editor] = useLexicalComposerContext();
	const toolbarRef = useRef(null);
	const [blockType, setBlockType] = useState<BlockType>("paragraph");
	const [alignment, setAlignment] = useState<string>();

	const formatBulletList = () => {
		if (blockType === "ul") {
			editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
		} else {
			editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
		}
	};

	const formatNumberedList = () => {
		if (blockType === "ol") {
			editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
		} else {
			editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
		}
	};

	// FIXME: cognitive complexity
	const updateToolbar = useCallback(() => {
		const selection = $getSelection();
		if ($isRangeSelection(selection)) {
			const anchorNode = selection.anchor.getNode();
			const element =
				anchorNode.getKey() === "root"
					? anchorNode
					: anchorNode.getTopLevelElementOrThrow();
			const elementKey = element.getKey();
			const elementDOM = editor.getElementByKey(elementKey);
			if (elementDOM !== null) {
				if ($isListNode(element)) {
					const parentList = $getNearestNodeOfType(anchorNode, ListNode);
					const type = parentList ? parentList.getTag() : element.getTag();
					setBlockType(type);
				} else {
					const type = $isHeadingNode(element)
						? element.getTag()
						: element.getType();
					setBlockType(type as BlockType);
				}
			}
		}
	}, [editor]);

	useEffect(() => {
		return mergeRegister(
			editor.registerUpdateListener(({ editorState }) => {
				editorState.read(() => {
					updateToolbar();
				});
			}),
			editor.registerCommand(
				SELECTION_CHANGE_COMMAND,
				(_payload, newEditor) => {
					updateToolbar();
					return false;
				},
				LowPriority,
			),
		);
	}, [editor, updateToolbar]);

	return (
		<Stack spacing={4} orientation="horizontal" verticalAlignment="stretch">
			<BlockOptionsMenu
				editor={editor}
				blockType={blockTypesDropDown.has(blockType) ? blockType : "paragraph"}
				toolbarRef={toolbarRef}
			/>
			<Stack spacing={1} orientation="horizontal" verticalAlignment="stretch">
				<ToggleButton isActive={blockType === "ul"} onClick={formatBulletList}>
					{getBlockIcon("ul")}
				</ToggleButton>
				<ToggleButton
					isActive={blockType === "ol"}
					onClick={formatNumberedList}
				>
					{getBlockIcon("ol")}
				</ToggleButton>
			</Stack>

			<Stack spacing={1} orientation="horizontal" verticalAlignment="stretch">
				<ToggleButton
					// isActive={alignment === "left"}
					isActive={false}
					onClick={() => {
						editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
						setAlignment("left");
					}}
				>
					<FaIcon icon={faAlignLeft} />
				</ToggleButton>
				<ToggleButton
					// isActive={alignment === "center"}
					isActive={false}
					onClick={() => {
						editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
						setAlignment("center");
					}}
				>
					<FaIcon icon={faAlignCenter} />
				</ToggleButton>
				<ToggleButton
					// isActive={alignment === "right"}
					isActive={false}
					onClick={() => {
						editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
						setAlignment("right");
					}}
				>
					<FaIcon icon={faAlignRight} />
				</ToggleButton>
				<ToggleButton
					// isActive={alignment === "justify"}
					isActive={false}
					onClick={() => {
						editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
						setAlignment("justify");
					}}
				>
					<FaIcon icon={faAlignJustify} />
				</ToggleButton>
			</Stack>
		</Stack>
	);
}
