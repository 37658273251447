/** @jsxImportSource @emotion/react */
import * as React from "react";
import {
	ReactNode,
	useEffect,
	useLayoutEffect,
	useReducer,
	useRef,
} from "react";
import {
	BaseProps,
	DividerItemProps,
	gridSystem,
	ListBoxButton,
	ListBoxList,
	ListBoxPopover,
	ListItem,
	OptionChildCallback,
	SpacingSize,
} from "@bilar/ui";
import { initialState, reducer } from "./state";
import {
	autoUpdate,
	flip,
	offset,
	size,
	useClick,
	useDismiss,
	useFloating,
	useInteractions,
	useListNavigation,
	useRole,
	useTypeahead,
} from "@floating-ui/react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { PrimitiveType } from "@bilar/types";
import { ListBoxContext } from "./ListBoxContext";

const overflowPadding = 10;

type ListBoxProps<T> = {
	items: ListItem<T>[];
	dividers?: number[];
	disabled?: boolean;
	onSelect?: (value: T, index: number) => void;
	children: ReactNode;
	/**
	 * Width of the list, this is necessary because the list virtualization positions the elements absolutely
	 * @default 40
	 */
	popoverWidth?: SpacingSize;
	value?: T;
	/**
	 * Space in grid units between the button and the listbox
	 * @default 3
	 */
	offset?: SpacingSize;
	getDividerComponent?: (props: DividerItemProps) => React.ReactElement;
	getOptionComponent?: OptionChildCallback<T>;
	isOpen?: boolean;
	onOpenChange?: (isOpen: boolean) => void;
	/**
	 * The index of the active item, it can be used to select an active item when the listbox is opened
	 */
	activeIndex?: number;
	focusItemOnOpen?: boolean;
} & BaseProps;

export const ListBox = <T = PrimitiveType,>(props: ListBoxProps<T>) => {
	const {
		items,
		children,
		onSelect,
		value,
		dividers,
		getOptionComponent,
		getDividerComponent,
		disabled = false,
		popoverWidth = 40,
		offset: popoverOffset = 2,
		isOpen,
		onOpenChange,
		activeIndex,
		focusItemOnOpen = true,
	} = props;
	const [state, dispatch] = useReducer(reducer, {
		...initialState,
		isPopoverOpen: !!isOpen,
	});

	const listElementsRef = useRef<Array<HTMLElement | null>>([
		...Array(items.length).fill(null),
	]);
	const listContentRef = useRef<Array<string | null>>(
		items.map((item) => item.label),
	);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const wrapperParentRef = useRef<HTMLDivElement>(null);
	const isTypingRef = useRef(false);

	// When closing the popover, we want to reset if it's a pointer or a keyboard interaction
	if (!state.isPopoverOpen && state.isPointer) {
		dispatch({ type: "SET_IS_POINTER", payload: false });
	}

	const floating = useFloating<HTMLButtonElement>({
		open: state.isPopoverOpen,
		placement: "bottom-start",
		onOpenChange: (isOpen) => {
			if (isOpen) {
				dispatch({ type: "OPEN_POPOVER" });
			} else {
				dispatch({ type: "CLOSE_POPOVER" });
			}
		},
		whileElementsMounted: autoUpdate,
		middleware: [
			offset(popoverOffset * gridSystem.size),
			flip({ padding: overflowPadding }),
			size({
				padding: overflowPadding,
			}),
		],
	});

	const virtualizer = useVirtualizer({
		count: state.items.length,
		getScrollElement: () => wrapperParentRef.current,
		estimateSize: () => 36,
		overscan: 5,
	});

	const click = useClick(floating.context);
	const role = useRole(floating.context, { role: "listbox" });
	const dismiss = useDismiss(floating.context);
	const listNavigation = useListNavigation(floating.context, {
		listRef: listElementsRef,
		activeIndex: state.activeIndex,
		selectedIndex: state.selectedIndex,
		focusItemOnOpen,
		enabled: !disabled,
		onNavigate: (index) => {
			// This is called everytime the mouse moves over an option,
			// so we need to check if the index has changed so that we don't dispatch the same action multiple times.
			if (state.activeIndex !== index) {
				dispatch({ type: "SET_ACTIVE_INDEX", payload: index });
			}
		},
		virtual: true,
		loop: true,
		// Dividers are not selectable and should be skipped.
		disabledIndices: state.items.reduce<number[]>((acc, item, index) => {
			if (item.isDivider) {
				acc.push(index);
			}
			return acc;
		}, []),
	});
	const typeahead = useTypeahead(floating.context, {
		listRef: listContentRef,
		activeIndex: state.activeIndex,
		onMatch: (index) => {
			if (state.isPopoverOpen) {
				dispatch({ type: "SET_ACTIVE_INDEX", payload: index });
			} else {
				dispatch({ type: "SET_SELECTED_INDEX", payload: index });
			}
		},
		onTypingChange(isTyping) {
			isTypingRef.current = isTyping;
		},
	});

	const interactions = useInteractions([
		click,
		role,
		dismiss,
		listNavigation,
		typeahead,
	]);

	// When the listbox is open, we want to scroll to the active item, only if it's by keyboard navigation.
	useLayoutEffect(() => {
		if (floating.isPositioned && !state.isPointer && state.items.length > 0) {
			// Nothing has been selected, reset scrolling upon open
			if (state.activeIndex === null && state.selectedIndex === null) {
				virtualizer.scrollToIndex(0);
			}

			// Scrolling is restored, but the item will be scrolled
			// into view when necessary
			if (state.selectedIndex !== null) {
				wrapperRef.current?.focus({ preventScroll: true });
				virtualizer.scrollToIndex(state.selectedIndex);
			}
		}
	}, [
		virtualizer,
		floating.isPositioned,
		state.activeIndex,
		state.selectedIndex,
		state.isPointer,
		floating.refs,
		state.items,
	]);

	useEffect(() => {
		// Merge the items with the dividers
		const _items = items.reduce<ListItem<T>[]>((acc, item, index) => {
			acc.push(item);
			// Add a divider if the index is in the divider array, and is not the last item
			if (dividers && dividers.includes(index) && index !== items.length - 1) {
				acc.push({ isDivider: true, label: null, value: null });
			}
			return acc;
		}, []);

		dispatch({ type: "SET_ITEMS", payload: _items });
		listElementsRef.current = [...Array(_items.length).fill(null)];
		listContentRef.current = items.map((item) => item.label);
	}, [items, dividers]);

	useEffect(() => {
		dispatch({ type: "SET_SELECTED_INDEX", payload: null });
		const index = state.items.findIndex((item) => item.value === value);
		if (index !== -1) {
			dispatch({ type: "SET_SELECTED_INDEX", payload: index });
		}
	}, [value, state.items]);

	useEffect(() => {
		if (isOpen) {
			dispatch({ type: "OPEN_POPOVER" });
		} else {
			dispatch({ type: "CLOSE_POPOVER" });
		}
	}, [isOpen]);

	useEffect(() => {
		onOpenChange?.(state.isPopoverOpen);
	}, [state.isPopoverOpen]);

	useEffect(() => {
		dispatch({ type: "SET_ACTIVE_INDEX", payload: activeIndex ?? null });
	}, [activeIndex]);

	const handleSelect = () => {
		const activeIndex = state.activeIndex;
		if (activeIndex !== null) {
			dispatch({ type: "SET_SELECTED_INDEX", payload: activeIndex });
			dispatch({ type: "CLOSE_POPOVER" });

			// Get the correct index, since dividers are not included in the item array
			const index = items.findIndex(
				(item) => item.value === state.items[activeIndex].value,
			);

			onSelect?.(state.items[activeIndex].value as T, index);
		}
	};

	// Used so that we can check if there is only one child or if the first child is a string
	const childrenArray = React.Children.toArray(children);
	const isSimpleComponent =
		childrenArray.length === 1 || typeof childrenArray[0] === "string";

	return (
		<ListBoxContext.Provider
			value={{
				state,
				dispatch,
				floating,
				interactions,
				handleSelect,
				disabled,
				virtualizer,
				wrapperRef,
				wrapperParentRef,
				isTypingRef,
				listElementsRef,
				popoverWidth,
				getDividerComponent,
				getOptionComponent,
			}}
		>
			{isSimpleComponent ? (
				<>
					<ListBoxButton id={props.id}>{children}</ListBoxButton>
					<ListBoxPopover>
						<ListBoxList />
					</ListBoxPopover>
				</>
			) : (
				children
			)}
		</ListBoxContext.Provider>
	);
};
