/** @jsxImportSource @emotion/react */
import { useAppFooterStyles } from "./appFooterStyles";
import Image from "next/legacy/image";
import { Container, Heading, NextLink, Stack } from "@bilar/ui";
import facebookCircle from "@bilar/assets/img/social/facebook/logo-circle.svg";
import twitterCircle from "@bilar/assets/img/social/twitter/logo-x.svg";
import logo from "@bilar/assets/img/bilar-logo-white.svg";
import { useAppTranslation } from "@bilar/common";
import { useRouter } from "next/router";

type AppFooterProps = {};

const AppFooter = (props: AppFooterProps) => {
	const { t } = useAppTranslation();
	const { locale } = useRouter();
	const styles = useAppFooterStyles(props);

	const logoHeight = 48;
	const logoRatio = 4.2;

	const getLocale = () => {
		if (locale === "en") {
			return "en";
		} else {
			return "is";
		}
	};

	return (
		<footer css={styles.root}>
			<Container css={styles.contentWrapper}>
				<section css={[styles.area, styles.about]}>
					<figure>
						<Image
							src={logo}
							width={logoHeight * logoRatio}
							height={logoHeight}
							alt="Bílar logo"
						/>
					</figure>
					{/*<NextLink href={"/about"}>About us</NextLink>*/}
				</section>
				<section css={[styles.area, styles.contact]}>
					<Heading as="h4" css={styles.heading}>
						{t("contactUs")}
					</Heading>
					<a href="mailto:info@bilar.is">info@bilar.is</a>
					{/*<FeedbackButton />*/}
				</section>
				<section css={[styles.area, styles.terms]}>
					<Heading as="h4" css={styles.heading}>
						{t("terms")}
					</Heading>
					<NextLink href={`/privacy/${getLocale()}`}>
						{t("privacyPolicy")}
					</NextLink>
					<NextLink href={`/terms/${getLocale()}`}>
						{t("termsOfService")}
					</NextLink>
				</section>
				<section css={[styles.area, styles.social]}>
					<Heading as="h4" css={styles.heading}>
						{t("followUs")}
					</Heading>
					<a
						href="https://www.facebook.com/bilar.is"
						target="_blank"
						rel="noopener"
					>
						<Stack orientation="horizontal" spacing={2}>
							<Image
								src={facebookCircle}
								width={24}
								height={24}
								alt="Facebook Logo"
							/>
							<span>@bilar.is</span>
						</Stack>
					</a>
					<a href="https://twitter.com/bilaris" target="_blank" rel="noopener">
						<Stack orientation="horizontal" spacing={2}>
							<Image src={twitterCircle} width={24} height={24} alt="X Logo" />
							<span>@bilaris</span>
						</Stack>
					</a>
				</section>
			</Container>
		</footer>
	);
};

export { AppFooter };
export type { AppFooterProps };
