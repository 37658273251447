import nextJsGetConfig from "next/config";
import axios, { AxiosRequestConfig } from "axios";

type PublicAppConfig = {
	baseUrl: string;
	apiUrl: string;
	countryCode: string;
	classifiedsPerPage: number;
	firebaseAppId: string;
	firebaseApiKey: string;
	firebaseAuthDomain: string;
	firebaseDatabaseUrl: string;
	firebaseProjectId: string;
	firebaseStorageBucket: string;
	firebaseMessagingSenderId: string;
	firebaseMeasurementId: string;
	facebook: {
		appId: string;
	};
	sentry: {
		dsn: string;
		project: string;
		org: string;
	};
	postHog: {
		apiKey: string;
		host: string;
		apiHost: string;
		apiStaticHost: string;
	};
};

type ServerAppConfig = {
	FIREBASE_CLIENT_EMAIL: string;
	FIREBASE_PRIVATE_KEY: string;
	SECRET_AUTO_DATA_API_CODE: string;
	SENTRY_AUTH_TOKEN: string;
};

type AppConfig = {
	public: PublicAppConfig;
	server: ServerAppConfig;
};

const getConfig = (): AppConfig => {
	return { public: getPublicAppConfig(), server: getServerConfig() };
};

/**
 * Returns the config which is available for both client and server.
 * More notes: This way is called build-time environment variables
 * Environment variables are declared in .env file on the root
 * and included in the source control with replacement strings for Octopus to replace them.
 * We recommend to use the prefix NEXT_PUBLIC so they are automatically picked up by NextJS
 * If you don't do that you need to add a mapping in next.config.js inside the env: {} and then it becomes process.env.YOUR_KEY
 * https://nextjs.org/docs/basic-features/environment-variables
 */
const getPublicAppConfig = () => {
	const config: PublicAppConfig = {
		baseUrl: process.env.NEXT_PUBLIC_VERCEL_URL
			? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
			: process.env.NEXT_PUBLIC_APP_URL!,
		apiUrl: process.env.NEXT_PUBLIC_API_URL!,
		countryCode: process.env.NEXT_PUBLIC_COUNTRY_CODE!,
		classifiedsPerPage: parseInt(process.env.NEXT_PUBLIC_CLASSIFIEDS_PER_PAGE!),
		firebaseApiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY!,
		firebaseAuthDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN!,
		firebaseDatabaseUrl: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL!,
		firebaseProjectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID!,
		firebaseStorageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET!,
		firebaseMessagingSenderId:
			process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID!,
		firebaseAppId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID!,
		firebaseMeasurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID!,
		facebook: {
			appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID!,
		},
		sentry: {
			dsn: process.env.NEXT_PUBLIC_SENTRY_DSN!,
			project: process.env.NEXT_PUBLIC_SENTRY_PROJECT!,
			org: process.env.NEXT_PUBLIC_SENTRY_ORG!,
		},
		postHog: {
			apiKey: process.env.NEXT_PUBLIC_POSTHOG_KEY!,
			host: process.env.NEXT_PUBLIC_POSTHOG_HOST!,
			apiHost: process.env.NEXT_PUBLIC_POSTHOG_API_HOST!,
			apiStaticHost: process.env.NEXT_PUBLIC_POSTHOG_API_STATIC_HOST!,
		},
	};

	return config;
};

const getServerConfig = () => {
	const config: ServerAppConfig = {
		FIREBASE_CLIENT_EMAIL: process.env.FIREBASE_CLIENT_EMAIL!,
		FIREBASE_PRIVATE_KEY: process.env.FIREBASE_PRIVATE_KEY!,
		SECRET_AUTO_DATA_API_CODE: process.env.SECRET_AUTO_DATA_API_CODE!,
		SENTRY_AUTH_TOKEN: process.env.SENTRY_AUTH_TOKEN!,
	};

	return config;
};

/**
 * Get ENV config that can only be accessed from the server. (ex: with getServerSideProps)
 * More notes: You need to declare the mappings in next.config.js inside the serverRuntime: {}
 * https://nextjs.org/docs/api-reference/next.config.js/runtime-configuration
 */
const getServerConfig1 = (): ServerAppConfig => {
	const { serverRuntimeConfig } = nextJsGetConfig() as {
		serverRuntimeConfig: ServerAppConfig;
	};

	return serverRuntimeConfig;
};

const isServer = typeof window === "undefined";

//----- MainApi -----
const getMainApiAxiosConfig = (): AxiosRequestConfig => {
	const basePath = getPublicAppConfig().apiUrl;

	return {
		baseURL: basePath,
	};
};

const getMainApiAxios = () => {
	const axiosConfig = getMainApiAxiosConfig();

	return axios.create(axiosConfig);
};

//----- CarQuery -----
const getCarQueryApiAxiosConfig = (): AxiosRequestConfig => {
	const basePath = process.env.NEXT_PUBLIC_CAR_QUERY_API_URL;

	return {
		baseURL: basePath,
	};
};

const getCarQueryApiAxios = () => {
	const axiosConfig = getCarQueryApiAxiosConfig();

	return axios.create(axiosConfig);
};

//----- Car-data.net -----
const getCarDataApiAxiosConfig = (): AxiosRequestConfig => {
	const basePath = process.env.NEXT_PUBLIC_AUTO_DATA_API_URL;

	return {
		baseURL: basePath,
	};
};

const getCarDataApiAxios = () => {
	const axiosConfig = getCarDataApiAxiosConfig();

	return axios.create(axiosConfig);
};

export {
	getConfig,
	getMainApiAxiosConfig,
	getMainApiAxios,
	isServer,
	getCarQueryApiAxios,
	getCarDataApiAxios,
};
export type { AppConfig };
