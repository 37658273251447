import { FieldSize } from "./formTypes";

const getFieldMargin = (size: FieldSize) => {
	switch (size) {
		case "small":
			return 2;
		case "regular":
			return 2;
		case "large":
			return 4;
	}

	return 1;
};

export { getFieldMargin };
