import { SelectProps } from "./Select";
import { DropdownOption, useDesignTokens } from "@bilar/ui";
import chroma from "chroma-js";
import { StylesConfig } from "react-select";

// If we want to add more, look here: https://react-select.com/styles
interface SelectStyles {
	control: any;
	option: any;
	menu: any;
	menuList: any;
	placeholder: any;
}

type StylingSelectProps = Partial<SelectProps<true | false>>;

type SelectState = {
	isDisabled: boolean;
	isFocused: boolean;
	isSelected: boolean;
};

const useSelectStyles = (
	props: StylingSelectProps,
): StylesConfig<DropdownOption> => {
	const tokens = useDesignTokens();
	const {
		fonts,
		palette,
		fontSizes,
		borders,
		space,
		radii,
		fontColors,
		zIndices,
	} = tokens;
	const primaryRgb = chroma(palette.secondary.main.background).rgb();
	const width = { width: "100%" };
	const isMulti = props.isMulti || false;

	const styles: StylesConfig<DropdownOption> = {
		control: (base, { isFocused }) => {
			return {
				...base,
				fontFamily: fonts.body,
				borderRadius: radii.md,
				minHeight: space[12],
				borderWidth: 2,
				borderColor: isFocused
					? palette.secondary.light.background
					: borders.color.main,
				...width,
			};
		},
		menuPortal: (base) => ({
			...base,
			zIndex: zIndices.tooltip,
		}),
		placeholder: (base) => ({
			...base,
			color: fontColors.main,
		}),
		option: (base, { isFocused, isSelected, isDisabled }) => {
			return {
				...base,
				lineHeight: fontSizes["2xl"],
				cursor: isDisabled ? "not-allowed" : "default",
				backgroundColor: isSelected
					? palette.primary.main.background
					: isFocused
						? palette.neutral.light.background
						: "transparent",
				color: isSelected
					? palette.primary.main.contrast
					: isFocused
						? palette.neutral.light.contrast
						: "inherit",
			};
		},
	};

	return styles;
};

export { useSelectStyles };
