import { CSSObject } from "@emotion/react";
import { MobileNavProps } from "./MobileNav";
import { useDesignTokens } from "@bilar/ui";

type MobileNavStyles = {
	root: CSSObject;
	list: CSSObject;
	listItem: CSSObject;
	listProfileItem: CSSObject;
	icon: CSSObject;
	link: CSSObject;
	linkActive: CSSObject;
};

const useMobileNavStyles = (props: MobileNavProps): MobileNavStyles => {
	const designTokens = useDesignTokens();
	const {
		palette,
		size,
		zIndices,
		radii,
		fontSizes,
		fontWeights,
		mediaQueries,
	} = designTokens;

	const navPalette = palette.primary;
	const mainColor = navPalette["50"];

	return {
		root: {
			position: "fixed",
			bottom: 0,
			left: 0,
			right: 0,
			display: "grid",
			alignItems: "center",

			backgroundColor: "#fff",
			zIndex: zIndices.docked,

			boxShadow: " 0px 1px 20px 0 rgba(0, 0, 0, 0.40)",

			boxSizing: "content-box",
			paddingTop: size(1),
			paddingBottom: `max(${size(1)}, env(safe-area-inset-bottom))`,

			[mediaQueries.tabletAndAbove]: {
				display: "none",
			},

			"&::before": {
				content: "''",
				position: "absolute",
				inset: 0,
				borderTop: `1px solid ${palette.grey["400"].background}`,
			},
		},
		list: {
			display: "grid",
			gridTemplateColumns: "repeat(4, 1fr)",
			alignItems: "center",
			gap: size(2),
			padding: `0 ${size(4)} 0`,
			height: "100%",
			zIndex: zIndices.docked,

			[mediaQueries.tabletAndAbove]: {
				display: "none",
			},
		},
		listItem: {
			display: "grid",
			justifyContent: "stretch",
		},
		listProfileItem: {
			gridTemplateRows: "1fr",
		},
		icon: {
			justifySelf: "center",
			alignSelf: "end",
			color: mainColor.contrast,
		},
		link: {
			display: "grid",
			gridTemplateRows: "1fr 1fr",
			borderRadius: radii.lg,
			whiteSpace: "nowrap",
			padding: `${size(2)} ${size(2)} ${size(1)}`,
			justifyContent: "center",

			color: mainColor.contrast,
			fontSize: fontSizes.md,
			fontWeight: fontWeights.normal,
			border: "none",

			"&:hover": {
				color: mainColor.contrast,
				backgroundColor: navPalette["50"].background,
			},
			"&:active": {
				color: mainColor.contrast,
				// fontWeight: fontWeights.medium,
			},
			"&:focus": {},
		},
		linkActive: {
			color: mainColor.contrast,
			backgroundColor: navPalette["50"].background,
		},
	};
};

type ProfileListStyles = {
	root: CSSObject;
	item: CSSObject;
	itemLink: CSSObject;
	profileButton: CSSObject;
	signOutButton: CSSObject;
};

export const useProfileMenuStyles = (): ProfileListStyles => {
	const designTokens = useDesignTokens();
	const { palette, space, radii, fontColors, fontWeights, size } = designTokens;

	return {
		root: {
			padding: `${space[2]} ${space[2]}`,
			border: `1px solid ${palette.grey["400"].background}`,
			minWidth: space["80"],
			marginBottom: size(4),
		},
		item: {
			width: "100%",
			borderRadius: radii.md,
			"&:last-of-type": {
				marginBottom: 0,
			},
		},
		itemLink: {
			display: "flex",
			flexDirection: "column",
			alignItems: "start",
			color: fontColors.main,
			textDecoration: "none",

			// This removes the underline because NextJs 12 wraps the Link component in another anchor tag
			a: {
				textDecoration: "none",
			},

			"&:hover": {
				color: fontColors.main,
				textDecoration: "none",
			},
		},
		profileButton: {
			display: "grid",
			alignItems: "center",
			height: "auto",
			whiteSpace: "nowrap",
			gridTemplateColumns: "56px auto",
		},
		signOutButton: {
			display: "flex",
			textAlign: "center",
			justifyContent: "center",
			backgroundColor: palette.error.main.background,
			color: palette.error.main.contrast,
			fontWeight: fontWeights.medium,

			"&[data-reach-menu-item][data-selected]:hover": {
				backgroundColor: palette.error.dark.background,
				color: palette.error.dark.contrast,
			},
		},
	};
};

export { useMobileNavStyles };
