import { ListItem } from "./types";

export interface ListBoxState<T> {
	isPopoverOpen: boolean;
	activeIndex: number | null; // Current active index, similar to focus or hover, used also with keyboard navigation
	selectedIndex: number | null; // Current selected value index
	items: ListItem<T>[];
	currentValue: T | null;
	isPointer: boolean;
}

export type Action<T> =
	| { type: "OPEN_POPOVER" }
	| { type: "CLOSE_POPOVER" }
	| { type: "SET_ACTIVE_INDEX"; payload: number | null }
	| { type: "SET_SELECTED_INDEX"; payload: number | null }
	| { type: "SET_ITEMS"; payload: ListItem<T>[] }
	| { type: "SET_CURRENT_VALUE"; payload: T | null }
	| { type: "SET_MAX_HEIGHT"; payload: number }
	| { type: "SET_IS_POINTER"; payload: boolean };

export const reducer = <T>(
	state: ListBoxState<T>,
	action: Action<T>,
): ListBoxState<T> => {
	switch (action.type) {
		case "OPEN_POPOVER":
			return { ...state, isPopoverOpen: true };
		case "CLOSE_POPOVER":
			return { ...state, isPopoverOpen: false };
		case "SET_ACTIVE_INDEX":
			return { ...state, activeIndex: action.payload };
		case "SET_SELECTED_INDEX":
			return { ...state, selectedIndex: action.payload };
		case "SET_ITEMS":
			return { ...state, items: action.payload };
		case "SET_CURRENT_VALUE":
			return { ...state, currentValue: action.payload };
		case "SET_IS_POINTER":
			return { ...state, isPointer: action.payload };
		default:
			return state;
	}
};

export const initialState: ListBoxState<unknown> = {
	isPopoverOpen: false,
	activeIndex: null,
	selectedIndex: null,
	items: [],
	currentValue: null,
	isPointer: false,
};
