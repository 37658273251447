const tokenAttr = "editor-tokenAttr";
const tokenProperty = "editor-tokenProperty";
const tokenSelector = "editor-tokenSelector";
const tokenComment = "editor-tokenComment";
const tokenFunction = "editor-tokenFunction";
const tokenOperator = "editor-tokenOperator";
const tokenVariable = "editor-tokenVariable";

export const editorTheme = {
	ltr: "ltr",
	rtl: "rtl",
	placeholder: "editor-placeholder",
	paragraph: "editor-paragraph",
	quote: "editor-quote",
	heading: {
		h1: "editor-heading-h1",
		h2: "editor-heading-h2",
		h3: "editor-heading-h3",
		h4: "editor-heading-h4",
		h5: "editor-heading-h5",
	},
	list: {
		nested: {
			listitem: "editor-nested-listitem",
		},
		ol: "editor-list-ol",
		ul: "editor-list-ul",
		listitem: "editor-listitem",
	},
	image: "editor-image",
	link: "editor-link",
	text: {
		bold: "editor-text-bold",
		italic: "editor-text-italic",
		overflowed: "editor-text-overflowed",
		hashtag: "editor-text-hashtag",
		underline: "editor-text-underline",
		strikethrough: "editor-text-strikethrough",
		underlineStrikethrough: "editor-text-underlineStrikethrough",
		code: "editor-text-code",
	},
	code: "editor-code",
	codeHighlight: {
		atrule: tokenAttr,
		attr: tokenAttr,
		boolean: tokenProperty,
		builtin: tokenSelector,
		cdata: tokenComment,
		char: tokenSelector,
		class: tokenFunction,
		"class-name": tokenFunction,
		comment: tokenComment,
		constant: tokenProperty,
		deleted: tokenProperty,
		doctype: tokenComment,
		entity: tokenOperator,
		function: tokenFunction,
		important: tokenVariable,
		inserted: tokenSelector,
		keyword: tokenAttr,
		namespace: tokenVariable,
		number: tokenProperty,
		operator: tokenOperator,
		prolog: tokenComment,
		property: tokenProperty,
		punctuation: "editor-tokenPunctuation",
		regex: tokenVariable,
		selector: tokenSelector,
		string: tokenSelector,
		symbol: tokenProperty,
		tag: tokenProperty,
		url: tokenOperator,
		variable: tokenVariable,
	},
};
