import { CSSObject } from "@emotion/react";
import { ChipProps } from "./Chip";
import { DesignTokens, useDesignTokens } from "@bilar/ui";
import chroma from "chroma-js";

type ChipStyles = {
	root: CSSObject;
};

const getSizeStyles = (props: ChipProps, tokens: DesignTokens): CSSObject => {
	const { size } = props;
	const { space } = tokens;
	switch (size) {
		case "small":
			return {
				minHeight: space[4],
			};
		case "regular":
			return {
				minHeight: space[8],
			};
		case "large":
			return {
				minHeight: space[12],
			};
		default:
			return {
				minHeight: space[8],
			};
	}
};

const getPaletteStyles = (
	props: ChipProps,
	tokens: DesignTokens,
): CSSObject => {
	const { palette: propPalette } = props;
	const { palette } = tokens;
	switch (propPalette) {
		case "error":
			return {
				backgroundColor: palette.error.light.background,
				color: palette.error.light.contrast,
			};
		case "highlight":
			return {
				backgroundColor: palette.highlight.light.background,
				color: palette.highlight.light.contrast,
			};
		case "info":
			return {
				backgroundColor: palette.info.light.background,
				color: palette.info.light.contrast,
			};
		case "primary":
			return {
				backgroundColor: palette.primary.light.background,
				color: palette.primary.light.contrast,
			};
		case "secondary":
			return {
				backgroundColor: palette.secondary.light.background,
				color: palette.secondary.light.contrast,
			};
		case "success":
			return {
				backgroundColor: palette.success.light.background,
				color: palette.success.light.contrast,
			};
		case "warning":
			return {
				backgroundColor: palette.warning.light.background,
				color: palette.neutral.light.contrast,
			};
		case "grey":
			return {
				backgroundColor: palette.grey["100"].background,
				color: palette.grey["100"].contrast,
			};
		default:
			return {
				backgroundColor: palette.neutral.light.background,
				color: palette.neutral.light.contrast,
			};
	}
};

// tmp: Just use black and white
// TODO: Find the best contrast color for the background color
const getContrastColor = (bg: string, tokens: DesignTokens): string => {
	const MIN_CONTRAST_RATIO = 4.5;
	const WHITE = tokens.colors.white;
	const BLACK = tokens.colors.black["900"];

	let contrastColor = "";

	let contrastWithWhite = chroma.contrast(bg, WHITE);

	if (contrastWithWhite >= MIN_CONTRAST_RATIO) {
		contrastColor = WHITE;
	} else {
		contrastColor = BLACK;
	}

	return contrastColor;
};

const getCustomBackgroundStyles = (
	props: ChipProps,
	tokens: DesignTokens,
): CSSObject => {
	if (!props.backgroundColor) return {};

	return {
		backgroundColor: props.backgroundColor,
		color: getContrastColor(props.backgroundColor, tokens),
	};
};

const getTextAlignStyles = (props: ChipProps) => {
	switch (props.textAlign) {
		case "center":
			return {
				justifyContent: "center",
			};
		case "right":
			return {
				justifyContent: "right",
			};
		case "left":
		default:
			return {
				justifyContent: "left",
			};
	}
};

const useChipStyles = (props: ChipProps): ChipStyles => {
	const designTokens = useDesignTokens();
	const { radii, fontWeights } = designTokens;
	return {
		root: {
			display: "inline-flex",
			borderRadius: props.layout === "rounded" ? radii.full : radii.md,
			alignItems: "center",
			fontWeight: fontWeights.normal,
			...getTextAlignStyles(props),
			...getSizeStyles(props, designTokens),
			...getPaletteStyles(props, designTokens),
			...getCustomBackgroundStyles(props, designTokens),
		},
	};
};

export { useChipStyles };
