import { DropdownOption } from "@bilar/ui";

export const generateYears = (
	countDirection: "up" | "down" | undefined,
	maxYears: number | undefined,
) => {
	const dt = new Date();
	const thisYear = dt.getFullYear();

	const years: DropdownOption[] = [];

	if (countDirection === "up") {
		const toYear = thisYear + maxYears!;
		for (let i = thisYear; i < toYear; i++) {
			// console.log(i, thisYear, toYear);

			const year: DropdownOption = {
				label: i.toString(),
				value: i.toString(),
			};
			years.push(year);
		}
	} else {
		const toYear = thisYear - maxYears!;
		for (let i = thisYear; i > toYear; i--) {
			const year: DropdownOption = {
				label: i.toString(),
				value: i.toString(),
			};
			years.push(year);
		}
	}
	return years;
};
