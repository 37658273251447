import { CSSObject } from "@emotion/react";
import { useDesignTokens } from "@bilar/ui";
import { MainNavProps } from "./MainNav";

type MainNavStyles = {
	root: CSSObject;
	userName: CSSObject;
	list: ListStyles;
};

type ListStyles = {
	root: CSSObject;
	item: CSSObject;
	itemLink: CSSObject;
	profileButton: CSSObject;
	signOutButton: CSSObject;
};

const useMainNavStyles = (
	props: MainNavProps,
	isLoggedIn: boolean,
): MainNavStyles => {
	const designTokens = useDesignTokens();
	const focusColor = "#fff";

	const {
		fontColors,
		fontWeights,
		borders,
		mediaQueries,
		space,
		radii,
		palette,
		styles,
	} = designTokens;
	return {
		root: {
			display: "none",

			[mediaQueries.tabletAndAbove]: {
				backgroundColor: "rgba(255, 255, 255, .1)",
				borderRadius: radii.full,
				minHeight: space[12],
				gridTemplateColumns: isLoggedIn ? "auto auto auto" : "auto auto",
				alignItems: "center",
				padding: `0 ${space[4]}`,
				color: palette.primary.main.contrast,
				display: "grid",
				"&:hover": {
					backgroundColor: "#fff",
				},
			},
		},
		userName: {
			display: "block",
			margin: 0,
			padding: `0 ${space[2]} 0 0`,
			marginRight: space[2],
			color: palette.primary.main.contrast,
		},
		list: {
			root: {
				display: "none",
				[mediaQueries.tabletAndAbove]: {
					display: "block",
					padding: `${space[2]} ${space[2]}`,
					minWidth: space["80"],
				},
			},
			item: {
				width: "100%",
				borderRadius: radii.md,
				"&:last-of-type": {
					marginBottom: 0,
				},
			},
			itemLink: {
				display: "flex",
				flexDirection: "column",
				alignItems: "start",
				color: fontColors.main,
				textDecoration: "none",

				// This removes the underline because NextJs 12 wraps the Link component in another anchor tag
				a: {
					textDecoration: "none",
				},

				"&:hover": {
					color: fontColors.main,
					textDecoration: "none",
				},
			},
			profileButton: {
				display: "grid",
				alignItems: "center",
				height: "auto",
				whiteSpace: "nowrap",
				gridTemplateColumns: "56px auto",
				gap: space[2],
			},
			signOutButton: {
				display: "flex",
				textAlign: "center",
				justifyContent: "center",
				backgroundColor: palette.error.main.background,
				color: palette.error.main.contrast,
				fontWeight: fontWeights.medium,

				"&[data-reach-menu-item][data-selected]:hover": {
					backgroundColor: palette.error.dark.background,
					color: palette.error.dark.contrast,
				},
			},
		},
	};
};

export { useMainNavStyles };
