import React, { FC, useEffect, useRef, useState } from "react";
import { useImageUploadListStyles } from "./imageUploadListStyles";
import short from "short-uuid";
import { Loading, useAppTranslation } from "@bilar/common";
import {
	createDefaultImageReader,
	createDefaultImageWriter,
	getEditorDefaults,
	plugin_annotate,
	plugin_crop,
	plugin_filter,
	plugin_finetune,
	plugin_redact,
	processImage,
	setPlugins,
} from "@pqina/pintura";
import { EditableImage } from "@bilar/types";
import { PinturaEditor } from "@pqina/react-pintura";
import {
	animate,
	AnimatePresence,
	motion,
	MotionValue,
	useMotionValue,
} from "framer-motion";
import { useDropzone } from "react-dropzone";
import { Dialog, FaIcon, InvisibleButton } from "@bilar/ui";
import { ReactSortable } from "react-sortablejs";
import { useRouter } from "next/router";
import { useDeepCompareEffect } from "react-use";
import { faWandMagicSparkles } from "@fortawesome/pro-solid-svg-icons";
import { faClose } from "@fortawesome/pro-solid-svg-icons/faClose";

setPlugins(
	plugin_crop,
	plugin_finetune,
	plugin_filter,
	plugin_annotate,
	plugin_redact,
);

// get default properties
const editorConfig = getEditorDefaults();

const inactiveShadow = "0px 0px 5px rgba(0,0,0,0)";

// NOTE: Not used for now, until we can use Framer Motion
export function useRaisedShadow(value: MotionValue<number>) {
	const boxShadow = useMotionValue(inactiveShadow);

	useEffect(() => {
		let isActive = false;
		value.on("change", (latest) => {
			const wasActive = isActive;
			if (latest !== 0) {
				isActive = true;
				if (isActive !== wasActive) {
					animate(boxShadow, "5px 5px 10px rgba(0,0,0,0.3)");
				}
			} else {
				isActive = false;
				if (isActive !== wasActive) {
					animate(boxShadow, inactiveShadow);
				}
			}
		});
	}, [value, boxShadow]);

	return boxShadow;
}

type Props = {
	file: EditableImage<File>;
	onEditClick: (file: EditableImage<File>) => void;
	onDeleteClick: (file: EditableImage<File>) => void;
	width: number;
	height: number;
};

export const Item: FC<Props> = (props: Props) => {
	const { t } = useAppTranslation("common");
	const { file, onEditClick, onDeleteClick, width, height } = props;
	// NOTE: Enable when we can use Framer motion
	// const y = useMotionValue(0);
	// const boxShadow = useRaisedShadow(y);
	const [background, setBackground] = React.useState<string | undefined>(
		URL.createObjectURL(file.processed || file.original),
	);
	const styles = useImageUploadListStyles();
	const [isProcessing, setIsProcessing] = useState(!file.processed);

	useEffect(() => {
		if (file.processed) {
			setBackground(URL.createObjectURL(file.processed));
			setIsProcessing(false);
		}
	}, [file.processed]);

	const motionProps = {
		initial: { scale: 0.5, opacity: 0 },
		animate: { scale: 1, opacity: 1 },
		exit: { scale: 0.5, opacity: 0 },
	};

	return (
		// TODO: we eventually would want to find a way to use the Reorder component here, as of now Framer motion
		// 	does not support elements in a grid layout, so we have to use the sortablejs library
		// <Reorder.Item
		// 	drag={true}
		// 	value={file}
		// 	id={file.processed?.name}
		// 	css={[
		// 		styles.fileItem.root,
		// 		{
		// 			width,
		// 			height,
		// 			backgroundImage: `url(${background})`,
		// 		},
		// 	]}
		// 	style={{
		// 		boxShadow,
		// 		y,
		// 	}}
		// 	initial={{ opacity: 0 }}
		// 	animate={{ opacity: 1 }}
		// 	exit={{ opacity: 0 }}
		// >
		<div css={styles.fileItem.root}>
			<fieldset
				className="wrapper"
				css={[
					styles.fileItem.wrapper,
					{
						width,
						height,
						backgroundImage: `url(${background})`,
					},
				]}
			>
				<div css={styles.fileItem.topControls}>
					<legend>{file.original.name}</legend>
					<button
						css={[styles.button, styles.fileItem.closeButton]}
						onClick={() => onDeleteClick(file)}
						disabled={isProcessing}
					>
						<AnimatePresence>
							{!isProcessing && <FaIcon icon={faClose} />}
							{isProcessing && (
								<motion.div {...motionProps} css={styles.fileItem.spinner}>
									<Loading variant="spinner" size={4} />
								</motion.div>
							)}
						</AnimatePresence>
					</button>
				</div>
				<AnimatePresence>
					{!isProcessing && (
						<motion.button
							{...motionProps}
							css={[styles.fileItem.editButton]}
							onClick={() => onEditClick(file)}
						>
							<>
								<span css={styles.fileItem.editButtonText}>
									{t("editImage")}
								</span>
								<FaIcon icon={faWandMagicSparkles} />
							</>
						</motion.button>
					)}
				</AnimatePresence>
			</fieldset>
		</div>
		// </Reorder.Item>
	);
};

/**
 * Validates that the file is an image by using the file signature
 * @param file
 */
const validateImage = (file: File) => {
	return new Promise((resolve, reject) => {
		const fileReader = new FileReader();
		fileReader.onloadend = function (e) {
			const arrayBuffer = e.target?.result as ArrayBuffer;
			if (!arrayBuffer || !arrayBuffer.byteLength) {
				return reject("Invalid file");
			}

			const arr = new Uint8Array(arrayBuffer).subarray(0, 4);
			let header = "";
			for (let i = 0; i < arr.length; i++) {
				header += arr[i].toString(16);
			}

			// Check the file signature against known types
			// https://en.wikipedia.org/wiki/List_of_file_signatures
			let type;
			switch (header) {
				case "89504e47":
					type = "image/png";
					break;
				case "47494638":
					type = "image/gif";
					break;
				case "ffd8ffe0":
				case "ffd8ffe1":
				case "ffd8ffe2":
				case "ffd8ffe3":
				case "ffd8ffe8":
					type = "image/jpeg";
					break;
				case "00028":
				case "66747970":
					type = "image/heic";
					break;
				default:
					type = "unknown"; // Or you can use the blob.type as fallback
					break;
			}

			if (type === "unknown") {
				return reject("Invalid file type");
			}

			return resolve(type);
		};
		fileReader.readAsArrayBuffer(file);
	});
};

type ImageUploadListProps = {
	files: EditableImage<File>[];
	onFileEdited?: (file: EditableImage<File>) => void;
	onFileRemoved: (file: EditableImage<File>) => void;
	targetWidth: number;
	targetHeight: number;
	onFilesAdded: (files: EditableImage<File>[]) => void;
	onFilesReordered: (files: EditableImage<File>[]) => void;
	onFileProcessed?: (file: EditableImage<File>) => void;
	/**
	 * Called when all files have been processed, returns only the processed files
	 * @param files
	 */
	onFilesProcessed?: (files: EditableImage<File>[]) => void;
};

const ImageUploadList = (props: ImageUploadListProps) => {
	const {
		files,
		onFileEdited,
		onFileRemoved,
		onFilesAdded,
		onFilesReordered,
		onFileProcessed,
		onFilesProcessed,
	} = props;
	const { t } = useAppTranslation();
	const styles = useImageUploadListStyles(props);
	const [openImageEditor, setOpenImageEditor] = useState(false);
	const editorRef = useRef<PinturaEditor>(null);
	const aspectRatio = props.targetWidth / props.targetHeight;
	const router = useRouter();
	const filesBeingProcessed = useRef(new Set<string>());
	const filesCompletedProcessing = useRef(
		new Map<string, EditableImage<File>>(),
	);
	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			// NOTE: this only works for Chrome, Firefox, and Edge, not Safari (Webkit)
			"image/*": [".jpeg", ".png"],
		},
		onDrop: async (acceptedFiles: File[]) => {
			const images = [];
			for (const file of acceptedFiles) {
				try {
					await validateImage(file);
					images.push(file);
				} catch (e) {
					console.error(e);
				}
			}

			const files = images.map((file) => ({
				id: short.uuid(),
				original: file,
			}));

			onFilesAdded?.(files);
		},
	});
	const { onClick, ...rootProps } = getRootProps();

	const handleEditClick = async (file: EditableImage<File>) => {
		setOpenImageEditor(true);

		// NOTE: This is a hack to get around React and Pintura not playing nice together
		setTimeout(async () => {
			try {
				const imageWriterResult = await editorRef.current?.editor.editImage(
					file.original,
					{
						imageState: file.imageState,
						// TODO: create language labels for Pintura, since it's not
						//  currently translated to the languages we support:
						//  https://pqina.nl/pintura/docs/v8/faq/change-language/#overwriting-label-captions
						locale: router.locale,
						imageReader: createDefaultImageReader(),
					},
				);

				if (imageWriterResult) {
					setOpenImageEditor(false);
					onFileEdited?.({
						...file,
						processed: imageWriterResult.dest,
						imageState: imageWriterResult.imageState,
					});
				}
			} catch (e) {
				console.error(e);
			}
		}, 100);
	};

	// Process any unprocessed files
	useDeepCompareEffect(() => {
		files
			// Remove any images that are currently being processed or have already been processed
			.filter(
				(file) => !file.processed && !filesBeingProcessed.current.has(file.id),
			)
			.forEach((file) => {
				filesBeingProcessed.current.add(file.id);
				processImage(file.original, {
					imageReader: createDefaultImageReader(),
					imageWriter: createDefaultImageWriter({
						quality: 1,
						targetSize: {
							width: props.targetWidth,
							height: props.targetHeight,
						},
					}),
					imageCropAspectRatio: props.targetWidth / props.targetHeight,
				}).then(({ dest, imageState }) => {
					filesBeingProcessed.current.delete(file.id);

					const processedFile = {
						...file,
						processed: dest,
						imageState: imageState,
					};

					filesCompletedProcessing.current.set(file.id, processedFile);
					onFileProcessed?.(processedFile);

					if (!filesBeingProcessed.current.size) {
						onFilesProcessed?.(
							Array.from(filesCompletedProcessing.current.values()),
						);
						filesCompletedProcessing.current.clear();
					}
				});
			});
	}, [files]);

	return (
		<div css={styles.root}>
			<section css={styles.dropzone} {...rootProps}>
				<input {...getInputProps()} />
				<InvisibleButton
					palette="secondary"
					fluid={true}
					onClick={onClick}
					css={styles.dropZoneButton}
				>
					{t("dragAndDropYourImages")}
				</InvisibleButton>
				<ReactSortable
					list={files}
					setList={onFilesReordered}
					animation={750}
					delay={10}
					touchStartThreshold={10}
					easing="cubic-bezier(0.16, 1, 0.3, 1)"
					ghostClass="placeholder"
					css={styles.fileList}
					forceFallback={true}
					fallbackClass="dragged-item"
					onStart={(ev) => {
						// We add a dragging class to the dragged item here so that it animates
						// Don't set the dragging class immediately, as it will prevent animations from running
						setTimeout(() => {
							const draggedElement = ev.target.querySelector(".dragged-item");
							draggedElement?.classList.add("is-dragging");
						}, 10);
					}}
					onEnd={(ev) => {
						// Sortable.js will immediately remove the element from the DOM, so this will not work
						// Remove the dragging class from the dragged item
						// const draggedElement = ev.target.querySelector(".dragged-item");
						// draggedElement?.classList.remove("is-dragging");
					}}
				>
					{/* TODO: we eventually would want to find a way to use the Reorder component here, as of now Framer motion
									          does not support elements in a grid layout, so we have to use the sortablejs library*/}
					{/*<Reorder.Group*/}
					{/*	className="files"*/}
					{/*	axis="x"*/}
					{/*	values={files}*/}
					{/*	onReorder={onFilesReordered}*/}
					{/*>*/}
					{/*<AnimatePresence initial={false}>*/}
					{files.map((file) => (
						<Item
							key={file.id}
							file={file}
							onEditClick={handleEditClick}
							onDeleteClick={onFileRemoved}
							width={200}
							height={200 / aspectRatio}
						/>
					))}
					{/*</AnimatePresence>*/}
					{/*</Reorder.Group>*/}
				</ReactSortable>
			</section>

			<Dialog
				isOpen={openImageEditor}
				onDismiss={() => setOpenImageEditor(false)}
				size="max"
				position="center"
				contentPadding={false}
				css={styles.dialog}
			>
				<PinturaEditor
					ref={editorRef}
					{...editorConfig}
					imageCropAspectRatio={aspectRatio}
				/>
			</Dialog>
		</div>
	);
};

export { ImageUploadList };
export type { ImageUploadListProps };
