import { CSSObject } from "@emotion/react";
import { GalleryProps } from "./Gallery";
import { SpacingSize, useDesignTokens } from "../..";

type GalleryStyles = {
	root: CSSObject;
	outerContainer: CSSObject;
	innerContainer: CSSObject;
	animationContainer: CSSObject;
	image: CSSObject;
	navButton: CSSObject;
	navButtonIcon: CSSObject;
	navButtonLeft: CSSObject;
	navButtonRight: CSSObject;

	thumbnailsRoot: CSSObject;
	thumbnailsWrapper: CSSObject;
	thumbnailsButton: CSSObject;
	thumbnailsImage: CSSObject;
};

const thumbnailsOffset: SpacingSize = 18;
const borderRadius: SpacingSize = 2;
const navButtonsSize: SpacingSize = 8;

const useGalleryStyles = (props: GalleryProps): GalleryStyles => {
	const designTokens = useDesignTokens();
	// add default value for maxWidth
	const { size } = designTokens;
	return {
		root: {
			height: "100%",
			backgroundColor: "#000000",
			position: "relative",
			borderRadius: size(borderRadius),
			marginBottom: size(thumbnailsOffset),
		},
		outerContainer: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			height: "100%",
			borderRadius: size(borderRadius),
			overflow: "hidden",
		},
		innerContainer: {
			overflow: "hidden",
			position: "relative",
		},
		animationContainer: {
			display: "flex",
		},
		image: {
			objectFit: "cover",
			aspectRatio: "4/3",
			height: "auto",  // let the browser calculate the height
			maxWidth: "100%",
		},
		navButton: {
			display: "flex",
			position: "absolute",
			top: "50%",
			marginTop: `-${size(navButtonsSize / 2)}`,
			alignItems: "center",
			justifyContent: "center",
			borderRadius: "9999px",
			backgroundColor: "#ffffff",
			width: size(navButtonsSize),
			height: size(navButtonsSize),
			cursor: "pointer",
			color: "#000",
		},
		navButtonLeft: {
			left: size(2),
		},
		navButtonRight: {
			right: size(2),
		},
		navButtonIcon: {
			width: size(navButtonsSize),
			height: size(navButtonsSize),
		},
		thumbnailsRoot: {
			display: "flex",
			overflow: "hidden",
			position: "absolute",
			right: "0",
			left: "0",
			bottom: `-${size(thumbnailsOffset)}`,
			justifyContent: "center",
		},
		thumbnailsWrapper: {
			display: "flex",
			height: size(14),
		},
		thumbnailsButton: {
			padding: 0,
			margin: 0,
			shrink: 0,
			border: "none",
			cursor: "pointer",
			borderRadius: size(0.5),
		},
		thumbnailsImage: {
			objectFit: "cover",
			height: "100%",
			maxWidth: "100%",
			width: "100%",
			borderRadius: size(0.5),
		},
	};
};

export { useGalleryStyles };
