/** @jsxImportSource @emotion/react */
import { useVehicleMiniItemStyles } from "./vehicleMiniItemStyles";
import { Heading, NextLink, Paper, useCalendarDate } from "@bilar/ui";
import Image from "next/legacy/image";
import noMainImagePlaceholder from "@bilar/assets/img/logo-green-car-only.svg";
import { routes } from "@bilar/config";
import { ClassifiedVehicle } from "@bilar/models";
import { imageSizeConfig } from "@bilar/utils";
import { useDisplayFormat } from "@bilar/features";

type VehicleMiniItemProps = {
	data: ClassifiedVehicle;
} & VehicleMiniItemDefaultProps;

type VehicleMiniItemDefaultProps = {};

const defaultProps: VehicleMiniItemDefaultProps = {};

const VehicleMiniItem = (props: VehicleMiniItemProps) => {
	const imageWidth = imageSizeConfig.xs.width;
	const styles = useVehicleMiniItemStyles(props, imageWidth);
	const { getBrandDisplay, getPriceDisplay } = useDisplayFormat();

	const { data } = props;
	const { id, vehicleInfo, price, description, mainImageUrl, createdAt } = data;
	const detailsPath = routes.vehicleClassifieds.index;
	const calendarCreatedAt = useCalendarDate(createdAt);

	return (
		<Paper as="article" css={styles.root} p={4}>
			<NextLink
				href={{
					pathname: `${detailsPath}/[vehicleAdId]`,
					query: { vehicleAdId: id },
				}}
				as={`${detailsPath}/${id}`}
				css={styles.link}
			>
				<div css={styles.container}>
					{mainImageUrl ? (
						<figure css={styles.imgContainer}>
							<Image
								alt={getBrandDisplay(vehicleInfo)}
								src={mainImageUrl}
								css={styles.img}
								width={imageWidth}
								height={imageWidth * 0.75}
								quality={50}
								layout="fixed"
								placeholder="blur"
								blurDataURL="/blur.png"
							/>
						</figure>
					) : (
						<div css={[styles.imgContainer, styles.imgPlaceHolder]}>
							<Image
								alt={getBrandDisplay(vehicleInfo)}
								src={noMainImagePlaceholder}
								css={styles.img}
								width={38}
								height={32}
								quality={50}
								layout="fixed"
							/>
							<p css={styles.noImagesText}>No images</p>
						</div>
					)}
					<div css={styles.contentContainer}>
						<Heading as="h4" size="small" css={styles.priceHeading}>
							{getPriceDisplay(data)}
						</Heading>
						<Heading
							as="h4"
							size="small"
							weight="normal"
							css={styles.brandHeading}
						>
							{getBrandDisplay(vehicleInfo)}
						</Heading>
						<p>{calendarCreatedAt}</p>
					</div>
				</div>
			</NextLink>
		</Paper>
	);
};

VehicleMiniItem.defaultProps = defaultProps;

export { VehicleMiniItem };
export type { VehicleMiniItemProps };
