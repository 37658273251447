/**
 * Compares two arrays and separates items to be updated, deleted and created
 * @param oldItems
 * @param newItems
 * @param changedFilter - optional filter to compare how an item changed
 */
export const crudItemsFilter = <T extends { id: string }>(
	oldItems: T[],
	newItems: T[],
	changedFilter?: (oldItem: T, newItem: T) => boolean,
) => {
	const itemsToDelete = oldItems.filter(
		(item) => !newItems.some(({ id }) => id === item.id),
	);
	const itemsToCreate = newItems.filter(
		(item) => !oldItems.some(({ id }) => id === item.id),
	);
	const itemsToUpdate = newItems.filter((item) =>
		oldItems.find(({ id }) => id === item.id),
	);
	const changedItems = itemsToUpdate.filter((item) => {
		const oldItem = oldItems.find(({ id }) => id === item.id);

		if (oldItem && changedFilter) {
			return changedFilter(oldItem, item);
		}
		return false;
	});
	const unchangedItems = itemsToUpdate.filter(
		(item) => !changedItems.some(({ id }) => id === item.id),
	);

	return {
		deleteItems: itemsToDelete,
		createItems: itemsToCreate,
		updateItems: changedItems,
		unchangedItems,
	};
};
