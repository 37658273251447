import { Global } from "@emotion/react";
import { baseDialogStyles, useDialogStyles } from "./dialogStyles";
import { forwardRef, Fragment } from "react";
import { DialogContent, DialogOverlay } from "@reach/dialog";
import { noop } from "@reach/utils";
import { DialogFooter } from "./DialogFooter";
import { AnimatePresence, motion, Variant, Variants } from "framer-motion";
import {
	DialogPosition,
	FaIcon,
	Heading,
	InvisibleButton,
	useAutoId,
} from "@bilar/ui";

import VisuallyHidden from "@reach/visually-hidden";
import { DialogDefaultProps, DialogProps } from "./dialogTypes";
import { faXmark } from "@fortawesome/sharp-solid-svg-icons";

// Animated overlay
const AnimatedDialogOverlay = motion(DialogOverlay);

const defaultProps: DialogDefaultProps = {
	position: "center",
	size: "regular",
	contentPadding: true,
};

const animationDirection: { [key in DialogPosition]: Variant } = {
	top: {
		translateY: -100,
	},
	bottom: {
		translateY: 100,
	},
	stretch: {
		scale: 0.7,
	},
	center: {
		scale: 0.7,
	},
};

// TODO: Add override styles: Had some errors because of RecursivePartial type

const Dialog = forwardRef<HTMLDivElement, DialogProps>(
	(props: DialogProps, forwardedRef) => {
		const {
			id,
			className,
			heading,
			footerComponent,
			contentPadding,
			isOpen,
			onDismiss = noop,
			initialFocusRef,
			allowPinchZoom,
			overrideStyles,
			...restProps
		} = props;

		const styles = useDialogStyles(props);
		const ariaLabelId = useAutoId("dialog", id);

		const overlayVariants: Variants = {
			opened: {
				opacity: 1,
				transition: {
					duration: 0.3,
					ease: "easeOut",
				},
			},
			closed: {
				opacity: 0,
				transition: {
					duration: 0.3,
					ease: "easeIn",
				},
			},
		};

		const variants: Variants = {
			opened: {
				scale: 1,
				transition: {
					duration: 0.3,
					ease: "easeOut",
				},
				translateY: 0,
			},
			closed: {
				transition: {
					duration: 0.3,
					ease: "easeIn",
				},
				...((props.position && animationDirection[props.position]) || {}),
			},
		};

		return (
			<Fragment>
				<AnimatePresence>
					{isOpen && (
						<AnimatedDialogOverlay
							initialFocusRef={initialFocusRef}
							allowPinchZoom={allowPinchZoom}
							isOpen={isOpen}
							onDismiss={onDismiss}
							css={styles.overlay}
							variants={overlayVariants}
							initial="closed"
							animate="opened"
							exit="closed"
						>
							<motion.div
								css={[styles.content.animationWrapper]}
								variants={variants}
								initial="closed"
								animate="opened"
								exit="closed"
							>
								<DialogContent
									id="dialog"
									aria-labelledby={ariaLabelId}
									css={[styles.content.root, overrideStyles?.content?.root]}
									ref={forwardedRef}
									className={className}
									{...restProps}
								>
									{heading && (
										<header
											css={[styles.header.root, overrideStyles?.header?.root]}
										>
											<Heading
												as="h1"
												css={[
													styles.header.heading,
													overrideStyles?.header?.heading,
												]}
												size="regular"
												id="dialog-header"
											>
												{heading}
											</Heading>
										</header>
									)}
									<main
										css={[styles.content.inner, overrideStyles?.content?.inner]}
									>
										{props.children}
									</main>

									{footerComponent && (
										<DialogFooter>{footerComponent}</DialogFooter>
									)}

									<InvisibleButton
										css={[styles.closeButton]}
										className="close-button"
										onClick={onDismiss}
									>
										<VisuallyHidden>Close</VisuallyHidden>
										<div aria-hidden>
											<FaIcon
												icon={faXmark}
												css={[styles.closeIcon]}
												size={4}
											/>
										</div>
									</InvisibleButton>
								</DialogContent>
							</motion.div>
						</AnimatedDialogOverlay>
					)}
				</AnimatePresence>

				<Global styles={baseDialogStyles} />
			</Fragment>
		);
	},
);

Dialog.displayName = "Dialog";

Dialog.defaultProps = defaultProps;

export { Dialog };
export type { DialogProps };
