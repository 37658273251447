/** @jsxImportSource @emotion/react */
import * as React from "react";
import { forwardRef, HTMLAttributes } from "react";
import { CSSObject } from "@emotion/react";
import { useListBoxContext } from "./ListBoxContext";
import { ListBoxOption } from "./ListBoxOption";
import { useMergeRefs } from "@floating-ui/react";
import { getTestId } from "../../utils";
import { BaseProps } from "@bilar/ui";

type ListBoxProps = {
	css?: CSSObject;
} & BaseProps &
	HTMLAttributes<HTMLDivElement>;

export const ListBoxList = forwardRef((props: ListBoxProps, ref) => {
	const { testId, ...rest } = props;
	const {
		dispatch,
		isTypingRef,
		interactions,
		wrapperRef,
		wrapperParentRef,
		handleSelect,
		virtualizer,
	} = useListBoxContext();
	const refs = useMergeRefs([wrapperParentRef, ref]);

	if (!virtualizer || !interactions) {
		return null;
	}

	return (
		<div
			data-testid={getTestId(testId, "wrapper")}
			ref={refs}
			css={{
				overflow: "auto",
				maxHeight: "50vh", // Important to have a max height, so that the virtualizer does not render all the items.
			}}
		>
			<div
				data-testid={testId}
				css={{
					position: "relative",
					width: "100%",
					outline: 0,
					height: `${virtualizer.getTotalSize()}px`,
				}}
				ref={wrapperRef}
				// Some screen readers do not like any wrapper tags inside
				// the element with the role, so we spread it onto the
				// virtualizer wrapper.
				{...interactions.getFloatingProps({
					onKeyDown(e) {
						dispatch({ type: "SET_IS_POINTER", payload: false });

						if (e.key === "ArrowDown" || e.key === "ArrowUp") {
							e.preventDefault();
						}

						if (e.key === " " && !isTypingRef.current) {
							e.preventDefault();
						}

						// onKeyDown for now, it would be nice to have a onKeyPress,
						// but it interferes with opening the popover for some reason.
						if ((e.key === " " || e.key === "Enter") && !isTypingRef.current) {
							handleSelect();
						}
					},
					onPointerMove() {
						dispatch({ type: "SET_IS_POINTER", payload: true });
					},
				})}
				// Ensure this element receives focus upon open so key-downing works.
				tabIndex={0}
				{...rest}
			>
				{virtualizer.getVirtualItems().map((virtualItem) => (
					<ListBoxOption key={virtualItem.key} item={virtualItem} />
				))}
			</div>
		</div>
	);
});

ListBoxList.displayName = "ListBoxList";
