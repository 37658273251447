export const routes = {
	home: "/",
	admin: {
		index: "/admin",
		brands: "/admin/brands",
		models: "/admin/models",
		vehicles: "/admin/vehicles",
	},
	signIn: "/auth/sign-in",
	signUp: "/auth/sign-up",
	completeSignup: "/auth/complete-signup",
	error: "/error",
	profile: {
		index: "/profile",
		classifieds: "/profile/classifieds",
	},
	vehicleClassifieds: {
		index: "/vehicles",
		new: "/vehicles/new",
		search: "/vehicles/search",
	},
} as const;

export type BilarRoutesKeys = typeof routes;
export type BilarRoute = (typeof routes)[keyof BilarRoutesKeys];
