import { Opacity } from "./types";

const opacity: Opacity = {
	"0": "0",
	"20%": "0.2",
	"40%": "0.4",
	"60%": "0.6",
	"80%": "0.8",
	"100%": "1",
};

export default opacity;
