import React from "react";
import NextJsLink, { LinkProps as NextJsLinkProps } from "next/link";
import { Link, LinkProps as BilarUILinkProps } from "..";

// Use href from NextJsLinkProps instead of BilarUILinkProps.
type BilarUILinkPropsFiltered = Omit<BilarUILinkProps, "href">;
// We always want to use passHref anyway (https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-custom-component-that-wraps-an-a-tag)
type NextJsLinkPropsFiltered = Omit<NextJsLinkProps, "passHref">;

type NextLinkProps = {} & NextJsLinkPropsFiltered & BilarUILinkPropsFiltered;

const NextLink = (props: NextLinkProps) => {
	const { as, href, prefetch, replace, scroll, shallow, ...normalProps } =
		props;

	return (
        <NextJsLink
            passHref={true}
            href={href}
            as={as}
            prefetch={prefetch}
            replace={replace}
            scroll={scroll}
            shallow={shallow}
            legacyBehavior>
			<Link {...normalProps} />
		</NextJsLink>
    );
};

export { NextLink };
