export const collections = {
	CLASSIFIEDS: "classifiedsVehicles",
	CLASSIFIEDS_IMAGES: "images",
	USERS: "users",
	USERS_LOGIN_PROVIDERS: "loginProviders",
	VEHICLES: "vehicleBrands",
	VEHICLES_MODELS: "models",
	VEHICLES_MODEL_GENERATIONS: "generations", // sub-collection under models
	VEHICLES_MODEL_GENERATIONS_MODIFICATIONS: "modifications", // sub collection under generations
	VEHICLES_MODEL_DETAILS: "vehicleModels",
};
