import { CSSObject } from "@emotion/react";
import { ReactNode } from "react";
import { BaseProps, useDesignTokens } from "../..";
import { BoxBaseProps } from "@bilar/ui";

type FieldLabelProps = {
	children?: ReactNode;
	htmlFor: string | undefined; // We must allow undefined since we use autoId from reach a lot which will always be undefined for first render
	onClick?: () => void;
} & FieldLabelDefaultProps &
	BoxBaseProps &
	BaseProps;

type FieldLabelDefaultProps = {};

const defaultProps: FieldLabelDefaultProps = {};

const useFieldLabelStyles = (): CSSObject => {
	const { fonts, fontColors, fontSizes, fontWeights } = useDesignTokens();

	return {
		fontFamily: fonts.body,
		display: "inline-block",
		fontSize: fontSizes.md,
		lineHeight: fontSizes["3xl"],
		fontWeight: fontWeights.bold,
		color: fontColors.main,
	};
};

/**
 * TODO add notes about this component.
 */
const FieldLabel = (props: FieldLabelProps) => {
	const styles = useFieldLabelStyles();

	return (
		<label
			css={styles}
			className={props.className}
			id={props.id}
			htmlFor={props.htmlFor}
			onClick={props.onClick}
		>
			{props.children}
		</label>
	);
};

FieldLabel.defaultProps = defaultProps;

// Add all your exports here
export { FieldLabel };
export type { FieldLabelProps };
