import React, { useContext, useState } from "react";

interface AutoIdCounter {
	count: number;
}

function useAutoId(componentName: string, id: string | null | undefined) {
	const autoIdCounter = useContext(AutoIdContext);

	const [randomId] = useState(() => {
		autoIdCounter.count++;
		return autoIdCounter.count;
	});

	if (!id) {
		return componentName + "-" + randomId;
	}

	return id;
}

const AutoIdContext = React.createContext<AutoIdCounter>({ count: 0 });

export { AutoIdContext, useAutoId };
