import { createEditor, SerializedEditorState } from "lexical";
import { serializeToHtml, serializeToText } from "./serializers";
import { CreateEditorArgs, LexicalEditor } from "lexical/LexicalEditor";
import { editorConfig } from "./editorConfig";

const createArgs: CreateEditorArgs = {
	nodes: editorConfig.nodes,
	namespace: editorConfig.namespace,
};

export const getHtml = async (
	state: SerializedEditorState,
	editor: LexicalEditor | undefined = undefined,
) => {
	if (!editor) {
		editor = createEditor(createArgs);
	}

	const newEditorState = editor.parseEditorState(state);
	editor.setEditorState(newEditorState);

	return serializeToHtml(editor.getEditorState());
};

export const getText = async (
	state: SerializedEditorState,
	editor: LexicalEditor | undefined = undefined,
) => {
	if (!editor) {
		editor = createEditor(createArgs);
	}

	const newEditorState = editor.parseEditorState(state);
	editor.setEditorState(newEditorState);

	return serializeToText(editor.getEditorState());
};
