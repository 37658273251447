import { useMotionValue, useScroll, useTransform } from "framer-motion";
import { useEffect } from "react";

let clamp = (number: number, min: number, max: number) =>
	Math.min(Math.max(number, min), max);

export const useBoundedScroll = (bounds: number) => {
	let { scrollY } = useScroll();
	let scrollYBounded = useMotionValue(0);
	let scrollYBoundedProgress = useTransform(
		scrollYBounded,
		[0, bounds],
		[0, 1],
	);

	useEffect(() => {
		return scrollY.on("change", (current) => {
			let previous = scrollY.getPrevious();
			// If previous is undefined, assign it the current value
			if (previous === undefined) {
				previous = current;
			}
			let diff = current - previous;
			let newScrollYBounded = scrollYBounded.get() + diff;

			scrollYBounded.set(clamp(newScrollYBounded, 0, bounds));
		});
	}, [scrollY, scrollYBounded]);

	return { scrollYBounded, scrollYBoundedProgress };
};
