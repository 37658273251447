import { DesignTokens } from "../../styling";
import { CSSObject } from "@emotion/react";
import { ButtonProps, ButtonVariant } from "./Button";
import { useDesignTokens } from "../..";

const getSizeStyles = (props: ButtonProps, tokens: DesignTokens): CSSObject => {
	const { size, fluid } = props;
	const { space, fontSizes } = tokens;

	let regularSizeStyles = {
		minHeight: space[8],
		fontSize: fontSizes.lg,
		padding: `${space[2]} ${space[6]}`,
		width: fluid ? "100%" : "auto",
	};

	switch (size) {
		case "small":
			return {
				...regularSizeStyles,
				height: space[6],
				fontSize: fontSizes.sm,
				paddingLeft: space[4],
				paddingRight: space[4],
			};

		case "large":
			return {
				...regularSizeStyles,
				height: space[12],
				fontSize: fontSizes.xl,
			};

		case "regular":
		default:
			return {
				...regularSizeStyles,
			};
	}
};

const getColorStyles = (
	props: ButtonProps,
	tokens: DesignTokens,
): CSSObject => {
	const { highlight, primary, secondary, neutral, success, error, warning } =
		tokens.palette;

	switch (props.palette) {
		case "highlight":
			return {
				backgroundColor: highlight.main.background,
				color: highlight.main.contrast,
				":hover": {
					color: highlight.dark.contrast,
					backgroundColor: highlight.dark.background,
				},
			};

		case "primary":
			return {
				backgroundColor: primary.main.background,
				color: primary.main.contrast,
				":hover": {
					color: primary.dark.contrast,
					backgroundColor: primary.dark.background,
				},
			};
		case "secondary":
			return {
				backgroundColor: secondary.main.background,
				color: secondary.main.contrast,
				":hover": {
					color: secondary.dark.contrast,
					backgroundColor: secondary.dark.background,
				},
			};
		case "success":
			return {
				backgroundColor: success.main.background,
				color: success.main.contrast,
				":hover": {
					backgroundColor: success.dark.background,
					color: success.dark.contrast,
				},
			};
		case "error":
			return {
				backgroundColor: error.main.background,
				color: error.main.contrast,
				":hover": {
					backgroundColor: error.dark.background,
					color: error.dark.contrast,
				},
			};
		case "warning":
			return {
				backgroundColor: warning.main.background,
				color: warning.main.contrast,
				":hover": {
					color: warning.dark.contrast,
					backgroundColor: warning.dark.background,
				},
			};

		default:
			return {
				backgroundColor: neutral.main.background,
				color: neutral.main.contrast,
				":hover": {
					backgroundColor: neutral.dark.background,
					color: neutral.dark.contrast,
				},
			};
	}
};

const getVariantStyles = (
	variant: ButtonVariant,
	tokens: DesignTokens,
): CSSObject => {
	if (variant === "outlined") {
		const { palette } = tokens;
		return {
			border: `1px solid ${palette.neutral.main.background}`,
			backgroundColor: "transparent",
			color: palette.neutral.main.background,
			":hover": {
				backgroundColor: palette.neutral.main.background,
				color: palette.neutral.main.contrast,
			},
		};
	}

	return {};
};

const getLayoutStyles = (
	props: ButtonProps,
	tokens: DesignTokens,
): CSSObject => {
	const { borders, radii } = tokens;
	const defaultLayoutStyles = {
		borderRadius: radii.md,
	};

	switch (props.layout) {
		case "squared":
			return {
				borderRadius: {
					small: radii.sm,
					regular: radii.md,
					large: radii.lg,
				}[props.size!],
			};
		case "circular":
			return {
				...defaultLayoutStyles,
				borderRadius: radii.full,
			};
		case "regular":
			return {
				...defaultLayoutStyles,
			};
	}

	return {
		...defaultLayoutStyles,
	};
};

const useButtonStyles = (props: ButtonProps): CSSObject => {
	const tokens = useDesignTokens();
	return {
		fontFamily: tokens.fonts.heading,
		fontWeight: tokens.fontWeights.medium,
		border: "none",
		cursor: "pointer",
		display: "inline-block",
		textDecoration: "none",
		padding: 0,
		outline: "none",
		transition: "transform, 200ms linear",
		":disabled": {
			opacity: 0.5,
			cursor: "not-allowed",
		},
		":focus": {
			// TODO: Change colours
			boxShadow:
				"0 0 0 1.5px hsla(0,0%,100%,.75), " +
				"inset 0 0 0 1.25px rgba(82,95,127,.5), " +
				"0 13px 27px -5px rgba(50,50,93,.25), " +
				"0 8px 16px -8px rgba(0,0,0,.3), " +
				"0 -6px 16px -6px rgba(0,0,0,.025)",
		},

		":active": {
			border: "none",
			transform: "translateY(1px)", // "scale(0.95)",
		},
		":active:focus": {
			border: "none",
			transform: "translateY(1px)", // "scale(0.95)",
		},
		...getSizeStyles(props, tokens),
		...getLayoutStyles(props, tokens), // Note: LayoutStyles need to be after SizeStyles because of padding overrides in "circular" layout
		...getColorStyles(props, tokens),
		...getVariantStyles(props.variant!, tokens),
	};
};

export { useButtonStyles };
