import { CSSObject } from "@emotion/react";
import { FormHeaderProps } from "./FormHeader";
import { useDesignTokens } from "@bilar/ui";

type FormHeaderStyles = {
	root: CSSObject;
	heading: CSSObject;
	line: CSSObject;
};

const getLineWidth = (step: number, numberOfSteps: number): string => {
	let width = 100;
	if (step / numberOfSteps > 0) {
		width = (step / numberOfSteps) * 100;
	}

	return `${width}%`;
};

const useFormHeaderStyles = (props: FormHeaderProps): FormHeaderStyles => {
	const designTokens = useDesignTokens();
	const { space, fontColors, palette } = designTokens;
	return {
		root: {
			padding: `${space[4]} ${space[4]} 0`,
		},
		heading: {
			color: fontColors.light,
		},
		line: {
			margin: `${space[1]} 0 0`,
			border: "none",
			height: space[1],
			backgroundColor: palette.neutral.lighter.background,
			position: "relative",
			"&:after": {
				content: "''",
				position: "absolute",
				top: 0,
				left: 0,
				bottom: 0,
				backgroundColor: palette.success.main.background,
				width: getLineWidth(props.step, props.numberOfSteps),
				transition: "width 300ms ease-out",
			},
		},
	};
};

export { useFormHeaderStyles };
