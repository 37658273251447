import { CSSObject } from "@emotion/react";
import { AvatarProps } from "./Avatar";
import { useDesignTokens } from "@bilar/ui";

type AvatarStyles = {
	root: CSSObject;
	img: CSSObject;
};

const useAvatarStyles = (props: AvatarProps): AvatarStyles => {
	const designTokens = useDesignTokens();
	const { space } = designTokens;
	return {
		root: {
			display: "inline-flex",
			justifyContent: props.align,
		},
		img: {
			borderRadius: "50%",
			maxHeight: space[12],
			alignSelf: "center",
			marginRight: space[4],
		},
	};
};

export { useAvatarStyles };
