import { useAdsListStyles, VehicleInfoChips } from "@bilar/features";
import {
	Heading,
	NextLink,
	Paper,
	useAppContext,
	useCalendarDate,
	useCurrency,
} from "@bilar/ui";
import { Fragment } from "react";
import { routes } from "@bilar/config";
import Image from "next/legacy/image";
import { useAppTranslation } from "@bilar/common";
import mainImagePlaceholder from "@bilar/assets/img/logo-green-car-only.svg";
import { ClassifiedVehicle } from "@bilar/models";
import Link from "next/link";

type VehicleListItemProps = {
	data: ClassifiedVehicle;
};

const VehicleListItem = (props: VehicleListItemProps) => {
	const { t } = useAppTranslation();
	const styles = useAdsListStyles(props);
	const { formatCurrency } = useCurrency();
	const { setModalVehicleId } = useAppContext();

	const {
		id,
		user,
		vehicleInfo,
		price,
		createdAt,
		expiresAt,
		description,
		mainImageUrl,
	} = props.data;

	const calendarCreatedAt = useCalendarDate(createdAt);

	const handleClick = (e: any) => {
		e.preventDefault();

		const url = `${routes.vehicleClassifieds.index}/${id}`;
		window.history.pushState({}, "", url);
		setModalVehicleId(id);
	};

	return (
		<Paper as="article" css={styles.root} id={id.toString()}>
			<div css={styles.contentArea}>
				<div css={styles.title}>
					<NextLink
						href={`${routes.vehicleClassifieds.index}/${id}`}
						onClick={handleClick}
						scroll={false}
						shallow={true}
						title={`${t("view")} ${vehicleInfo.brandName}`}
						aria-label={`${t("view")} ${vehicleInfo.brandName}`}
						css={styles.titleLink}
					>
						<Heading as="h3" size="regular">
							{vehicleInfo.brandName}
							{vehicleInfo.modelName && (
								<Fragment> - {vehicleInfo.modelName}</Fragment>
							)}
						</Heading>
					</NextLink>
				</div>

				<div css={styles.price}>
					{price?.amount && (
						<Heading as="h4">
							{t("price")}: {formatCurrency(price.amount)}
						</Heading>
					)}
				</div>

				<div css={styles.date}>
					<p css={styles.date}>
						{t("postCreatedAt")}: {calendarCreatedAt}
					</p>
				</div>

				<div css={styles.meta}>
					<VehicleInfoChips data={vehicleInfo} />
				</div>
			</div>
			<figure css={styles.mediaArea}>
				<Link
					href={`${routes.vehicleClassifieds.index}/${id}`}
					scroll={false}
					shallow={true}
					aria-label={`${t("viewPicturesOf")} ${vehicleInfo.brandName}`}
					title={`${t("view")} ${vehicleInfo.brandName}`}
					onClick={handleClick}
				>
					{mainImageUrl ? (
						<Image
							alt={`${description}`}
							src={mainImageUrl} // use normal <img> attributes as props
							css={styles.image.root}
							width={300}
							height={300 * 0.75}
							quality={50}
							layout="responsive"
							placeholder="blur"
							blurDataURL="/blur.png"
						/>
					) : (
						<div css={styles.image.placeholder}>
							<Image
								alt={`${description}`}
								src={mainImagePlaceholder}
								css={styles.image}
								width={38}
								height={32}
								quality={50}
								layout="fixed"
							/>
							<p css={styles.image.noImagesText}>No images</p>
						</div>
					)}
				</Link>
			</figure>
		</Paper>
	);
};

export { VehicleListItem };
export type { VehicleListItemProps };
