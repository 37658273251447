import { CSSObject } from "@emotion/react";
import { AppHeaderProps } from "./AppHeader";
import { useDesignTokens } from "@bilar/ui";
import logo from "@bilar/assets/img/bilar-logo-white.svg";
import { appHeaderVariables } from "../variables";

type AppHeaderStyles = {
	root: CSSObject;
	logo: LogoStyles;
	container: CSSObject;
	nav: NavStyles;
	languageMenu: LanguageMenuStyles;
	userImage: CSSObject;
};

type LogoStyles = {
	root: CSSObject;
	img: CSSObject;
};

type NavStyles = {
	root: CSSObject;
	linkText: CSSObject;
};

type LanguageMenuStyles = {
	root: CSSObject;
	list: CSSObject;
	itemText: CSSObject;
	img: CSSObject;
};

const useAppHeaderStyles = (props: AppHeaderProps): AppHeaderStyles => {
	const tokens = useDesignTokens();

	const focusColor = "#fff";

	const {
		space,
		palette,
		fontSizes,
		fontWeights,
		styles,
		mediaQueries,
		radii,
	} = tokens;
	return {
		root: {
			minHeight: space[appHeaderVariables.mobileHeightUnit],
			backgroundColor: palette.primary.main.background,

			[mediaQueries.tabletAndAbove]: {
				minHeight: space[appHeaderVariables.heightUnit],
			},
		},
		userImage: {
			borderRadius: "50%",
			maxHeight: space[12],
			alignSelf: "center",
			marginRight: space[4],
		},
		container: {
			display: "grid",
			height: "100%",
			gridTemplateColumns: `auto auto`,
			alignItems: "center",
			position: "relative",

			[mediaQueries.desktopAndAbove]: {
				gridTemplateColumns: `${space[64]} auto`,
			},
		},
		logo: {
			root: {
				width: "100%",
				display: "flex",
				height: "100%",
				alignItems: "center",
				"& a": {
					transition: "outline, 200ms linear",
					...styles.focusOutline({}, focusColor),
				},
				[mediaQueries.desktopAndAbove]: {
					marginLeft: `-${space[8]}`,
				},
			},
			img: {
				width: space[24],
				height: space[6],
				display: "block",
				backgroundImage: `url(${logo})`,
				backgroundSize: "contain",
				backgroundRepeat: "no-repeat",

				[mediaQueries.tabletAndAbove]: {
					margin: space[2],
					marginLeft: space[8],
					marginRight: space[8],
					width: space[40],
					height: space[12],
					backgroundImage: `url(${logo})`,
					backgroundSize: "100% 100%",
				},
			},
		},
		nav: {
			root: {
				// position: "fixed",
				// right: 0,
				// bottom: 0,
				// left: 0,
				// zIndex: zIndices.docked,
				// background: palette.neutral.lighter.background,

				[mediaQueries.tabletAndAbove]: {
					display: "grid",
					bottom: "auto",
					// position: "relative",
					justifyContent: "end",
					gridAutoFlow: "column",
					gap: space[2],
					alignItems: "center",
					background: "transparent",

					"& a, & button": {
						...styles.focusOutline({}, focusColor),
					},
				},

				"& a, & a:hover": {
					textDecoration: "none",
				},
			},

			linkText: {
				display: "flex",
				alignItems: "center",
				height: "100%",
				cursor: "pointer",
				backgroundColor: "transparent",
				border: "none",
				padding: `0 ${space[4]}`,
				color: palette.primary.main.contrast,
				textDecoration: "none",
				fontSize: fontSizes.lg,
				lineHeight: fontSizes.xl,
				fontWeight: fontWeights.medium,

				"&:hover": {},
			},
		},
		languageMenu: {
			root: {},
			list: {
				backgroundColor: "#E0E0E0",
			},
			img: {
				borderRadius: radii.md,
			},
			itemText: {
				padding: `0 8px`,
			},
		},
	};
};

export { useAppHeaderStyles };
