/** @jsxImportSource @emotion/react */
import * as React from "react";
import {
	forwardRef,
	HTMLAttributes,
	isValidElement,
	PropsWithChildren,
} from "react";
import { CSSObject } from "@emotion/react";
import { useListBoxButtonStyles } from "./listBoxStyles";
import { useListBoxContext } from "./ListBoxContext";
import { useMergeRefs } from "@floating-ui/react";
import { emotionClone } from "../../utils";

export type ListBoxButtonProps = PropsWithChildren<
	{
		css?: CSSObject;
	} & HTMLAttributes<HTMLDivElement>
>;
export const ListBoxButton = forwardRef<HTMLElement, ListBoxButtonProps>(
	(props, propRef) => {
		const { children, ...rest } = props;
		const { floating, interactions, disabled } = useListBoxContext();
		const styles = useListBoxButtonStyles(disabled);

		const childrenRef = (children as any).ref;
		const ref = useMergeRefs([
			floating?.refs.setReference,
			propRef,
			childrenRef,
		]);

		const elementProps: HTMLAttributes<HTMLDivElement> = {
			role: "button",
			"aria-haspopup": "listbox",
			...rest,
			...(disabled
				? {
						ref,
				  }
				: interactions?.getReferenceProps({
						ref,
				  })),
		};

		// If we pass in a custom component, we need to clone it and add the props
		if (
			floating &&
			(typeof children === "function" || typeof children === "object") &&
			isValidElement(children)
		) {
			return emotionClone(children, {
				...elementProps,
				...(children.props as {}),
				tabIndex: 0,
				css: [props.css, styles, (children.props as ListBoxButtonProps).css],
			});
		}

		return (
			<div {...elementProps} css={styles}>
				{children}
			</div>
		);
	},
);

ListBoxButton.displayName = "ListBoxButton";
