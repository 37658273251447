import { useDesignTokens } from "@bilar/ui";
import { CSSObject } from "@emotion/react";

type SharedSearchStyles = {
	fieldset: CSSObject;
	legend: CSSObject;
	radioAndCheckboxWrapper: CSSObject;
};

export const useSharedSearchStyles = (): SharedSearchStyles => {
	const { mediaQueries, space, fontWeights } = useDesignTokens();

	return {
		fieldset: {
			margin: `${space[2]} 0`,
		},
		legend: {
			fontWeight: fontWeights.medium,
			lineHeight: space[8],
		},
		radioAndCheckboxWrapper: {
			display: "grid",
			gap: space[4],
			gridTemplateColumns: "1fr 1fr",
			[mediaQueries.tabletAndAbove]: {
				gridTemplate: `
					"left right"
					"error error"
				`,
				gridTemplateColumns: "1fr 1fr",
			},
		},
	};
};
