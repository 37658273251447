/**
 * Takes in a string that should be a number and removes all commas, spaces etc.
 * Returns a number, if fails it returns NaN
 * @param numberString
 */
const parseStringToInt = (numberString: string): number => {
	// Remove any spaces + characters (for currency inputs etc.)
	let normalizedText = numberString
		.replaceAll(" ", "")
		.replaceAll(",", "")
		.replaceAll(".", "");

	return parseInt(normalizedText);
};

export { parseStringToInt };
