import { AxiosRequestConfig } from "axios";
import { getCarDataApiAxios, getMainApiAxios } from "@bilar/config";

const mainDataFetcher = async (axiosRequestConfig: AxiosRequestConfig) => {
	const customAxios = getMainApiAxios();
	const req = await customAxios(axiosRequestConfig);

	return req.data;
};

const carDataFetcher = async <T = any>(
	axiosRequestConfig: AxiosRequestConfig,
): Promise<T> => {
	const customAxios = getCarDataApiAxios();
	const req = await customAxios(axiosRequestConfig);

	return req.data;
};

export { mainDataFetcher, carDataFetcher };
