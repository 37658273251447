import { CSSObject } from "@emotion/react";
import { gridSystem, useDesignTokens } from "../../styling";

const useListBoxStyles = (width: number): CSSObject => {
	const { radii, borders, zIndices, palette, space } = useDesignTokens();

	return {
		background: palette.neutral.lighter.background,
		color: palette.neutral.lighter.contrast,
		outline: "none",
		minWidth: "min-content",
		whiteSpace: "nowrap",
		borderRadius: radii.md,
		borderColor: palette.neutral.lighter.contrast,
		borderWidth: 1,
		borderStyle: "solid",
		"[data-reach-listbox-list]": {
			cursor: "default",
		},
		zIndex: zIndices.popover,
		width: width * gridSystem.size,
	};
};

const useListBoxOptionStyles = (
	isActive: boolean,
	isSelected: boolean,
): CSSObject => {
	const { space, palette } = useDesignTokens();

	return {
		transition: "background 0.2s ease-in-out",
		cursor: "default",
		paddingLeft: space[4],
		paddingRight: space[4],
		paddingTop: space[3],
		paddingBottom: space[3],
		display: "block",
		width: "100%",
		background: "transparent",
		border: "none",
		textAlign: "left",
		...(isActive && {
			background: palette.primary.main.background,
			color: palette.primary.main.contrast,
		}),
		...(isSelected && {
			background: palette.secondary.light.background,
			color: palette.secondary.light.contrast,
		}),
		...(isActive &&
			isSelected && {
				background: palette.secondary.darker.background,
				color: palette.secondary.darker.contrast,
			}),
	};
};
const useListBoxButtonStyles = (disabled: boolean): CSSObject => {
	return {
		display: "inline-flex",
		...(disabled
			? {
					opacity: 0.5,
					cursor: "not-allowed",
			  }
			: {}),
	};
};

export { useListBoxStyles, useListBoxOptionStyles, useListBoxButtonStyles };
