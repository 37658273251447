import {
	DropdownOnChange,
	DropdownOption,
	Select,
	SelectProps,
} from "@bilar/ui";
import { useEffect, useState } from "react";
import { useAppTranslation, useGetPrices } from "@bilar/common";

type BaseSelProps = Omit<SelectProps<false>, "options" | "value">;
type PriceDropdownProps = {
	minValue: number;
	value: number | undefined;
} & BaseSelProps &
	PriceDropdownDefaultProps;

type PriceDropdownDefaultProps = {};

const defaultProps: PriceDropdownDefaultProps = {};

const PriceDropdown = (props: PriceDropdownProps) => {
	const { t } = useAppTranslation();
	const { value, minValue, onChange, ...filteredProps } = props;
	const [options, setOptions] = useState<DropdownOption[]>([]);
	const [selectedDropdownItem, setSelectedDropdownItem] =
		useState<DropdownOption | null>(null);

	const { getPrices } = useGetPrices();

	useEffect(() => {
		const prices = getPrices(minValue);
		setOptions(prices);
	}, [minValue]);

	/**
	 * When the prop value changes from the outside, we need to update the selected dropdown item.
	 */
	useEffect(() => {
		let selItem: DropdownOption | null = null;
		if (value) {
			// Get item from list if exists by value
			const item = options.find((i) => i.value === value.toString());

			selItem = item ?? null;
		}

		setSelectedDropdownItem(selItem);
	}, [options, value]);

	const handleChange: DropdownOnChange<false> = (
		selected: DropdownOption | null,
		actionMeta,
	) => {
		if (onChange) {
			onChange(selected, actionMeta);
		}
	};

	return (
		<Select
			placeholder={t("selectPrice")}
			options={options}
			value={selectedDropdownItem}
			isSearchable={props.isSearchable ?? false}
			isClearable={true}
			autoFocus={false}
			onChange={handleChange}
			{...filteredProps}
		/>
	);
};

PriceDropdown.defaultProps = defaultProps;

export { PriceDropdown };
export type { PriceDropdownProps };
