import { DisplayImage, VehicleImage } from "@bilar/models";
import { GalleryImage } from "@bilar/ui";
import { getProcessedImageUrl, imageSizeConfig } from "@bilar/utils";
import { OldGalleryImage } from "@bilar/features";

export const getGalleryImages = (images: VehicleImage[]): GalleryImage[] => {
	const imageList: GalleryImage[] = [];
	const sortedImages = images.sort((a, b) => a.orderNumber - b.orderNumber);

	for (const vehicleImage of sortedImages) {
		const img = vehicleImage.processed ?? vehicleImage.original;

		const galleryImage: GalleryImage = {
			orderNumber: vehicleImage.orderNumber,
			url: img.downloadUrl,
			alt: "",
			width: img.width,
			height: img.height,
			blurDataUrl: img.blurDataUrl,
		};

		imageList.push(galleryImage);
	}

	return imageList;
};

/**
 * For the old ImageGallery component
 * @param images
 * @param processImages
 */
export const getOldGalleryImages = (
	images: VehicleImage[],
	processImages: boolean = true,
) => {
	const imageList: OldGalleryImage[] = [];
	const sortedImages = images.sort((a, b) => a.orderNumber - b.orderNumber);

	for (const vehicleImage of sortedImages) {
		const img = vehicleImage.processed ?? vehicleImage.original;

		let thumbnailUrl = img.downloadUrl;
		let mediumUrl = img.downloadUrl;
		let fullScreenUrl = img.downloadUrl;

		if (processImages) {
			thumbnailUrl = getProcessedImageUrl(
				img.downloadUrl,
				imageSizeConfig.thumbnail.width,
			);
			mediumUrl = getProcessedImageUrl(
				img.downloadUrl,
				imageSizeConfig.original.width,
			);
			fullScreenUrl = getProcessedImageUrl(
				img.downloadUrl,
				imageSizeConfig.fullScreen.width,
			);
		}

		const thumbnailImg: DisplayImage = {
			...img,
			downloadUrl: thumbnailUrl,
			orderNumber: vehicleImage.orderNumber,
		};

		const mediumImg: DisplayImage = {
			...img,
			downloadUrl: mediumUrl,
			orderNumber: vehicleImage.orderNumber,
		};

		const fullScreenImg: DisplayImage = {
			...img,
			downloadUrl: fullScreenUrl,
			orderNumber: vehicleImage.orderNumber,
		};

		const galleryImage: OldGalleryImage = {
			thumbnail: thumbnailImg,
			medium: mediumImg,
			fullScreen: fullScreenImg,
		};

		imageList.push(galleryImage);
	}

	return imageList;
};
