import { motion } from "framer-motion";
import React from "react";
import { BaseLoadingProps } from "./index";
import { CSSObject } from "@emotion/react";
import { useDesignTokens } from "@bilar/ui";

const containerVariants = {
	initial: {
		transition: {
			staggerChildren: 0.2,
		},
	},
	animate: {
		transition: {
			staggerChildren: 0.2,
		},
	},
};

const dotVariants = {
	initial: {
		y: "0%",
	},
	animate: {
		y: "100%",
	},
};

const dotTransition = {
	duration: 0.5,
	yoyo: Infinity,
	ease: "easeInOut",
};

type Styles = {
	root: CSSObject;
	loadingContainer: CSSObject;
	loadingDot: CSSObject;
};

// TODO: Implement size from props
const useThreeDotsStyles = (props: ThreeDotsWaveProps): Styles => {
	const designTokens = useDesignTokens();
	const { palette, space } = designTokens;
	return {
		root: {
			width: "100%",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		loadingContainer: {
			margin: `${space[4]} 0`,
			width: "10rem",
			height: "4rem",
			display: "flex",
			justifyContent: "space-around",
		},
		loadingDot: {
			display: "block",
			width: "2rem",
			height: "2rem",
			backgroundColor: palette.primary.main.background,
			borderRadius: "50%",
		},
	};
};

type ThreeDotsWaveProps = {} & BaseLoadingProps;

const ThreeDotsWave = (props: ThreeDotsWaveProps) => {
	const styles = useThreeDotsStyles(props);
	return (
		<div css={styles.root}>
			<motion.div
				css={styles.loadingContainer}
				variants={containerVariants}
				initial="initial"
				animate="animate"
			>
				<motion.span
					css={styles.loadingDot}
					variants={dotVariants}
					transition={dotTransition}
				/>
				<motion.span
					css={styles.loadingDot}
					variants={dotVariants}
					transition={dotTransition}
				/>
				<motion.span
					css={styles.loadingDot}
					variants={dotVariants}
					transition={dotTransition}
				/>
			</motion.div>
		</div>
	);
};

export { ThreeDotsWave };
